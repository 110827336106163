import { useMutationLockUser, useMutationUnlockUser } from '@coop/apollo'
import { Button, Col, Divider, Drawer, message, Modal, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'
// @ts-ignore
import tw from 'tailwind.macro'
import { FirebaseContext } from '../../contexts/FirebaseContext'
import { useSource } from '../../contexts/SourceContext'
import { CompletedOrderList } from '../../shared/components/CompletedOrderList'
import { useLazyQueryCompletedOrders } from '../../shared/hooks/apollo'
import { User } from '../../shared/model'

const { confirm } = Modal

const StyledDrawer = styled(Drawer).attrs({ className: 'text-gray-900' })`
  .ant-drawer-header {
    .ant-drawer-title {
      ${tw`text-center`}
    }
    button {
      ${tw`left-0 right-auto`}
    }
    .ant-drawer-body {
      ${tw`flex flex-col h-full`}
    }
  }
`

const Title = styled('p').attrs({ className: 'text-lg font-normal' })``
const StyledRow = styled(Row).attrs({ className: 'mb-6' })``
const Label = styled('p').attrs({ className: 'uppercase font-normal text-gray-500' })``

type ItemProps = {
  label: string
  text: string
}

const Item: React.FC<ItemProps> = ({ label, text }) => (
  <Col span={12}>
    <Label>{label}</Label>
    <p>{text}</p>
  </Col>
)

const PAGE_SIZE = 10

type Props = {
  customer: User
  visible: boolean
  onClose: () => void
  onClickSendNotification: () => void
  onClickOpenCreateOrder: () => void
}

export const CustomerDrawer: React.FC<Props> = ({
  customer,
  visible,
  onClose,
  onClickSendNotification,
  onClickOpenCreateOrder,
}) => {
  const { source } = useSource()
  const variables = { pageSize: PAGE_SIZE, customerId: customer.id, ...(source && { sourceCode: source.source_code }) }

  const [lockUser] = useMutationLockUser()
  const [unlockUser] = useMutationUnlockUser()
  const [getCompletedOrders, { data, loading: loadingOrders, fetchMore }] = useLazyQueryCompletedOrders({
    variables,
  })

  const [loading, setLoading] = React.useState(false)
  const { functions } = React.useContext(FirebaseContext)

  const handleUnlock = () => {
    const reactivateUser = functions.httpsCallable('firestore-reactivateUser')
    setLoading(true)
    unlockUser({ variables: { uid: customer.id } })
      .then(() => {
        reactivateUser({ uid: customer.id })
          .then(() => message.success('Đã mở khóa tài khoản khách hàng!'))
          .catch(e => message.error(`Đã có lỗi xảy ra! ${e.message}`))
          .finally(() => {
            onClose()
            setLoading(false)
          })
      })
      .catch(error => message.error('Đã có lỗi xảy ra', error.message))
  }

  const handleLock = () => {
    const deactivateUser = functions.httpsCallable('firestore-deactivateUser')
    setLoading(true)
    lockUser({ variables: { uid: customer.id } })
      .then(() => {
        deactivateUser({ uid: customer.id })
          .then(() => message.success('Đã khóa tài khoản khách hàng!'))
          .catch(e => message.error(`Đã có lỗi xảy ra! ${e.message}`))
          .finally(() => {
            onClose()
            setLoading(false)
          })
      })
      .catch(error => message.error('Đã có lỗi xảy ra', error.message))
  }

  const onLockClick = () => {
    confirm({
      title: 'Bạn có đồng ý khóa tài khoản khách hàng?',
      okText: 'Khóa',
      okType: 'danger',
      cancelText: 'Hủy',
      onOk: handleLock,
    })
  }

  const onUnlockClick = () => {
    confirm({
      title: 'Bạn có đồng ý mở khóa tài khoản khách hàng?',
      okText: 'Mở khóa',
      okType: 'primary',
      cancelText: 'Hủy',
      onOk: handleUnlock,
    })
  }

  React.useEffect(() => {
    getCompletedOrders()
  }, [getCompletedOrders])

  return (
    <StyledDrawer
      width={400}
      visible={visible}
      onClose={onClose}
      closable={true}
      keyboard={true}
      title={customer.name}
      placement="right">
      <StyledRow>
        <Title>Thông tin</Title>
      </StyledRow>
      <StyledRow>
        <Item label="HỌ VÀ TÊN" text={customer.name || ''} />
        <Item label="SỐ ĐIỆN THOẠI" text={customer.phone} />
      </StyledRow>
      <StyledRow>
        <Button type="primary" ghost className="flex-1 px-6 py-3 h-auto mr-2" onClick={onClickSendNotification}>
          Gửi thông báo
        </Button>
        {customer.disabled ? (
          <Button
            type="primary"
            className="flex-1 px-6 py-3 h-auto bg-green-500 border-0 hover:bg-green-400 focus:bg-green-400"
            loading={loading}
            onClick={onUnlockClick}>
            Mở khóa tài khoản
          </Button>
        ) : (
          <Button type="primary" danger className="flex-1 px-6 py-3 h-auto" loading={loading} onClick={onLockClick}>
            Khóa tài khoản
          </Button>
        )}
        {isActiveCustomer(customer) && (
          <>
            <Divider />
            <Button
              type="primary"
              ghost
              className="flex-1 px-6 py-3 h-auto"
              loading={loading}
              onClick={onClickOpenCreateOrder}>
              Tạo đơn hàng
            </Button>
          </>
        )}
      </StyledRow>

      <Divider />

      <CompletedOrderList title="Đơn hàng đã đặt" {...{ data, loading: loadingOrders, fetchMore }} />
    </StyledDrawer>
  )
}

const isActiveCustomer = (customer: User) => {
  return !customer.disabled && !!customer.phone && !!customer.name && !!customer.email
}
