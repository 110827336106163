import React from 'react'

type WindowSizeContext = {
  height: number
}

const WindowSizeContext = React.createContext<WindowSizeContext | string>(
  'useWindowSize should be used inside WindowSizeProvider',
)

export const WindowSizeProvider: React.FC = ({ children }) => {
  const [height, setHeight] = React.useState(window.innerHeight)

  React.useEffect(() => {
    const updateHeight = () => setHeight(window.innerHeight)
    window.addEventListener('orientationchange', updateHeight)
    window.addEventListener('resize', updateHeight)

    return () => {
      window.removeEventListener('orientationchange', updateHeight)
      window.removeEventListener('resize', updateHeight)
    }
  }, [setHeight])

  const value = {
    height,
  }

  return <WindowSizeContext.Provider {...{ value, children }} />
}

export const useWindowSize = () => {
  const context = React.useContext(WindowSizeContext)
  if (typeof context === 'string') {
    throw Error(context)
  }
  return context
}
