import { ApolloProvider } from '@apollo/react-hooks'
import { createApolloClient } from '@coop/apollo'
import { Spin } from 'antd'
import React from 'react'
import env from '../shared/Env'
import { useAuth } from './AuthContext'

const Url = env.serverUrl + '/rest'

export const ApolloClientProvider: React.FC<{}> = ({ children }) => {
  const [loading, setLoading] = React.useState(true)
  const { user } = useAuth()
  const [client, setClient] = React.useState(createApolloClient(Url))

  React.useEffect(() => {
    const createClientWithToken = async () => {
      try {
        setLoading(true)
        const token = await user?.getIdToken()
        setClient(createApolloClient(Url, token))
      } catch {
      } finally {
        setLoading(false)
      }
    }
    createClientWithToken()
  }, [user])

  if (loading) {
    return (
      <div className="w-full min-h-screen flex items-center justify-center">
        <Spin tip="Loading..." />
      </div>
    )
  }
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
