import { FcmTopics } from '@coop/common'
import React from 'react'
import styled from 'styled-components'
import PageTitle from '../../shared/components/PageTitle'
import { NotificationPage } from './NotificationPage'

const Wrapper = styled.div``
const Content = styled.div``

// TODO: get Topics from Magento / Firestore
const notifications = {
  drivers: [
    {
      id: 'Tất cả tài xế',
      name: 'Tất cả tài xế',
      description: 'Tất cả tài xế của G1 Mart',
      topic: FcmTopics.G1Drivers,
    },
  ],
  customers: [
    {
      id: 'Tất cả khách hàng',
      name: 'Tất cả khách hàng',
      description: 'Tất cả khách hàng của G1 Mart',
      topic: FcmTopics.G1Customers,
    },
  ],
  pickers: [
    {
      id: 'Tất cả Nhân viên gom hàng',
      name: 'Tất cả Nhân viên gom hàng',
      description: 'Tất cả Nhân viên gom hàng của G1 Mart',
      topic: FcmTopics.G1Pickers,
    },
  ],
}

export const Notifications: React.FC = () => {
  return (
    <Wrapper className="w-full flex flex-1">
      <Content className="flex flex-1 flex-col">
        <div className="w-full flex justify-start items-center mb-10">
          <PageTitle title="Thông báo" description="3 nhóm" />
        </div>
        <NotificationPage
          notifications={[...notifications.drivers, ...notifications.customers, ...notifications.pickers]}
        />
      </Content>
    </Wrapper>
  )
}
