import React from 'react'
import styled from 'styled-components'

const StyledBtn = styled('button')`
  :hover {
    color: #ec145c;
    border: 1px solid #ec145c;
  }
`

type Props = {
  onClick?: () => void
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const Button: React.FC<Props> = ({ onClick, children, disabled = false, ...restProps }) => {
  return (
    <StyledBtn
      {...restProps}
      disabled={disabled}
      className={`px-6 py-3 text-white hover:bg-gray-900 rounded-md border border-white ${
        disabled ? 'bg-gray-900 opacity-50 cursor-not-allowed' : 'bg-black'
      }`}
      onClick={onClick}>
      {children}
    </StyledBtn>
  )
}

export default Button
