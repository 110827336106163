import { EmployeeType, WorkingHistoryType } from '@coop/common'
import { Button, Col, Divider, Drawer, Row, Spin } from 'antd'
import React from 'react'
import styled from 'styled-components'
// @ts-ignore
import tw from 'tailwind.macro'
import { useFirestore } from '../../contexts/FirestoreContext'
import { useSource } from '../../contexts/SourceContext'
import { WorkHistory } from '../../shared/components'
import { CompletedOrderList } from '../../shared/components/CompletedOrderList'
import { useLazyQueryCompletedOrders } from '../../shared/hooks/apollo'
import { Picker } from '../../shared/model'

const StyledDrawer = styled(Drawer).attrs({ className: 'text-gray-900' })`
  .ant-drawer-header {
    .ant-drawer-title {
      ${tw`text-center`}
    }
    button {
      ${tw`left-0 right-auto`}
    }
  }
  .ant-drawer-body {
    ${tw`flex flex-col h-full`}
  }
`

const StyledRow = styled(Row).attrs({ className: 'mb-6' })``

const Label = styled('span').attrs({ className: 'uppercase font-normal text-gray-500' })``

type ItemProps = {
  label: string
  text: string
}

const Item: React.FC<ItemProps> = ({ label, text }) => (
  <Col span={12}>
    <Label>{label}</Label>
    <p>{text}</p>
  </Col>
)

const PAGE_SIZE = 2

type Props = {
  visible: boolean
  onClose: () => void
  picker: Picker
  handleLockAccount: (picker: Picker) => Promise<void>
  handleUnlockAccount: (picker: Picker) => Promise<void>
  handleClickEdit: (picker: Picker) => void
}

export const PickerDrawer: React.FC<Props> = ({
  visible,
  onClose,
  picker,
  handleLockAccount,
  handleUnlockAccount,
  handleClickEdit,
}) => {
  const [declinedTotal, setDeclinedTotal] = React.useState(0)
  const { Data, Util } = useFirestore()
  React.useEffect(() => {
    const unsubDeclined = Data.workingHistory(Data.picker().doc(picker.id)).onSnapshot(snapshot =>
      setDeclinedTotal(
        snapshot.docs
          .map(doc => doc.data() as WorkingHistoryType)
          .reduce((accum, item) => accum + (item.canceledOrder || 0), 0),
      ),
    )

    return () => {
      unsubDeclined()
    }
  }, [Data, Util, picker.id])

  const { source } = useSource()
  const variables = React.useMemo(
    () => ({ pageSize: PAGE_SIZE, pickerId: picker.id, ...(source && { sourceCode: source.source_code }) }),
    [picker.id, source],
  )
  const [getCompletedOrders, { data: ordersData, loading: loadingOrders, fetchMore }] = useLazyQueryCompletedOrders({
    variables,
  })
  React.useEffect(() => {
    getCompletedOrders()
  }, [getCompletedOrders])

  const [loading, setLoading] = React.useState(false)
  const onClickLock = () => {
    setLoading(true)
    handleLockAccount(picker).finally(() => setLoading(false))
  }
  const onClickUnlock = () => {
    setLoading(true)
    handleUnlockAccount(picker).finally(() => setLoading(false))
  }

  return (
    <StyledDrawer
      width={400}
      visible={visible}
      onClose={onClose}
      closable={true}
      keyboard={true}
      title={picker.name}
      placement="right">
      <StyledRow>
        <WorkHistory value={loadingOrders ? <Spin /> : ordersData?.completedOrders.total_count || 0} />
        <div className="w-4" />
        <WorkHistory type="declined" value={declinedTotal} />
      </StyledRow>
      <StyledRow>
        <Item label="HỌ VÀ TÊN" text={picker.name} />
        <Item label="SỐ ĐIỆN THOẠI" text={picker.phone} />
      </StyledRow>
      <StyledRow>
        <Item label="VAI TRÒ" text={picker.type === EmployeeType.Cashier ? 'Thanh toán' : 'Gom hàng'} />
      </StyledRow>
      <StyledRow>
        <Button type="primary" ghost className="flex-1 py-3 h-auto mr-2" onClick={() => handleClickEdit(picker)}>
          Sửa thông tin
        </Button>
        {picker.disabled ? (
          <Button
            type="primary"
            loading={loading}
            className="flex-1 py-3 h-auto bg-green-500 border-0 hover:bg-green-400 focus:bg-green-400"
            onClick={onClickUnlock}>
            Mở khóa tài khoản
          </Button>
        ) : (
          <Button danger type="primary" loading={loading} className="flex-1 py-3 h-auto" onClick={onClickLock}>
            Khóa tài khoản
          </Button>
        )}
      </StyledRow>

      <Divider />

      <CompletedOrderList
        title="Đơn hàng đã thực hiện"
        data={ordersData}
        loading={loadingOrders}
        fetchMore={fetchMore}
      />
    </StyledDrawer>
  )
}
