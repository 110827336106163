import { Product } from '@coop/apollo'
import { OrderItem, OrderStatus } from '@coop/common'
import firebase from 'firebase'
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber'
import moment from 'moment'
import env from '../Env'
import { GeoType, Order } from '../model'

export const geopointToMapPosition = (point?: GeoType): google.maps.LatLngLiteral | undefined =>
  point
    ? {
        lat: point.latitude,
        lng: point.longitude,
      }
    : undefined

export const getOrderPrice = (order: Order): number =>
  order.products?.reduce(
    (sum: number, { product, amount }: Omit<OrderItem<firebase.firestore.DocumentReference>, 'productRef'>) =>
      sum + product.price * amount,
    (order.fee || 0) - (order.discount || 0),
  )

const ICON_SIZE = 64

export const scaledIcon = (iconUrl: string) => ({
  url: iconUrl,
  // eslint-disable-next-line no-undef
  anchor: new google.maps.Point(ICON_SIZE / 2, ICON_SIZE),
  // eslint-disable-next-line no-undef
  scaledSize: new google.maps.Size(ICON_SIZE, ICON_SIZE),
})

export const validatePhoneNumber = (phone?: string) => {
  try {
    const util = PhoneNumberUtil.getInstance()
    const number = util.parse(phone, 'VN')
    return util.isValidNumber(number)
  } catch {
    return false
  }
}

export const formatPhoneNumber = (phone: string, format: PhoneNumberFormat = PhoneNumberFormat.E164) => {
  try {
    const util = PhoneNumberUtil.getInstance()
    const number = util.parse(phone, 'VN')
    return util.format(number, format)
  } catch {
    return phone
  }
}

export const orderStatusDescription = (status?: OrderStatus): string | null => {
  switch (status) {
    case OrderStatus.ReadyToPick:
      return 'Đơn hàng mới'
    case OrderStatus.Pick:
      return 'Đang gom hàng'
    case OrderStatus.Delivering:
      return 'Đang giao hàng'
    case OrderStatus.Complete:
      return 'Hoàn thành'
    case OrderStatus.Cancel:
      return 'Đã hủy'
    default:
      return null
  }
}

export const currencyFormat = (value: number) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')

export const formatUrl = (value?: string | null) => {
  if (!value) {
    return ''
  }
  if (value.startsWith('http')) {
    return value
  }
  return env.serverUrl + value
}

export const formatDate = (value: number) => new Date(value).toLocaleDateString('en-GB')
export const dateFormat = 'DD-MM-YYYY'
export const yearFirstFormat = 'YYYY-MM-DD'
export const offsetUTC = (date: string, offset: string) =>
  moment.utc(date).utcOffset(offset).format('DD-MM-YYYY HH:mm:ss')

export function checkIfOutOfStock(product: Product) {
  return !(product.status === 1 || product.status === 2) || product.salable === 0
}
