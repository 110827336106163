import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div``

type Props = {
  title: string
  description?: string
}

const PageTitle: React.FC<Props> = ({ title, description }) => {
  return (
    <Wrapper>
      <div className="text-black text-2xl">{title}</div>
      <span className="text-gray-500 text-base">{description}</span>
    </Wrapper>
  )
}

export default PageTitle
