import { Col, Row } from 'antd'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useAbility } from 'src/shared/accessControl'
import ReportPage from './ReportPage/ReportPage'

const Report: React.FC<{}> = () => {
  const ability = useAbility()

  return (
    <Row className="flex w-full">
      <Col span={24}>
        <Switch>
          {ability.can('read', '/report') && <Route exact path="/report" component={ReportPage} />}
          <Redirect to="/report" />
        </Switch>
      </Col>
    </Row>
  )
}

export default Report
