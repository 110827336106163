import { SearchOutlined } from '@ant-design/icons'
import { Button, Input } from 'antd'
import React, { useState } from 'react'
import { useOrders } from 'src/contexts/OrderContext'

interface orderFilterBarTypes {
  wrapStyle: React.CSSProperties
}

const OrderFilterBar = ({ wrapStyle }: orderFilterBarTypes) => {
  const { setFilterOrderText } = useOrders()

  const [showSearchState, setShowSearchState] = useState<boolean>(true)

  let timeout = 0

  const handleFilter = (ele: any) => {
    const value = ele.target.value.toLocaleLowerCase('vi')

    if (timeout) {
      clearTimeout(timeout)
    }

    timeout = setTimeout(() => {
      setFilterOrderText(value)
    }, 500)
  }

  React.useEffect(() => {
    return () => setFilterOrderText('')
  }, [setFilterOrderText])

  return (
    <div style={{ display: 'flex', ...wrapStyle }}>
      <Input
        placeholder="Nhập mã đơn hàng / số điện thoại / tên khách hàng để tìm ..."
        style={{
          borderRadius: 18,
          paddingRight: showSearchState ? 44 : 23,
          width: showSearchState ? 400 : 0,
          border: 'solid 1px #1890ff',
          transition: 'width 0.5s',
        }}
        onChange={ele => handleFilter(ele)}
      />

      <Button
        type="primary"
        size="large"
        shape="circle"
        style={{ marginLeft: -36 }}
        icon={<SearchOutlined style={{ fontSize: 24 }} />}
        onClick={() => setShowSearchState(!showSearchState)}
      />
    </div>
  )
}

export default OrderFilterBar
