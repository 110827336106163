import React from 'react'
import styled from 'styled-components'
import { TableWrapper } from '../../shared/components'
import PageTitle from '../../shared/components/PageTitle'
import SideMenu from '../../shared/components/SideMenu'
import FiltersGroup, { FilterInputType } from './FiltersGroup'

const Wrapper = styled.div``
const Header = styled.div``
const Content = styled.div``
const SideMenuWrapper = styled.div.attrs({ className: 'flex-initial w-1/6 h-full mr-12' })``

type Action = { type: keyof FilterInputType; payload: string | undefined }

function filterReducer(state: FilterInputType, action: Action) {
  switch (action.type) {
    case 'text':
      return { ...state, text: action.payload }
    default:
      return state
  }
}

type Props = {}

const Orders: React.FC<Props> = () => {
  const [sideFilter, setSideFilter] = React.useState<'ongoing' | 'done'>('ongoing')
  const [filterInput, dispatchFilterInput] = React.useReducer<React.Reducer<FilterInputType, Action>>(filterReducer, {
    text: '',
  })

  const sideMenuItems = [
    {
      title: 'Đang diễn ra',
      description: '99+',
      onClick: () => setSideFilter('ongoing'),
      isActive: sideFilter === 'ongoing',
    },
    {
      title: 'Đã hoàn thành',
      description: '99+',
      onClick: () => setSideFilter('done'),
      isActive: sideFilter === 'done',
    },
  ]

  const handleFilterChange = (type: keyof FilterInputType, value: string | undefined) => {
    dispatchFilterInput({ type, payload: value })
  }

  return (
    <Wrapper className="h-full w-full flex flex-col">
      <Content className="flex flex-1">
        <SideMenuWrapper>
          <Header className="flex justify-between items-center mb-8">
            <PageTitle title="Đơn hàng" description="100,000 đơn hàng đã giao thành công" />
          </Header>
          <SideMenu data={sideMenuItems} type="state" />
        </SideMenuWrapper>
        <TableWrapper className="flex-1">
          <FiltersGroup searchValue={filterInput} onValueChange={handleFilterChange} />
          {/* {sideFilter === 'ongoing' ? <OngoingOrders {...{ filterInput }} /> : <FinishedOrders {...{ filterInput }} />} */}
        </TableWrapper>
      </Content>
    </Wrapper>
  )
}

export default Orders
