import { EnvironmentFilled, PhoneFilled, WarningOutlined } from '@ant-design/icons'
import { ManagerRole, ProcessStatus, TransportStatus } from '@coop/common'
import { GoogleMap } from '@react-google-maps/api'
import { Button, Form, Input, message, Modal as AntdModal, Popconfirm, Spin } from 'antd'
import { PhoneNumberFormat } from 'google-libphonenumber'
import React from 'react'
import PerfectScrollBar from 'react-perfect-scrollbar'
import { useAuth } from 'src/contexts/AuthContext'
import { useFirebase } from 'src/contexts/FirebaseContext'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { AssignPicker } from '.'
import { useFirestore } from '../../../contexts/FirestoreContext'
import { useMap } from '../../../contexts/MapContext'
import { useSource } from '../../../contexts/SourceContext'
import Modal, { ModalHeader } from '../../../shared/components/Modal'
import { Money } from '../../../shared/components/Money'
import { Order, OrderItem, SystemOrder, Tracking } from '../../../shared/model'
import { formatPhoneNumber } from '../../../shared/utils'
import ModalCancelOrder from './ModalCancelOrder'
import ModalReOrderTransport from './ModalReOrderTransport'
import { OrderLogs } from './OrderLogs'
import { OrdersMap } from './OrdersMap'
import { paymentMethodToText } from './PaymentMethod'
import { ReturnOrder } from './ReturnOrder'

const Row = styled('div')<{ bottom?: boolean }>`
  ${tw`flex items-center justify-between py-4`}
  ${props => props.bottom && tw`border-b border-gray-300`}
`

const Name = styled('span')`
  ${tw`text-gray-500 uppercase`}
`

const Value = styled('span')`
  ${tw`text-gray-900`}
`

const Container = styled('div')`
  ${tw`flex flex-col items-stretch`}
`

const Info: React.FC<{ name: string; value: string | number | undefined }> = ({ name, value }) => (
  <div className="flex flex-col">
    <Name>{name}</Name>
    <Value>{value}</Value>
  </div>
)

type Props = {
  isOpen: boolean
  onClose: () => void
  systemOrderId: SystemOrder['id']
}

const Item = ({
  item,
  isItemRemoved,
  isAmountModified,
  lastItem,
}: {
  item: OrderItem
  isAmountModified?: boolean
  isItemRemoved?: boolean
  lastItem: boolean
}) => (
  <div key={item.product.sku} className={`py-3 w-full flex items-stretch ${!lastItem && 'border-b border-gray-200'}`}>
    <span
      style={{ minWidth: 24, textAlign: 'right', position: 'relative' }}
      className="flex items-center text-gray-900 text-lg mr-2">
      {!isItemRemoved && (
        <>
          <span style={isAmountModified ? { position: 'absolute', top: 0, left: 0 } : {}}>{item.amount}</span>
          {isAmountModified && (
            <>
              <span style={{ transform: 'skew(-45deg)', position: 'absolute', top: '10px', left: '10px' }}>|</span>
              <span style={{ position: 'absolute', right: 0, bottom: 0, fontSize: 14 }} className="text-gray-600">
                {item.baseAmount}
              </span>
            </>
          )}
        </>
      )}
    </span>
    <img
      src={item.product.image}
      alt=""
      className="h-full mr-4 object-contain rounded-md border border-gray-300"
      style={{ width: '48px' }}
    />
    <div className="flex flex-col flex-1 justify-center">
      <p className="text-gray-900">{item.product.name}</p>
      <p className="text-gray-700">{item.product.sku}</p>
    </div>
    {!isItemRemoved && (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Money value={item.amount * item.product.price} className="font-semibold" />
        {isAmountModified && item.baseAmount && (
          <Money value={item.baseAmount * item.product.price} className="text-gray-600 line-through" />
        )}
      </div>
    )}
  </div>
)
const ItemsList = ({ products }: { products: Order['products'] }) => {
  const removedProducts = products?.filter(p => p.amount === 0 && p.baseAmount !== 0) || []
  const remainProducts = products?.filter(p => p.amount > 0) || []

  return (
    <div>
      {remainProducts.map((p, i) => (
        <Item
          key={p.product.sku}
          item={p}
          isAmountModified={!!p.baseAmount && p.baseAmount !== p.amount}
          lastItem={i === remainProducts.length - 1}
        />
      ))}
      {!!removedProducts.length && (
        <div className="text-gray-500 uppercase mt-4 pt-4 border-t border-gray-300">Hết hàng</div>
      )}
      {removedProducts.map((p, i) => (
        <Item key={p.product.sku} item={p} isItemRemoved lastItem={i === removedProducts.length - 1} />
      ))}
    </div>
  )
}

export const OrderModal: React.FC<Props> = ({ isOpen, onClose, systemOrderId }) => {
  const [systemOrder, setSystemOrder] = React.useState<SystemOrder>()
  const [order, setOrder] = React.useState<Order & { subPaymentMethod?: string }>()
  const [loaded, setLoaded] = React.useState(false)
  const [trackings, setTrackings] = React.useState<Tracking[]>([])
  const [cancelLoading, setCancelLoading] = React.useState(false)
  const [confirmCancelOrder, setConfirmCancelOrder] = React.useState(false)
  const [confirmCancelTransport, setConfirmCancelTransport] = React.useState(false)
  const [reOrderTransportVisible, setReOrderTransportVisible] = React.useState(false)
  const [formCancel] = Form.useForm()

  const { functions } = useFirebase()
  const { me } = useAuth()

  const { Data, Util } = useFirestore()

  const isAdminOrManager = me?.role === ManagerRole.Admin || me?.role === ManagerRole.Manager
  const isCancelOrder =
    systemOrder &&
    isAdminOrManager &&
    [ProcessStatus.Pending, ProcessStatus.ReadyToPick, ProcessStatus.Picking, ProcessStatus.ReadyToDeliver].includes(
      systemOrder.status,
    )

  const isShowReturnOrder = systemOrder && isAdminOrManager && ProcessStatus.Returning === systemOrder.status

  React.useEffect(() => {
    const unsubSystemOrder = Data.systemOrder()
      .doc(systemOrderId)
      .onSnapshot(snapshot => setSystemOrder(snapshot.exists ? Util.convert<SystemOrder>(snapshot) : undefined))

    const unsubOrder = Data.order()
      .doc(systemOrderId)
      .onSnapshot(snapshot => {
        setOrder(snapshot.exists ? Util.convert<Order>(snapshot) : undefined)
        setLoaded(true)
      })

    const unsubTracking = Data.tracking(Data.order().doc(systemOrderId)).onSnapshot(snapshot =>
      setTrackings(snapshot.docs.map(doc => Util.convert<Tracking>(doc))),
    )

    return () => {
      unsubSystemOrder()
      unsubOrder()
      unsubTracking()
    }
  }, [Data, Util, systemOrderId])

  const { isLoaded } = useMap()
  const [currentLocation, setCurrentLocation] = React.useState<google.maps.LatLngLiteral>()
  const { source } = useSource()
  React.useEffect(() => {
    source?.latitude && source.longitude && setCurrentLocation({ lat: source.latitude, lng: source.longitude })
  }, [source])

  const title = (
    <p className="text-black">
      Đơn hàng <span className="text-gray-500">{order?.id}</span>
    </p>
  )

  const [showReturnModal, setShowReturnModal] = React.useState(false)

  const tracking = (trackings.length && trackings[trackings.length - 1].tracking) || undefined

  React.useEffect(() => {
    if (loaded && !order) {
      onClose()
    }
  }, [loaded, onClose, order])

  const showMap =
    isLoaded &&
    tracking &&
    systemOrder &&
    [ProcessStatus.Delivering, ProcessStatus.Returning].includes(systemOrder.status)

  const showDriverNoTracking =
    !tracking && systemOrder && [ProcessStatus.Delivering, ProcessStatus.Returning].includes(systemOrder.status)

  const showSharedShipping = systemOrder && systemOrder.transport && !!systemOrder.transport.sharedLink

  const isDriverRejected =
    systemOrder && systemOrder.transport && systemOrder.transport.lastStatus === TransportStatus.CANCELLED
  const canCancelTransportOrder =
    systemOrder &&
    systemOrder.transport &&
    !isDriverRejected &&
    [ProcessStatus.ReadyToPick, ProcessStatus.Picking, ProcessStatus.ReadyToDeliver].includes(systemOrder.status) &&
    isAdminOrManager

  const validateForm = async () => {
    try {
      const value = await formCancel.validateFields()
      if (value?.reason) {
        return value?.reason
      }
      return undefined
    } catch (err) {
      return undefined
    }
  }

  const cancelTransportOrder = async () => {
    try {
      const reason = await validateForm()
      if (!reason) {
        return
      }
      setConfirmCancelTransport(false)
      if (!order || !order.transport) {
        throw new Error('')
      }

      setReOrderTransportVisible(false)
      const cancel = functions.httpsCallable('transport-cancelOrder')
      const { transport } = order
      setCancelLoading(true)
      await cancel({
        partner: transport?.type.toLowerCase(),
        orderId: transport?.id,
        comment: reason,
      })
      message.success('Hủy đơn giao hàng thành công!')
    } catch (error) {
      message.error('Hủy đơn thất bại, vùi lòng kiếm tra lại!!')
    } finally {
      setCancelLoading(false)
    }
  }

  const cancelTransport = () => {
    setConfirmCancelTransport(true)
  }

  return (
    <Modal isFullScreen={true} isOpen={isOpen} title={title} onRequestClose={onClose}>
      <AntdModal
        visible={confirmCancelTransport}
        title="Xác nhận hủy đơn vận chuyển"
        onOk={cancelTransportOrder}
        okText="Hủy đơn vận chuyển"
        okType="danger"
        onCancel={() => setConfirmCancelTransport(false)}
        cancelText="Đóng">
        <Form form={formCancel} layout="vertical">
          <Form.Item required name="reason" rules={[{ required: true, message: 'Vui lòng nhập lý do hủy!' }]}>
            <Input.TextArea placeholder="Lý do hủy" />
          </Form.Item>
        </Form>
      </AntdModal>

      {order && (
        <ModalReOrderTransport
          visible={reOrderTransportVisible}
          onClose={() => setReOrderTransportVisible(false)}
          order={order}
        />
      )}

      {order && (
        <div className="flex-1 flex flex-col">
          <ModalHeader
            title={title}
            actionRenderer={
              <>
                {isCancelOrder && (
                  <Button type="primary" danger className="mr-8 rounded-md" onClick={() => setConfirmCancelOrder(true)}>
                    Hủy đơn
                  </Button>
                )}
                {isShowReturnOrder && <ActionReturnOrder systemOrder={systemOrder} />}
              </>
            }
          />
          <ModalCancelOrder
            systemOrder={systemOrder}
            visible={confirmCancelOrder}
            setVisible={e => setConfirmCancelOrder(e)}
          />
          <div className="flex-1 flex overflow-hidden bg-gray-400">
            {/* left panel  */}
            <div className="relative flex flex-col bg-gray-300" style={{ width: '500px' }}>
              {systemOrder && (
                <Container className="py-2 bg-white px-4">
                  <OrderLogs
                    order={order}
                    systemOrder={systemOrder}
                    onClickReturnOrder={() => setShowReturnModal(true)}
                  />
                </Container>
              )}
              <Container className="bg-white">
                <Row bottom className="px-4">
                  <Info name="Khách hàng" value={order.user?.name} />
                  <Button className="py-2 h-auto rounded-lg flex items-center">
                    <PhoneFilled />
                    {formatPhoneNumber(order.user?.phone, PhoneNumberFormat.INTERNATIONAL)}
                  </Button>
                </Row>
                {order.note && (
                  <Row bottom className="px-4">
                    <Info name="Ghi chú" value={order.note} />
                  </Row>
                )}
                {systemOrder && <AssignPicker systemOrder={systemOrder} picker={order.picker} />}
                <Row bottom className="px-4">
                  {isDriverRejected && isAdminOrManager && (
                    <Button
                      onClick={() => {
                        setReOrderTransportVisible(true)
                      }}
                      className="py-2 h-auto rounded-lg"
                      type="primary"
                      danger>
                      Đặt lại đơn vận chuyển
                    </Button>
                  )}
                  {canCancelTransportOrder && (
                    <Button
                      loading={cancelLoading}
                      onClick={cancelTransport}
                      className="py-2 h-auto rounded-lg"
                      type="primary">
                      Hủy đơn vận chuyển
                    </Button>
                  )}
                </Row>
              </Container>
              <div className="h-2" />
              <Container className="bg-white flex-1 overflow-hidden px-4">
                <PerfectScrollBar>
                  <ItemsList products={order.products} />
                </PerfectScrollBar>
                <Row className="flex-row justify-between border-b-2 border-gray-300">
                  <Name>Tổng tiền hàng</Name>
                  <Value>
                    <Money value={order.subTotal} />
                  </Value>
                </Row>
                <Row className="flex-row justify-between border-b-2 border-gray-300">
                  <Name>Phí</Name>
                  <Value>
                    <Money value={order.fee} />
                  </Value>
                </Row>
                <Row className="flex-row justify-between border-b-2 border-gray-300">
                  <Name>
                    Giảm giá {order?.couponCode && <span style={{ marginLeft: 8 }}>(Coupon: {order?.couponCode})</span>}
                  </Name>
                  <Value>
                    <Money value={order.discount} />
                  </Value>
                </Row>
                <Row className="flex-row justify-between border-b-2 border-gray-300">
                  <Name>Tổng thanh toán</Name>
                  <Value>
                    <Money value={order.total} />
                  </Value>
                </Row>
                <Row className="flex-row justify-between items-center">
                  <Name>Thanh toán</Name>
                  <Value>
                    <div>{paymentMethodToText(order.paymentMethod, order.subPaymentMethod)}</div>
                  </Value>
                </Row>
              </Container>
            </div>

            {/* right panel */}
            <div className="relative flex-1 flex flex-col items-stretch justify-between p-4">
              {!showSharedShipping && (
                <div className="flex items-center z-10 py-2 px-3 rounded-md bg-white shadow-lg">
                  <EnvironmentFilled />
                  <span className="w-1" />
                  <span>{order.address.name}</span>
                </div>
              )}

              {showDriverNoTracking && (
                <div style={{ textAlign: 'center', fontSize: 36 }}>Chưa có thông tin vị trí tài xế</div>
              )}

              {/* {systemOrder &&
                !showSharedShipping &&
                ![ProcessStatus.ReadyToPick, ProcessStatus.Picking, ProcessStatus.Pending].includes(
                  systemOrder.status,
                ) && <AssignDriver driver={order.driver} systemOrder={systemOrder} />} */}

              {systemOrder?.status === ProcessStatus.Returning && showReturnModal && (
                <ReturnOrder order={{ ...order, products: order.products.filter(p => p.amount > 0) }} />
              )}

              {showMap && (
                <GoogleMap
                  mapContainerClassName="absolute w-full h-full top-0 left-0"
                  zoom={13}
                  center={currentLocation}
                  options={{ disableDefaultUI: true }}>
                  <OrdersMap order={order} currentLocation={currentLocation} tracking={tracking} />
                </GoogleMap>
              )}

              {/* show 3PL webview to tracking shipping order */}
              {showSharedShipping && (
                <iframe
                  src={systemOrder?.transport?.sharedLink}
                  className="absolute w-full h-full top-0 left-0"
                  title="SHOW 3PL WEBVIEW"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Modal>
  )
}

type ActionProps = {
  systemOrder?: SystemOrder
  order?: Order
}
const ActionReturnOrder = ({ systemOrder }: ActionProps) => {
  const [loading, setLoading] = React.useState(false)
  const { functions } = useFirebase()

  const handleReturnOrder = async () => {
    const returnOrder = functions.httpsCallable('firestore-adminReturnOrder')
    try {
      if (!systemOrder) {
        throw new Error('Cannot find system order')
      }
      setLoading(true)
      await returnOrder({ id: systemOrder.id })
      message.success('Hoàn thành!')
    } catch (err) {
      message.error('Đã có lỗi xảy ra, vui lòng thử lại!')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="absolute" style={{ right: 10 }}>
      {loading && <Spin className="mr-4" />}
      {systemOrder && (
        <Popconfirm
          icon={<WarningOutlined />}
          title="Xác nhận giao hàng không thành công?"
          onConfirm={handleReturnOrder}
          okText="Xác nhận"
          cancelText="Đóng">
          <Button type="primary" danger className="mr-8 rounded-md" disabled={loading}>
            Xác nhận giao hàng không thành công
          </Button>
        </Popconfirm>
      )}
    </div>
  )
}
