import { WarningFilled } from '@ant-design/icons'
import { ProcessStatus, ShipperInfo, TransportStatus } from '@coop/common'
import { Avatar } from 'antd'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import IconFace from '../../../assets/img/face.png'
import { Money } from '../../../shared/components/Money'
import env from '../../../shared/Env'
import { SystemOrder } from '../../../shared/model'
import { paymentMethodToIcon } from './PaymentMethod'
import { Colors, LaneType } from './styles'

type Props = {
  order: SystemOrder & { paymentMethod?: string } & { user?: { name?: string; phone?: string } }
  onClick: () => void
}

const LATE_MILLISECONDS = (env.lateThreshold || 5) * 60 * 1000 // milliSeconds

type PersonInCharge = { name: string | undefined; avatar: string | undefined } | undefined

const getPersonInCharge = (order: SystemOrder): PersonInCharge => {
  if ([ProcessStatus.Delivering, ProcessStatus.Returning].includes(order.status)) {
    return { name: order.driver?.name, avatar: order.driver?.avatar }
  }
  if (order.status === ProcessStatus.Picking) {
    return { name: order.picker?.name, avatar: order.picker?.avatar }
  }
  return undefined
}

export const Card: React.FC<Props> = ({ order, onClick }) => {
  const [elapsed, setElapsed] = React.useState(0)
  const [humanize, setHumanize] = React.useState('')

  React.useEffect(() => {
    const setData = () => {
      const lastUpdate =
        order.status === ProcessStatus.ReadyToPick
          ? order.createdAt
          : order.status === ProcessStatus.Picking
          ? order.startPickingAt
          : order.status === ProcessStatus.Delivering
          ? order.startShippingAt
          : order.lastUpdatedTime

      if (!lastUpdate) {
        setHumanize('Unknown')
        return
      }
      setElapsed(Date.now() - lastUpdate.toMillis())
      setHumanize(moment(lastUpdate.toMillis()).from(moment(Date.now())))
    }
    setData()
    const interval = setInterval(setData, 60000)

    return () => clearInterval(interval)
  }, [order])

  const showLateAlert =
    elapsed >= LATE_MILLISECONDS && [ProcessStatus.ReadyToPick, ProcessStatus.ReadyToDeliver].includes(order.status)

  const isPaymentPending = order.status === ProcessStatus.Pending

  const showAttentionRequired = order && order.status === ProcessStatus.Picking && order.attentionRequired

  const isDriverRejected = order && order.transport && order.transport.lastStatus === TransportStatus.CANCELLED

  const showDriverInfoAlert =
    order &&
    order.transport &&
    order.transport.shipperInfo &&
    !!order.transport.shipperInfo.name &&
    order.transport.lastStatus !== TransportStatus.CANCELLED

  return (
    <div
      className="flex flex-col self-stretch rounded-md m-1 my-3 cursor-pointer text-base hover:shadow-lg"
      style={{ backgroundColor: Colors[order.status].card.background, cursor: 'pointer' }}
      onClick={onClick}>
      <CardHeader
        title={order.id}
        subTitle={humanize}
        type={order.status}
        name={order.user?.name}
        phone={order.user?.phone}
      />
      <CardContent
        personInCharge={getPersonInCharge(order)}
        totalPrice={order.totalPrice}
        type={order.status}
        paymentMethod={order.paymentMethod}
      />
      {isPaymentPending && <CardAlert message="Đang chờ thanh toán" type={order.status} />}
      {showLateAlert && !isPaymentPending && <CardAlert message="Xử lý quá lâu" type={order.status} />}
      {showAttentionRequired && <CardAlert message="Hết hàng. Yêu cầu hủy." type={order.status} />}
      {order.status === ProcessStatus.Returning && (
        <CardAlert message="Không giao được. Yêu cầu hủy." type={order.status} />
      )}
      {showDriverInfoAlert && <DriverAlert driverInfo={order.transport?.shipperInfo} />}
      {isDriverRejected && <CardDangerAlert message="Yêu cầu hủy và đặt lại đơn vận chuyển." type={order.status} />}
    </div>
  )
}

type HeaderProps = {
  title?: string
  subTitle?: string
  type: LaneType
  name?: string
  phone?: string
}

const CardHeader: React.FC<HeaderProps> = ({ title, subTitle: sub, type, name, phone }) => (
  <div className="self-strech flex flex-col items-stretch">
    <div
      className="flex justify-between items-center py-2 px-3 text-sm"
      style={{ color: Colors[type].card.title, opacity: 0.5 }}>
      <div className="flex items-center">
        <span>{title}</span>
      </div>

      <span>{sub}</span>
    </div>

    <div
      className="flex justify-between items-center py-2 px-3 text-sm"
      style={{ color: Colors[type].card.title, opacity: 0.5 }}>
      <div className="flex items-center">
        <span>{name}</span>
      </div>
      <span>{phone}</span>
    </div>

    <div
      className="mx-2"
      style={{ height: '2px', backgroundColor: '#F4F5F6', opacity: Colors[type].card.separatorOpacity }}
    />
  </div>
)

type ContentProps = {
  totalPrice: number
  personInCharge: PersonInCharge
  type: LaneType
  paymentMethod?: string
}

const ImgTag = styled('img')`
  width: 24px;
  height: 24px;
`

const CardContent: React.FC<ContentProps> = ({ totalPrice, personInCharge, type, paymentMethod }) => {
  return (
    <div className="py-2 px-3 text-sm" style={{ color: Colors[type].card.title }}>
      {personInCharge && (
        <div className="flex justify-between items-center mb-3">
          <Avatar src={personInCharge.avatar || IconFace} size={24} shape="square" />
          <span className="mr-2 font-semibold" style={{ paddingLeft: 10 }}>
            {personInCharge.name}
          </span>
        </div>
      )}

      <div className="flex justify-between items-center">
        <span className="font-semibold">{<Money value={totalPrice} />}</span>
        <span className="">
          <ImgTag src={paymentMethodToIcon(paymentMethod)} />
        </span>
      </div>
    </div>
  )
}

type AlertProps = {
  message: string
  type: LaneType
}

const CardAlert: React.FC<AlertProps> = ({ message: text, type }) => (
  <div
    className="self-stretch flex items-center py-2 px-3 text-sm"
    style={{ backgroundColor: Colors[type].card.alertBackground, color: Colors[type].card.alert }}>
    <WarningFilled />
    <span className="ml-2">{text}</span>
  </div>
)

const CardDangerAlert: React.FC<AlertProps> = ({ message: text }) => (
  <div
    className="self-stretch flex items-center py-2 px-3 rounded-b-md text-sm"
    style={{ backgroundColor: '#EF5236', color: '#FFF' }}>
    <WarningFilled />
    <span className="ml-2">{text}</span>
  </div>
)

type DriverAlertProps = {
  driverInfo?: ShipperInfo
}

const DriverAlert: React.FC<DriverAlertProps> = ({ driverInfo }) => (
  <div
    className="self-stretch flex flex-col items-center py-2 px-3 text-sm"
    style={{ backgroundColor: Colors.delivering.card.driverAlertBackground, color: Colors.delivering.card.alert }}>
    <span className="font-bold">Thông tin tài xế</span>
    <span>
      {driverInfo?.name} ✩ {driverInfo?.phoneNumber}
    </span>
  </div>
)
