type EnvType = {
  firebaseProject: string
  firebaseApiKey: string
  firebaseRegion?: string
  serverUrl: string
  sentryDns: string
  lateThreshold: number
  googleApiKey: string
  functionApi: string
  imageApi: string
}

const env: EnvType = {
  firebaseProject: process.env.REACT_APP_FIREBASE_PROJECT || '',
  serverUrl: process.env.REACT_APP_INVENTORY_API || '',
  firebaseApiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
  firebaseRegion: process.env.REACT_APP_FIREBASE_REGION || '',
  sentryDns: process.env.REACT_APP_SENTRY_DNS || '',
  lateThreshold: +(process.env.REACT_APP_LATE_THRESHOLD || 5),
  googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
  functionApi: `https://${process.env.REACT_APP_FIREBASE_REGION}-${process.env.REACT_APP_FIREBASE_PROJECT}.cloudfunctions.net`,
  imageApi: process.env.REACT_APP_IMAGE_API || '',
}

export default env
