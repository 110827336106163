import { message, Popconfirm, Switch } from 'antd'
import React from 'react'
import { useFirestore } from '../../../../contexts/FirestoreContext'
import { Coupon } from '../../../../shared/model'

export const ActiveSwitch: React.FC<{ coupon: Coupon }> = ({ coupon }) => {
  const [loading, setLoading] = React.useState(false)
  const { Data } = useFirestore()

  const on = () => {
    setLoading(true)
    Data.coupon()
      .doc(coupon.id)
      .update({ isHidden: false })
      .catch(e => message.error(`Có lỗi xảy ra: $${e.message}`))
      .finally(() => setLoading(false))
  }
  const off = () => {
    setLoading(true)
    Data.coupon()
      .doc(coupon.id)
      .update({ isHidden: true })
      .catch(e => message.error(`Có lỗi xảy ra: $${e.message}`))
      .finally(() => setLoading(false))
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      {!coupon.isHidden ? (
        <Popconfirm
          title="Ẩn mã khuyến mãi này trên hệ thống?"
          okText="Xác nhận"
          cancelText="Hủy"
          okButtonProps={{ type: 'primary', danger: true }}
          onConfirm={off}>
          <Switch checked={!coupon.isHidden} loading={loading} />
        </Popconfirm>
      ) : (
        <Switch loading={loading} checked={!coupon.isHidden} onChange={on} />
      )}
    </div>
  )
}
