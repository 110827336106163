import { AutoComplete, Form, Input, message, Spin } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React, { useState } from 'react'
import { useFirebase } from 'src/contexts/FirebaseContext'

interface initProps {
  form: FormInstance
  setMustSelectTransport: (e: boolean) => void
}
let timeout = 0

const AddressAutocomplete = ({ form, setMustSelectTransport }: initProps) => {
  const allFormData = form.getFieldsValue(['address'])
  const { functions } = useFirebase()

  const [addressState, setAddressState] = useState([
    {
      value: allFormData.address,
      label: <div>{allFormData.address}</div>,
    },
  ])

  const onSearch = (value: string) => {
    const bodyRequest = { input: value }
    // eslint-disable-next-line radix
    if (timeout) {
      clearTimeout(timeout)
    }
    setAddressState([{ value: '', label: <Spin size="small" /> }])
    timeout = setTimeout(() => {
      functions
        .httpsCallable('firestore-MapsAutoComplete')(bodyRequest)
        .then(response => {
          const suggessAddress = response.data?.predictions?.map((e: any) => ({
            key: e?.place_id || '',
            value: e?.description || '',
            label: <p>{e?.description || ''}</p>,
          }))
          setAddressState(suggessAddress)
        })
        .catch(error => message.error('không tìm thấy địa chỉ này', error))
    }, 1000)
  }

  const onSelect = (value: string, option: any) => {
    const bodyRequest = { place_id: option.key, value }
    setMustSelectTransport(true)
    functions
      .httpsCallable('firestore-MapsPlaceDetail')(bodyRequest)
      .then(response => {
        const { result } = response.data
        const { lat, lng } = result?.geometry?.location
        form.setFieldsValue({ lat, lng, transportList: [{ type: [] }] })
        message.success('Thay đổi địa chỉ giao hàng thành công')
      })
      .catch(error => message.error('Thay đổi địa chỉ giao hàng không thành công', error))
  }

  return (
    <Form.Item
      style={{ marginBottom: 12 }}
      label="Địa chỉ giao hàng"
      name="address"
      required
      rules={[{ required: true, message: 'Vui lòng nhập đầy đủ địa chỉ giao hàng!' }]}>
      <AutoComplete options={addressState} onSearch={onSearch} onSelect={onSelect}>
        <Input.Search size="large" placeholder="" enterButton />
      </AutoComplete>
    </Form.Item>
  )
}

export default AddressAutocomplete
