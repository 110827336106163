import React from 'react'
import Modal from 'react-modal'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import './App.css'
import { ApolloClientProvider } from './contexts/ApolloClientProvider'
import { AuthProvider } from './contexts/AuthContext'
import { FirebaseProvider } from './contexts/FirebaseContext'
import { FirestoreProvider } from './contexts/FirestoreContext'
import { MapProvider } from './contexts/MapContext'
import { SourceProvider } from './contexts/SourceContext'
import { WindowSizeProvider } from './contexts/WindowSizeContext'
import './index.css'
import { Auth } from './routes/Auth'
import { Main } from './routes/Main'
import { PrivacyPolicy } from './routes/PrivacyPolicy'
import { ProtectedRoute } from './shared/components'

Modal.setAppElement('#root')

function App() {
  return (
    <WindowSizeProvider>
      <FirebaseProvider>
        <FirestoreProvider>
          <AuthProvider>
            <ApolloClientProvider>
              <SourceProvider>
                <MapProvider>
                  <Router>
                    <Switch>
                      <Route path="/xac-thuc" component={Auth} />
                      <Route path="/privacy-policy" component={PrivacyPolicy} />
                      <ProtectedRoute path="/" component={Main} />
                      <Redirect to="/" />
                    </Switch>
                  </Router>
                </MapProvider>
              </SourceProvider>
            </ApolloClientProvider>
          </AuthProvider>
        </FirestoreProvider>
      </FirebaseProvider>
    </WindowSizeProvider>
  )
}

export default App
