import { PayType, ProcessStatus } from '@coop/common'
import React from 'react'
import { useOrders } from 'src/contexts/OrderContext'
import { Money } from 'src/shared/components/Money'
import { SystemOrder } from 'src/shared/model'
import styled from 'styled-components'

const THead = styled.th`
  border: solid 1px #1890ff;
  text-align: center;
  padding: 8px;
  font-size: 1.5em;
`

const TDetail = styled.td`
  border: solid 1px #1890ff;
  padding: 8px;
  font-size: 1.1em;
`

const TDetailCenter = styled.td`
  border: solid 1px #1890ff;
  text-align: center;
  padding: 8px;
  font-size: 1.1em;
`

const OrderReport: React.FC = () => {
  const { ordersMap, freePickers, comingDrivers } = useOrders()

  let codOrder = 0
  let ccOrder = 0
  let atmOrder = 0
  let maxOrder = 0
  let minOrder = 0
  let orderValue = 0

  const newOrders = [...(ordersMap[ProcessStatus.ReadyToPick] || []), ...(ordersMap[ProcessStatus.Pending] || [])]
  const pickingOrders = ordersMap[ProcessStatus.Picking] || []
  const readyToDeliverOrder = ordersMap[ProcessStatus.ReadyToDeliver] || []
  const returnOrders = ordersMap[ProcessStatus.Returning] || []
  const deliveringOrders = ordersMap[ProcessStatus.Delivering] || []

  const summaryOrder = (theOrder: SystemOrder & { paymentMethod?: string; subTotal?: number }, i: number) => {
    const { totalPrice, paymentMethod } = theOrder
    const subTotal = theOrder?.subTotal || 0
    codOrder += paymentMethod === PayType.COD ? 1 : 0
    ccOrder += paymentMethod === PayType.CreditCard ? 1 : 0
    atmOrder += paymentMethod === PayType.ATM ? 1 : 0
    maxOrder = totalPrice > maxOrder ? totalPrice : maxOrder
    minOrder = i === 0 ? totalPrice : totalPrice < minOrder ? totalPrice : minOrder
    orderValue += subTotal
  }

  newOrders.map((e, i) => summaryOrder(e, i))
  pickingOrders.map((e, i) => summaryOrder(e, i))
  readyToDeliverOrder.map((e, i) => summaryOrder(e, i))
  returnOrders.map((e, i) => summaryOrder(e, i))
  deliveringOrders.map((e, i) => summaryOrder(e, i))

  return (
    <div className="flex-1">
      <div className="flex justify-center my-4">
        <h1 className="text-lg font-bold">Thống kê tất cả đơn hàng đang xử lý</h1>
      </div>
      <table className="border-collapse border border-green-800 table-fixed" style={{ width: '100%' }}>
        <thead>
          <tr>
            <THead className="w-3/5">Loại thống kê </THead>
            <THead className="w-1/5">Giá trị </THead>
            <THead className="w-1/5">Đơn vị </THead>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TDetail>Tổng số đơn hàng mới</TDetail>
            <TDetailCenter>{newOrders.length}</TDetailCenter>
            <TDetailCenter>Đơn hàng</TDetailCenter>
          </tr>
          <tr>
            <TDetail>Tổng số đơn đang soạn hàng</TDetail>
            <TDetailCenter>{pickingOrders.length}</TDetailCenter>
            <TDetailCenter>Đơn hàng</TDetailCenter>
          </tr>
          <tr>
            <TDetail>Tổng số đơn chờ giao</TDetail>
            <TDetailCenter>{readyToDeliverOrder.length}</TDetailCenter>
            <TDetailCenter>Đơn hàng</TDetailCenter>
          </tr>
          <tr>
            <TDetail>Tổng số đơn hàng hoàn</TDetail>
            <TDetailCenter>{returnOrders.length}</TDetailCenter>
            <TDetailCenter>Đơn hàng</TDetailCenter>
          </tr>
          <tr>
            <TDetail>Tổng số đơn đang giao</TDetail>
            <TDetailCenter>{deliveringOrders.length}</TDetailCenter>
            <TDetailCenter>Đơn hàng</TDetailCenter>
          </tr>
          <tr>
            <TDetail>Tổng giá trị tất cả đơn hàng (giá gốc đơn hàng)</TDetail>
            <TDetailCenter>
              <Money value={orderValue} />
            </TDetailCenter>
            <TDetailCenter>VND</TDetailCenter>
          </tr>
          <tr>
            <TDetail>Đơn hàng có giá cao nhất (sau khi áp mã khuyến mãi)</TDetail>
            <TDetailCenter>
              <Money value={maxOrder} />
            </TDetailCenter>
            <TDetailCenter>VND</TDetailCenter>
          </tr>
          <tr>
            <TDetail>Đơn hàng có giá thấp nhất (sau khi áp mã khuyến mãi)</TDetail>
            <TDetailCenter>
              <Money value={minOrder} />
            </TDetailCenter>
            <TDetailCenter>VND</TDetailCenter>
          </tr>
          <tr>
            <TDetail>Tổng số đơn đang thanh toán theo hình thức COD</TDetail>
            <TDetailCenter>{codOrder}</TDetailCenter>
            <TDetailCenter>Đơn hàng</TDetailCenter>
          </tr>
          <tr>
            <TDetail>Tổng số đơn đang thanh toán theo hình thức ATM</TDetail>
            <TDetailCenter>{atmOrder}</TDetailCenter>
            <TDetailCenter>Đơn hàng</TDetailCenter>
          </tr>
          <tr>
            <TDetail>Tổng số đơn đang thanh toán theo hình thức VISA/MASTER</TDetail>
            <TDetailCenter>{ccOrder}</TDetailCenter>
            <TDetailCenter>Đơn hàng</TDetailCenter>
          </tr>
          <tr>
            <TDetail>Tổng số nhân viên soạn hàng rãnh tay</TDetail>
            <TDetailCenter>{freePickers.length}</TDetailCenter>
            <TDetailCenter>Nhân viên</TDetailCenter>
          </tr>
          <tr>
            <TDetail>Tổng số tài xế rãnh tay</TDetail>
            <TDetailCenter>{comingDrivers.length}</TDetailCenter>
            <TDetailCenter>Tài xế</TDetailCenter>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default OrderReport
