import { Select } from 'antd'
import { firestore } from 'firebase'
import React, { useEffect, useState } from 'react'
import { useFirestore } from 'src/contexts/FirestoreContext'
import PageTitle from 'src/shared/components/PageTitle'
import styled from 'styled-components'
// @ts-ignore
import tw from 'tailwind.macro'
import { StoreTable } from './StoreTable'

const Wrapper = styled.div``
const Header = styled.div``
const Content = styled.div`
  .ant-table thead tr > th {
    ${tw`text-gray-500 uppercase tracking-wider font-normal pb-2`}
  }
`
const RightSideContent = styled.div``
const SelectWrapper = styled(Select)`
  width: 20rem;
`

type Props = {}

interface storeInfo {
  sourceCode?: string
  storeOrder?: number
  tag?: []
}
interface tagList {
  collectionId?: string
  code?: string
  group?: string
  icon?: string
  stores?: storeInfo[]
}

const ManageHome: React.FC<Props> = () => {
  const { Option } = Select
  const { Data } = useFirestore()

  const [tagListFromAPI, setTagListFromAPI] = useState<firestore.DocumentData & tagList[]>([])
  const [currentTag, setCurrentTag] = useState<string>('mart')

  const tagListFiltered = tagListFromAPI.filter(e => e.code === currentTag)[0]
  const storeListAddKey = tagListFiltered?.stores?.map((e, i) => ({ ...e, key: `storeList_${i}` })) || []
  const reOrderStoreList = storeListAddKey.sort((a, b) => (a?.storeOrder || 0) - (b?.storeOrder || 1))

  useEffect(() => {
    Data.homeScreen()
      .get()
      .then(result =>
        result.docs.map(e => {
          const data = { collectionId: e.id, ...e.data() }
          setTagListFromAPI(init => [...init, data])
          return null
        }),
      )
  }, [Data])

  return (
    <Wrapper className="h-full w-full flex flex-col">
      <Header className="flex flex-col mb-8">
        <PageTitle title="Quản lý trang chủ" description={'Vui lòng chọn phân loại cửa hàng'} />
        <SelectWrapper
          className="mt-2"
          showSearch
          optionFilterProp="children"
          onChange={(e: any) => setCurrentTag(e)}
          defaultValue={'mart'}>
          <Option value="mart">Đi chợ</Option>
          <Option value="food">Đồ ăn</Option>
          <Option value="fresh">Tươi sống</Option>
        </SelectWrapper>
      </Header>
      <Content className="flex flex-1">
        <RightSideContent className="w-full">
          <StoreTable storeList={reOrderStoreList} tagListFiltered={tagListFiltered} />
        </RightSideContent>
      </Content>
    </Wrapper>
  )
}

export { ManageHome }
