import { OrderStatus } from '@coop/apollo'
import { ProcessStatus } from '@coop/common'
import { EPaymentMethod } from 'src/shared/model'

const processstatusList = [
  { code: ProcessStatus.Canceled, text: 'Đã hủy' },
  { code: ProcessStatus.Delivering, text: 'Đang giao' },
  { code: ProcessStatus.Finished, text: 'Đã hoàn thành' },
  { code: ProcessStatus.Pending, text: 'Đang chờ thanh toán' },
  { code: ProcessStatus.ReadyToDeliver, text: 'Sẵn sàng giao' },
  { code: ProcessStatus.Picking, text: 'Đang soạn hàng' },
  { code: ProcessStatus.ReadyToPick, text: 'Đơn hàng mới' },
  { code: ProcessStatus.Returning, text: 'Đơn trả hàng' },
]

const magentoStatusList = [
  { code: OrderStatus.Pending, text: 'Đơn hàng mới' },
  { code: OrderStatus.Processing, text: 'Đơn đang vận hành' },
  { code: OrderStatus.Complete, text: 'Đơn hàng đã hoàn thành' },
  { code: OrderStatus.Canceled, text: 'Đơn hàng bị huỷ' },
  { code: OrderStatus.Closed, text: 'Đơn hàng đã refund xong' },
]

const paymentList = [
  { code: EPaymentMethod.COD, text: 'Tiền mặt' },
  { code: EPaymentMethod.BANK, text: 'Chuyển khoản Bank' },
  { code: EPaymentMethod.ATM, text: 'ATM' },
  { code: EPaymentMethod.CREDITS, text: 'Thanh toán credits' },
  { code: EPaymentMethod.Momo, text: 'Momo' },
  { code: EPaymentMethod.EW, text: 'Ew' },
  { code: EPaymentMethod.Zalo, text: 'Zalo' },
]

const processingStatusCodeToText = (code: string) => processstatusList.find(e => e.code === code)?.text
const paymentCodeToText = (code: string) => paymentList.find(e => e.code === code)?.text
const magentoStatusCodeToText = (code: string) => magentoStatusList.find(e => e.code === code)?.text

export { processingStatusCodeToText, paymentCodeToText, magentoStatusCodeToText }
