import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons'
import { useDebounceCallback } from '@react-hook/debounce'
import { useThrottleCallback } from '@react-hook/throttle'
import { AutoComplete, Button, Col, Divider, Form, Modal as AntModal, Row, Select, Spin, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import htmlParse from 'html-react-parser'
import React from 'react'
import styled from 'styled-components'
import { CartItem, CartProvider, Outlet, useCart } from '../../contexts/CartContext'
import { useSource } from '../../contexts/SourceContext'
import Modal, { ModalHeader } from '../../shared/components/Modal'
import { Money } from '../../shared/components/Money'
import { useCloudFunctionApi, useUserToken } from '../../shared/hooks/UserTokenFunctionApi'
import { Market, User } from '../../shared/model'
import { formatUrl } from '../../shared/utils'
import { ProductPage, TableWrapper } from '../Products/ProductPage'
import CheckoutCartModal from './CheckoutCartModal'

const StyledRow = styled(Row).attrs({ className: '' })``
const Title = styled('p').attrs({ className: 'text-lg font-normal' })``
const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0px;
  }
`

type Props = {
  customer?: User
  onRequestClose?: () => void
}

export const CreateCartModal: React.FC<Props> = ({ customer, onRequestClose }) => {
  const { allSources, loading: sourceLoading } = useSource()
  const [selectedSource, setSelectedSource] = React.useState<Market>()
  const { loading: tokenLoading, uid, jwtToken } = useUserToken(customer)

  const onSelectSource = React.useCallback(
    (e: string) => {
      setSelectedSource(allSources.find(s => s.source_code === e))
    },
    [allSources],
  )

  const loading = !customer || tokenLoading || sourceLoading

  if (!customer) {
    return null
  }

  return (
    <div className="flex w-full overflow-hidden">
      <div className="flex flex-col w-full">
        <CartProvider uid={uid} customer={customer} market={selectedSource} jwtToken={jwtToken}>
          <ModalHeader title="Tạo đơn hàng" />
          <div className="w-full mx-auto p-4 px-24 overflow-y-auto">
            <Row gutter={16}>
              <StyledForm labelCol={{ span: 4 }} className="flex flex-col w-full">
                <Col flex={1}>
                  <StyledRow>
                    <Title>Thông tin khách hàng</Title>
                  </StyledRow>
                  <Form.Item label="HỌ VÀ TÊN" labelAlign="left">
                    {customer.name || ''}
                  </Form.Item>
                  <Form.Item label="SỐ ĐIỆN THOẠI" labelAlign="left">
                    {customer.phone || ''}
                  </Form.Item>
                  <Form.Item label="EMAIL" labelAlign="left">
                    {customer.email || ''}
                  </Form.Item>
                  {loading && (
                    <div className="flex justify-center">
                      <Spin />
                    </div>
                  )}
                </Col>
              </StyledForm>
            </Row>
            {!loading && (
              <Content
                uid={uid}
                jwtToken={jwtToken}
                customer={customer}
                market={selectedSource}
                onChangeMarket={onSelectSource}
                onRequestClose={onRequestClose}
              />
            )}
          </div>
        </CartProvider>
      </div>
    </div>
  )
}

type ContentProps = {
  customer?: User
  uid?: string
  jwtToken?: string
  onChangeMarket: (e: string) => void
  market?: Market
  onRequestClose?: () => void
}
const Content = ({ onChangeMarket, market, onRequestClose }: ContentProps) => {
  const { allSources } = useSource()
  const {
    count,
    total,
    addToCart,
    clearCart,
    createMagentoCart,
    shippingAddress,
    setShippingAddress,
    jwtToken,
  } = useCart()
  const throttleAdd = useThrottleCallback(addToCart, 8)
  const [showCheckoutModal, setShowCheckoutModal] = React.useState(false)
  const [addresses, setAddresses] = React.useState<SearchAddressType[]>([])
  const [savingAdress, setSavingAdress] = React.useState(false)
  const { executeApi: apiMapsAutoComplete } = useCloudFunctionApi('firestore-MapsAutoComplete', jwtToken as string)
  const { executeApi: apiMapsPlaceDetail } = useCloudFunctionApi('firestore-MapsPlaceDetail', jwtToken as string)
  const closeCheckoutModal = () => setShowCheckoutModal(false)
  const openCheckoutModal = async () => {
    AntModal.info({
      title: <div />,
      okText: <div />,
      icon: <div />,
      centered: true,
      okButtonProps: { type: 'text', ghost: true, disabled: true },
      content: (
        <div className="flex flex-1 justify-center">
          <Spin tip={'Đang tạo giỏ hảng'} />
        </div>
      ),
    })

    try {
      await createMagentoCart()
      AntModal.destroyAll()
      setShowCheckoutModal(true)
    } catch (error) {
      AntModal.error({
        title: 'Lỗi',
        okText: 'Đóng',
        onOk: () => AntModal.destroyAll(),
        content: error.message,
      })
    }
  }

  const onChange = (e: string) => {
    if (count > 0) {
      AntModal.confirm({
        title: 'Tất cả sản phẩm trong giỏ sẽ bị xóa khi thay đổi cửa hàng',
        okText: 'Đồng ý đổi cửa hàng',
        okType: 'danger',
        cancelText: 'Đóng',
        onOk: () => {
          clearCart()
          onChangeMarket(e)
        },
      })
    } else {
      onChangeMarket(e)
    }
  }
  const searchLocation = useDebounceCallback(async (input: string) => {
    if (input) {
      try {
        const response: any = await apiMapsAutoComplete({ input })
        setAddresses(
          response.predictions.map((i: any) => ({
            name: i.structured_formatting.main_text,
            id: i.place_id,
            alias: i.structured_formatting.secondary_text,
          })),
        )
      } finally {
        // setLoading(false)
      }
    }
  }, 400)
  const onSaveAddress = async (_: string, option: any) => {
    setSavingAdress(true)
    try {
      const response = await apiMapsPlaceDetail({ place_id: option.key })
      const payload: Outlet = {
        name: response.result.formatted_address,
        latitude: response.result.geometry.location.lat,
        longitude: response.result.geometry.location.lng,
        alias: 'dashboard',
      }

      setShippingAddress(payload)
    } finally {
      setSavingAdress(false)
    }
  }

  return (
    <>
      <Row>
        <Divider />
        <Form layout="vertical" className="flex w-full">
          <Col span={12}>
            <div className="flex flex-1 flex-col mr-4">
              <Form.Item label="Chọn cửa hàng" labelAlign="left">
                <Select className={'mb-4'} value={market?.source_code} onChange={onChange}>
                  {allSources.map((e: Market, i: number) => (
                    <Select.Option key={`channel_item_${i}`} value={e.source_code}>
                      {e.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col span={12}>
            <div className="flex flex-1 flex-col ml-4">
              <Form.Item label="Nhập địa chỉ giao hàng" labelAlign="left">
                <AutoComplete onSearch={searchLocation} onSelect={onSaveAddress}>
                  {addresses.map((address: SearchAddressType) => (
                    <AutoComplete.Option key={address.id} value={`${address.name} ${address.alias}`}>
                      {address.name}
                      <br />
                      {address.alias}
                    </AutoComplete.Option>
                  ))}
                </AutoComplete>
                {count > 0 && !!shippingAddress && (
                  <Button type="primary" className="mt-2" onClick={openCheckoutModal} disabled={savingAdress}>
                    {'Tiến hành thanh toán'}
                  </Button>
                )}
              </Form.Item>
            </div>
          </Col>
        </Form>
      </Row>
      {market && (
        <>
          <Row>
            <Col span={24}>
              <div className="flex flex-1 flex-col mr-4">
                <ProductPage
                  source={market}
                  actionOnProduct={throttleAdd}
                  visibleTitles={['Sản phẩm', 'Tình trạng', 'Giá bán', 'Hành động']}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="ml-4">
                <div className="self-end mb-8" />
                <div className="mb-2 flex flex-1 items-center font-semibold" style={{ backgroundColor: '#fafafa' }}>
                  <Title>{'Tổng tiền:'}</Title>
                  <Money className="ml-4 text-red-600" value={total} />
                </div>
                <CartItems />
              </div>
            </Col>
          </Row>
        </>
      )}
      <Modal isFullScreen isOpen={showCheckoutModal} onRequestClose={closeCheckoutModal}>
        <CheckoutCartModal onRequestClose={onRequestClose} />
      </Modal>
    </>
  )
}

const CartItems = () => {
  const { addToCart, removeFromCart, removeAllFromCart, items: cartItems } = useCart()
  const throttleAdd = useThrottleCallback(addToCart, 8)
  const throttleRemove = useThrottleCallback(removeFromCart, 8)
  const removeProduct = React.useCallback(
    (p: CartItem) => {
      AntModal.confirm({
        title: 'Bạn muốn xóa sản phẩm ra khỏi giỏ hàng?',
        okText: 'Đồng ý xóa',
        okType: 'danger',
        cancelText: 'Đóng',
        onOk: () => {
          removeAllFromCart(p.product)
        },
      })
    },
    [removeAllFromCart],
  )

  const columns = React.useMemo<ColumnProps<CartItem>[]>(
    () => [
      {
        title: 'Sản phẩm',
        width: '50%',
        render: (d: CartItem) => (
          <div className="flex items-center">
            <img
              src={formatUrl(d.product.thumbnail_path)}
              width="56"
              alt=""
              style={{ objectFit: 'contain', height: 56 }}
            />
            <span className="ml-2">{htmlParse(d.product.name)}</span>
          </div>
        ), // accessor is the "key" in the data
      },
      {
        title: 'Số lượng',
        render: (p: CartItem) => {
          return (
            <div className="flex items-center space-x-4">
              <div
                onClick={() => {
                  if (p.amount === 1) {
                    return removeProduct(p)
                  }
                  throttleRemove(p.product)
                }}>
                <MinusCircleFilled style={{ margin: '8px', fontSize: '16px' }} />
              </div>
              <div className="mx-4">{p.amount}</div>
              <div onClick={() => throttleAdd(p.product)}>
                <PlusCircleFilled style={{ margin: '8px', fontSize: '16px' }} />
              </div>
            </div>
          )
        },
      },
      { title: 'Thành tiền', render: (d: CartItem) => <Money value={d.total} /> },
      {
        title: 'Hành động',
        render: (p: CartItem) => {
          return (
            <div className="flex items-center my-2" onClick={() => removeProduct(p)}>
              {'Xóa sản phẩm'}
            </div>
          )
        },
      },
    ],
    [throttleAdd, throttleRemove, removeProduct],
  )

  return (
    <TableWrapper className="flex-1">
      <Table<CartItem> rowKey={(_, index) => `${index}`} dataSource={cartItems} columns={columns} scroll={{ y: 400 }} />
    </TableWrapper>
  )
}

export type SearchAddressType = { name: string; id: string; alias: string }
