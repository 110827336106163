import { Input } from 'antd'
import React from 'react'

export type FilterInputType = {
  text: string | undefined
}

type Props = {
  searchValue: FilterInputType
  onValueChange: (key: keyof FilterInputType, value: string | undefined) => void
}

const FiltersGroup: React.FC<Props> = ({ searchValue, onValueChange }) => {
  return (
    <div className="mb-8 flex items-center" style={{ backgroundColor: '#fafafa' }}>
      <div className="flex-1">
        <Input
          allowClear
          placeholder="Tìm kiếm theo tên, siêu thị"
          value={searchValue.text}
          onChange={e => onValueChange('text', e.target.value)}
        />
      </div>
    </div>
  )
}

export default FiltersGroup
