import { WorkingHistoryType } from '@coop/common'
import { Button, Col, Divider, Drawer, Modal, Row, Spin } from 'antd'
import React from 'react'
import styled from 'styled-components'
// @ts-ignore
import tw from 'tailwind.macro'
import { useFirestore } from '../../contexts/FirestoreContext'
import { WorkHistory } from '../../shared/components'
import { CompletedOrderList } from '../../shared/components/CompletedOrderList'
import { useLazyQueryCompletedOrders } from '../../shared/hooks/apollo'
import { Driver } from '../../shared/model'

const { confirm } = Modal

const StyledDrawer = styled(Drawer).attrs({ className: 'text-gray-900' })`
  .ant-drawer-header {
    .ant-drawer-title {
      ${tw`text-center`}
    }
    button {
      ${tw`left-0 right-auto`}
    }
  }
  .ant-drawer-body {
    ${tw`flex flex-col h-full`}
  }
`

const StyledRow = styled(Row)`
  ${tw`mb-6`}
`

const Label = styled('span').attrs({ className: 'uppercase font-normal text-gray-500' })``

type ItemProps = {
  label: string
  text: string
}

const Item: React.FC<ItemProps> = ({ label, text }) => (
  <Col span={12}>
    <Label>{label}</Label>
    <p>{text}</p>
  </Col>
)

const PAGE_SIZE = 10

type Props = {
  visible: boolean
  onClose: () => void
  driver: Driver
  handleLockAccount: (driver: Driver) => Promise<void>
  handleUnlockAccount: (driver: Driver) => Promise<void>
  handleClickEdit: (driver: Driver) => void
}

export const DriverDrawer: React.FC<Props> = ({
  visible,
  onClose,
  driver,
  handleLockAccount,
  handleUnlockAccount,
  handleClickEdit,
}) => {
  const [declinedOrders, setDeclinedOrders] = React.useState(0)
  const { Data, Util } = useFirestore()
  React.useEffect(() => {
    const unsubWorkingHistory = Data.workingHistory(Data.driver().doc(driver.id)).onSnapshot(snapshot =>
      setDeclinedOrders(
        snapshot.docs
          .map(doc => doc.data() as WorkingHistoryType)
          .reduce((accum, item) => accum + (item.canceledOrder || 0), 0),
      ),
    )
    return () => {
      unsubWorkingHistory()
    }
  }, [Data, Util, driver.id])

  const variables = React.useMemo(() => ({ pageSize: PAGE_SIZE, driverId: driver.id }), [driver.id])
  const [getCompletedOrders, { data: ordersData, loading: loadingOrders, fetchMore }] = useLazyQueryCompletedOrders({
    variables,
  })
  React.useEffect(() => {
    getCompletedOrders()
  }, [getCompletedOrders])

  const [loading, setLoading] = React.useState(false)
  const onClickLock = () => {
    setLoading(true)
    handleLockAccount(driver).finally(() => setLoading(false))
  }
  const onClickUnlock = () => {
    setLoading(true)
    handleUnlockAccount(driver).finally(() => setLoading(false))
  }

  const onCofirm = () => {
    confirm({
      title: 'Bạn có đồng ý khóa tài khoản nhân sự?',
      okText: 'Khóa',
      okType: 'danger',
      cancelText: 'Hủy',
      onOk: onClickLock,
    })
  }

  return (
    <StyledDrawer
      width={400}
      visible={visible}
      onClose={onClose}
      closable={true}
      keyboard={true}
      title={driver.name}
      placement="right">
      <StyledRow className="flex justify-between">
        <WorkHistory value={loadingOrders ? <Spin /> : ordersData?.completedOrders.total_count || 0} />
        <div className="w-4" />
        <WorkHistory type="declined" value={declinedOrders} />
      </StyledRow>
      <StyledRow>
        <Item label="HỌ VÀ TÊN" text={driver.name} />
        <Item label="SỐ ĐIỆN THOẠI" text={driver.phone} />
      </StyledRow>
      <StyledRow>
        <Item label="BIỂN KIỂM SOÁT" text={driver.plate?.code} />
      </StyledRow>
      <StyledRow>
        <Button type="primary" ghost className="mr-2 flex-1 px-6 py-3 h-auto" onClick={() => handleClickEdit(driver)}>
          Sửa thông tin
        </Button>
        {driver.disabled ? (
          <Button
            type="primary"
            className="flex-1 px-6 py-3 h-auto bg-green-500 border-0 hover:bg-green-400 focus:bg-green-400"
            loading={loading}
            onClick={onClickUnlock}>
            Mở khóa tài khoản
          </Button>
        ) : (
          <Button type="primary" danger className="flex-1 px-6 py-3 h-auto" loading={loading} onClick={onCofirm}>
            Khóa tài khoản
          </Button>
        )}
      </StyledRow>

      <Divider />

      <CompletedOrderList
        title="Đơn hàng đã thực hiện"
        data={ordersData}
        loading={loadingOrders}
        fetchMore={fetchMore}
      />
    </StyledDrawer>
  )
}
