import { ManagerRole } from '@coop/common'
import { Button, Col, Divider, Drawer, Modal, Row, Skeleton } from 'antd'
import React from 'react'
import styled from 'styled-components'
// @ts-ignore
import tw from 'tailwind.macro'
import { useSource } from '../../contexts/SourceContext'
import { Manager } from '../../shared/model'

const StyledDrawer = styled(Drawer).attrs({ className: 'text-gray-900' })`
  .ant-drawer-header {
    .ant-drawer-title {
      ${tw`text-center`}
    }
    button {
      ${tw`left-0 right-auto`}
    }
  }
  .ant-drawer-body {
    ${tw`flex flex-col h-full`}
  }
`

const Title = styled('p').attrs({ className: 'text-lg font-normal' })``

const StyledRow = styled(Row).attrs({ className: 'mb-6' })``

const Label = styled('p').attrs({ className: 'uppercase font-normal text-gray-500' })``

type ItemProps = {
  label: string
  text: string
  loading?: boolean
}

const Item: React.FC<ItemProps> = ({ label, text, loading }) => (
  <Col span={12}>
    <Label>{label}</Label>
    <p>{loading ? <Skeleton active title={{ width: 'calc(100% - 20px)' }} paragraph={{ rows: 0 }} /> : text}</p>
  </Col>
)

type Props = {
  visible: boolean
  onClose: () => void
  manager: Manager
  handleLockAccount: (manager: Manager) => Promise<void>
  handleUnlockAccount: (manager: Manager) => Promise<void>
  handleClickEdit: (manager: Manager) => void
}

const { confirm } = Modal

export const ManagerDrawer: React.FC<Props> = ({
  visible,
  onClose,
  manager,
  handleLockAccount,
  handleUnlockAccount,
  handleClickEdit,
}) => {
  const { loading: loadingSources, allSources } = useSource()

  const { role, name, email, disabled } = manager

  const [loading, setLoading] = React.useState(false)
  const onClickLock = () => {
    setLoading(true)
    handleLockAccount(manager).finally(() => setLoading(false))
  }
  const onClickUnlock = () => {
    setLoading(true)
    handleUnlockAccount(manager).finally(() => setLoading(false))
  }

  const onConfirm = () => {
    confirm({
      title: 'Bạn có đồng ý khóa tài khoản nhân sự?',
      okText: 'Khóa',
      okType: 'danger',
      cancelText: 'Hủy',
      onOk: onClickLock,
    })
  }

  return (
    <StyledDrawer
      width={400}
      visible={visible}
      onClose={onClose}
      closable={true}
      keyboard={true}
      title={name}
      placement="right">
      <StyledRow>
        <Title>Thông tin</Title>
      </StyledRow>
      <StyledRow>
        <Item label="HỌ VÀ TÊN" text={name || ''} />
        <Item label="EMAIL" text={email} />
      </StyledRow>
      <StyledRow>
        <Item
          label="QUYỀN HẠN"
          text={role === ManagerRole.Admin ? 'Admin' : `Manager${role === ManagerRole.Owner ? ' (Owner)' : ''}`}
        />
        <Item label="TRẠNG THÁI" text={disabled ? 'Chờ kích hoạt' : 'Đang hoạt động'} />
      </StyledRow>

      {role === ManagerRole.Manager ||
        (role === ManagerRole.Owner && (
          <StyledRow>
            <Item
              label="SIÊU THỊ"
              text={allSources.find(s => s?.source_code === manager.sourceCode)?.name || ''}
              loading={loadingSources}
            />
          </StyledRow>
        ))}

      <StyledRow>
        <Button type="primary" ghost className="flex-1 py-3 h-auto mr-2" onClick={() => handleClickEdit(manager)}>
          Sửa thông tin
        </Button>
        {disabled ? (
          <Button
            type="primary"
            loading={loading}
            className="flex-1 py-3 h-auto bg-green-500 border-0 hover:bg-green-400 focus:bg-green-400"
            onClick={onClickUnlock}>
            Kích hoạt tài khoản
          </Button>
        ) : (
          <Button danger type="primary" loading={loading} className="flex-1 py-3 h-auto" onClick={onConfirm}>
            Khóa tài khoản
          </Button>
        )}
      </StyledRow>

      <Divider />
    </StyledDrawer>
  )
}
