import { DragOutlined } from '@ant-design/icons'
import { message } from 'antd'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useFirestore } from 'src/contexts/FirestoreContext'
import { Section } from 'src/shared/model'

interface Props {
  sections: Section[]
  handleSelectedCategory: (e: Section) => void
}

const DragListCategory: React.FC<Props> = ({ sections, handleSelectedCategory }) => {
  const { Data, db } = useFirestore()

  const reOrder = (item: any) => {
    message.loading({ content: 'Đang xử lý', key: 'reOrder' })
    const { destination, source } = item

    if (destination?.index || destination?.index === 0) {
      const batch = db.batch()
      const [removed] = sections.splice(source.index, 1)
      sections.splice(destination.index, 0, removed)
      sections.forEach((e: Section, i: number) => {
        const docRef = Data.section().doc(e.id)
        batch.update(docRef, { order: i + 1 })
      })
      batch
        .commit()
        .then(() => message.success({ content: 'Cập nhật thứ tự kệ hàng thành công', key: 'reOrder', duration: 2 }))
        .catch(error =>
          message.error({ content: `Cập nhật kệ hàng thất bại ${JSON.stringify(error)}`, key: 'reOrder' }),
        )
    }
  }

  return (
    <DragDropContext onDragEnd={(e: any) => reOrder(e)}>
      <Droppable droppableId="droppableId">
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {(sections || []).map((e, i) => (
              <Draggable key={`drag_${i}`} draggableId={`drag_${i}`} index={i}>
                {(providedDrag, snapshotDrag) => (
                  <div
                    ref={providedDrag.innerRef}
                    {...providedDrag.draggableProps}
                    {...providedDrag.dragHandleProps}
                    style={{
                      ...providedDrag.draggableProps.style,
                      margin: '0 0 8px 0',
                      background: snapshotDrag.isDragging ? '#1890ff' : 'WhiteSmoke',
                    }}
                    className={'flex w-full items-center justify-between px-6 py-4 '}
                    onClick={() => handleSelectedCategory(e)}>
                    <div className="flex items-center cursor-pointer w-11/12">
                      <div className="font-bold text-xl ">{e.order}</div>
                      <div className="flex-col ml-5">
                        <div className="font-semibold">{e.name}</div>
                        <div>{e.items.length} sản phẩm</div>
                      </div>
                    </div>

                    <DragOutlined style={{ fontSize: 24 }} className="cursor-pointer" />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DragListCategory
