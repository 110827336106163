import React from 'react'

type Props = {
  value: number | undefined | null
  className?: string
}

export const Money: React.FC<Props> = ({ value, className }) => (
  <span className={className}>
    {typeof value === 'number' ? value.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' }) : '-'}
  </span>
)

export const getMoneyString = (value: Props['value']) =>
  value ? value.toLocaleString('vi', { style: 'currency', currency: 'VND' }) : ''
