import { CheckCircleFilled, CloseCircleFilled, PlusCircleFilled } from '@ant-design/icons'
import { useLazyQueryProducts, useMutationUpdateProduct } from '@coop/apollo'
import { Button, Empty, message, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import htmlParse from 'html-react-parser'
import React from 'react'
import styled from 'styled-components'
// @ts-ignore
import tw from 'tailwind.macro'
import { useSource } from '../../../contexts/SourceContext'
import { Modal } from '../../../shared/components'
import { Money } from '../../../shared/components/Money'
import useDebounce from '../../../shared/hooks/useDebounce'
import { usePagination } from '../../../shared/hooks/usePagination'
import { Market, Product } from '../../../shared/model'
import { checkIfOutOfStock, formatUrl } from '../../../shared/utils'
import FiltersGroup, { FilterInputType, OPTION_ALL, ProductStatusOption } from './FiltersGroup'
import ProductFormModal from './ProductFormModal'

export const TableWrapper = styled.div`
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 4px 16px;
  }

  .ant-table thead tr > th {
    ${tw`text-gray-500 uppercase tracking-wider font-normal pb-2`}
  }

  .ant-table-tbody > tr {
    ${tw`cursor-pointer`}
  }
`

type Props = {
  source?: Market
  actionOnProduct?: (product: Product) => void
  visibleTitles?: string[]
}

type Action = { type: keyof FilterInputType; payload: any }

interface UpdateProduct {
  sku?: string
  status?: number | null
  quantity?: number
  price?: number
  price_from?: string | null
  price_to?: string | null
  visibility?: number | null
}

function filterReducer(state: FilterInputType, action: Action) {
  switch (action.type) {
    case 'text':
      return { ...state, text: action.payload }
    case 'category':
      return { ...state, category: action.payload }
    case 'visibility':
      return { ...state, visibility: action.payload }
    default:
      return state
  }
}

const ProductPage: React.FC<Props> = props => {
  const [filterInput, dispatchFilterInput] = React.useReducer<React.Reducer<FilterInputType, Action>>(filterReducer, {
    text: '',
    category: OPTION_ALL,
    visibility: OPTION_ALL,
  })
  const [selectedItem, setSelectedItem] = React.useState<Product>()

  const PAGE_SIZE = 10
  const { pagination, setPagination, renderPagination } = usePagination(
    () => productsData?.products?.total_count || 0,
    undefined,
    PAGE_SIZE,
    'sản phẩm',
  )

  const { source: market } = useSource()
  const source = props.source || market

  const debouncedSearchText = useDebounce(filterInput.text, 500)

  const [getProducts, { data: productsData, loading, refetch: refetchCurrentPage }] = useLazyQueryProducts({
    searchName: `%${debouncedSearchText}%`,
    pageSize: pagination.pageSize,
    currentPage: pagination.currentPage,
    filter: {
      source_code: source?.source_code,
      category_ids: filterInput.category && filterInput.category !== OPTION_ALL ? [+filterInput.category] : [],
      visibility:
        filterInput.visibility !== undefined && filterInput.visibility !== OPTION_ALL
          ? +filterInput.visibility
          : undefined,
    },
  })

  const handleSelectTableItem = React.useCallback((product: Product) => {
    setSelectedItem(product)
  }, [])

  const handleFilterChange = (type: keyof FilterInputType, value: string | undefined) => {
    dispatchFilterInput({ type, payload: value })
    setPagination({ currentPage: 1, pageSize: PAGE_SIZE })
  }

  const handleCloseModal = () => {
    setSelectedItem(undefined)
  }

  const [updateProduct] = useMutationUpdateProduct()

  const handleSubmitProduct = async ({
    sku,
    status,
    quantity,
    price,
    price_from,
    price_to,
    visibility,
  }: UpdateProduct) => {
    message.loading({ content: '...Đang cập nhật', key: 'updateProduct' })
    if (!source || !source.source_code) {
      message.error({ content: 'Cập nhật thất bại', key: 'updateProduct' })
      return
    }
    try {
      const result = await updateProduct({
        variables: {
          sku: sku || selectedItem?.sku || '',
          sourceCode: source?.source_code,
          status,
          quantity,
          price,
          price_from,
          price_to,
          visibility,
        },
      })
      if (result) {
        message.success({ content: 'Cập nhật sản phẩm thành công!', key: 'updateProduct' })
        handleCloseModal()
        refetchCurrentPage()
        return
      }
    } catch (error) {
      message.error({
        content: `Cập nhật sản phẩm thất bại!, ${JSON.parse(error.message)}`,
        key: 'updateProduct',
      })
    }
  }

  React.useEffect(() => {
    source && getProducts()
  }, [getProducts, source])

  const columns: ColumnProps<Product>[] = [
    {
      title: 'Sản phẩm',
      width: '40%',
      render: (d: Product) => (
        <div className="flex items-center">
          <img src={formatUrl(d.thumbnail_path)} width="56" alt="" style={{ objectFit: 'contain', height: 56 }} />
          <span className="ml-2">{htmlParse(d.name)}</span>
        </div>
      ), // accessor is the "key" in the data
    },
    {
      title: 'SKU',
      render: (p: Product) => <div>{htmlParse(p.sku)}</div>,
    },
    source?.source_code
      ? {
          title: 'Tình trạng',
          render: (data: Product) => {
            return (
              <div className="font-semibold flex items-center">
                {checkIfOutOfStock(data) ? (
                  <div className="text-red-600">
                    <CloseCircleFilled /> Hết hàng
                  </div>
                ) : (
                  <div className="text-green-600">
                    <CheckCircleFilled /> Còn hàng
                  </div>
                )}
              </div>
            )
          },
        }
      : {},
    source?.source_code ? { title: 'Số lượng', dataIndex: 'quantity' } : {},
    {
      title: 'Giá gốc',
      render: (p: Product) => <Money value={p.price} />,
    },
    source?.source_code ? { title: 'Giá bán', render: (d: Product) => <Money value={d.special_price} /> } : {},
    source?.source_code
      ? {
          title: 'Trạng thái kinh doanh',
          render: (p: Product & { visibility: number }) => (
            <Button
              type={p.visibility === ProductStatusOption.Unavailable ? 'primary' : 'default'}
              style={{ width: 64 }}
              onClick={() =>
                handleSubmitProduct({
                  sku: p.sku,
                  visibility:
                    p.visibility === ProductStatusOption.Available
                      ? ProductStatusOption.Unavailable
                      : ProductStatusOption.Available,
                })
              }>
              {p.visibility === ProductStatusOption.Unavailable ? 'Bật' : 'Tắt'}
            </Button>
          ),
        }
      : {},
    {
      title: '',
      render: (d: Product) => (
        <div style={{ paddingLeft: 4 }}>
          <Button type="default" onClick={() => handleSelectTableItem(d)}>
            {source?.source_code ? 'Sửa' : 'Xem'}
          </Button>
        </div>
      ),
    },
    props.actionOnProduct
      ? {
          title: 'Hành động',
          render: (p: Product) =>
            checkIfOutOfStock(p) ? null : (
              <div
                className="flex items-center"
                style={{ height: 56 }}
                onClick={e => {
                  e.stopPropagation()
                  props.actionOnProduct?.(p)
                }}>
                <PlusCircleFilled style={{ fontSize: '24px' }} />
              </div>
            ),
        }
      : {},
  ].filter(c => !props.visibleTitles || props.visibleTitles.indexOf(c.title || '') > -1)

  return source ? (
    <>
      <div className="self-end mb-8">{/* <Button onClick={handleShowModal}>Thêm sản phẩm</Button> */}</div>

      <TableWrapper className="flex-1">
        <FiltersGroup searchValue={filterInput} onValueChange={handleFilterChange} />
        <Table<Product>
          loading={loading}
          rowKey="id"
          // onRow={d => ({ onClick: () => handleSelectTableItem(d) })}
          dataSource={(productsData?.products?.items as Product[]) || []}
          columns={columns}
          scroll={{ y: 400 }}
          footer={() => renderPagination()}
        />
      </TableWrapper>
      <Modal isFullScreen isOpen={!!selectedItem} title="Sản phẩm" onRequestClose={handleCloseModal}>
        <ProductFormModal data={selectedItem} onSubmit={handleSubmitProduct} />
      </Modal>
    </>
  ) : (
    <div className="flex flex-1 items-center justify-center pt-48">
      <Empty description="Xem theo siêu thị để hiển thị sản phẩm của siêu thị đó" />
    </div>
  )
}

export default ProductPage
