import React from 'react'
import { UploadContext } from 'src/contexts/UploadContext'
// import { FirebaseContext } from '../../contexts/FirebaseContext'

type UploadType = {
  url?: string
  uploading: boolean
  error?: Error
  upload: (f: File) => Promise<void>
}

export const useUpload = (): UploadType => {
  const [url, setUrl] = React.useState<string>()
  const [uploading, setUploading] = React.useState(false)
  const [error, setError] = React.useState<Error>()
  // const { functions } = React.useContext(FirebaseContext)

  const { getImgUrl } = React.useContext(UploadContext)

  const upload = async (file: File) => {
    const newFormData = new FormData()
    newFormData.append('file', file)

    try {
      setUploading(true)
      const imgUrl = await getImgUrl(file)
      imgUrl ? setUrl(imgUrl) : setUrl(undefined)
    } catch (e) {
      setError(e)
    } finally {
      setUploading(false)
    }
  }

  return { url, uploading, error, upload }
}
