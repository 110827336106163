import { Query } from '@coop/apollo'
import { Button, Spin } from 'antd'
import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import styled from 'styled-components'
import { Money } from './Money'

const Label = styled('span').attrs({ className: 'uppercase font-normal text-gray-500' })``

type Props = {
  title: string
  data?: Pick<Query, 'completedOrders'>
  loading?: boolean
  fetchMore?: () => void
}

export const CompletedOrderList: React.FC<Props> = ({ title, data, loading, fetchMore }) => {
  return (
    <>
      <div className="mb-6 flex justify-between">
        <div className="text-lg font-normal">{title}</div>
        <Label>{loading ? <Spin /> : data?.completedOrders.total_count || 0}</Label>
      </div>
      <div className="w-full overflow-hidden flex-1">
        <PerfectScrollbar>
          {data?.completedOrders.items.map(item => (
            <div key={item.display_id} className="w-full p-3 mt-2 border border-gray-300">
              <div className="w-full flex justify-between">
                <Label>{item.display_id}</Label>
                <Money value={item.grand_total} />
              </div>
              <div className="w-full">{item.address.street?.length && item.address.street[0]}</div>
            </div>
          ))}
          {fetchMore && (
            <div className="w-full flex justify-center items-center py-2">
              <Button loading={loading} className="py-2 h-auto rounded-lg" onClick={() => fetchMore()}>
                Xem thêm
              </Button>
            </div>
          )}
        </PerfectScrollbar>
      </div>
    </>
  )
}
