import { Empty } from 'antd'
import React from 'react'
import { useSource } from 'src/contexts/SourceContext'
import StoreControlPage from './StoreControlPage'

type Props = {}

const ShippingControlPage: React.FC<Props> = () => {
  const { source } = useSource()

  return source ? (
    <>
      <StoreControlPage />
    </>
  ) : (
    <div className="flex flex-1 items-center justify-center">
      <Empty description="Xem theo siêu thị để hiển thị danh sách đơn vị vận chuyển" />
    </div>
  )
}

export default ShippingControlPage
