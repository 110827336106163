import { InboxOutlined } from '@ant-design/icons'
import { Button, Form, Input, message } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as Logo } from '../../assets/img/logo.svg'
import { useAuth } from '../../contexts/AuthContext'

const Screen = styled.div``
const Wrapper = styled.div``
const LoginButton = styled(Button)`
  width: 100%;
  height: 50px;
`

type Props = {}

const ForgotPassword: React.FC<Props> = () => {
  const history = useHistory()

  const [loading, setLoading] = React.useState(false)
  const { requestResetPassword } = useAuth()
  const [sentEmail, setSentEmail] = React.useState<string>()

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true)
      await requestResetPassword(values.email, {
        url: [...window.location.href.split(/\//).slice(0, 3), 'xac-thuc/dang-nhap/'].join('/') + values.email,
      })
      message.success('Email đã được gửi thành công!')
      setSentEmail(values.email)
    } catch (e) {
      console.error(e)
      message.error('Đã có lỗi xảy ra. Vui lòng thử lại sau!')
    } finally {
      setLoading(false)
    }
  }
  return (
    <Screen className="flex min-h-screen items-center" style={{ background: '#F4F5F6' }}>
      <Wrapper className="w-1/3 mx-auto bg-white pt-16" style={{ maxWidth: 500 }}>
        <div className="w-full flex justify-center mb-4">
          <Logo width="250" style={{ background: '#111226' }} className="p-2 rounded-lg" />
        </div>
        <Form size="large" onFinish={handleSubmit}>
          {!sentEmail && <div className="w-full text-center mb-4">{'Nhập tài khoản email được cung cấp'}</div>}
          <Form.Item
            className="px-8"
            name="email"
            rules={[{ required: true, message: 'Vui lòng nhập email!' }]}
            style={{ display: sentEmail ? 'none' : 'block' }}>
            <Input prefix={<InboxOutlined className="site-form-item-icon" />} placeholder="Email" />
          </Form.Item>
          {sentEmail && (
            <div className="p-8">
              <span className="text-gray-600">Email khôi phục mật khẩu đã được gửi đến email:</span>
              <div className="text-lg">{sentEmail}</div>
              <span className="text-gray-600">Vui lòng kiểm tra và làm theo hướng dẫn để thay đổi mật khẩu.</span>
            </div>
          )}
          <Form.Item className="text-center">
            <Button size="small" type="link" onClick={() => history.goBack()}>
              Quay lại
            </Button>
          </Form.Item>
          {sentEmail && (
            <div className="w-full text-center mb-1">
              <small>Không nhận được email?</small>
            </div>
          )}
          <LoginButton
            type="primary"
            htmlType="submit"
            className="login-form-button"
            disabled={loading}
            loading={loading}>
            {sentEmail ? 'Gửi lại' : 'Xác nhận'}
          </LoginButton>
        </Form>
      </Wrapper>
    </Screen>
  )
}

export default ForgotPassword
