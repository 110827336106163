import { PaymentTypeCode, PayType } from '@coop/common'
import { Button, Form, Input, message, Modal } from 'antd'
import React from 'react'
import { useFirebase } from 'src/contexts/FirebaseContext'
import { Order } from 'src/shared/model'
import { currencyFormat } from 'src/shared/utils'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import AddressAutocomplete from './AddressAutocomplete'
import ModalTransportFee from './ModalTransportFee'

const Name = styled('span')`
  ${tw`text-gray-900 uppercase`}
`

const Value = styled('span')`
  ${tw`text-gray-900 ml-4`}
`

const Container = styled('div')`
  ${tw`flex flex-col items-stretch`}
`

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const Info: React.FC<{ name: string; value: string | number | undefined }> = ({ name, value }) => (
  <div className="flex">
    <Name>{name}:</Name>
    <Value>{value}</Value>
  </div>
)

type ModalProps = {
  visible: boolean
  onClose: () => void
  order: Order & any
}

const ModalReOrderTransport = ({ visible, onClose, order }: ModalProps) => {
  const [form] = Form.useForm()
  const { functions } = useFirebase()
  const [confirmLoading, setConfirmLoading] = React.useState(false)
  const [feeLoading, setFeeLoading] = React.useState(false)
  const [mustSelectTransport, setMustSelectTransport] = React.useState(false)
  const [transportFeeModal, setTransportFeeModal] = React.useState(false)

  const { store, products, paymentMethod, total } = order
  const allDataForm = form.getFieldsValue(['address', 'lat', 'lng', 'name', 'mobile', 'couponCode', 'partner'])
  const storeAddress = {
    lat: store.loc.latitude,
    lng: store.loc.longitude,
    address: store.address || '',
    name: store.name,
    mobile: store.phone as string,
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { partner, couponCode, ...customerAddress } = allDataForm

  const listItem =
    products?.map((e: any) => ({
      _id: e?.product.sku,
      num: e?.amount,
      name: e?.product?.name,
      price: e?.product.price,
    })) || []

  const reOrderTransport = async () => {
    setConfirmLoading(true)
    const bodyRequest = {
      storeAddress: storeAddress,
      customerAddress: customerAddress,
      orderId: order?.id,
      partner: allDataForm?.partner?.toLowerCase(),
      totalPrice: total,
      listItem,
      paymentMethod:
        paymentMethod === PayType.CreditCard
          ? PaymentTypeCode.CreditCard
          : paymentMethod === PayType.ATM
          ? PaymentTypeCode.ATM
          : PaymentTypeCode.COD,
      comment: '',
      coupon: allDataForm?.couponCode || '',
      reOrder: true,
    }

    mustSelectTransport
      ? message.error('Phải chọn lại đơn vị vận chuyển sau khi thay đổi địa chỉ') && setConfirmLoading(false)
      : functions
          .httpsCallable('transport-createOrder')(bodyRequest)
          .then(() => message.success('Tạo lại đơn vận chuyển thành công') && onClose())
          .catch(error => message.error('Tạo lại đơn vận chuyển thất bại, vui lòng thử lại sau!', error))
          .finally(() => setConfirmLoading(false))
  }

  React.useEffect(() => {
    const { address, user, transport, fee } = order
    form.setFieldsValue({
      name: user?.name,
      mobile: user?.phone,
      address: address?.name,
      lat: address?.latitude,
      lng: address?.longitude,
      partner: transport?.type?.toLowerCase(),
      transportList: [{ type: [] }],
      couponCode,
      fee,
    })
  }, [couponCode, form, order])

  React.useEffect(() => {
    setFeeLoading(true)
    const bodyRequest = {
      storeAddress: storeAddress,
      customerAddress: customerAddress,
      listItem,
      totalPrice: total,
    }

    allDataForm?.lat &&
      functions
        .httpsCallable('transport-estimateOrderFeeManyService')(bodyRequest)
        .then(result => form.setFieldsValue({ transportList: result.data }))
        .catch(error => message.error('Không thể tìm ra loại hình vận chuyển', error))
        .finally(() => setFeeLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDataForm.lat, functions])

  return (
    <Modal
      visible={visible}
      centered
      title="Xác nhận đặt lại đơn vận chuyển"
      okText="Đặt lại"
      cancelText="Đóng"
      okType="danger"
      onCancel={onClose}
      onOk={reOrderTransport}
      confirmLoading={confirmLoading}
      width={1000}>
      <Container>
        <div className="mb-4">
          <span className="font-bold text-black uppercase">Thông tin giao hàng</span>
          <div className="mt-4" />
          <Info name="Cửa hàng" value="Russia Mart" />
          <Info name="Địa chỉ" value="19 Nguyễn Đình Chiều Quận 1" />
          <Info name="SĐT" value="0906876146" />
        </div>

        <div className="mt-4">
          <span className="font-bold text-black uppercase">Thông tin nhận hàng</span>
          <div className="mt-4" />

          <Form form={form} layout="vertical" {...layout}>
            <Form.Item
              style={{ marginBottom: 12 }}
              label="Tên người nhận hàng"
              name="name"
              required
              rules={[{ required: true, message: 'Vui lòng nhập họ tên người nhận hàng!' }]}>
              <Input placeholder="Tên" />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 12 }}
              label="Số điện thoại"
              name="mobile"
              required
              rules={[{ required: true, message: 'Vui lòng nhập số điện thoại!' }]}>
              <Input placeholder="Số điện thoại" />
            </Form.Item>

            <AddressAutocomplete form={form} setMustSelectTransport={(e: boolean) => setMustSelectTransport(e)} />

            <Form.Item>
              <Button type="primary" onClick={() => setTransportFeeModal(true)}>
                Chọn đơn vị vận chuyển
              </Button>
              <ModalTransportFee
                visible={transportFeeModal}
                feeLoading={feeLoading}
                setMustSelectTransport={(e: boolean) => setMustSelectTransport(e)}
                setVisible={(e: boolean) => setTransportFeeModal(e)}
                order={order}
                form={form}
              />
            </Form.Item>
            <Form.Item style={{ marginBottom: 12 }} label="Coupon đơn hàng :" name="couponCode">
              <Input
                placeholder="Nhập coupon cho đơn hàng "
                onChange={e => form.setFieldsValue({ fee: e.target.value })}
              />
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              Phí ship : <span>{currencyFormat(form.getFieldValue('fee') || '')}đ</span>
            </Form.Item>
          </Form>
        </div>
      </Container>
    </Modal>
  )
}

export default ModalReOrderTransport
