import React from 'react'

type Props = { type?: 'total' | 'declined'; value: React.ReactNode | number }

export const WorkHistory = ({ type = 'total', value }: Props) => (
  <div
    className="flex-1 flex flex-col p-4 rounded-lg bg-gray-200 text-gray-900"
    style={type === 'declined' ? { color: '#EF5236', backgroundColor: '#FEEDEA' } : {}}>
    <span className="opacity-75 uppercase">{type === 'total' ? 'Đã hoàn thành' : 'Từ chối'}</span>
    <span className="text-xl font-bold">{value}</span>
  </div>
)
