import { PlusOutlined } from '@ant-design/icons'
import { Spin, Upload } from 'antd'
import { UploadProps as AntUploadProps } from 'antd/lib/upload'
import React from 'react'
import styled from 'styled-components'

const Container = styled('div').attrs({
  className: 'absolute top-0 left-0 w-full h-full flex justify-center items-center',
})``

const uploadButton = (
  <Container>
    <PlusOutlined />
    <div className="ant-upload-text">Thêm ảnh</div>
  </Container>
)

type UploadProps = {
  upload: (file: File) => Promise<void>
  uploading?: boolean
  url?: string
  ratio?: number
} & AntUploadProps

export const UploadImage: React.FC<UploadProps> = ({ upload, uploading, url, ratio = 1, ...rest }) => {
  const uploadProps: AntUploadProps = {
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    customRequest: async ({ file }) => {
      upload(file as File)
    },
    ...rest,
  }

  return (
    <Upload listType="picture-card" className="w-full" {...uploadProps}>
      <div className="relative w-full" style={{ paddingTop: `calc(${ratio} * 100%)` }}>
        {uploading ? (
          <Container>
            <Spin tip="Uploading..." />
          </Container>
        ) : url ? (
          <img src={url} alt="" className="object-scale-down absolute top-0 left-0 w-full h-full" />
        ) : (
          uploadButton
        )}
      </div>
    </Upload>
  )
}
