import { ChannelConfigType, ENotificationLogType, ManagerRole } from '@coop/common'
import { Button, DatePicker, Input, message, Select, Table, Typography } from 'antd'
import { isEmpty, pickBy } from 'lodash'
import moment from 'moment'
import React from 'react'
import { CSVLink } from 'react-csv'
import { useAuth } from 'src/contexts/AuthContext'
import { dateFormat } from 'src/shared/utils'
import styled from 'styled-components'
// @ts-ignore
import tw from 'tailwind.macro'
import { FirebaseContext } from '../../contexts/FirebaseContext'
import { useFirestore } from '../../contexts/FirestoreContext'
import { Modal, TableWrapper } from '../../shared/components'
import PageTitle from '../../shared/components/PageTitle'
import SideMenu from '../../shared/components/SideMenu'
import { Notification, User } from '../../shared/model'
import { NotificationFormModal } from '../Notifications/NotificationFormModal'
import { CreateCartModal } from '../Orders/CreateCartModal'
import { customerColumns, customerHeader } from './CustomerColumn'
import { CustomerDrawer } from './CustomerDrawer'
import FormAddCustomers from './FormAddCustomers'

const Wrapper = styled.div``
const Header = styled.div``
const Content = styled.div`
  .ant-table thead tr > th {
    ${tw`text-gray-500 uppercase tracking-wider font-normal pb-2`}
  }
`
const SideMenuWrapper = styled.div.attrs({ className: 'flex-initial w-1/6 h-full mr-12' })``

type Props = {}

const Customers: React.FC<Props> = () => {
  const { Data, Util } = useFirestore()
  const { me } = useAuth()
  const { functions } = React.useContext(FirebaseContext)

  const [originCustomers, setOriginCustomers] = React.useState<User[]>([])
  const [customers, setCustomers] = React.useState<User[]>([])
  const [listState, setListState] = React.useState<string>('user')
  const [selectedCustomer, setSelectedCustomer] = React.useState<User>()
  const [showDrawer, setShowDrawer] = React.useState(false)
  const [showNotificationModal, setShowNotificationModal] = React.useState(false)
  const [showCreateOrderModal, setShowCreateOrderModal] = React.useState(false)
  const [channelList, setChannelList] = React.useState<ChannelConfigType[]>([])
  const [searchValue, setSearchValue] = React.useState({
    fromDate: moment().startOf('day').valueOf(),
    toDate: moment().endOf('day').valueOf(),
    channel: me?.channel || 'default',
  })

  const handleFilterChange = (value: string) => {
    const filteredData = originCustomers.filter(
      e => e?.name?.toLowerCase().includes(value.toLowerCase()) || e?.phone?.includes(value),
    )
    setCustomers(filteredData)
  }

  const handleSearch = () => {
    const isDefaultChannel = searchValue.channel === 'default'
    isDefaultChannel
      ? Data.user()
          .where('disabled', '==', listState === 'disabled-user')
          .where('createdAt', '>=', searchValue.fromDate)
          .get()
          .then(result => {
            const docsList = result.docs
              .map(doc => Util.convert<User>(doc))
              .filter(e => (e?.createdAt || 0) <= searchValue.toDate)
            setOriginCustomers(docsList)
            setCustomers(docsList)
            docsList.length === 0 && message.warn({ content: 'Không có dữ liệu khớp điều kiện' })
          })
          .catch(error => message.warn({ content: `Lỗi lấy dữ liệu ${error}` }))
      : Data.user()
          .where('disabled', '==', listState === 'disabled-user')
          .where('channel', '==', searchValue.channel)
          .where('createdAt', '>=', searchValue.fromDate)
          .get()
          .then(result => {
            const docsList = result.docs
              .map(doc => Util.convert<User>(doc))
              .filter(e => (e?.createdAt || 0) <= searchValue.toDate)
            setOriginCustomers(docsList)
            setCustomers(docsList)
            docsList.length === 0 && message.warn({ content: 'Không có dữ liệu khớp điều kiện' })
          })
          .catch(error => message.warn({ content: `Lỗi lấy dữ liệu ${error}` }))
  }

  const handleSelectItem = (c: User) => {
    setSelectedCustomer(c)
    setShowDrawer(true)
  }

  const handleCloseDrawer = () => {
    setSelectedCustomer(undefined)
    setShowDrawer(false)
  }

  const handleSendNotification = async (notification: Notification) => {
    const notifySpecificUser = functions.httpsCallable('messaging-notifySpecificUser')
    const submitNotificationLog = functions.httpsCallable('firestore-logNotifications')

    if (selectedCustomer) {
      try {
        await submitNotificationLog({
          notification: { ...pickBy(notification, _ => !isEmpty(_)), type: ENotificationLogType.specific },
          userId: selectedCustomer.id,
        })
        await notifySpecificUser({
          uid: selectedCustomer.id,
          notification: { ...notification, body: notification.shortDesc },
        })
        message.success('Gửi thông báo tới khách hàng thành công!')
      } catch (e) {
        message.error(`Đã có lỗi xảy ra. Vui lòng thử lại sau: ${e.message}`)
      }
    } else {
      message.error('Vui lòng chọn khách hàng để gửi thông báo!')
    }
  }

  const handleDrawerClickSendNoti = () => {
    setShowDrawer(false)
    setShowNotificationModal(true)
  }
  const handleDrawerOpenCreateOrder = () => {
    setShowDrawer(false)
    setShowCreateOrderModal(true)
  }

  const sideMenuItems = [
    { title: 'Đang hoạt động', onClick: () => setListState('user'), isActive: listState === 'user' },
    {
      title: 'Đã khóa tài khoàn',
      onClick: () => setListState('disabled-user'),
      isActive: listState === 'disabled-user',
    },
    { title: 'Tạo tài khoản mới', onClick: () => setListState('form'), isActive: listState === 'form' },
  ]

  React.useEffect(() => {
    me?.channel
      ? Data.user()
          .where('disabled', '==', listState === 'disabled-user')
          .where('channel', '==', me?.channel)
          .get()
          .then(result => {
            const docsList = result.docs.map(doc => Util.convert<User>(doc))
            const filtered = docsList.filter(d => !!d.phone && !!d.email)
            setOriginCustomers(filtered)
            setCustomers(filtered)
          })
          .catch(error => message.error('Lấy thông tin user không thành công', error.message))
      : Data.user()
          .where('disabled', '==', listState === 'disabled-user')
          .get()
          .then(result => {
            const docsList = result.docs.map(doc => Util.convert<User>(doc))
            const filtered = docsList.filter(d => !!d.phone && !!d.email)
            setOriginCustomers(filtered)
            setCustomers(filtered)
          })
          .catch(error => message.error('Lấy thông tin user không thành công', error.message))
  }, [Data, Util, listState, me])

  React.useEffect(() => {
    const isManager = me?.role === ManagerRole.Manager

    Data.channelConfig()
      .get()
      .then(result => {
        const resultChannelList = result.docs.map((e: any) => ({ ...e.data(), id: e.id })) as ChannelConfigType[]
        const managerChannelList = resultChannelList.filter(e => e.appId === me?.channel)
        setChannelList(isManager ? managerChannelList : resultChannelList)
      })
      .catch(error => message.error('Lấy thông tin channel không thành công', error.message))
  }, [Data, me])

  return (
    <Wrapper className="h-full w-full flex flex-col">
      <Header className="flex justify-between items-center mb-8">
        <PageTitle
          title="Khách hàng"
          description={`${customers.length} khách hàng ${
            listState === 'disabled-user' ? 'bị khóa tài khoản' : 'đang sử dụng dịch vụ'
          }`}
        />
      </Header>
      <Content className="flex flex-1">
        <SideMenuWrapper>
          <SideMenu data={sideMenuItems} type="state" />
        </SideMenuWrapper>
        {listState !== 'form' ? (
          <TableWrapper className="flex-1">
            <div className="flex justify-between pb-4">
              <div className="flex items-center">
                <Typography className="mr-4">Từ ngày</Typography>
                <DatePicker
                  className="mr-6 w-48"
                  defaultValue={moment()}
                  allowClear={false}
                  format={dateFormat}
                  onChange={value =>
                    setSearchValue(init => ({ ...init, fromDate: moment(value).startOf('day').valueOf() }))
                  }
                />
                <Typography className="mr-4">Đến ngày</Typography>
                <DatePicker
                  className="mr-6 w-48"
                  allowClear={false}
                  format={dateFormat}
                  defaultValue={moment()}
                  onChange={value =>
                    setSearchValue(init => ({ ...init, toDate: moment(value).endOf('day').valueOf() }))
                  }
                  // disabledDate={current => current && current >= moment().endOf('day')}
                />

                <Select
                  defaultValue={me?.channel || 'default'}
                  className="ml-4 w-64"
                  onChange={value => setSearchValue(init => ({ ...init, channel: value }))}>
                  {!me?.channel && <Select.Option value={'default'}>Chọn channel</Select.Option>}
                  {channelList.map((e, i) => (
                    <Select.Option key={`channel_${i}`} value={e.appId}>
                      {e.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="pl-4">
                <Button type="primary" className="mr-4" style={{ width: 120 }} onClick={() => handleSearch()}>
                  Tìm
                </Button>
                {customers.length === 0 ? (
                  <Button
                    type="primary"
                    style={{ width: 120 }}
                    onClick={() => message.warn('Không có dữ liệu để xuất')}>
                    Xuất báo cáo
                  </Button>
                ) : (
                  <CSVLink
                    filename={`customer-${moment().format(dateFormat)}.csv`}
                    data={customers.map(e => ({ ...e, createdAt: moment(e.createdAt).format(dateFormat) }))}
                    headers={customerHeader}>
                    <Button type="primary" style={{ width: 120 }}>
                      Xuất báo cáo
                    </Button>
                  </CSVLink>
                )}
              </div>
            </div>
            <Input
              allowClear
              placeholder="Lọc theo họ tên hoặc số điện thoại khách hàng"
              onChange={e => handleFilterChange(e.target.value)}
            />
            <Table<User>
              rowKey="id"
              dataSource={customers}
              columns={customerColumns}
              onRow={record => ({ onClick: () => handleSelectItem(record) })}
              pagination={{
                pageSize: 10,
                showTotal: (total, range) => `Hiển thị ${range[0]}-${range[1]} trong ${total} khách hàng`,
              }}
            />
          </TableWrapper>
        ) : (
          <FormAddCustomers />
        )}
      </Content>
      {selectedCustomer && (
        <CustomerDrawer
          customer={selectedCustomer}
          onClose={handleCloseDrawer}
          visible={showDrawer}
          onClickSendNotification={handleDrawerClickSendNoti}
          onClickOpenCreateOrder={handleDrawerOpenCreateOrder}
        />
      )}
      <Modal isFullScreen isOpen={showNotificationModal} onRequestClose={() => setShowNotificationModal(false)}>
        <NotificationFormModal onSubmit={handleSendNotification} />
      </Modal>
      <Modal isFullScreen isOpen={showCreateOrderModal} onRequestClose={() => setShowCreateOrderModal(false)}>
        <CreateCartModal onRequestClose={() => setShowCreateOrderModal(false)} customer={selectedCustomer} />
      </Modal>
    </Wrapper>
  )
}

export default Customers
