import { useLazyQueryProducts } from '@coop/apollo'
import { Spin } from 'antd'
import React, { useState } from 'react'
import { useSource } from 'src/contexts/SourceContext'
import { Money } from 'src/shared/components/Money'
import styled from 'styled-components'

const THead = styled.th`
  border: solid 1px #1890ff;
  text-align: center;
  padding: 8px;
  font-size: 1.5em;
`

const TDetail = styled.td`
  border: solid 1px #1890ff;
  padding: 8px;
  font-size: 1.1em;
`

const TDetailCenter = styled.td`
  border: solid 1px #1890ff;
  text-align: center;
  padding: 8px;
  font-size: 1.1em;
`

interface ProductCountProps {
  availableProduct: number
  outProduct: number
  availableQuantity: number
  originProductValue: number
  promoteProductValue: number
  maxPrice: number
  minPrice: number
}

const ProductReport: React.FC = () => {
  const { source } = useSource()

  const [allProductState, setAllProductState] = useState<ProductCountProps>({
    availableProduct: 0,
    outProduct: 0,
    availableQuantity: 0,
    originProductValue: 0,
    promoteProductValue: 0,
    maxPrice: 0,
    minPrice: 0,
  })

  const [getProducts, { data: productsData, loading }] = useLazyQueryProducts({
    pageSize: 1000000,
    currentPage: 1,
    filter: {
      source_code: source?.source_code,
      category_ids: null,
      stock_status: null,
    },
  })

  React.useEffect(() => {
    getProducts()
  }, [getProducts])

  React.useEffect(() => {
    if (productsData) {
      productsData.products?.items.map((e, i) => {
        const outOfStock = e.status === 0 || e.quantity === 0
        const availableProduct = !outOfStock ? 1 : 0
        const outProduct = outOfStock ? 1 : 0
        const availableQuantity = !outOfStock ? e.quantity : 0
        const originProductValue = !outOfStock ? e.price * e.quantity : 0
        const promoteProductValue = !outOfStock ? e.origin_special_price * e.quantity : 0
        setAllProductState(init => ({
          ...init,
          availableProduct: init.availableProduct + availableProduct,
          outProduct: init.outProduct + outProduct,
          availableQuantity: init.availableQuantity + availableQuantity,
          originProductValue: init.originProductValue + originProductValue,
          promoteProductValue: init.promoteProductValue + promoteProductValue,
          maxPrice: e.price > init.maxPrice ? e.price : init.maxPrice,
          minPrice: i === 0 ? e.price : e.price < init.minPrice ? e.price : init.minPrice,
        }))
      })
    }
  }, [productsData])

  return loading ? (
    <div className="flex justify-center">
      <Spin size={'large'} />
    </div>
  ) : (
    <div className="flex-1">
      <div className="flex justify-center my-4">
        <h1 className="text-lg font-bold">Thống kê sản phẩm cửa hàng</h1>
      </div>
      <table className="border-collapse border border-green-800 table-fixed" style={{ width: '100%' }}>
        <thead>
          <tr>
            <THead className="w-3/5">Loại thống kê </THead>
            <THead className="w-1/5">Giá trị </THead>
            <THead className="w-1/5">Đơn vị </THead>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TDetail>Tổng số mã sản phẩm đang có</TDetail>
            <TDetailCenter>{productsData?.products?.items.length}</TDetailCenter>
            <TDetailCenter>SKU</TDetailCenter>
          </tr>
          <tr>
            <TDetail>Tổng số mã sản phẩm còn hàng</TDetail>
            <TDetailCenter>{allProductState.availableProduct}</TDetailCenter>
            <TDetailCenter>SKU</TDetailCenter>
          </tr>
          <tr>
            <TDetail>Tổng số mã sản phẩm hết hàng</TDetail>
            <TDetailCenter>{allProductState.outProduct}</TDetailCenter>
            <TDetailCenter>SKU</TDetailCenter>
          </tr>
          <tr>
            <TDetail>Tổng số lượng sản phẩm đang có</TDetail>
            <TDetailCenter>{allProductState.availableQuantity}</TDetailCenter>
            <TDetailCenter>Sản phẩm</TDetailCenter>
          </tr>
          <tr>
            <TDetail>Sản phẩm có giá gốc cao nhất</TDetail>
            <TDetailCenter>
              <Money value={allProductState.maxPrice} />
            </TDetailCenter>
            <TDetailCenter>VND</TDetailCenter>
          </tr>
          <tr>
            <TDetail>Sản phẩm có giá gốc thấp nhất</TDetail>
            <TDetailCenter>
              <Money value={allProductState.minPrice} />
            </TDetailCenter>
            <TDetailCenter>VND</TDetailCenter>
          </tr>
          <tr>
            <TDetail>Tổng giá trị hàng tồn theo giá gốc</TDetail>
            <TDetailCenter>
              <Money value={allProductState.originProductValue} />
            </TDetailCenter>
            <TDetailCenter>VND</TDetailCenter>
          </tr>
          <tr>
            <TDetail>Tổng giá trị hàng tồn theo giá khuyến mãi</TDetail>
            <TDetailCenter>
              <Money value={allProductState.promoteProductValue} />
            </TDetailCenter>
            <TDetailCenter>VND</TDetailCenter>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default ProductReport
