import { firestore } from 'firebase/app'
import 'firebase/firestore'
import React from 'react'
import { Data, DataType, Dto, DtoType, Util, UtilType } from '../shared/model'
import { FirebaseContext } from './FirebaseContext'

type ContextType = {
  db: firestore.Firestore
  Data: DataType
  Dto: DtoType
  Util: UtilType
}

const FirestoreContext = React.createContext<ContextType | string>(
  'useFirestore should be used inside FirestoreProvider',
)

export const FirestoreProvider: React.FC = ({ children }) => {
  const { app } = React.useContext(FirebaseContext)

  const value = React.useMemo(() => {
    const db = app.firestore()
    // if (process.env.REACT_APP_ALGOLIA_INDEX === 'dev') {
    //   db.settings({
    //     host: 'localhost:8080',
    //     ssl: false,
    //   })
    // }

    return { db, Data: Data(db), Dto, Util }
  }, [app])

  return <FirestoreContext.Provider value={value}>{children}</FirestoreContext.Provider>
}

export const useFirestore = (): ContextType => {
  const context = React.useContext(FirestoreContext)
  if (typeof context === 'string') {
    throw new Error(context)
  }
  return context
}
