import { Input, Select } from 'antd'
import React from 'react'

const { Option } = Select

export type FilterInputType = {
  text: string | undefined
  status: string
}

type Props = {
  searchValue: FilterInputType
  onValueChange: (key: keyof FilterInputType, value: string | undefined) => void
}

const FiltersGroup: React.FC<Props> = ({ searchValue, onValueChange }) => {
  return (
    <div className="mb-8 flex items-center" style={{ backgroundColor: '#fafafa' }}>
      <div className="flex-1">
        <Input
          allowClear
          placeholder="Tìm kiếm theo tên, biển kiểm soát, số điện thoại"
          value={searchValue.text}
          onChange={e => onValueChange('text', e.target.value)}
        />
      </div>
      <div className="flex w-1/6 items-center ml-4">
        <label className="mr-2">Tình trạng</label>
        <Select style={{ flex: 1 }} value={searchValue.status} onChange={e => onValueChange('status', e)}>
          <Option value="all">Tất cả</Option>
          <Option value="online">Trực tuyến</Option>
          <Option value="offline">Ngoại tuyến</Option>
        </Select>
      </div>
    </div>
  )
}

export default FiltersGroup
