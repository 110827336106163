import { PlusOutlined } from '@ant-design/icons'
import MDEditor from '@uiw/react-md-editor'
import { Avatar, Col, Form, Input, message, Row, Spin, Upload } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { UploadProps } from 'antd/lib/upload'
import { FormikProvider, useFormik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { UploadContext } from '../../contexts/UploadContext'
import { ModalActionGroup, ModalHeader } from '../../shared/components/Modal'
import { Notification } from '../../shared/model'

const defaultValues: Notification = {
  id: '',
  title: '',
  shortDesc: '',
  body: '',
  image: '',
  createAt: new Date().getTime(),
}

const validationSchema = Yup.object<Notification>().shape({
  title: Yup.string().required('Vui lòng nhập tiêu đề!'),
  body: Yup.string().required('Vui lòng nhập nội dung!'),
  shortDesc: Yup.string().required('Vui lòng nhập nội dung!'),
})

type Props = {
  data?: Notification
  onSubmit: (data: Notification) => Promise<void>
}

export const NotificationFormModal: React.FC<Props> = ({ data, onSubmit }) => {
  const [loading, setLoading] = React.useState(false)

  const formik = useFormik<Notification>({
    initialValues: data || defaultValues,
    onSubmit: formData => {
      setLoading(true)
      onSubmit(formData).finally(() => setLoading(false))
    },
    validationSchema,
    initialTouched: {},
  })
  const { handleSubmit, values, errors, touched, setFieldValue, setFieldTouched } = React.useMemo(() => formik, [
    formik,
  ])

  const {
    uploadMultiple,
    upload,
    isUploading,
    multipleLoading,
    imageUrls,
    multipleImageUrls,
    cleanUp,
  } = React.useContext(UploadContext)

  const singleUploadProps: UploadProps = {
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    listType: 'picture-card',
    customRequest: async ({ file }) => {
      upload([file])
    },
  }

  const multipleUploadProps: UploadProps = {
    ...singleUploadProps,
    multiple: true,
    customRequest: async ({ file }) => {
      uploadMultiple([file])
    },
  }

  const copyLink = (link: string) => {
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(link)
      message.success('Đã copy link hình ảnh')
    } else {
      message.warn('Trình duyệt ko hỗ trợ copy')
    }
  }

  React.useEffect(() => {
    if (imageUrls.length) {
      setFieldValue('image', imageUrls[0])
      cleanUp()
    }
  }, [cleanUp, imageUrls, setFieldValue])

  return (
    <div className="w-full">
      <FormikProvider value={formik}>
        <Form labelCol={{ span: 4 }} className="flex flex-col w-full h-full" onFinish={() => handleSubmit()}>
          <ModalHeader
            title="Gửi tin nhắn"
            actionRenderer={<ModalActionGroup loading={loading} submitText={'Gửi'} />}
          />
          <div className="w-full mx-auto p-4 px-24 overflow-y-auto">
            <Row gutter={16}>
              <Col span={18}>
                <Form.Item
                  required
                  label="Tiêu đề"
                  help={touched.title && errors.title}
                  validateStatus={errors.title && touched.title ? 'error' : 'validating'}>
                  <Input
                    name="title"
                    placeholder="Tiêu đề"
                    value={values.title}
                    onFocus={() => setFieldTouched('title')}
                    onChange={e => setFieldValue('title', e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Nội dung ngắn"
                  help={touched.shortDesc && errors.shortDesc}
                  validateStatus={errors.shortDesc && touched.shortDesc ? 'error' : 'validating'}>
                  <TextArea
                    value={values.shortDesc}
                    onFocus={() => setFieldTouched('shortDesc')}
                    onChange={e => setFieldValue('shortDesc', e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Nội dung chi tiết"
                  help={touched.body && errors.body}
                  validateStatus={errors.body && touched.body ? 'error' : 'validating'}>
                  <MDEditor
                    value={values.body}
                    height={500}
                    onFocus={() => setFieldTouched('body')}
                    onChange={value => setFieldValue('body', value)}
                  />
                </Form.Item>
                <Form.Item label="Ảnh bìa">
                  <Upload {...singleUploadProps}>
                    {isUploading ? (
                      <Spin tip="uploading..." />
                    ) : values.image ? (
                      <img src={values.image} alt="" />
                    ) : (
                      <div>
                        <PlusOutlined />
                        <div className="ant-upload-text">Thêm ảnh</div>
                      </div>
                    )}
                  </Upload>
                </Form.Item>
                <Form.Item label="Ảnh nội dung chi tiết" className="flex">
                  <div className="w-auto float-left">
                    <Upload {...multipleUploadProps}>
                      {multipleLoading ? (
                        <Spin tip="uploading..." />
                      ) : (
                        <div>
                          <PlusOutlined />
                          <div className="ant-upload-text">Thêm ảnh</div>
                        </div>
                      )}
                    </Upload>
                  </div>
                  <div className="w-5/6 flex flex-wrap">
                    {multipleImageUrls.map((e, i) => (
                      <div className="w-1/5 pl-2 text-center" key={`imageNoti_${i}`}>
                        <div className="h-40 flex flex-wrap content-center justify-center border-dashed border-2 border-light-blue-500">
                          <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={e} alt={`galaxy mart ${i}`} />
                        </div>
                        <div
                          className="w-auto cursor-pointer rounded-md py-2 mt-4 bg-purple-500"
                          onClick={() => copyLink(e)}>
                          <p className="text-gray-200">Copy Link</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <div className="w-full rounded flex flex-col overflow-hidden">
                  <div className="w-full bg-gray-500 py-1 px-4">Galaxy Mart</div>
                  <div className="flex-1 flex bg-gray-300 p-4">
                    <Avatar src={values.image} size="large" shape="square" />
                    <div className="ml-4 flex-1 flex flex-col">
                      <p className="text-black">{values.title || 'Tiêu đề'}</p>
                      <p className="text-gray-700">{values.shortDesc || 'Tin nhắn hiển thị'}</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </FormikProvider>
    </div>
  )
}
