import { Button, message, Switch } from 'antd'
import React from 'react'
import { useFirestore } from 'src/contexts/FirestoreContext'
import { useSource } from 'src/contexts/SourceContext'
import styled from 'styled-components'
import { useFirebase } from '../../../contexts/FirebaseContext'

const Title = styled.div`
  text-transform: capitalize;
`

type Props = {}
interface PaymentConfigProp {
  atm?: boolean
  cod?: boolean
  creditCard?: boolean
  banktransfer?: boolean
  ew?: boolean
}

const PaymentControlPage: React.FC<Props> = () => {
  const { source } = useSource()
  const { Data } = useFirestore()
  const [paymentConfigState, setPaymentConfigState] = React.useState<PaymentConfigProp | null>(null)
  const [hasChange, setChange] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const { functions } = useFirebase()

  React.useEffect(() => {
    if (source) {
      Data.paymentStore()
        .doc(source.source_code)
        .onSnapshot(snapshot => {
          const config = snapshot.exists ? snapshot.data() : undefined
          config ? setPaymentConfigState(config) : setPaymentConfigState(null)
        })
    }
  }, [Data, source])

  const onSaveChange = async () => {
    if (!paymentConfigState) {
      return
    }
    const saveConfig = functions.httpsCallable('firestore-adminPaymentConfig')

    try {
      setLoading(true)
      await saveConfig({
        storeId: source?.source_code || '',
        config: paymentConfigState,
      })
      message.success('Lưu thành công')
      setChange(false)
    } catch (err) {
      message.error('Lưu thất bại, vui lòng thử lại')
    } finally {
      setLoading(false)
    }
  }

  const onChange = (checked: boolean, code: string) => {
    setChange(true)
    const config = { ...paymentConfigState, [code]: checked }
    setPaymentConfigState(config)
  }

  return (
    <div className="flex-1">
      <div className="flex flex-row w-1/3 mt-8">
        <Title className="flex-1 font-bold">Phương thức thanh toán</Title>
        <Title className="font-bold justify-end">Kích hoạt</Title>
      </div>

      <div key={'item-'}>
        {[
          { title: 'ATM', checked: paymentConfigState?.atm, value: 'atm' },
          { title: 'COD', checked: paymentConfigState?.cod, value: 'cod' },
          { title: 'Credit Card', checked: paymentConfigState?.creditCard, value: 'creditCard' },
          { title: 'Bank Transfer', checked: paymentConfigState?.banktransfer, value: 'banktransfer' },
          { title: 'eWallet (ZaloPay/Momo)', checked: paymentConfigState?.ew, value: 'ew' },
        ].map((e, i) => (
          <div key={`paymethod_${i}`} className="flex flex-row w-1/3 mt-8">
            <Title className="flex-1 font-bold">{e.title}</Title>
            <Switch checked={e.checked} onChange={checked => onChange(checked, e.value)} />
          </div>
        ))}
      </div>

      <Button loading={loading} disabled={!hasChange} className="mt-12" type="primary" onClick={onSaveChange}>
        Lưu thay đổi
      </Button>
    </div>
  )
}

export default PaymentControlPage
