import { EyeFilled } from '@ant-design/icons'
import { ManagerRole } from '@coop/common'
import { Button } from 'antd'
import React, { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useSource } from '../../contexts/SourceContext'
import ModalStoreList from './ModalStoreList'

type Props = {}

export const SelectStore: React.FC<Props> = () => {
  const { me, isStoreSelected, handleSelectStore } = useAuth()
  const { allSources } = useSource()

  const [showStoreList, setShowStoreList] = useState<boolean>(false)

  const canSelectStore = me?.role === ManagerRole.Admin || me?.role === ManagerRole.Manager

  return canSelectStore ? (
    isStoreSelected ? (
      <div>
        Đang xem{' '}
        <span className="font-bold">{allSources.find(s => s?.source_code === me?.sourceCode)?.name || ''}</span>.{' '}
        <Button onClick={() => handleSelectStore(undefined)}>Thoát</Button>
      </div>
    ) : (
      <>
        <ModalStoreList visible={showStoreList} setVisible={setShowStoreList} />
        <Button ghost onClick={() => setShowStoreList(true)}>
          Xem theo siêu thị <EyeFilled />
        </Button>
      </>
    )
  ) : null
}
