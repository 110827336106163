import { ManagerRole, OrderStatus, PayType, ProcessStatus, TransactionStatus } from '@coop/common'
import { Button, message, Timeline } from 'antd'
import { firestore } from 'firebase'
import React, { useReducer } from 'react'
import Modal from 'react-modal'
import PerfectScrollBar from 'react-perfect-scrollbar'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { useAuth } from '../../../contexts/AuthContext'
import { FirebaseContext } from '../../../contexts/FirebaseContext'
import { useFirestore } from '../../../contexts/FirestoreContext'
import { Log, Order, SystemOrder } from '../../../shared/model'
import { LaneType } from './styles'

const StyledItem = styled(Timeline.Item)`
  :last-child {
    padding-bottom: 0;
  }
  .ant-timeline-item-label {
    width: calc(20% - 12px) !important;
    ${tw`text-gray-500`}
  }
  .ant-timeline-item-tail {
    left: 20% !important;
  }
  .ant-timeline-item-head {
    left: 20% !important;
  }
  .ant-timeline-item-content {
    left: calc(20% - 4px) !important;
    width: calc(74% - 14px) !important;
  }
`

const Container = styled('div')`
  ${tw`w-full pt-2 flex flex-col items-stretch bg-white overflow-hidden rounded-md shadow-lg border-t border-gray-100`}
`
const StatusWidth = {
  [ProcessStatus.Pending]: '0%',
  [ProcessStatus.ReadyToPick]: '10%',
  [ProcessStatus.Picking]: '25%',
  [ProcessStatus.ReadyToDeliver]: '50%',
  [ProcessStatus.Delivering]: '75%',
  [ProcessStatus.Returning]: '50%',
  [ProcessStatus.Canceled]: '50%',
  [ProcessStatus.Finished]: '50%',
}

type Props = {
  systemOrder: SystemOrder
  onClickReturnOrder: () => void
  order: Order
}

enum ActionTypes {
  Close = 'CLOSE_MODAL',
  ContinueOrder = 'CONTINUE_ORDER',
  CancelOrder = 'CANCEL_ORDER',
  SetLoading = 'SET_LOADING',
  Complete = 'COMPLETE',
}

type Action = {
  type: ActionTypes
  payload?: any
}

enum ModalType {
  Cancel = 'cancel',
  Continue = 'continue',
  Complete = 'complete',
}

type Store = {
  show: boolean
  type: ModalType | null
  loading: boolean
}

function modalReducer(state: Store, action: Action) {
  switch (action.type) {
    case ActionTypes.Close:
      return { loading: false, show: false, type: null }
    case ActionTypes.CancelOrder:
      return { ...state, show: true, type: ModalType.Cancel }
    case ActionTypes.ContinueOrder:
      return { ...state, show: true, type: ModalType.Continue }
    case ActionTypes.Complete:
      return { ...state, show: true, type: ModalType.Complete }
    case ActionTypes.SetLoading:
      return { ...state, loading: true }
    default:
      return { ...state }
  }
}

export const OrderLogs: React.FC<Props> = ({ systemOrder, onClickReturnOrder, order }) => {
  const { Data, Util } = useFirestore()
  const [logs, setLogs] = React.useState<Log[]>([])
  const [showAll, setShowAll] = React.useState(false)

  const { me } = useAuth()
  const isManager = me?.role === ManagerRole.Manager
  const isAdmin = me?.role === ManagerRole.Admin
  const isOwner = me?.role === ManagerRole.Owner

  React.useEffect(() => {
    const unsub = Data.log(Data.systemOrder().doc(systemOrder.id))
      .orderBy('time', 'desc')
      .onSnapshot(snapshot => setLogs(snapshot.docs.map(doc => Util.convert<Log>(doc))))

    return unsub
  }, [Data, Util, systemOrder.id])

  const toggleShowAll = () => setShowAll(!showAll)

  const [modal, dispatchModal] = useReducer<React.Reducer<Store, Action>>(modalReducer, {
    show: false,
    type: null,
    loading: false,
  })

  const { functions } = React.useContext(FirebaseContext)

  const handleCancelOrder = async () => {
    const managerCancelOrder = functions.httpsCallable('firestore-managerCancelOrder')
    const adminCancelOrder = functions.httpsCallable('firestore-adminCancelOrder')

    try {
      dispatchModal({ type: ActionTypes.SetLoading })
      //! new follow & admin cancel order from owner request

      if (isAdmin || isManager) {
        await adminCancelOrder({ id: systemOrder.id })
      } else {
        await managerCancelOrder({ id: systemOrder.id })
      }
      message.success('Huỷ đơn thành công!')
    } catch (e) {
      message.error(`Không thể huỷ đơn, có lỗi xảy ra: ${e.message}`)
    } finally {
      dispatchModal({ type: ActionTypes.Close })
    }
  }

  const handleContinueOrder = async () => {
    const continuePicking = functions.httpsCallable('firestore-managerSetContinuePicking')
    const adminContinuePicking = functions.httpsCallable('firestore-adminSetContinuePicking')

    try {
      dispatchModal({ type: ActionTypes.SetLoading })

      if (isAdmin || isManager) {
        const skus = order.products.map(item => item.product.sku)
        await adminContinuePicking({ id: systemOrder.id, returnSkus: skus })
      } else {
        await continuePicking({ id: systemOrder.id })
      }

      message.success('Tiếp tục đơn hàng thành công!')
    } catch (e) {
      message.error(`Có lỗi xảy ra: ${e.message}`)
    } finally {
      dispatchModal({ type: ActionTypes.Close })
    }
  }

  const handleCompleteOrder = async () => {
    const completeFunc = functions.httpsCallable('firestore-adminCompletedOrder')
    try {
      dispatchModal({ type: ActionTypes.SetLoading })
      await completeFunc({ id: systemOrder.id })
      message.success('Hoàn thành đơn hàng thành công')
    } catch (e) {
      message.error(`Có lỗi xảy ra: ${e.message}`)
    } finally {
      dispatchModal({ type: ActionTypes.Close })
    }
  }

  const divRef = React.useRef<HTMLDivElement>(null)
  const bound = divRef.current?.getBoundingClientRect()
  const contentStyle: React.CSSProperties | undefined = bound && {
    top: bound.top,
    left: bound.left,
    width: bound.width,
    padding: 0,
    border: 0,
    maxHeight: 'fit-content',
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  }
  const cancelModalStyle: React.CSSProperties | undefined = bound && {
    top: bound.top + bound.height + 4,
    left: bound.left,
    width: bound.width,
    padding: 0,
    border: 0,
    maxHeight: 'fit-content',
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  }

  const alert =
    (systemOrder.attentionRequired && systemOrder.status === ProcessStatus.Picking) ||
    systemOrder.status === ProcessStatus.Returning
  const timelinebar = (
    <div className="bg-gray-400">
      <div
        className="h-1"
        style={{
          width: StatusWidth[systemOrder.status as LaneType],
          backgroundColor: alert ? '#EF5236' : '#309F7D',
        }}
      />
    </div>
  )

  return (
    <div className="flex-1 relative" ref={divRef}>
      <Container>
        <div className="w-full" onClick={toggleShowAll}>
          <Timeline mode="left" className="flex-1 overflow-x-hidden pl-3 pt-4 cursor-pointer">
            {logs?.[0] &&
              renderItem({
                log: logs[0],
                index: 0,
                lastItem: true,
                order,
                systemOrder,
                onClickReturnOrder,
                openModal: (type: ActionTypes) => dispatchModal({ type }),
                isManager,
                isAdmin: isAdmin || isManager,
                isOwner,
              })}
          </Timeline>

          {timelinebar}
        </div>
        <Modal
          onRequestClose={() => setShowAll(false)}
          isOpen={showAll}
          style={{ content: contentStyle, overlay: { background: 'transparent' } }}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc>
          <Container>
            <PerfectScrollBar className="pl-3" style={{ maxHeight: '300px' }}>
              <Timeline mode="left" className="flex-1 overflow-x-hidden pt-4 cursor-pointer">
                {logs.map((log, index) =>
                  renderItem({
                    log,
                    index,
                    lastItem: index === 0 ? true : false,
                    order,
                    systemOrder,
                    onClickReturnOrder,
                    openModal: (type: ActionTypes) => dispatchModal({ type }),
                    isManager,
                    isAdmin: isAdmin || isManager,
                    isOwner,
                  }),
                )}
              </Timeline>
            </PerfectScrollBar>
            {timelinebar}
          </Container>
        </Modal>

        <Modal
          onRequestClose={() => dispatchModal({ type: ActionTypes.Close })}
          isOpen={modal.show}
          style={{ content: cancelModalStyle, overlay: { background: 'rgba(0,0,0,0.5)' } }}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc>
          {modal.type === ModalType.Complete && (
            <Container className="p-4">
              <span className="text-gray-900 font-semibold">Hoàn thành đơn hàng?</span>
              <br />
              <div className="flex flex-row">
                <Button
                  loading={modal.loading}
                  className="rounded-lg mr-2 py-2 h-auto"
                  type="primary"
                  onClick={handleCompleteOrder}>
                  Xác nhận hoàn thành
                </Button>
                <Button className="rounded-lg py-2 h-auto" onClick={() => dispatchModal({ type: ActionTypes.Close })}>
                  Không
                </Button>
              </div>
            </Container>
          )}

          {modal.type === ModalType.Cancel && (
            <Container className="p-4">
              <span className="text-gray-900 font-semibold">Xác nhận hủy đơn?</span>
              <br />
              <div className="flex flex-row">
                <Button
                  loading={modal.loading}
                  className="rounded-lg mr-2 py-2 h-auto"
                  danger
                  type="primary"
                  onClick={handleCancelOrder}>
                  Xác nhận hủy
                </Button>
                <Button className="rounded-lg py-2 h-auto" onClick={() => dispatchModal({ type: ActionTypes.Close })}>
                  Không
                </Button>
              </div>
            </Container>
          )}
          {modal.type === ModalType.Continue && (
            <Container className="p-4">
              <span className="text-gray-900 font-semibold">Xác nhận</span>
              <span className="text-gray-500 text-sm">Tiếp tục thực hiện đơn hàng?</span>
              <br />
              <div className="flex flex-row">
                <Button
                  loading={modal.loading}
                  className="rounded-lg mr-2 py-2 h-auto"
                  type="primary"
                  onClick={handleContinueOrder}>
                  Xác nhận
                </Button>
                <Button className="rounded-lg py-2 h-auto" onClick={() => dispatchModal({ type: ActionTypes.Close })}>
                  Không
                </Button>
              </div>
            </Container>
          )}
        </Modal>
      </Container>
    </div>
  )
}

const Dot = ({ size, moreStyle }: { size: number; moreStyle: any }) => (
  <div
    style={{
      width: size,
      height: size,
      border: '2px solid #309F7D',
      borderRadius: '50%',
      ...(moreStyle ?? {}),
    }}
  />
)

type ItemProps = {
  log: Log
  index: number
  lastItem: boolean
  order: Order
  systemOrder: SystemOrder
  onClickReturnOrder: Props['onClickReturnOrder']
  openModal: (type: ActionTypes) => void
  isManager: boolean
  isAdmin: boolean
  isOwner: boolean
}

const renderItem = ({
  log,
  index,
  lastItem,
  systemOrder,
  order,
  openModal,
  onClickReturnOrder,
  isManager,
  isAdmin,
}: ItemProps) => {
  const isHighlight = Object.values(OrderStatus).includes(log.name as OrderStatus)
  const needToCancel = systemOrder.attentionRequired && systemOrder.status === ProcessStatus.Picking
  const needToReturn = systemOrder.status === ProcessStatus.Returning
  const alert = needToCancel || needToReturn
  const isPaymentFail =
    order.status === OrderStatus.Pending &&
    order.paymentMethod !== PayType.COD &&
    ![TransactionStatus.success, TransactionStatus.pending].includes(order.trxStatus as TransactionStatus)

  const canHandleCancelOrder = isAdmin || isManager

  const handleClickReturnOrder = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    onClickReturnOrder()
  }

  const handleClickCancelOrder = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    openModal(ActionTypes.CancelOrder)
  }

  const handleContinueOrder = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    openModal(ActionTypes.ContinueOrder)
  }

  const handleCompleteOrder = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    openModal(ActionTypes.Complete)
  }

  return (
    <StyledItem
      key={index}
      dot={
        <Dot
          size={isHighlight ? 12 : 10}
          moreStyle={{
            ...(isHighlight ? { backgroundColor: '#309F7D', size: 16 } : {}),
            ...(alert && index === 0 ? { backgroundColor: '#EF5236', border: '#EF5236', size: 16 } : {}),
          }}
        />
      }
      label={<Time time={log.time} />}>
      <div className="flex items-start flex-col">
        <div className="flex-1 flex flex-col justify-center">
          <p>{log.event}</p>
          <p className="text-gray-500">{log.creatorName}</p>
        </div>
        {/* on delevering status */}
        {systemOrder?.status === ProcessStatus.Delivering.toString() && isAdmin && lastItem && (
          <div className="flex justify-end w-full">
            <Button
              className="rounded-lg py-2 h-auto"
              style={{ marginRight: '10px' }}
              type="primary"
              onClick={handleCompleteOrder}>
              Hoàn thành
            </Button>
          </div>
        )}

        {index === 0 && isPaymentFail && isManager && (
          <div className="flex justify-end w-full">
            <Button className="rounded-lg py-2 h-auto" danger type="primary" onClick={handleClickCancelOrder}>
              Hủy đơn
            </Button>
          </div>
        )}
        {index === 0 && needToCancel && canHandleCancelOrder && (
          <div className="flex justify-end w-full">
            <Button className="rounded-lg py-2 h-auto" danger type="primary" onClick={handleClickCancelOrder}>
              Hủy đơn
            </Button>
            <Button className="rounded-lg py-2 h-auto ml-2" type="primary" onClick={handleContinueOrder}>
              Quay lại
            </Button>
          </div>
        )}
        {index === 0 && needToReturn && isManager && (
          <div className="flex justify-end w-full">
            <Button className="rounded-lg py-2 h-auto" danger type="primary" onClick={handleClickReturnOrder}>
              Trả hàng
            </Button>
          </div>
        )}
      </div>
    </StyledItem>
  )
}

const Locale = 'vi-VN'
const Time: React.FC<{ time?: firestore.Timestamp }> = ({ time }) => {
  const date = time?.toDate()
  return (
    <>
      <p className="break-words">
        <span className="font-thin">{date?.toLocaleDateString(Locale, { month: 'numeric', day: 'numeric' })} </span>
        {date?.toLocaleTimeString(Locale, { hour: '2-digit', minute: '2-digit' })}
      </p>
    </>
  )
}
