import firebase from 'firebase/app'
import 'firebase/functions'
import React from 'react'
import env from '../shared/Env'

type FirebaseContextType = {
  app: firebase.app.App
  functions: firebase.functions.Functions
}

const defaultValues: FirebaseContextType = {
  app: {} as firebase.app.App,
  functions: {} as firebase.functions.Functions,
}

export const FirebaseContext = React.createContext<FirebaseContextType>(defaultValues)

const app = firebase.initializeApp({
  name: 'Coop',
  apiKey: `${env.firebaseApiKey}`,
  authDomain: `${env.firebaseProject}.firebaseapp.com`,
  databaseURL: `https://${env.firebaseProject}.firebaseio.com`,
  projectId: `${env.firebaseProject}`,
})

export const FirebaseProvider: React.FC = ({ children }) => {
  const functions = React.useMemo(() => {
    const funcs = app.functions(env.firebaseRegion)
    // if (process.env.REACT_APP_ALGOLIA_INDEX === 'dev') {
    //   funcs.useFunctionsEmulator('http://localhost:5001')
    // }

    // throw new Error('error please')
    return funcs
  }, [])

  return <FirebaseContext.Provider value={{ app, functions }}>{children}</FirebaseContext.Provider>
}

export const useFirebase = (): FirebaseContextType => {
  const context = React.useContext(FirebaseContext)
  if (typeof context === 'string') {
    throw new Error(context)
  }
  return context
}
