import { Spin } from 'antd'
import React from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { useAbility } from '../../shared/accessControl'
import { MainLayout } from '../../shared/Layouts'
import { Customers } from '../Customers'
import { Employees } from '../Employees/Employees'
import { Home } from '../Home'
import { Notifications } from '../Notifications'
import { Orders } from '../Orders'
import { Products } from '../Products'
import { Report } from '../Report'
import { SystemConfig } from '../SystemConfig'

type Props = {}

const Main: React.FC<Props> = () => {
  const { pathname } = useLocation()
  const ability = useAbility()

  return (
    <MainLayout isFullScreen={pathname.includes('home')}>
      {!ability ? (
        <Spin />
      ) : (
        <Switch>
          <Route path="/home" component={Home} />
          {ability?.can('read', '/san-pham') && <Route path="/san-pham" component={Products} />}
          {ability?.can('read', '/don-hang') && <Route path="/don-hang" component={Orders} />}
          {ability?.can('read', '/khach-hang') && <Route path="/khach-hang" component={Customers} />}
          {ability?.can('read', '/nhan-su') && <Route path="/nhan-su" component={Employees} />}
          {ability?.can('read', '/thong-bao') && <Route path="/thong-bao" component={Notifications} />}
          {ability?.can('read', '/system') && <Route path="/system" component={SystemConfig} />}
          {ability?.can('read', '/report') && <Route path="/report" component={Report} />}
          <Redirect to="/home" />
        </Switch>
      )}
    </MainLayout>
  )
}

export default Main
