import { Category, useLazyQueryStore, useMutationUpdateStore, useQueryCategories, useQuerySources } from '@coop/apollo'
import React from 'react'
import { Market } from '../shared/model'
import { useAuth } from './AuthContext'

type StoreInfo = {
  phone: string
  openingTime?: number
  closingTime?: number
}

type SourceContext = {
  fetchCurrentSource: () => void
  updateCurrentStore: (storeInfo: StoreInfo) => Promise<void>
  updateCurrentStoreLoading: boolean
  source?: Market
  loading: boolean
  allSources: Market[]
  categories: Category[]
}

const Context = React.createContext<SourceContext | string>('useSource should be used inside SourceProvider')

type Props = {}

export const SourceProvider: React.FC<Props> = ({ children }) => {
  const { me } = useAuth()

  const [source, setSource] = React.useState<Market>()
  const [categories, setCategories] = React.useState<Category[]>([])
  const { data, loading } = useQuerySources({ lat: 10.7837, lng: 106.699139 })

  const [getCategories, { data: categoriesData, loading: categoriesLoading }] = useQueryCategories()
  const [getSource, { data: dataSource, loading: dataSourceLoading }] = useLazyQueryStore({
    fetchPolicy: 'network-only',
  })
  const [updateStore, { loading: updateCurrentStoreLoading }] = useMutationUpdateStore()

  React.useEffect(() => {
    if (!source) {
      return
    }
    getCategories({ variables: { sourceCode: source?.source_code } })
  }, [getCategories, source])

  React.useEffect(() => {
    setCategories((categoriesData?.categories || []) as Category[])
  }, [categoriesData])

  React.useEffect(() => {
    data && data.sources && setSource(data.sources.find(m => m?.source_code === me?.sourceCode) || undefined)
    return () => {
      setSource(undefined)
    }
  }, [data, me])

  const fetchCurrentSource = React.useCallback(() => {
    if (!source) {
      return
    }

    getSource({ variables: { sourceCode: source?.source_code as string } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source])

  const updateCurrentStore = React.useCallback(
    async ({ phone, openingTime, closingTime }: StoreInfo) => {
      if (!source) {
        return
      }

      await updateStore({
        variables: {
          source: {
            phone,
            openingTime,
            closingTime,
            source_code: source.source_code,
            name: source.name,
            latitude: source.latitude,
            longitude: source.longitude,
            enabled: source.enabled as boolean,
            street: source.street as string,
            postcode: source.postcode,
            country_id: source.country_id,
            region: source.region as string,
            distance: source.distance as number,
          },
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [source],
  )

  React.useEffect(() => {
    if (dataSourceLoading || !dataSource?.store) {
      return
    }
    // console.log('setSource', source?.source_code)
    setSource({ ...source, ...dataSource?.store })
  }, [dataSource, dataSourceLoading, source])

  const value: SourceContext = {
    fetchCurrentSource,
    updateCurrentStore,
    categories,
    source,
    loading: loading || categoriesLoading,
    updateCurrentStoreLoading,
    allSources: (data?.sources as Market[]) || [],
  }

  return <Context.Provider {...{ value, children }} />
}

export const useSource = (): SourceContext => {
  const context = React.useContext(Context)
  if (typeof context === 'string') {
    throw new Error(context)
  }
  return context
}
