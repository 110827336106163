import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useAbility } from 'src/shared/accessControl'
import styled from 'styled-components'
import SideMenu from '../../shared/components/SideMenu'
import { ChannelAppPage } from './ChannelAppPage'
import { DashboardConfigPage } from './DashboardConfigPage'
import { ManageHome } from './ManageHome'
import PaymentControlPage from './PaymentControlPage'
import { PromoPage } from './PromoPage'
import { ShippingControlPage } from './ShippingControlPage'
import StoreInfoControlPage from './StoreInfoControlPage'

const Wrapper = styled.div``
const LeftPanel = styled.div``
const Content = styled.div``
const SideMenuWrapper = styled.div.attrs({ className: 'flex-initial h-full mt-8 w-full' })``
type Props = {}

const SystemConfig: React.FC<Props> = () => {
  const ability = useAbility()

  const sideMenuItems = [
    ...(ability.can('read', '/system') ? [{ title: 'Quản lý cửa hàng', path: '/system' }] : []),
    ...(ability.can('read', '/system/khuyen-mai') ? [{ title: 'Quản lý khuyến mãi', path: '/system/khuyen-mai' }] : []),
    ...(ability.can('read', '/system/channel') ? [{ title: 'Quản lý Channel', path: '/system/quanly-channel' }] : []),
    ...(ability.can('read', '/system') ? [{ title: 'Quản lý Kệ hàng', path: '/system/quanly-kehang' }] : []),
    ...(ability.can('read', '/system/trang-chu')
      ? [{ title: 'Quản lý trang chủ', path: '/system/quanly-trangchu' }]
      : []),
    ...(ability.can('read', '/system')
      ? [{ title: 'Quản lý đơn vị vận chuyển', path: '/system/don-vi-van-chuyen' }]
      : []),
    ...(ability.can('read', '/system') ? [{ title: 'Quản lý phương thức thanh toán', path: '/system/payment' }] : []),
  ]

  return (
    <Wrapper className="w-full flex flex-1">
      {ability.can('read', 'systemConfig-side-menu') && (
        <LeftPanel className="flex flex-col w-1/6 mr-12">
          <SideMenuWrapper>
            <SideMenu data={sideMenuItems} />
          </SideMenuWrapper>
        </LeftPanel>
      )}
      <Content className="flex flex-1 flex-col">
        <Switch>
          {ability.can('read', '/system/khuyen-mai') && <Route path="/system/khuyen-mai" component={PromoPage} />}
          {ability.can('read', '/system/channel') && <Route path="/system/quanly-channel" component={ChannelAppPage} />}
          {ability.can('read', '/system') && <Route path="/system/quanly-kehang" component={DashboardConfigPage} />}
          {ability.can('read', '/system/trang-chu') && <Route path="/system/quanly-trangchu" component={ManageHome} />}
          {ability.can('read', '/system') && <Route path="/system/don-vi-van-chuyen" component={ShippingControlPage} />}
          {ability.can('read', '/system') && <Route path="/system/payment" component={PaymentControlPage} />}
          {ability.can('read', '/system') && <Route path="/system" component={StoreInfoControlPage} />}
          <Redirect to="/system" />
        </Switch>
      </Content>
    </Wrapper>
  )
}

export default SystemConfig
