import { ManagerRole, ProfileCol } from '@coop/common'
import { message, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { useAuth } from 'src/contexts/AuthContext'
import { useSource } from 'src/contexts/SourceContext'
import styled from 'styled-components'
import { FirebaseContext } from '../../contexts/FirebaseContext'
import { useFirestore } from '../../contexts/FirestoreContext'
import { Button, TableWrapper } from '../../shared/components'
import Modal from '../../shared/components/Modal'
import PageTitle from '../../shared/components/PageTitle'
import SideMenu from '../../shared/components/SideMenu'
import { Driver, Dto } from '../../shared/model'
import { DriverDrawer } from './DriverDrawer'
import { DriverDto, DriverFormModal } from './DriverFormModal'
import FiltersGroup, { FilterInputType } from './FiltersGroup'

const Wrapper = styled.div``
const Header = styled.div``
const Content = styled.div``
const SideMenuWrapper = styled.div.attrs({ className: 'flex-initial w-1/6 h-full mr-12' })``

type Props = {}

const Drivers: React.FC<Props> = () => {
  const { Data, Util } = useFirestore()
  const { functions } = React.useContext(FirebaseContext)
  const { me } = useAuth()
  const { allSources } = useSource()

  const [disabled, setDisabled] = React.useState<boolean>(false)
  const [originDriversList, setOriginDriversList] = React.useState<Driver[]>([])
  const [driversList, setDriversList] = React.useState<Driver[]>([])
  const [filterInput, setfilterInput] = React.useState<{ text: string; status: string }>({ text: '', status: 'all' })
  const [selectedDriver, setSelectedDriver] = React.useState<Driver>()
  const [showDrawer, setShowDrawer] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)

  const sideMenuItems = [
    {
      title: 'Đang hoạt động',
      onClick: () => setDisabled(false),
      isActive: !disabled,
    },
    {
      title: 'Đã khóa tài khoàn',
      onClick: () => setDisabled(true),
      isActive: disabled,
    },
  ]

  const columns: ColumnProps<Driver>[] = [
    {
      title: 'HỌ & TÊN',
      render: d => (
        <div className="flex items-center">
          <span className="ml-2">{d.name}</span>
        </div>
      ),
    },
    {
      title: 'Store',
      render: (data: Driver) => <div>{allSources.find(e => e.source_code === data?.store?.id)?.name}</div>,
    },
    {
      title: 'BIỂN KIỂM SOÁT',
      dataIndex: ['plate', 'code'],
    },
    {
      title: 'SỐ ĐIỆN THOẠI',
      dataIndex: 'phone',
    },
    {
      title: 'TRẠNG THÁI',
      render: d => (d.disabled ? 'Đã khóa' : d.isOl ? 'Đang trực tuyến' : 'Ngoại tuyến'),
    },
  ]

  const handleFilterChange = (key: keyof FilterInputType, value: string | undefined) => {
    setfilterInput(init => ({ ...init, [key]: value }))
  }

  const createDriver = React.useMemo(() => functions.httpsCallable('firestore-createDriver'), [functions])
  const deactivateDriver = React.useMemo(() => functions.httpsCallable('firestore-deactivateDriver'), [functions])
  const reactivateDriver = React.useMemo(() => functions.httpsCallable('firestore-reactivateDriver'), [functions])

  const handleShowModal = () => setShowModal(true)

  const handleCloseModal = () => {
    setShowModal(false)
    setSelectedDriver(undefined)
  }

  const handleSelectDriver = (d: Driver) => {
    setSelectedDriver(d)
    setShowDrawer(true)
  }

  const handleLockAccount = async (d: Driver) => {
    try {
      await deactivateDriver({ uid: d.id })
      message.success('Đã khóa tài xế!')
      return Promise.resolve()
    } catch (e) {
      message.error(`Có lỗi xảy ra: ${e.message}`)
      console.error(e)
    } finally {
      setShowModal(false)
      setSelectedDriver(undefined)
    }
  }

  const handleUnlockAccount = async (d: Driver) => {
    try {
      await reactivateDriver({ uid: d.id })
      message.success('Đã mở khóa tài xế!')
    } catch (e) {
      message.error(`Có lỗi xảy ra: ${e.message}`)
      console.error(e)
    } finally {
      setShowModal(false)
      setSelectedDriver(undefined)
    }
  }

  const handleCloseDrawer = () => {
    setShowDrawer(false)
    setSelectedDriver(undefined)
  }

  const handleClickEdit = (d: Driver) => {
    setShowDrawer(false)
    setSelectedDriver(d)
    setShowModal(true)
  }

  const handleSubmit = async (data: DriverDto) => {
    try {
      const { id, name, phone, avatar, plate, profile } = data
      const profileData = Dto.profile({
        ...profile,
        identity: {
          ...profile.identity,
          // @ts-ignore
          issuedDate: profile.identity.issuedDate.toMillis(),
        },
      })
      if (!selectedDriver) {
        // Add new
        await createDriver({ phone, name, plate, avatar, profile: profileData })
        message.success('Thêm tài xế thành công!')
        handleCloseModal()
      } else {
        // Update
        const updatePayload: Partial<Driver> = { name, plate, avatar }
        await Data.driver().doc(id).update(updatePayload)
        await Data.profile(Data.driver().doc(id)).doc(ProfileCol).set(profile, { merge: true })
        message.success('Cập nhật thông tin tài xế thành công!')
        handleCloseModal()
      }
    } catch (e) {
      message.error(`Có lỗi xảy ra: ${e.message}`)
      console.error(e)
    }
  }

  React.useEffect(() => {
    const { text, status } = filterInput
    const comparedText = text.toLocaleLowerCase()
    const filteredDrivers = originDriversList.filter(
      item =>
        item?.name?.toLocaleLowerCase().includes(comparedText) ||
        item?.plate?.code?.toLocaleLowerCase().includes(comparedText) ||
        item?.phone?.toLocaleLowerCase().includes(comparedText),
    )
    const filterStatusDrivers =
      status === 'online'
        ? filteredDrivers.filter(eF => eF.isOl)
        : status === 'offline'
        ? filteredDrivers.filter(eF => !eF.isOl)
        : filteredDrivers
    setDriversList(filterStatusDrivers)
  }, [filterInput, me, originDriversList])

  React.useEffect(() => {
    const isManager = me?.role === ManagerRole.Manager
    const defaultRef = Data.driver().where('disabled', '==', disabled)
    // const ref = me && me.sourceCode ? defaultRef.where('sourceCode', '==', me.sourceCode) : defaultRef
    const unsub = defaultRef.onSnapshot(snapshot => {
      const snapshotData = snapshot.docs.map(doc => Util.convert<Driver>(doc))
      const snapshotDriver = isManager
        ? snapshotData.filter(eF =>
            eF.store === null ? true : (me?.listSourceCode || []).indexOf(eF?.store?.id || '') > -1,
          )
        : snapshotData
      setOriginDriversList(snapshotDriver)
    })

    return () => unsub()
  }, [Data, Util, disabled, me])

  return (
    <Wrapper className="h-full w-full flex flex-col">
      <Header className="flex justify-between items-center mb-8">
        <PageTitle
          title="Tài xế"
          description={`${driversList.length} tài xế ${disabled ? 'đã khóa tài khoản' : 'đang tham gia hệ thống'}`}
        />
        <Button onClick={handleShowModal}>Thêm tài xế</Button>
      </Header>
      <Content className="flex flex-1">
        <SideMenuWrapper>
          <SideMenu data={sideMenuItems} type="state" />
        </SideMenuWrapper>
        <TableWrapper className="flex-1">
          <FiltersGroup searchValue={filterInput} onValueChange={handleFilterChange} />
          <Table<Driver>
            rowKey={'id'}
            dataSource={driversList}
            columns={columns}
            onRow={record => ({
              onClick: () => handleSelectDriver(record),
            })}
            pagination={{
              pageSize: 10,
              showTotal: (total, range) => `Hiển thị ${range[0]}-${range[1]} trong ${total} tài xế`,
            }}
          />
        </TableWrapper>
      </Content>
      <Modal
        isFullScreen
        title={'Thêm tài xế'}
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        shouldCloseOnEsc={false}>
        <DriverFormModal data={selectedDriver} onSubmit={handleSubmit} />
      </Modal>
      {selectedDriver && (
        <DriverDrawer
          driver={selectedDriver}
          visible={showDrawer}
          onClose={handleCloseDrawer}
          handleLockAccount={handleLockAccount}
          handleUnlockAccount={handleUnlockAccount}
          handleClickEdit={handleClickEdit}
        />
      )}
    </Wrapper>
  )
}

export default Drivers
