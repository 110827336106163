/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Input, message, Switch } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { useFirebase } from 'src/contexts/FirebaseContext'
import { useFirestore } from 'src/contexts/FirestoreContext'
import { useSource } from 'src/contexts/SourceContext'
import { Driver, TransportSystem } from 'src/shared/model'
import styled from 'styled-components'
import StoreTransportList from './StoreTransportList'

const Title = styled.div`
  text-transform: capitalize;
`

type Props = {}

const StoreControlPage: React.FC<Props> = () => {
  const { source } = useSource()
  const { Data, Util } = useFirestore()
  const [transportSystem, setTransportSystem] = React.useState<TransportSystem[]>([])
  const [transportStore, setTransportStore] = React.useState<any>({})
  const [storeOriginDriverList, setStoreOriginDriverList] = React.useState<Driver[]>([])
  const [shippingFee, setShippingFee] = React.useState<number>(0)
  const [hasChange, setHasChange] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const { functions } = useFirebase()

  const onSaveChange = () => {
    const saveConfig = functions.httpsCallable('firestore-adminTransportConfig')
    const configDetail = { storeId: source?.source_code, config: transportStore, shippingFee: { inhouse: shippingFee } }

    setLoading(true)
    if (transportStore?.inhouse && storeOriginDriverList.length === 0) {
      message.error('Phải chọn ít nhất 1 tài xế cho inhouse', 3)
      setLoading(false)
    } else {
      saveConfig(configDetail)
        .then(() => {
          setHasChange(false)
          message.success('Lưu thành công', 2)
        })
        .catch(e => message.error(`Lưu thất bại, vui lòng thử lại ${e}`, 2))
        .finally(() => setLoading(false))
    }
  }

  const onChange = (checked: boolean, code: string) => {
    setHasChange(true)
    const newTransportList =
      code === 'inhouse'
        ? Object.entries(transportStore).reduce((p, [k]) => ({ ...p, [k]: k === code ? checked : false }), {})
        : { ...transportStore, inhouse: false, [code]: checked }

    const enableOne = Object.entries(newTransportList).filter(([_, v]) => v === true)[0]
    !enableOne ? message.error('Không thể tắt hết loại hình vận chuyển', 3) : setTransportStore(newTransportList)
  }

  React.useEffect(() => {
    transportSystem.map((e: TransportSystem) => setTransportStore((init: any) => ({ [e.code]: false, ...init })))
  }, [transportSystem])

  React.useEffect(() => {
    Data.transportSystem().onSnapshot(snapshot => {
      const system = snapshot.docs.map(doc => Util.convert<TransportSystem>(doc))
      setTransportSystem(system)
    })
    Data.transportStore()
      .doc(source?.source_code)
      .onSnapshot(snapshot => {
        const config = snapshot.exists ? snapshot.data() : null
        setTransportStore(config)
      })
    Data.transportStore()
      .doc(source?.source_code)
      .collection('ShippingFee')
      .get()
      .then(docs => docs.forEach(doc => doc.exists && setShippingFee(doc.data().fee)))
  }, [Data, Util, source])

  return (
    <div className="flex-1">
      <div className="flex flex-row w-1/3 mt-8">
        <Title className="flex-1 font-bold">Đơn vị vận chuyển</Title>
        <Title className="font-bold justify-end">Kích hoạt</Title>
      </div>
      {transportSystem.map(
        (e, i) =>
          e.isActive && (
            <div key={`transport_select-${i}`}>
              <div className="flex flex-row w-1/3 mt-8">
                <p className="flex-1 font-bold">{e?.title}</p>
                <Switch checked={transportStore[e?.code] || false} onChange={checked => onChange(checked, e.code)} />
              </div>
              {transportStore?.inhouse && e.code === 'inhouse' && (
                <>
                  <StoreTransportList
                    transportStore={transportStore}
                    storeOriginDriverList={storeOriginDriverList}
                    setStoreOriginDriverList={setStoreOriginDriverList}
                  />
                  <Text>Phí vận chuyển: </Text>
                  <Text strong>{shippingFee.toLocaleString()} đ </Text>
                  <Text strong type="danger">
                    {shippingFee === 0 ? ' (Freeship)' : ''}
                  </Text>
                  <div>
                    <Input
                      value={shippingFee}
                      onKeyPress={evt =>
                        (evt.keyCode >= 48 && evt.keyCode <= 57) || (evt.keyCode >= 96 && evt.keyCode <= 105)
                          ? false
                          : true
                      }
                      onChange={ele => {
                        setShippingFee(
                          ele?.target?.value.length > 0
                            ? window.parseInt(ele?.target?.value.replace(/[xX]/g, '') || '0')
                            : 0,
                        )
                        setHasChange(true)
                      }}
                      style={{ width: '20%' }}
                      // placeholder={'Nhập phí vận chuyển, để 0 nếu muốn free ship'}
                    />
                  </div>
                </>
              )}
            </div>
          ),
      )}
      <Button loading={loading} disabled={!hasChange} className="mt-12" type="primary" onClick={onSaveChange}>
        Lưu thay đổi
      </Button>
    </div>
  )
}

export default StoreControlPage
