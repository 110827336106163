import { CloseOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'
import RModal from 'react-modal'
import styled from 'styled-components'
import { useWindowSize } from '../../../contexts/WindowSizeContext'

const StyledModal = styled(RModal)<{ height?: number; transparent?: boolean }>`
  &.ReactModal__Content {
    overflow: hidden;
    display: flex;
    transform: translateY(200px);
    transition: all 200ms ease-in-out;
    background: ${props => (props.transparent ? 'transparent !important' : '')};
  }

  &.ReactModal__Content--after-open {
    transform: translateY(0px);
  }

  &.ReactModal__Content--before-close {
    transform: translateY(200px);
  }
`

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: 0,
    background: '#fff',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}

type Props = {
  isOpen: boolean
  shouldCloseOnEsc?: boolean
  shouldCloseOnOverlayClick?: boolean
  onRequestClose?: () => void
  isFullScreen?: boolean
  title?: string | React.ReactNode
  transparent?: boolean
}

const Modal: React.FC<Props> = ({
  isOpen,
  isFullScreen = false,
  shouldCloseOnEsc = true,
  shouldCloseOnOverlayClick = true,
  onRequestClose,
  transparent,
  children,
}) => {
  const { height } = useWindowSize()
  return (
    <StyledModal
      closeTimeoutMS={200}
      // @ts-ignore
      style={isFullScreen ? { content: { height: `${height}px` } } : modalStyle}
      transparent={transparent}
      isOpen={isOpen}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onRequestClose={onRequestClose}>
      <div className={isFullScreen ? 'w-screen h-full bg-white overflow-hidden flex' : 'w-auto flex overflow-hidden'}>
        <Button
          type="link"
          size="large"
          onClick={onRequestClose}
          style={{ position: 'absolute', left: 12, top: 5, zIndex: 70 }}
          className="hover:text-red-600">
          <CloseOutlined className="text-red-500" />
        </Button>
        {children}
      </div>
    </StyledModal>
  )
}

export default Modal
