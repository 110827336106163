import { Source } from '@coop/apollo'
import firebase from 'firebase'
import React from 'react'
import defineAbilityFor, { AbilityType } from '../shared/accessControl/ability'
import { Manager } from '../shared/model'
import { FirebaseContext } from './FirebaseContext'
import { useFirestore } from './FirestoreContext'

type AuthContext = {
  signIn(email: string, password: string): Promise<void>
  signOut(): Promise<void>
  requestResetPassword(email: string, actionCodeSettings?: firebase.auth.ActionCodeSettings): Promise<void>
  loading: boolean
  user?: firebase.User | null
  me?: Manager
  isStoreSelected?: boolean
  ability?: AbilityType
  handleSelectStore(sourceCode?: Source['source_code']): void
}

const Context = React.createContext<AuthContext | string>('useAuth should be used inside AuthProvider')

type Props = {}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const { app } = React.useContext(FirebaseContext)
  const { Data, Util } = useFirestore()

  const [loading, setLoading] = React.useState(true)
  const [user, setUser] = React.useState<firebase.User | null>()
  const [me, setMe] = React.useState<Manager>()

  const auth = React.useMemo(() => app.auth(), [app])

  const [isStoreSelected, setIsStoreSelected] = React.useState(false)

  const [ability, setAbility] = React.useState<AbilityType>()

  React.useEffect(() => {
    me && setAbility(defineAbilityFor(me))
  }, [me, isStoreSelected])

  const handleSelectStore = (sourceCode?: Source['source_code']) => {
    if (me?.role) {
      setIsStoreSelected(sourceCode ? true : false)
      setMe({ ...me, sourceCode })
    }
  }

  const signIn = async (email: string, password: string) => {
    try {
      setLoading(true)
      await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      await auth.signInWithEmailAndPassword(email, password)
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e)
    } finally {
      setLoading(false)
    }
  }

  const signOut = async () => {
    await auth.signOut()
    setIsStoreSelected(false)
  }

  const requestResetPassword = async (email: string, actionCodeSettings?: firebase.auth.ActionCodeSettings) => {
    try {
      setLoading(true)
      await auth.sendPasswordResetEmail(email, actionCodeSettings)
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async authState => {
      setLoading(true)
      setUser(authState)
      const _me = authState && Util.convert<Manager>(await Data.manager().doc(authState.uid).get())
      setMe(_me || undefined)
      setLoading(false)
    })
    return () => {
      unsubscribe()
    }
  }, [Data, Util, auth])

  React.useEffect(() => {
    const unsubscribe = user
      ? Data.manager()
          .doc(user?.uid)
          .onSnapshot(snapshot => {
            const userInFireStore = snapshot?.exists ? { ...snapshot.data() } : null
            userInFireStore?.disabled && setUser(undefined)
          })
      : () => {}

    return () => unsubscribe()
  }, [Data, user])

  const value: AuthContext = {
    signIn,
    signOut,
    loading,
    me,
    user,
    requestResetPassword,
    isStoreSelected,
    handleSelectStore,
    ability,
  }

  return <Context.Provider {...{ value, children }} />
}

export const useAuth = (): AuthContext => {
  const context = React.useContext(Context)
  if (typeof context === 'string') {
    throw new Error(context)
  }
  return context
}
