// @ts-ignore
import { ManagerRole, ProfileCol } from '@coop/common'
import { message, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { useAuth } from 'src/contexts/AuthContext'
import { useSource } from 'src/contexts/SourceContext'
import styled from 'styled-components'
import { FirebaseContext } from '../../contexts/FirebaseContext'
import { useFirestore } from '../../contexts/FirestoreContext'
import { Button, Modal, TableWrapper } from '../../shared/components'
import PageTitle from '../../shared/components/PageTitle'
import SideMenu from '../../shared/components/SideMenu'
import { Dto, Manager } from '../../shared/model'
import FiltersGroup, { FilterInputType } from './FiltersGroup'
import { ManagerDrawer } from './ManagerDrawer'
import ManagerFormModal, { ManagerModalType } from './ManagerFormModal'

const Wrapper = styled.div``
const Header = styled.div``
const Content = styled.div``
const SideMenuWrapper = styled.div.attrs({ className: 'flex-initial h-full w-1/6 mr-12' })``

const PAGE_SIZE = 10

type Props = {}

const ManagerPage: React.FC<Props> = () => {
  const { Data, Util } = useFirestore()
  const { allSources } = useSource()
  const [sideFilter, setSideFilter] = React.useState<'active' | 'inactive'>('active')
  const [showDrawer, setShowDrawer] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)
  const [selectedItem, setSelectedItem] = React.useState<Manager>()
  const [originUsersList, setOriginUsersList] = React.useState<Manager[]>([])
  const [usersList, setUsersList] = React.useState<Manager[]>([])
  const [filterInput, setfilterInput] = React.useReducer<React.Reducer<FilterInputType, Partial<FilterInputType>>>(
    (state, newState) => ({ ...state, ...newState }),
    { text: '' },
  )

  const columns: ColumnProps<Manager>[] = [
    {
      title: 'Họ tên',
      dataIndex: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Store',
      render: (data: Manager) => {
        return <div>{allSources.find(e => e.source_code === data.sourceCode)?.name}</div>
      },
    },
    {
      title: 'Quyền',
      dataIndex: 'role',
    },
    {
      title: 'Trạng thái',
      render: d => (d.disabled ? 'Chờ kích hoạt' : 'Đang hoạt động'),
    },
  ]

  const { functions } = React.useContext(FirebaseContext)
  const { me } = useAuth()

  const deactivateManager = functions.httpsCallable('firestore-deactivateManager')
  const reactivateManager = functions.httpsCallable('firestore-reactivateManager')
  const isManager = me?.role === ManagerRole.Manager

  const sideMenuItems = [
    {
      title: 'Đang hoạt động',
      onClick: () => setSideFilter('active'),
      isActive: sideFilter === 'active',
    },
    {
      title: 'Chờ kích hoạt',
      onClick: () => setSideFilter('inactive'),
      isActive: sideFilter === 'inactive',
    },
  ]

  const handleFilterChange = (key: keyof FilterInputType, value: string | undefined) => {
    setfilterInput({ [key]: value })
  }

  const handleCloseModal = () => {
    setSelectedItem(undefined)
    setShowModal(false)
  }

  const handleSubmit = async (data: ManagerModalType) => {
    try {
      const { avatar, name, role, sourceCode, disabled, profile } = data
      const profileData = Dto.profile({
        ...profile,
        identity: {
          ...profile.identity,
          // @ts-ignore
          issuedDate: profile.identity.issuedDate.toMillis(),
        },
      })
      if (!selectedItem) {
        // Add new
        const createManager = functions.httpsCallable('firestore-createManager')
        const userRecord = await createManager({
          ...data,
          profile: profileData,
        })
        const { uid } = userRecord.data
        disabled ? await deactivateManager({ uid }) : await reactivateManager({ uid })
        message.success('Thêm nhân sự thành công!')
        handleCloseModal()
      } else {
        // Update
        const updateData = { avatar, name, role, sourceCode: sourceCode || null }
        await Data.manager().doc(selectedItem.id).update(updateData)
        await Data.profile(Data.manager().doc(selectedItem.id)).doc(ProfileCol).set(profile, { merge: true })
        disabled ? await deactivateManager({ uid: selectedItem.id }) : await reactivateManager({ uid: selectedItem.id })
        message.success('Cập nhật thông tin nhân sự thành công!')
        handleCloseModal()
      }
    } catch (e) {
      message.error(`Có lỗi xảy ra: ${e.message}`)
      console.error(e)
    }
  }

  const handleLockAccount = async (m: Manager) => {
    try {
      await deactivateManager({ uid: m.id })
      message.success('Đã khóa tài khoản quản lý!')
      return Promise.resolve()
    } catch (e) {
      message.error(`Có lỗi xảy ra: ${e.message}`)
      console.error(e)
    } finally {
      setShowModal(false)
      setSelectedItem(undefined)
    }
  }

  const handleUnlockAccount = async (m: Manager) => {
    try {
      await reactivateManager({ uid: m.id })
      message.success('Đã kích hoạt tài khoản quản lý!')
    } catch (e) {
      message.error(`Có lỗi xảy ra: ${e.message}`)
      console.error(e)
    } finally {
      setShowModal(false)
      setSelectedItem(undefined)
    }
  }

  const handleCloseDrawer = () => {
    setShowDrawer(false)
    setSelectedItem(undefined)
  }

  const handleClickEdit = (m: Manager) => {
    setShowDrawer(false)
    setSelectedItem(m)
    setShowModal(true)
  }

  React.useEffect(() => {
    const { text } = filterInput
    if (text) {
      const matchingText = text.toLocaleLowerCase()
      const filteredUser = originUsersList.filter(
        item =>
          item.name?.toLocaleLowerCase().includes(matchingText) ||
          item.email?.toLocaleLowerCase().includes(matchingText),
      )
      setUsersList(filteredUser)
    }
  }, [filterInput, originUsersList])

  React.useEffect(() => {
    if (sideFilter === 'active') {
      const activeUser = originUsersList.filter(item => !item.disabled)
      setUsersList(activeUser)
    } else if (sideFilter === 'inactive') {
      const inactiveUser = originUsersList.filter(item => item.disabled === true)
      setUsersList(inactiveUser)
    }
  }, [isManager, me, originUsersList, sideFilter])

  React.useEffect(() => {
    const unsub = Data.manager().onSnapshot(snapshot => {
      const userList = snapshot.docs.map(doc => Util.convert<Manager>(doc))
      const userListInChannel = userList
        .filter(e => (me?.listSourceCode || []).indexOf(e?.sourceCode || '') > -1)
        .filter(eF => eF.role === ManagerRole.Owner)
      setOriginUsersList(isManager ? userListInChannel : userList)
    })
    return () => unsub()
  }, [Data, Util, isManager, me])

  return (
    <Wrapper className="w-full flex flex-col">
      <Header className="flex justify-between items-center mb-8">
        <PageTitle title="Quản lý" description="" />
        <Button onClick={() => setShowModal(true)}>Thêm quản lý</Button>
        <Modal
          isFullScreen
          title={selectedItem ? 'Cập nhật quản lý' : 'Thêm quản lý'}
          isOpen={showModal}
          shouldCloseOnEsc={false}
          onRequestClose={handleCloseModal}>
          <ManagerFormModal onSubmit={handleSubmit} data={selectedItem} />
        </Modal>
      </Header>
      <Content className="flex flex-1">
        <SideMenuWrapper>
          <SideMenu data={sideMenuItems} type="state" />
        </SideMenuWrapper>
        <TableWrapper className="flex-1">
          <FiltersGroup searchValue={filterInput} onValueChange={handleFilterChange} />
          <Table
            rowKey={'email'}
            dataSource={usersList}
            columns={columns}
            onRow={record => ({
              onClick: () => {
                setSelectedItem(record)
                setShowDrawer(true)
              },
            })}
            pagination={{
              pageSize: PAGE_SIZE,
              showTotal: (total, range) => `Hiển thị ${range[0]}-${range[1]} trong ${total} managers`,
            }}
          />
        </TableWrapper>
      </Content>
      {selectedItem && (
        <ManagerDrawer
          manager={selectedItem}
          visible={showDrawer}
          onClose={handleCloseDrawer}
          handleLockAccount={handleLockAccount}
          handleUnlockAccount={handleUnlockAccount}
          handleClickEdit={handleClickEdit}
        />
      )}
    </Wrapper>
  )
}

export default ManagerPage
