import { Tabs } from 'antd'
import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { useAbility } from '../../shared/accessControl'
import { Drivers } from '../Drivers'
import { Managers } from '../Managers'
import Pickers from '../Picker/Pickers'

const Wrapper = styled.div``
const StyledTabs = styled(Tabs)`
  .ant-tabs-tab.ant-tabs-tab-active {
    ${tw`text-gray-900 font-bold`}
  }
`

type Props = {}

export const Employees: React.FC<Props> = () => {
  const ability = useAbility()

  return (
    <Wrapper className="w-full flex-1">
      <StyledTabs className="w-full">
        {ability.can('read', '/nhan-su/quan-ly') && (
          <>
            <Tabs.TabPane tab="Quản lý" key="managers">
              <Managers />
            </Tabs.TabPane>
          </>
        )}
        {ability.can('read', '/nhan-su/tai-xe') && (
          <>
            <Tabs.TabPane tab="Tài xế" key="drivers">
              <Drivers />
            </Tabs.TabPane>
          </>
        )}
        {ability.can('read', '/nhan-su/picker') && (
          <>
            <Tabs.TabPane tab="Nhân viên gom hàng" key="pickers">
              <Pickers />
            </Tabs.TabPane>
          </>
        )}
      </StyledTabs>
    </Wrapper>
  )
}
