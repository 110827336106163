import { useLazyQuery } from '@apollo/react-hooks'
import { Product, PRODUCTS_QUERY, Query, QueryProductsArgs, useQueryProductPromise } from '@coop/apollo'
import { Spin } from 'antd'
import Select, { SelectProps } from 'antd/lib/select'
import React from 'react'
import { useSource } from '../../../contexts/SourceContext'
import useDebounce from '../../../shared/hooks/useDebounce'

const PAGE_SIZE = 20

export const SelectProduct: React.FC<SelectProps<string>> = ({ value, onChange }) => {
  const [selectedProduct, setSelectedProduct] = React.useState<Product>()
  const [loadingSelected, setLoadingSelected] = React.useState(false)
  const [inputText, setInputText] = React.useState('')
  const debouncedSearch = useDebounce(inputText.toLowerCase(), 500)
  const [getProducts, { data: productsData, loading: loadingProducts }] = useLazyQuery<
    Pick<Query, 'products'>,
    QueryProductsArgs
  >(PRODUCTS_QUERY)
  const getSelectedProduct = useQueryProductPromise()

  const { source } = useSource()
  React.useEffect(() => {
    if (value) {
      setLoadingSelected(true)
      getSelectedProduct({
        sourceCode: source?.source_code,
        sku: value,
      })
        .then(result => result.data?.product && setSelectedProduct(result.data?.product))
        .finally(() => setLoadingSelected(false))
    }
  }, [getSelectedProduct, selectedProduct, source, value])

  React.useEffect(() => {
    getProducts({
      variables: {
        searchName: debouncedSearch,
        filter: {},
        pageSize: PAGE_SIZE,
      },
    })
  }, [debouncedSearch, getProducts])

  const hideSelectedOption =
    productsData?.products?.items.some(p => p.sku === selectedProduct?.sku) ||
    (!selectedProduct?.sku.toLowerCase().includes(debouncedSearch) &&
      !selectedProduct?.name.toLowerCase().includes(debouncedSearch))

  return (
    <Select
      loading={loadingProducts}
      value={value}
      onChange={onChange}
      filterOption={false}
      notFoundContent={loadingProducts || loadingSelected ? <Spin size="small" /> : null}
      showSearch
      searchValue={inputText}
      onSearch={setInputText}>
      {[
        ...(selectedProduct && !hideSelectedOption ? [selectedProduct] : []),
        ...(productsData?.products?.items || []),
      ].map(p => (
        <Select.Option value={p.sku} key={p.sku}>
          <span>
            {p.name}
            <span className="font-light text-gray-500 ml-2">{p.sku}</span>
          </span>
        </Select.Option>
      ))}
    </Select>
  )
}
