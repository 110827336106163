import { OrderDetail, PaymentMethod } from '@coop/apollo'
import React from 'react'

const reportOrderHeader = [
  { label: 'STT', key: 'STT' },
  { label: 'Tên cửa hàng', key: 'sourceName' },
  { label: 'Mã đơn hàng', key: 'display_id' },
  { label: 'Trạng thái đơn hàng', key: 'statusText' },
  { label: 'Thời gian đặt hàng', key: 'created_at' },
  { label: 'Ngày hoàn thành', key: 'updated_at' },
  { label: 'Tên khách hàng', key: 'customerName' },
  { label: 'Số điện thoại', key: 'customerPhone' },
  { label: 'Giá trị đơn hàng', key: 'subtotal' },
  { label: 'Giá trị đơn hàng điều chỉnh', key: 'adjust_subtotal' },
  { label: 'Phí vận chuyển', key: 'shipping_amount' },
  { label: 'Giá trị khuyến mãi', key: 'discount_amount' },
  { label: 'Mã khuyến mãi', key: 'coupon_code' },
  { label: 'Giá trị hoàn trả', key: 'total_refunded' },
  { label: 'Tổng giá trị đơn hàng', key: 'grand_total' },
  { label: 'Tổng giá trị đơn hàng điều chỉnh', key: 'adjust_grandtotal' },
  { label: 'Địa chỉ giao hàng', key: 'addressStreet' },
  { label: 'Hình thức thanh toán', key: 'paymentText' },
  { label: 'Ghi chú của khách hàng', key: 'note' },
  { label: 'Transaction ID', key: 'transaction_id' },
]

const reportSkuHeader = [
  { label: 'Ngày đặt hàng', key: 'created_at' },
  { label: 'Mã đơn hàng', key: 'display_id' },
  { label: 'Tên khách hàng', key: 'customerName' },
  { label: 'Số điện thoại', key: 'customerPhone' },
  { label: 'Trạng thái', key: 'statusText' },
  { label: 'Mã sản phẩm', key: 'sku' },
  { label: 'Tên sản phẩm', key: 'productName' },
  { label: 'Giá', key: 'price' },
  { label: 'Số lượng khách hàng đặt', key: 'qty_ordered' },
  { label: 'Số lượng trả về', key: 'qty_refunded' },
  { label: 'Ghi chú của khách hàng', key: 'note' },
]

const orderColumn = [
  {
    title: 'Tên cửa hàng',
    render: (data: OrderDetail & { sourceName?: string }) => <div>{data.sourceName}</div>,
  },
  {
    title: 'Mã đơn hàng',
    render: (data: OrderDetail) => <div>{data.display_id}</div>,
  },
  {
    title: 'Trạng thái đơn hàng',
    render: (data: OrderDetail & { statusText?: string }) => <div>{data.statusText}</div>,
  },
  {
    title: 'Thời gian đặt hàng',
    render: (data: OrderDetail) => <div>{data.created_at}</div>,
  },
  {
    title: 'Ngày hoàn thành',
    render: (data: OrderDetail) => <div>{data.completed_date}</div>,
  },
  {
    title: 'Tên khách hàng',
    render: (data: OrderDetail & { customerName?: string }) => <div>{`${data.customerName}`}</div>,
  },
  {
    title: 'Số điện thoại',
    render: (data: OrderDetail) => <div>{data.address.telephone}</div>,
  },
  {
    title: 'Giá trị đơn hàng',
    render: (data: OrderDetail) => <div>{data?.adjust_subtotal || data.subtotal}</div>,
  },
  {
    title: 'Phí vận chuyển',
    render: (data: OrderDetail) => <div>{data.shipping_amount}</div>,
  },
  {
    title: 'Giá trị khuyến mãi',
    render: (data: OrderDetail) => <div>{data.discount_amount}</div>,
  },
  {
    title: 'Mã khuyến mãi',
    render: (data: OrderDetail) => <div>{data.coupon_code}</div>,
  },
  {
    title: 'Giá trị hoàn trả',
    render: (data: OrderDetail & { total_refunded: number }) => <div>{data?.total_refunded || 0}</div>,
  },
  {
    title: 'Tổng giá trị đơn hàng',
    render: (data: OrderDetail) => <div>{data?.adjust_grandtotal || data.grand_total}</div>,
  },
  {
    title: 'Địa chỉ giao hàng',
    render: (data: OrderDetail) => <div>{data.address.street && data?.address?.street[0]}</div>,
  },
  {
    title: 'Hình thức thanh toán',
    render: (data: OrderDetail & { paymentText?: string }) => <div>{data.paymentText}</div>,
  },
  {
    title: 'Ghi chú của khách hàng',
    render: (data: OrderDetail) => <div>{data.note}</div>,
  },
  {
    title: 'Transaction ID',
    render: (data: OrderDetail) => <div>{data.transaction_id}</div>,
  },
]

const skuColumn = [
  {
    title: 'Ngày đặt hàng',
    render: (data: OrderDetail) => <div>{data.created_at}</div>,
  },
  {
    title: 'Mã đơn hàng',
    render: (data: OrderDetail) => <div>{data.display_id}</div>,
  },
  {
    title: 'Tên khách hàng',
    render: (data: OrderDetail & { customerName: string }) => <div>{data.customerName}</div>,
  },
  {
    title: 'Số điện thoại',
    render: (data: OrderDetail & { customerPhone: string }) => <div>{data.customerPhone}</div>,
  },
  {
    title: 'Trạng thái',
    render: (data: OrderDetail & { statusText: string }) => <div>{data.statusText}</div>,
  },
  {
    title: 'Mã sản phẩm',
    render: (data: OrderDetail & { sku: string }) => <div>{data.sku}</div>,
  },
  {
    title: 'Tên sản phẩm',
    render: (data: OrderDetail & { productName: string }) => <div>{data.productName}</div>,
  },
  {
    title: 'Giá',
    render: (data: OrderDetail & { price: number }) => <div>{data.price}</div>,
  },
  {
    title: 'Số lượng khách hàng đặt',
    render: (data: OrderDetail & { qty_ordered: number }) => <div>{data.qty_ordered}</div>,
    align: 'center' as 'center',
  },
  {
    title: 'Số lượng trả về',
    render: (data: OrderDetail & { qty_ordered: number; qty_shipped: number; qty_refunded: number }) => (
      <div>
        {data.payment?.method === PaymentMethod.Cashondelivery
          ? data.qty_ordered - data.qty_shipped
          : data.qty_refunded}
      </div>
    ),
    align: 'center' as 'center',
  },
  {
    title: 'Ghi chú của khách hàng',
    render: (data: OrderDetail) => <div>{data.note}</div>,
  },
]

export { orderColumn, skuColumn, reportOrderHeader, reportSkuHeader }
