import { PlusOutlined } from '@ant-design/icons'
import { Col, DatePicker, Form, Input, Radio, Row, Select, Spin, Upload } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { UploadProps } from 'antd/lib/upload'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { useSource } from '../../../contexts/SourceContext'
import { UploadContext } from '../../../contexts/UploadContext'
import { useAbility } from '../../../shared/accessControl'
import { ModalActionGroup, ModalHeader } from '../../../shared/components/Modal'
import { Product } from '../../../shared/model'
import { formatUrl } from '../../../shared/utils'
import { ProductStatusOption } from './FiltersGroup'

const Wrapper = styled.div``

const StyledCol = styled(Col)`
  width: 128px;
  .ant-upload {
    width: 128px;
    /* height: 128px; */
  }
`

type Props = {
  data?: Product & any
  onSubmit: (
    data: Pick<Product, 'quantity' | 'status' | 'price' | 'price_from' | 'price_to' | 'visibility'>,
  ) => Promise<void>
}

const ProductFormModal: React.FC<Props> = ({ data, onSubmit }) => {
  const ability = useAbility()
  const { source, categories } = useSource()
  const [form] = Form.useForm()
  const { upload, isUploading, imageUrls, cleanUp } = React.useContext(UploadContext)
  const isSmallerSpecialPrice = data?.origin_special_price < data?.price && data?.origin_special_price > 0

  const [formLoading, setFormLoading] = React.useState(false)
  const [isPromotePrice, setIsPromotePrice] = React.useState(isSmallerSpecialPrice)
  const [imageUrl, setImageUrl] = React.useState<string>(formatUrl(data?.thumbnail_path))
  const [submitDisabled, setSubmitDisabled] = React.useState(true)

  const handleSubmitForm = (formValues: any) => {
    setFormLoading(true)

    const priceFrom = moment(formValues.price_from?.format('YYYY-MM-DD') + 'T00:00:00.000Z').unix()
    const priceTo = moment(formValues.price_to?.format('YYYY-MM-DD') + 'T23:59:59.000Z').unix()
    const specialPriceDate = isPromotePrice
      ? {
          price_from: (+priceFrom).toString(),
          price_to: (+priceTo).toString(),
        }
      : {}

    onSubmit({
      quantity: +formValues.quantity,
      status: +formValues.status,
      price: +formValues.origin_special_price,
      ...specialPriceDate,
      visibility: formValues.visibility,
    })
      .then(() => setFormLoading(false))
      .catch(error => console.log('error update product', error))
      .finally(() => setFormLoading(false))
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Thêm ảnh</div>
    </div>
  )

  const uploadProps: UploadProps = {
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    customRequest: async ({ file }) => {
      upload([file])
    },
  }

  React.useEffect(() => {
    const { price_from, price_to } = data || {}

    form.setFieldsValue({
      ...data,
      status: data?.quantity === 0 ? 0 : data?.status,
      price_from: price_from ? moment(price_from) : '',
      price_to: price_from ? moment(price_to) : '',
    })
  }, [data, form])

  React.useEffect(() => {
    if (imageUrls.length > 0) {
      setImageUrl(imageUrls[0])
      form.setFieldsValue({ thumbnail_path: imageUrls[0] })
      cleanUp()
    }
  }, [cleanUp, form, imageUrls])

  return (
    <Wrapper className="flex w-full overflow-hidden">
      <Form
        labelCol={{ span: 8, xl: { span: 6 } }}
        form={form}
        name="product-form"
        onFinish={handleSubmitForm}
        onChange={() => setSubmitDisabled(false)}
        className="flex flex-col w-full">
        <ModalHeader
          title={data ? 'Cập nhật sản phẩm' : 'Thêm sản phẩm'}
          actionRenderer={
            ability.can('update', '/san-pham') ? (
              <ModalActionGroup submitText={'Lưu'} loading={formLoading} disabledSubmit={submitDisabled} />
            ) : undefined
          }
        />
        <div className="w-full mx-auto p-4 overflow-y-auto" style={{ maxWidth: '960px' }}>
          <Row gutter={16} className="flex-wrap-reverse">
            <Col flex={1}>
              <Form.Item name="sku" label="SKU">
                <Input placeholder="Nhập mã sản phẩm" disabled />
              </Form.Item>
              <Form.Item name="name" label="Tên sản phẩm">
                <Input placeholder="Nhập tên sản phẩm" disabled />
              </Form.Item>
              <Form.Item name="description" label="Mô tả">
                <TextArea placeholder="Nhập mô tả" rows={3} disabled />
              </Form.Item>
              <Form.Item name={'category'} label="Danh mục hàng">
                {categories
                  ?.filter(c => data?.category_ids?.includes(c?.id || null))
                  .map(c => c?.name)
                  .join(', ') || 'Không có ngành hàng hoặc ngành hàng đã bị ẩn'}
              </Form.Item>
              <Form.Item name="price" label="Giá sản phẩm (Gốc)">
                <Input type="number" placeholder="Nhập giá sản phẩm (Gốc)" disabled />
              </Form.Item>
              {source?.source_code && (
                <>
                  <Form.Item
                    name="origin_special_price"
                    label="Giá sản phẩm (Khuyến mãi)"
                    required
                    rules={[
                      { required: true, message: 'Vui lòng nhập giá bán ra!' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (getFieldValue('price') < value) {
                            return Promise.reject('Giá khuyến mãi không thể cao hơn giá gốc!')
                          } else if (getFieldValue('price') > value && value > 0) {
                            setIsPromotePrice(true)
                          } else {
                            setIsPromotePrice(false)
                          }
                          return Promise.resolve()
                        },
                      }),
                    ]}>
                    <Input placeholder="Nhập giá sản phẩm" type="number" addonAfter={'đ'} />
                  </Form.Item>
                  {isPromotePrice && (
                    <>
                      <Form.Item
                        rules={[{ required: true, message: 'Ngày khuyến mãi không để trống' }]}
                        name="price_from"
                        label="Giá khuyến mãi từ ngày">
                        <DatePicker
                          onChange={() => setSubmitDisabled(false)}
                          format="DD-MM-YYYY"
                          style={{ width: '100%' }}
                          placeholder="Chọn ngày"
                        />
                      </Form.Item>
                      <Form.Item
                        rules={[{ required: true, message: 'Ngày khuyến mãi không để trống' }]}
                        name="price_to"
                        label="Giá khuyến mãi đến ngày">
                        <DatePicker
                          onChange={() => setSubmitDisabled(false)}
                          format="DD-MM-YYYY"
                          style={{ width: '100%' }}
                          placeholder="Chọn ngày"
                        />
                      </Form.Item>
                    </>
                  )}

                  <Form.Item
                    name="quantity"
                    label="Số lượng"
                    required
                    rules={[
                      { required: true, message: 'Vui lòng nhập số lượng sản phẩm!' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const salable = getFieldValue('salable')
                          const quantity = data?.quantity || 0
                          if (value <= quantity - salable) {
                            return Promise.reject(
                              `Số lượng stock không thể nhỏ hơn số lượng đã đặt hàng (${quantity - salable})!`,
                            )
                          }
                          return Promise.resolve()
                        },
                      }),
                    ]}>
                    <Input type="number" placeholder="Nhập số lượng" />
                  </Form.Item>
                  <Form.Item name="salable" label="Số lượng có thể bán">
                    <Input type="number" placeholder="Nhập số lượng có thể bán" disabled />
                  </Form.Item>

                  <Form.Item
                    name="status"
                    label="Trạng thái"
                    required
                    rules={[{ required: true, message: 'Vui lòng nhập trạng thái sản phẩm!' }]}>
                    <Select onChange={() => setSubmitDisabled(false)}>
                      <Select.Option value={1}>Còn hàng</Select.Option>
                      <Select.Option value={0}>Hết hàng</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="visibility"
                    label="Trạng thái kinh doanh"
                    required
                    rules={[{ required: true, message: 'Vui lòng chọn trạng thái sản phẩm!' }]}>
                    <Radio.Group
                      buttonStyle="solid"
                      onChange={e => form.setFieldsValue({ visibility: e.target.value })}>
                      <Radio.Button value={ProductStatusOption.Available}>Bật</Radio.Button>
                      <Radio.Button value={ProductStatusOption.Unavailable}>Tắt</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </>
              )}
            </Col>
            <StyledCol>
              <Form.Item name="thumbnail_path" valuePropName="fileList" wrapperCol={{ span: 24 }}>
                <Upload listType="picture-card" className="w-full" {...uploadProps} disabled>
                  {isUploading ? (
                    <Spin tip="uploading..." />
                  ) : imageUrl ? (
                    <img src={imageUrl} alt={imageUrl} />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
            </StyledCol>
          </Row>
        </div>
      </Form>
    </Wrapper>
  )
}

export default ProductFormModal
