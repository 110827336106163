import { EmployeeType, ManagerRole, ProfileCol } from '@coop/common'
import { Col, DatePicker, Form, Input, Row, Select, Spin } from 'antd'
import Title from 'antd/lib/typography/Title'
import { firestore } from 'firebase'
import { FormikProvider, useFormik } from 'formik'
import moment from 'moment'
import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useAuth } from 'src/contexts/AuthContext'
import { getSourceAvatar } from 'src/shared/components/getSourceAvatar'
import styled from 'styled-components'
// @ts-ignore
import tw from 'tailwind.macro'
import * as Yup from 'yup'
import { useFirestore } from '../../contexts/FirestoreContext'
import { useSource } from '../../contexts/SourceContext'
import { UploadImage } from '../../shared/components'
import { ModalActionGroup, ModalHeader } from '../../shared/components/Modal'
import { useUpload } from '../../shared/hooks/useUpload'
import { Picker, Profile } from '../../shared/model'
import { formatPhoneNumber, validatePhoneNumber } from '../../shared/utils'

const Wrapper = styled('div').attrs({ className: 'w-full' })`
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    margin: 0;
  }
`

const StyledCol = styled(Col)`
  width: 128px;
  .ant-upload img {
    width: 128px;
    /* height: 128px; */
  }
`

const FormItem = styled(Form.Item).attrs({ className: 'mb-0 border border-gray-400' })`
  margin-bottom: -1px;
  .ant-form-item-label {
    ${tw`bg-gray-200 pl-4 flex items-center border-r border-gray-400`}
  }
  input {
    ${tw`h-12 border-transparent`}
  }
  .ant-select .ant-select-selector {
    ${tw`h-12 border-transparent flex items-center`}
  }
  .ant-form-item-explain {
    ${tw`pl-2`}
  }
`

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

type ProfileDto = Omit<Profile, 'id'>
export type PickerDto = Picker & { profile: ProfileDto }

const defaultValues = {
  name: '',
  avatar: '',
  phone: '',
  disabled: false,
  sourceCode: '',
  type: EmployeeType.Picker,
  profile: {
    tin: '',
    permanentAddress: '',
    identity: {
      id: '',
      issuedDate: null,
      issuedAddress: '',
      backPicture: '',
      frontPicture: '',
    },
  },
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Vui lòng nhập tên!'),
  phone: Yup.string()
    .required('Vui lòng nhập số điện thoại!')
    .test('phone', 'Số điện thoại chưa chính xác!', validatePhoneNumber),
  avatar: Yup.string().required('Vui lòng chọn ảnh!'),
  type: Yup.string().required('Vui lòng chọn vai trò!'),
  sourceCode: Yup.string().required('Vui lòng chọn siêu thị'),
  profile: Yup.object().shape({
    tin: Yup.string().required('Vui lòng nhập số mã số thuế'),
    permanentAddress: Yup.string().required('Vui lòng nhập địa chỉ thường trú'),
    identity: Yup.object().shape({
      id: Yup.string().required('Vui lòng nhập số CMND'),
      issuedDate: Yup.mixed().required('Vui lòng nhập ngày cấp CMND'),
      issuedAddress: Yup.string().required('Vui lòng nhập địa chỉ cấp CMND'),
      frontPicture: Yup.string().required('Vui lòng chọn ảnh mặt trước CMND'),
      backPicture: Yup.string().required('Vui lòng chọn ảnh mặt sau CMND'),
    }),
  }),
})

type Props = {
  data?: Picker
  onSubmit: (data: PickerDto) => Promise<void>
}

export const PickerFormModal: React.FC<Props> = ({ data, onSubmit }) => {
  const [formLoading, setFormLoading] = React.useState(false)
  const formik = useFormik<PickerDto>({
    initialValues: ((data ? { ...defaultValues, ...data } : defaultValues) as unknown) as PickerDto,
    onSubmit: (formData: PickerDto) => {
      setFormLoading(true)
      onSubmit({
        ...formData,
        phone: formatPhoneNumber(formData.phone),
      }).finally(() => setFormLoading(false))
    },
    validationSchema,
  })

  const { Data } = useFirestore()
  const { me } = useAuth()
  const { loading, allSources } = useSource()
  const { values, setFieldValue, handleSubmit, errors, touched } = formik
  const avatar = useUpload()
  const frontPicture = useUpload()
  const backPicture = useUpload()

  const marketList = me?.sourceCode
    ? allSources.filter(e => e.source_code === me.sourceCode)
    : me?.listSourceCode
    ? allSources.filter(e => (me?.listSourceCode || []).indexOf(e.source_code) > -1)
    : allSources

  React.useEffect(() => {
    if (!values.sourceCode && me?.role === ManagerRole.Manager) {
      setFieldValue('sourceCode', me.sourceCode)
    }
  }, [me, setFieldValue, values.sourceCode])

  React.useEffect(() => {
    const loadProfile = async () => {
      let pickerProfile
      try {
        if (!data) {
          throw Error()
        }
        const snapshot = await Data.profile(Data.picker().doc(data.id)).doc(ProfileCol).get()
        pickerProfile = snapshot.exists ? (snapshot.data() as ProfileDto) : undefined
      } catch {
      } finally {
        pickerProfile && setFieldValue('profile', pickerProfile)
      }
    }

    loadProfile()
  }, [Data, data, setFieldValue])

  React.useEffect(() => {
    if (avatar.url) {
      setFieldValue('avatar', avatar.url)
    }
    if (frontPicture.url) {
      setFieldValue('profile.identity.frontPicture', frontPicture.url)
    }
    if (backPicture.url) {
      setFieldValue('profile.identity.backPicture', backPicture.url)
    }
  }, [avatar.url, backPicture.url, frontPicture.url, setFieldValue])

  return (
    <Wrapper>
      <FormikProvider value={formik}>
        <Form
          {...layout}
          onFinish={() => handleSubmit()}
          name="picker-form"
          labelAlign="left"
          colon={false}
          className="flex flex-col flex-1 overflow-hidden h-full">
          <ModalHeader
            title="Thêm nhân viên gom hàng"
            actionRenderer={<ModalActionGroup submitText={'Lưu'} loading={formLoading} />}
          />
          <PerfectScrollbar>
            <div className="w-full mx-auto p-4" style={{ maxWidth: '960px' }}>
              <Row gutter={16} className="flex-wrap-reverse">
                <Col flex={1}>
                  <Title level={4}>Thông tin</Title>
                  <FormItem
                    label="Họ và Tên"
                    required
                    validateStatus={errors.name && touched.name ? 'error' : 'validating'}
                    help={touched.name && errors.name}>
                    <Input
                      name="name"
                      value={values.name}
                      onChange={e => setFieldValue('name', e.target.value)}
                      placeholder="Họ và tên nhân viên"
                    />
                  </FormItem>
                  <FormItem
                    label="Số điện thoại"
                    required
                    validateStatus={errors.phone && touched.phone ? 'error' : 'validating'}
                    help={touched.phone && errors.phone}>
                    <Input
                      name="phone"
                      value={values.phone}
                      onChange={!data ? e => setFieldValue('phone', e.target.value) : undefined}
                      disabled={!!data}
                      placeholder="Số điện loại liên hệ"
                    />
                  </FormItem>
                  <FormItem
                    label="Mã số thuế"
                    required
                    validateStatus={errors.profile?.tin && touched.profile?.tin ? 'error' : 'validating'}
                    help={touched.profile?.tin && errors.profile?.tin}>
                    <Input
                      name="phone"
                      value={values.profile?.tin}
                      onChange={!data ? e => setFieldValue('profile.tin', e.target.value) : undefined}
                      disabled={!!data}
                      placeholder="Mã số thuế"
                    />
                  </FormItem>
                  <FormItem
                    label="Vai trò"
                    required
                    validateStatus={errors.type && touched.type ? 'error' : 'validating'}
                    help={touched.type && errors.type}>
                    <Select
                      onChange={value => setFieldValue('type', value)}
                      value={values.type}
                      defaultValue={EmployeeType.Picker}
                      disabled
                      placeholder="Chọn vai trò">
                      <Select.Option value={EmployeeType.Picker}>Gom hàng</Select.Option>
                      {/* <Select.Option value={EmployeeType.Cashier}>Thanh toán</Select.Option> */}
                    </Select>
                  </FormItem>
                  <br />
                  <Title level={4}>Siêu thị</Title>
                  <FormItem
                    label="Siêu thị"
                    required
                    validateStatus={errors.sourceCode && touched.sourceCode ? 'error' : 'validating'}
                    help={touched.sourceCode && errors.sourceCode}>
                    <Select
                      // disabled={me?.role === ManagerRole.Manager}
                      onChange={market => setFieldValue('sourceCode', market)}
                      value={values.sourceCode}
                      placeholder="Chọn siêu thị">
                      {loading ? (
                        <Spin tip="Loading..." />
                      ) : (
                        marketList.map(item => (
                          <Select.Option key={item!.source_code} value={item!.source_code}>
                            {getSourceAvatar(item)} {item!.name}
                          </Select.Option>
                        ))
                      )}
                    </Select>
                  </FormItem>
                  <br />
                  <Row>
                    <Col span={24}>
                      <Title level={4}>Giấy tờ tùy thân</Title>
                      <FormItem
                        label="Địa chỉ"
                        required
                        validateStatus={
                          errors.profile?.permanentAddress && touched.profile?.permanentAddress ? 'error' : 'validating'
                        }
                        help={touched.profile?.permanentAddress && errors.profile?.permanentAddress}>
                        <Input
                          placeholder="Địa chỉ thường trú"
                          value={values.profile.permanentAddress}
                          onChange={e => setFieldValue('profile.permanentAddress', e.target.value)}
                        />
                      </FormItem>
                      <FormItem
                        label="Số CMND"
                        required
                        validateStatus={
                          errors.profile?.identity?.id && touched.profile?.identity?.id ? 'error' : 'validating'
                        }
                        help={touched.profile?.identity?.id && errors.profile?.identity?.id}>
                        <Input
                          placeholder="Nhập số CMND"
                          value={values.profile.identity.id}
                          onChange={e => setFieldValue('profile.identity.id', e.target.value)}
                        />
                      </FormItem>
                      <FormItem
                        label="Ngày cấp"
                        required
                        validateStatus={
                          errors.profile?.identity?.issuedDate && touched.profile?.identity?.issuedDate
                            ? 'error'
                            : 'validating'
                        }
                        help={touched.profile?.identity?.issuedDate && errors.profile?.identity?.issuedDate}>
                        <DatePicker
                          className="border-transparent w-full"
                          placeholder="Nhập ngày cấp"
                          value={
                            (values.profile.identity?.issuedDate &&
                              moment(values.profile.identity?.issuedDate?.toMillis())) ||
                            undefined
                          }
                          onChange={value =>
                            value !== null &&
                            setFieldValue(
                              'profile.identity.issuedDate',
                              firestore.Timestamp.fromMillis(value.valueOf()),
                            )
                          }
                        />
                      </FormItem>
                      <FormItem
                        label="Nơi cấp"
                        required
                        validateStatus={
                          errors.profile?.identity?.issuedAddress && touched.profile?.identity?.issuedAddress
                            ? 'error'
                            : 'validating'
                        }
                        help={touched.profile?.identity?.issuedAddress && errors.profile?.identity?.issuedAddress}>
                        <Input
                          placeholder="Nơi cấp CMND"
                          value={values.profile.identity.issuedAddress}
                          onChange={e => setFieldValue('profile.identity.issuedAddress', e.target.value)}
                        />
                      </FormItem>
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            wrapperCol={{ span: 24 }}
                            validateStatus={
                              errors.profile?.identity?.frontPicture && touched.profile?.identity?.frontPicture
                                ? 'error'
                                : 'validating'
                            }
                            help={touched.profile?.identity?.frontPicture && errors.profile?.identity?.frontPicture}>
                            <div className="border-gray-400 border p-4 w-full">
                              <div className="text-gray-500 mb-2 flex w-full justify-center uppercase">
                                Mặt trước CMND
                              </div>
                              <UploadImage
                                upload={frontPicture.upload}
                                uploading={frontPicture.uploading}
                                url={values.profile.identity.frontPicture}
                                ratio={9 / 16}
                              />
                            </div>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            wrapperCol={{ span: 24 }}
                            validateStatus={
                              errors.profile?.identity?.backPicture && touched.profile?.identity?.backPicture
                                ? 'error'
                                : 'validating'
                            }
                            help={touched.profile?.identity?.backPicture && errors.profile?.identity?.backPicture}>
                            <div className="border-gray-400 border p-4 w-full border-l-0">
                              <div className="text-gray-500 mb-2 flex w-full justify-center uppercase">
                                Mặt sau CMND
                              </div>
                              <UploadImage
                                upload={backPicture.upload}
                                uploading={backPicture.uploading}
                                url={values.profile.identity.backPicture}
                                ratio={9 / 16}
                              />
                            </div>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <StyledCol>
                  <div className="text-gray-500 mb-2 flex w-full justify-center uppercase">Ảnh đại diện</div>
                  <UploadImage upload={avatar.upload} uploading={avatar.uploading} url={values.avatar} />
                  <div className="text-red-500">{touched.avatar && errors.avatar}</div>
                </StyledCol>
              </Row>
            </div>
          </PerfectScrollbar>
        </Form>
      </FormikProvider>
    </Wrapper>
  )
}
