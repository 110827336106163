import { ProfileCol } from '@coop/common'
import { message, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { useAuth } from 'src/contexts/AuthContext'
import styled from 'styled-components'
import { FirebaseContext } from '../../contexts/FirebaseContext'
import { useFirestore } from '../../contexts/FirestoreContext'
import { useSource } from '../../contexts/SourceContext'
import { Button, TableWrapper } from '../../shared/components'
import Modal from '../../shared/components/Modal'
import PageTitle from '../../shared/components/PageTitle'
import SideMenu from '../../shared/components/SideMenu'
import { Dto, Picker } from '../../shared/model'
import FiltersGroup, { FilterInputType } from './FiltersGroup'
import { PickerDrawer } from './PickerDrawer'
import { PickerDto, PickerFormModal } from './PickerFormModal'

const Wrapper = styled.div``
const Header = styled.div``
const Content = styled.div``
const SideMenuWrapper = styled.div.attrs({ className: 'flex-initial w-1/6 h-full mr-12' })``

const columns: ColumnProps<Picker>[] = [
  {
    title: 'HỌ & TÊN',
    render: d => (
      <div className="flex items-center">
        <span className="ml-2">{d.name}</span>
      </div>
    ),
  },
  {
    title: 'SỐ ĐIỆN THOẠI',
    dataIndex: 'phone',
  },
  {
    title: 'TRẠNG THÁI',
    render: d => (d.disabled ? 'Đã khóa' : d.isOl ? 'Đang trực tuyến' : 'Ngoại tuyến'),
  },
]

type Props = {}

const Pickers: React.FC<Props> = () => {
  const { Data, Util } = useFirestore()
  const { source } = useSource()
  const { functions } = React.useContext(FirebaseContext)
  const { me } = useAuth()

  const [pickers, setPickers] = React.useState<Picker[]>([])
  const [disabled, setDisabled] = React.useState<boolean>(false)
  const [filterInput, setfilterInput] = React.useReducer<React.Reducer<FilterInputType, Partial<FilterInputType>>>(
    (state, newState) => ({ ...state, ...newState }),
    { text: '' },
  )
  const [selectedPicker, setSelectedPicker] = React.useState<Picker>()
  const [showDrawer, setShowDrawer] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)

  const sideMenuItems = [
    {
      title: 'Đang hoạt động',
      onClick: () => setDisabled(false),
      isActive: !disabled,
    },
    {
      title: 'Đã khóa tài khoàn',
      onClick: () => setDisabled(true),
      isActive: disabled,
    },
  ]

  const handleFilterChange = (key: keyof FilterInputType, value: string | undefined) => {
    setfilterInput({ [key]: value })
  }

  const handleSelectPicker = (d: Picker) => {
    setSelectedPicker(d)
    setShowDrawer(true)
  }

  const handleShowModal = () => setShowModal(true)

  const createPicker = React.useMemo(() => functions.httpsCallable('firestore-createPicker'), [functions])
  const deactivatePicker = React.useMemo(() => functions.httpsCallable('firestore-deactivatePicker'), [functions])
  const reactivatePicker = React.useMemo(() => functions.httpsCallable('firestore-reactivatePicker'), [functions])

  const handleSubmit = async (data: PickerDto) => {
    try {
      const { id, name, phone, avatar, sourceCode, type, profile } = data
      const profileData = Dto.profile({
        ...profile,
        identity: {
          ...profile.identity,
          // @ts-ignore
          issuedDate: profile.identity.issuedDate.toMillis(),
        },
      })
      if (!selectedPicker) {
        // Add new
        await createPicker({
          phone,
          name,
          avatar,
          sourceCode: sourceCode || null,
          type: type || null,
          profile: profileData,
        })
        message.success('Thêm nhân viên gom hàng thành công!')
      } else {
        // Update
        const updatePayload: Partial<Picker> = { name, avatar }
        if (sourceCode) {
          updatePayload.sourceCode = sourceCode
        }
        if (type) {
          updatePayload.type = type
        }
        await Data.picker().doc(id).update(updatePayload)
        await Data.profile(Data.picker().doc(id)).doc(ProfileCol).set(profile, { merge: true })
        message.success('Cập nhật thông tin nhân viên gom hàng thành công!')
        handleCloseModal()
      }
    } catch (e) {
      message.error(`Có lỗi xảy ra: ${e.message}`)
      console.error(e)
    }
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setSelectedPicker(undefined)
  }

  const handleLockAccount = async (d: Picker) => {
    try {
      await deactivatePicker({ uid: d.id })
      message.success('Đã khóa nhân viên gom hàng!')
      return Promise.resolve()
    } catch (e) {
      message.error(`Có lỗi xảy ra: ${e.message}`)
      console.error(e)
    } finally {
      setShowModal(false)
      setSelectedPicker(undefined)
    }
  }

  const handleUnlockAccount = async (d: Picker) => {
    try {
      await reactivatePicker({ uid: d.id })
      message.success('Đã mở khóa nhân viên gom hàng!')
    } catch (e) {
      message.error(`Có lỗi xảy ra: ${e.message}`)
      console.error(e)
    } finally {
      setShowModal(false)
      setSelectedPicker(undefined)
    }
  }

  const handleCloseDrawer = () => {
    setShowDrawer(false)
    setSelectedPicker(undefined)
  }

  const handleClickEdit = (d: Picker) => {
    setShowDrawer(false)
    setSelectedPicker(d)
    setShowModal(true)
  }

  const filteredData = React.useMemo(() => {
    if (!pickers) {
      return []
    }
    let currentData = pickers
    const { text } = filterInput
    if (text) {
      const comparedText = text.toLocaleLowerCase()
      currentData = currentData.filter(
        item =>
          item.name.toLocaleLowerCase().includes(comparedText) ||
          (item.phone && item.phone.toLocaleLowerCase().includes(comparedText)),
      )
    }
    return currentData
  }, [pickers, filterInput])

  React.useEffect(() => {
    const defaultRef = Data.picker().where('disabled', '==', disabled)
    const sourceRef = source?.source_code ? defaultRef.where('sourceCode', '==', source.source_code) : defaultRef
    const unsub = sourceRef.onSnapshot(snapshot => {
      const snapshotPicker = snapshot.docs.map(doc => Util.convert<Picker>(doc))
      const filteredPickter = me?.sourceCode
        ? snapshotPicker.filter(eF => eF.sourceCode === me.sourceCode)
        : me?.listSourceCode
        ? snapshotPicker.filter(eF => (me.listSourceCode || []).indexOf(eF.sourceCode || '') > -1)
        : snapshotPicker
      setPickers(filteredPickter)
    })

    return unsub
  }, [Data, Util, disabled, me, source])

  return (
    <Wrapper className="h-full w-full flex flex-col">
      <Header className="flex justify-between items-center mb-8">
        <PageTitle
          title="Nhân viên gom hàng"
          description={`${pickers.length} nhân viên gom hàng ${
            disabled ? 'đã khóa tài khoản' : 'đang tham gia hệ thống'
          }`}
        />
        <Button onClick={handleShowModal}>Thêm nhân viên gom hàng</Button>
      </Header>
      <Content className="flex flex-1">
        <SideMenuWrapper>
          <SideMenu data={sideMenuItems} type="state" />
        </SideMenuWrapper>
        <TableWrapper className="flex-1">
          <FiltersGroup searchValue={filterInput} onValueChange={handleFilterChange} />
          <Table<Picker>
            rowKey={'id'}
            dataSource={filteredData}
            columns={columns}
            onRow={record => ({
              onClick: () => handleSelectPicker(record),
            })}
            pagination={{
              pageSize: 10,
              showTotal: (total, range) => `Hiển thị ${range[0]}-${range[1]} trong ${total} nhân viên gom hàng`,
            }}
          />
        </TableWrapper>
      </Content>
      <Modal
        isFullScreen
        title={'Thêm nhân viên gom hàng'}
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        shouldCloseOnEsc={false}>
        <PickerFormModal data={selectedPicker} onSubmit={handleSubmit} />
      </Modal>
      {selectedPicker && (
        <PickerDrawer
          picker={selectedPicker}
          visible={showDrawer}
          onClose={handleCloseDrawer}
          handleLockAccount={handleLockAccount}
          handleUnlockAccount={handleUnlockAccount}
          handleClickEdit={handleClickEdit}
        />
      )}
    </Wrapper>
  )
}

export default Pickers
