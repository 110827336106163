import { useGoogleMap } from '@react-google-maps/api'
import React from 'react'
import IconDriver from '../../../assets/img/bike.png'
import IconDestination from '../../../assets/img/destination.png'
import IconSource from '../../../assets/img/source.png'
import { Order, Tracking } from '../../../shared/model'
import { geopointToMapPosition, scaledIcon } from '../../../shared/utils'

type Props = {
  currentLocation?: google.maps.LatLngLiteral
  order: Order
  tracking?: Tracking['tracking']
}

export const OrdersMap: React.FC<Props> = ({ order, currentLocation, tracking }) => {
  const map = useGoogleMap()

  React.useEffect(() => {
    if (currentLocation) {
      map?.setCenter(currentLocation)
    }
    map?.setZoom(13)
  }, [currentLocation, map])

  React.useEffect(() => {
    if (!map || !order) {
      return
    }

    // eslint-disable-next-line no-undef
    const bounds = new google.maps.LatLngBounds()

    const fromLatLng = geopointToMapPosition(order?.store?.loc)
    const toLatLng = geopointToMapPosition(order?.address?.loc)
    // const driverLatLng = geopointToMapPosition(order?.driver?.loc)
    const driverLatLng = geopointToMapPosition(tracking)

    // eslint-disable-next-line no-undef
    const fromMarker = new google.maps.Marker({
      map,
      position: fromLatLng,
      icon: scaledIcon(IconDestination),
    })
    // eslint-disable-next-line no-undef
    const toMarker = new google.maps.Marker({
      map,
      position: toLatLng,
      icon: scaledIcon(IconSource),
    })
    // eslint-disable-next-line no-undef
    const driverMarker = new google.maps.Marker({
      map,
      position: driverLatLng,
      icon: scaledIcon(IconDriver),
    })

    fromLatLng && bounds.extend(fromLatLng)
    toLatLng && bounds.extend(toLatLng)
    driverLatLng && bounds.extend(driverLatLng)

    map.fitBounds(bounds)

    return () => {
      fromMarker.setMap(null)
      toMarker.setMap(null)
      driverMarker.setMap(null)
    }
  }, [map, order, tracking])

  return <div />
}
