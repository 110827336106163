import { PhoneFilled } from '@ant-design/icons'
import { Avatar, Button, Input } from 'antd'
import { PhoneNumberFormat } from 'google-libphonenumber'
import React from 'react'
import Modal from 'react-modal'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useOrders } from '../../../contexts/OrderContext'
import useDebounce from '../../../shared/hooks/useDebounce'
import { Picker, SystemOrder } from '../../../shared/model'
import { formatPhoneNumber } from '../../../shared/utils'

type Props = {
  picker?: Omit<Picker, 'id'>
  systemOrder?: SystemOrder
}

export const AssignPicker: React.FC<Props> = ({ picker }) => {
  const [showList, setShowList] = React.useState(false)
  // const [assigning, setAssigning] = React.useState(false)
  const { freePickers } = useOrders()
  // const { me } = useAuth()
  // const { functions } = React.useContext(FirebaseContext)
  // const assignOrderToPickerDirectly = functions.httpsCallable('firestore-assignOrderToPickerDirectly')

  // const handleSelectPicker = async (data: Picker) => {
  //   try {
  //     setShowList(false)
  //     setAssigning(true)
  //     await assignOrderToPickerDirectly({ id: systemOrder.id, pickerId: data.id })
  //     message.success('Chỉ định nhân viên gom hàng thành công!')
  //   } catch (e) {
  //     message.error(`Chỉ định nhân viên gom hàng không thành công, có lỗi xảy ra: ${e.message}`)
  //   } finally {
  //     setAssigning(false)
  //   }
  // }

  // const SelectButton = ({ picker: data }: { picker: Picker }) => (
  //   <Popconfirm
  //     title="Chỉ định nhân viên này gom hàng?"
  //     okText="Đồng ý"
  //     cancelText="Hủy"
  //     onConfirm={() => handleSelectPicker(data)}>
  //     <Button type="primary" className="py-2 h-auto rounded-lg px-2">
  //       <div style={{ width: 80 }}>Chỉ định</div>
  //     </Button>
  //   </Popconfirm>
  // )

  // const toggleShowList = () => setShowList(!showList)

  const [inputText, setInputText] = React.useState('')
  const search = useDebounce(inputText, 500)
  const filteredPickers = React.useMemo(() => {
    const lowercaseSearch = search.toLowerCase()
    return freePickers.filter(
      p =>
        p.name.toLocaleLowerCase().includes(lowercaseSearch) ||
        (p.phone && p.phone.toLocaleLowerCase().includes(lowercaseSearch)),
    )
  }, [freePickers, search])

  const divRef = React.useRef<HTMLDivElement>(null)
  const bound = divRef.current?.getBoundingClientRect()
  const contentStyle: React.CSSProperties | undefined = bound && {
    position: 'absolute',
    top: bound.y,
    left: bound.x,
    width: bound.width,
    padding: 0,
    border: 0,
    maxHeight: 'fit-content',
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  }

  const header = (
    <div className="flex items-center justify-between p-4">
      <div className="flex flex-col">
        <span className="text-gray-500 uppercase">Nv. gom hàng</span>
        {picker?.name ? (
          <span className="text-gray-900">{picker?.name}</span>
        ) : (
          <span className="text-gray-600">Chưa chỉ định</span>
        )}
      </div>
      {picker?.phone && (
        <Button className="py-2 h-auto rounded-lg flex items-center">
          <PhoneFilled />
          {formatPhoneNumber(picker.phone, PhoneNumberFormat.INTERNATIONAL)}
        </Button>
      )}
    </div>
  )

  // const showAssignButton =
  //   [ProcessStatus.Picking, ProcessStatus.ReadyToPick].includes(systemOrder.status) && me?.role === ManagerRole.Manager

  return (
    <div className="flex flex-col">
      {header}
      {/* {showAssignButton && (
        <Button
          type={showList ? 'default' : 'primary'}
          loading={assigning}
          danger={showList}
          className="py-2 h-auto rounded-lg mx-4 mb-4"
          onClick={toggleShowList}>
          {showList ? 'ĐÓNG' : 'CHỈ ĐỊNH'}
        </Button>
      )} */}

      <div className="relative flex flex-col" ref={divRef} />
      <Modal
        closeTimeoutMS={500}
        onRequestClose={() => setShowList(false)}
        isOpen={showList}
        style={{ content: contentStyle, overlay: { background: 'transparent' } }}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc>
        <>
          <div
            className="w-full pb-1 flex flex-col items-stretch bg-white shadow-lg rounded-b-lg border-t border-gray-300"
            style={{ maxHeight: '400px' }}>
            <div className="flex-1 p-3 border-b border-gray-300">
              <Input
                className="bg-gray-300 border-0 hover:border-transparent focus:border-transparent rounded-md py-2"
                placeholder="Nhập tên nhân viên, số điện thoại"
                value={inputText}
                onChange={e => setInputText(e.target.value)}
              />
            </div>
            <div className="h-2" />
            <PerfectScrollbar>
              <div className="w-full pr-0">
                {filteredPickers.map((p, idx) => (
                  <div
                    className={`flex justify-between items-stretch p-4 border-gray-300 ${
                      idx < filteredPickers.length - 1 ? 'border-b' : ''
                    }`}
                    key={p.id}>
                    <div className="flex items-center">
                      <Avatar src={p.avatar} />
                      <div className="flex flex-col ml-4">
                        <span className="text-gray-900">{p.name}</span>
                        <span className="text-gray-700 rounded-md border border-gray-500 px-1 mr-4">
                          <PhoneFilled className="mr-2" />
                          {formatPhoneNumber(p.phone, PhoneNumberFormat.INTERNATIONAL)}
                        </span>
                      </div>
                    </div>
                    {/* <SelectButton picker={p} /> */}
                  </div>
                ))}
              </div>
            </PerfectScrollbar>
          </div>
        </>
      </Modal>
    </div>
  )
}
