import { ProcessStatus } from '@coop/common'
import React from 'react'
import { useSource } from 'src/contexts/SourceContext'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { OrdersProvider, useOrders } from '../../contexts/OrderContext'
import { SystemOrder } from '../../shared/model'
import { Lane, OrderModal } from './components'
import OrderFilterBar from './components/OrderFilterBar'

const Wrapper = styled.div`
  height: 100%;
`

const Col = styled.div`
  ${tw`w-1/4 flex`}
  min-width: 230px;
  height: 100%;
`

type Props = {}

const KanbanBoard: React.FC<Props> = () => {
  const { source } = useSource()
  const { ordersMap, freePickers, comingDrivers } = useOrders()
  const [selectedOrder, setSelectedOrder] = React.useState<SystemOrder | null>(null)

  const returnOrders = ordersMap[ProcessStatus.Returning] || []
  const showModal = !!selectedOrder

  const newOrders = [...(ordersMap[ProcessStatus.ReadyToPick] || []), ...(ordersMap[ProcessStatus.Pending] || [])]

  return (
    <Wrapper className="w-full flex items-stretch py-4 px-2">
      <Col>
        <Lane
          headerProps={{
            title: 'Đơn hàng mới',
            value: newOrders.length,
            type: ProcessStatus.ReadyToPick,
          }}
          onCardClick={item => setSelectedOrder(item)}
          orders={newOrders}
        />
      </Col>
      <Col>
        <Lane
          headerProps={{
            type: ProcessStatus.Picking,
            value: ordersMap[ProcessStatus.Picking]?.length || 0,
            title: 'Gom hàng',
            subProps: { value: freePickers.length, title: 'Rảnh tay' },
          }}
          onCardClick={item => setSelectedOrder(item)}
          orders={ordersMap[ProcessStatus.Picking] || []}
        />
      </Col>
      <Col>
        <Lane
          headerProps={{
            type: ProcessStatus.ReadyToDeliver,
            value: ordersMap[ProcessStatus.ReadyToDeliver]?.length || 0,
            title: 'Chờ giao',
            subProps: { value: comingDrivers.length, title: 'Đang đến' },
          }}
          onCardClick={item => setSelectedOrder(item)}
          orders={ordersMap[ProcessStatus.ReadyToDeliver] || []}
        />
      </Col>
      <Col style={{ flexDirection: 'column' }}>
        {returnOrders.length ? (
          <>
            <div className="flex" style={{ flexShrink: 2, overflow: 'hidden' }}>
              <Lane
                headerProps={{
                  type: ProcessStatus.Returning,
                  value: returnOrders.length || 0,
                  title: 'Không thành công',
                }}
                onCardClick={item => setSelectedOrder(item)}
                orders={returnOrders}
              />
            </div>
            <div className="h-2" />
          </>
        ) : undefined}

        <div className="flex flex-1" style={{ flexBasis: '50%', overflow: 'hidden' }}>
          <Lane
            headerProps={{
              type: ProcessStatus.Delivering,
              value: ordersMap[ProcessStatus.Delivering]?.length || 0,
              title: 'Đang giao',
            }}
            onCardClick={item => setSelectedOrder(item)}
            orders={ordersMap[ProcessStatus.Delivering] || []}
          />
        </div>
      </Col>

      {selectedOrder && (
        <OrderModal isOpen={showModal} systemOrderId={selectedOrder.id} onClose={() => setSelectedOrder(null)} />
      )}

      {source?.source_code && <OrderFilterBar wrapStyle={{ position: 'fixed', right: 20, bottom: 36 }} />}
    </Wrapper>
  )
}

export const Home = () => (
  <OrdersProvider>
    <KanbanBoard />
  </OrdersProvider>
)
