import { Empty } from 'antd'
import React from 'react'
import { useSource } from '../../../contexts/SourceContext'
import DashboardBanner from './DashboardBanner'
import DashboardCategory from './DashboardCategory'

type Props = {}

const DashboardConfigPage: React.FC<Props> = () => {
  const { source } = useSource()

  return source ? (
    <>
      <DashboardBanner />
      <DashboardCategory />
    </>
  ) : (
    <div className="flex flex-1 items-center justify-center">
      <Empty description="Xem theo siêu thị để hiển thị danh sách banner, kệ hàng" />
    </div>
  )
}

export default DashboardConfigPage
