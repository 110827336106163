import { PlusOutlined } from '@ant-design/icons'
import { Tag } from 'antd'
import React, { FC, useState } from 'react'
import styled from 'styled-components'

interface ITagInput {
  tags: string[] | []
  disabled?: boolean
  changeTags: (tags: string[] | []) => void
}

const InputWrapper = styled.div`
  width: 100%;
  min-width: 400px;
  padding: 4px;
  min-height: 2rem;
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`
const Input = styled.input`
  border: dashed 1px #d9d9d9;
  outline: none;
  min-width: 30%;
  margin: 0 4px;
`
const TagWrapper = styled(Tag)`
  display: flex;
  align-items: center;
  max-width: 50%;
  margin: 4px;
`
const TextEllipsis = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const NewTag = styled(Tag)`
  background: #fff;
  border-style: dashed;
  margin: 0 4px;
`

const TagInput: FC<ITagInput> = ({ tags, changeTags, disabled }) => {
  const [inputState, setInputState] = useState({ inputVisible: false })

  const onKeyDown = (event: React.KeyboardEvent & React.FormEvent<HTMLInputElement>) => {
    const value = event?.currentTarget?.value
    if (event.key === 'Enter' && value) {
      changeTags([...tags, value])
      event.currentTarget.value = ''
    } else if (event.key === 'Escape') {
      setInputState(init => ({ ...init, inputVisible: false }))
    }
  }

  const onClose = (tag: string) => () => {
    changeTags([...tags.filter((e: string) => e !== tag)])
  }

  return (
    <InputWrapper>
      {(tags as []).map((tag: string, idx: number) => (
        <TagWrapper closable={!disabled} onClose={onClose(tag)} key={tag + idx}>
          <TextEllipsis>{tag}</TextEllipsis>
        </TagWrapper>
      ))}
      {!inputState?.inputVisible && !disabled ? (
        <NewTag
          style={{ cursor: !disabled ? 'pointer' : 'default' }}
          onClick={() => setInputState(init => ({ ...init, inputVisible: true }))}>
          <PlusOutlined /> Thêm tag
        </NewTag>
      ) : inputState?.inputVisible && !disabled ? (
        <Input
          onKeyDown={onKeyDown}
          autoFocus
          onBlur={() => setInputState(init => ({ ...init, inputVisible: false }))}
        />
      ) : null}
    </InputWrapper>
  )
}

export { TagInput }
