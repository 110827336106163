import { DiscountType } from '@coop/common'
import { Col, Form, Input, Row, Select, Switch } from 'antd'
import React from 'react'
import styled from 'styled-components'
// @ts-ignore
import tw from 'tailwind.macro'
import { ModalActionGroup, ModalHeader } from '../../../shared/components/Modal'
import { Coupon } from '../../../shared/model'

const Wrapper = styled.div``
const GroupTitle = styled.div`
  ${tw`text-xl mb-4`}
`

const FormItem = styled(Form.Item).attrs({ className: 'mb-0 border border-gray-400' })`
  margin-bottom: -1px;
  .ant-form-item-label {
    ${tw`bg-gray-200 pl-4 flex items-center border-r border-gray-400`}
  }
  input {
    ${tw`h-12 border-transparent`}
  }
  .ant-select .ant-select-selector {
    ${tw`h-12 border-transparent flex items-center`}
  }
  .ant-form-item-explain {
    ${tw`pl-2`}
  }
  .ant-form-item-control-input-content > * {
    ${tw`border-transparent`}
  }
`

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

type Props = {
  coupon: Coupon
  onSubmit: (data: Pick<Coupon, 'isHidden'>) => Promise<void>
}

const PromoFormModal: React.FC<Props> = ({ onSubmit, coupon }) => {
  const [form] = Form.useForm()
  const [submitting, setSubmitting] = React.useState(false)

  // Initial form data
  React.useEffect(() => {
    form.setFieldsValue({ ...coupon, isActive: !coupon?.isHidden })
  }, [form, coupon])

  const handleSubmit = async (formValues: any) => {
    setSubmitting(true)
    const { isActive } = formValues
    await onSubmit({ isHidden: !isActive }).finally(() => setSubmitting(false))
  }

  const discountTypeSelector = (
    <FormItem noStyle>
      <Select defaultValue={DiscountType.Fixed} style={{ width: 70 }}>
        {/* <Select.Option value={DiscountType.Percentage}>%</Select.Option> */}
        <Select.Option value={DiscountType.Fixed}>&#8363;</Select.Option>
      </Select>
    </FormItem>
  )

  return (
    <Wrapper className="w-full flex justify-center items-center">
      <Form
        className="h-full w-full flex flex-col"
        {...layout}
        form={form}
        name="promo-form"
        onFinish={handleSubmit}
        initialValues={{
          type: DiscountType.Fixed,
        }}>
        <ModalHeader
          title={coupon ? 'Cập nhật khuyến mãi' : 'Thêm khuyến mãi'}
          actionRenderer={<ModalActionGroup submitText={'Lưu'} loading={submitting} />}
        />
        <div className="w-full mx-auto pt-4" style={{ maxWidth: '960px' }}>
          <Row className="mb-4">
            <Col span={24}>
              <GroupTitle>Thông tin</GroupTitle>
              <FormItem
                label="Mã giảm giá"
                name="id"
                required
                rules={[{ required: true, message: 'Vui lòng nhập mã giảm giá!' }]}>
                <Input placeholder="Mã giảm giá. Ví dụ: FREESHIP" disabled={!!coupon} />
              </FormItem>
              <FormItem name="name" label="Tên chương trình">
                <Input placeholder="Nhập tên chương trình giảm giá" disabled />
              </FormItem>
              <FormItem name="description" label="Mô tả">
                <Input.TextArea placeholder="Nhập mô tả" rows={3} disabled />
              </FormItem>
              <FormItem label="Mức giảm" name="value">
                <Input type="number" addonAfter={discountTypeSelector} disabled />
              </FormItem>
              <FormItem label="Trạng thái">
                <div className="h-12 flex items-center pl-2">
                  <FormItem name="isActive" noStyle valuePropName="checked" initialValue={true}>
                    <Switch />
                  </FormItem>{' '}
                  {'Sử dụng mã giảm giá'}
                </div>
              </FormItem>
            </Col>
          </Row>
        </div>
      </Form>
    </Wrapper>
  )
}

export default PromoFormModal
