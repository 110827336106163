import { Avatar, Checkbox, Input, Table } from 'antd'
import Text from 'antd/lib/typography/Text'
import React, { useEffect, useState } from 'react'
import { TableWrapper } from 'src/shared/components'
import env from 'src/shared/Env'
import { Market } from 'src/shared/model'

type IDataTable = Market & {
  key: string
  storeOrder: number
  name: string
  sourceCode: string
  tag: string[] | []
}

interface Props {
  originStoreList: IDataTable & any
  setStoreListWithChange: any
}

const ChannelAppStoreList = (props: Props) => {
  const { originStoreList, setStoreListWithChange } = props

  const [storeListState, setStoreListState] = useState<any[]>([])

  const handleFilter = (e: any) => {
    const filteredStore = originStoreList.filter(
      (eF: any) => eF.source_code.includes(e.target.value) || eF.name.includes(e.target.value),
    )
    setStoreListState(filteredStore)
  }

  const statusChange = (e: any, change: any) => {
    const newStoreList = storeListState.map((eM: any) =>
      eM.source_code === e.source_code ? { ...eM, isActive: change.target.checked } : { ...eM },
    )
    setStoreListState(newStoreList)
  }

  const radiusChange = (e: any, change: any) => {
    const onlyNumber = change?.target?.value.replace(/\D+/g, '')
    const servingRadius = onlyNumber.length > 0 ? window.parseInt(onlyNumber) : ''
    const newStoreList = storeListState.map((eM: any) =>
      eM.source_code === e.source_code ? { ...eM, servingRadius } : { ...eM },
    )
    setStoreListState(newStoreList)
  }

  useEffect(() => {
    setStoreListState(originStoreList)
  }, [originStoreList])

  useEffect(() => {
    setStoreListWithChange(storeListState)
  }, [setStoreListWithChange, storeListState])

  return (
    <>
      <TableWrapper className="flex-1">
        <Input
          allowClear
          className="mt-8 mb-4"
          placeholder="Tìm kiếm theo tên hoặc mã cửa hàng"
          onChange={handleFilter}
        />

        <Text className=" font-bold">Danh sách cửa hàng</Text>

        <Table
          rowKey={'source_code'}
          dataSource={storeListState}
          columns={[
            { title: 'Tên cửa hàng', dataIndex: 'name', width: '30%' },
            {
              title: 'Logo',
              dataIndex: 'imageUrl',
              render: (text: string) => (
                <Avatar shape="square" size={40} src={`${text ? env.serverUrl : ''}/${text}`} />
              ),
              width: 50,
            },
            { title: 'Địa chỉ', render: (e: any) => `${e.street}, ${e.region}, ${e.city}`, width: '35%' },
            {
              title: 'Bán kính (m)',
              render: (e: any) => (
                <Input
                  defaultValue={e.servingRadius}
                  disabled={!e.isActive}
                  value={e.servingRadius}
                  onChange={(change: any) => radiusChange(e, change)}
                  className="w-full"
                />
              ),
              width: 250,
            },
            {
              title: 'Trạng thái',
              render: (e: any) => <Checkbox checked={e.isActive} onChange={(change: any) => statusChange(e, change)} />,
              align: 'center',
            },
          ]}
          pagination={{
            pageSize: 10,
            showTotal: (total, range) => `Hiển thị ${range[0]}-${range[1]} trong ${total} cửa hàng`,
          }}
        />
      </TableWrapper>
    </>
  )
}

export default ChannelAppStoreList
