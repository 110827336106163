import { Button, Popconfirm, Spin } from 'antd'
import React from 'react'

type Props = {
  submitText: string
  submitIcon?: React.ReactNode
  onDelete?: () => void
  loading?: boolean
  disabledSubmit?: boolean
}

const ModalActionGroup: React.FC<Props> = ({
  submitIcon,
  submitText,
  onDelete,
  loading = false,
  disabledSubmit = false,
}) => {
  return (
    <div className="absolute" style={{ right: 10 }}>
      {loading && <Spin className="mr-4" />}
      {onDelete && (
        <Popconfirm title="Bạn có chắc muốn xóa không?" onConfirm={onDelete} okText="Xóa" cancelText="Hủy">
          <Button danger ghost size="large" className="mr-4" disabled={loading}>
            Xóa
          </Button>
        </Popconfirm>
      )}
      <Button type="primary" htmlType="submit" size="large" className="mr-4" disabled={loading || disabledSubmit}>
        {submitIcon && submitIcon}
        {submitText}
      </Button>
    </div>
  )
}

export default ModalActionGroup
