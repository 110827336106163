import { ChannelConfigType, ManagerRole } from '@coop/common'
import { Button, Checkbox, message, Select } from 'antd'
import Text from 'antd/lib/typography/Text'
import React, { useContext, useEffect, useState } from 'react'
import { useAuth } from 'src/contexts/AuthContext'
import { FirebaseContext } from 'src/contexts/FirebaseContext'
import { useFirestore } from 'src/contexts/FirestoreContext'
import { useSource } from 'src/contexts/SourceContext'
import { Market } from 'src/shared/model'
import ChannelAppStoreList from './ChannelAppStoreList'

type Props = {}

const ChannelAppPage: React.FC<Props> = () => {
  const { allSources } = useSource()
  const { Data } = useFirestore()
  const { me } = useAuth()
  const { functions } = useContext(FirebaseContext)

  const [channelList, setChannelList] = useState<ChannelConfigType[]>([])
  const [selectedChannel, setSelectedChannel] = useState<string>('')
  const [currentChannelItem, setCurrentChannelItem] = useState<any>({})
  const [originStoreList, setOriginStoreList] = useState<any[]>([])
  const [storeListWithChange, setStoreListWithChange] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleUpdate = () => {
    setIsLoading(true)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...rest } = currentChannelItem
    const stores = storeListWithChange
      .filter((eF: any) => eF.isActive || eF.isActive === false)
      .map((eM: any) => ({
        sourceCode: eM.source_code,
        isActive: eM.isActive,
        servingRadius: eM.servingRadius,
      }))
    const dataToPost = { ...rest, stores }

    functions
      .httpsCallable('firestore-adminChannelConfig')(dataToPost)
      .then(() => {
        message.success('Cập nhật thành công', 3)
        message.loading('Đang tải lại dữ liệu', 2)
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      })
      .catch(error => message.error(`Cập nhật thất bại ${error.message}`))
      .finally(() => setIsLoading(false))
  }

  const changeChannel = (e: string) => {
    setSelectedChannel(e)
    message.success('Đổi channel thành công, các thay đổi trước chưa bấm "Cập nhật" sẽ mất đi', 5)
  }

  useEffect(() => {
    const isManager = me?.role === ManagerRole.Manager

    Data.channelConfig()
      .get()
      .then(result => {
        const resultChannelList = result.docs.map((e: any) => ({ ...e.data(), id: e.id })) as ChannelConfigType[]
        const managerChannel = resultChannelList.filter(e => e.appId === me?.channel)
        setChannelList(isManager ? managerChannel : resultChannelList)
      })
      .catch(() => message.error('Lấy thông tin channel không thành công'))
  }, [Data, me])

  useEffect(() => {
    setSelectedChannel(channelList[0]?.appId || '')
  }, [channelList])

  useEffect(() => {
    setCurrentChannelItem(channelList.filter(e => e.appId === selectedChannel)[0] || {})
  }, [channelList, selectedChannel])

  useEffect(() => {
    const isManager = me?.role === ManagerRole.Manager
    const storeWithChannel = allSources.map((e: Market) => ({
      ...e,
      ...currentChannelItem?.stores?.find((eF: { sourceCode: string }) => eF.sourceCode === e.source_code),
    })) as Market[]
    const managerStore = storeWithChannel.filter(eF => (me?.listSourceCode || []).indexOf(eF.source_code) > -1)
    setOriginStoreList(isManager ? managerStore : storeWithChannel)
  }, [allSources, currentChannelItem, me])

  return (
    <div className="w-full ">
      <div className="flex justify-end">
        <Button
          type="primary"
          size={'large'}
          disabled={isLoading}
          loading={isLoading}
          style={{ padding: '0 24px', borderRadius: '4px' }}
          onClick={handleUpdate}>
          Cập nhật
        </Button>
      </div>

      <Text className=" font-bold">{'Chọn channel'} </Text>

      <Select className={'w-full mt-2 mb-4'} value={selectedChannel} onChange={e => changeChannel(e)}>
        {channelList.map((e: any, i: number) => (
          <Select.Option key={`channel_item_${i}`} value={e?.appId}>
            {e?.name}
          </Select.Option>
        ))}
      </Select>

      <div className={'w-full'}>
        <Checkbox
          checked={currentChannelItem?.isActive}
          onChange={e => setCurrentChannelItem((init: any) => ({ ...init, isActive: e.target.checked }))}>
          is Active
        </Checkbox>
        <Checkbox
          checked={currentChannelItem?.isLocation}
          onChange={e => setCurrentChannelItem((init: any) => ({ ...init, isLocation: e.target.checked }))}>
          Kiểm tra bán kính cửa hàng
        </Checkbox>
      </div>

      <ChannelAppStoreList originStoreList={originStoreList} setStoreListWithChange={setStoreListWithChange} />
    </div>
  )
}

export default ChannelAppPage
