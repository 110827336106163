import { PlusOutlined } from '@ant-design/icons'
import { ReferenceType } from '@coop/common'
import { Col, Form, Input, Row, Select, Spin, Upload } from 'antd'
import { UploadProps } from 'antd/lib/upload'
import { FormikProvider, useFormik } from 'formik'
import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { useSource } from '../../../contexts/SourceContext'
import { UploadContext } from '../../../contexts/UploadContext'
import { ModalActionGroup, ModalHeader } from '../../../shared/components/Modal'
import { Banner } from '../../../shared/model'
import { SelectProduct } from './SelectProduct'

const Wrapper = styled.div``

const uploadButton = (
  <div className="border border-gray-400 border-dotted p-8 flex justify-center items-center flex-col hover:border-gray-600">
    <PlusOutlined />
    <div className="ant-upload-text">Thêm ảnh banner</div>
  </div>
)

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Vui lòng nhập tên banner!'),
  image: Yup.string().required('Vui lòng chọn ảnh banner!'),
  reference: Yup.object().shape({
    type: Yup.string().required('Vui lòng chọn loại đường dẫn!'),
    sku: Yup.string().when('type', {
      is: ReferenceType.Product,
      then: Yup.string().required('Vui lòng chọn Danh mục sản phẩm!'),
    }),
    categoryId: Yup.number().when('type', {
      is: ReferenceType.Category,
      then: Yup.number().required('Vui lòng chọn Danh mục sản phẩm!'),
    }),
  }),
  // sourceCodes: Yup.array().required('Vui lòng chọn siêu thị áp dụng'),
})

type Props = {
  data?: Banner
  onSubmit: (data: Omit<Banner, 'id'>) => Promise<void>
  onDeleteItem?: () => Promise<void>
}

const ModalBannerForm: React.FC<Props> = ({ data, onDeleteItem, onSubmit }) => {
  const formik = useFormik<Omit<Banner, 'id'>>({
    // @ts-ignore
    initialValues: data || {
      name: '',
      image: '',
      reference: { type: ReferenceType.View, categoryId: '', sku: '' },
      sourceCode: '',
    },
    onSubmit,
    validationSchema,
  })
  const { values, setFieldValue, isSubmitting, handleSubmit, touched, errors } = formik
  const { source, categories, loading } = useSource()

  const handleDelete = () => {
    onDeleteItem && onDeleteItem()
  }

  const { upload, isUploading, imageUrls, cleanUp } = React.useContext(UploadContext)
  React.useEffect(() => {
    if (imageUrls.length > 0) {
      setFieldValue('image', imageUrls[0])
      cleanUp()
    }
  }, [imageUrls, cleanUp, setFieldValue])
  const uploadProps: UploadProps = {
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    customRequest: async ({ file }) => {
      upload([file])
    },
  }

  React.useEffect(() => {
    setFieldValue('sourceCode', source?.source_code)
  }, [setFieldValue, source])

  return (
    <Wrapper className="w-full">
      <FormikProvider value={formik}>
        <Form labelCol={{ span: 4 }} onFinish={() => handleSubmit()} className="flex flex-col">
          <ModalHeader
            title={data ? 'Cập nhật Banner' : 'Thêm Banner'}
            actionRenderer={
              <ModalActionGroup submitText={'Lưu'} loading={isSubmitting} onDelete={data ? handleDelete : undefined} />
            }
          />
          <div className="w-full mx-auto pt-4" style={{ maxWidth: '960px' }}>
            <Row className="mb-4">
              <Col span={24}>
                <Form.Item
                  label="Tên banner"
                  required
                  validateStatus={touched.name && errors.name ? 'error' : 'validating'}
                  help={touched.name ? errors.name : undefined}>
                  <Input
                    value={values.name}
                    onChange={e => setFieldValue('name', e.target.value)}
                    placeholder="Nhập tên banner"
                  />
                </Form.Item>
                <Form.Item
                  label="Loại đường dẫn"
                  required
                  validateStatus={touched.reference?.type && errors.reference?.type ? 'error' : 'validating'}
                  help={touched.reference?.type ? errors.reference?.type : undefined}>
                  <Select value={values.reference.type} onChange={value => setFieldValue('reference.type', value)}>
                    <Select.Option value={ReferenceType.View}>Không chứa đường dẫn</Select.Option>
                    <Select.Option value={ReferenceType.Category}>Danh mục</Select.Option>
                    <Select.Option value={ReferenceType.Product}>Sản phẩm</Select.Option>
                  </Select>
                </Form.Item>
                {values.reference.type === ReferenceType.Category && (
                  <Form.Item
                    label="Danh mục hàng"
                    required
                    validateStatus={
                      touched.reference?.categoryId && errors.reference?.categoryId ? 'error' : 'validating'
                    }
                    help={touched.reference?.categoryId ? errors.reference?.categoryId : undefined}>
                    <Select
                      placeholder="Chọn danh mục sản phẩm"
                      loading={loading}
                      value={values.reference.categoryId}
                      onChange={value => setFieldValue('reference.categoryId', value)}>
                      {categories?.map(
                        cat =>
                          cat && (
                            <Select.Option key={cat.id} value={cat.id}>
                              {cat.name}
                            </Select.Option>
                          ),
                      )}
                    </Select>
                  </Form.Item>
                )}
                {values.reference.type === ReferenceType.Product && (
                  <Form.Item
                    label="Sản phẩm"
                    required
                    validateStatus={touched.reference?.sku && errors.reference?.sku ? 'error' : 'validating'}
                    help={touched.reference?.sku ? errors.reference?.sku : undefined}>
                    <SelectProduct
                      value={values.reference.sku}
                      onChange={value => setFieldValue('reference.sku', value)}
                    />
                  </Form.Item>
                )}
                <Form.Item label="Áp dụng cho siêu thị">
                  <Input value={source?.name} disabled />
                </Form.Item>
                <Form.Item
                  required
                  label="Ảnh banner"
                  valuePropName="fileList"
                  className="mt-8"
                  validateStatus={touched.image && errors.image ? 'error' : 'validating'}
                  help={touched.image ? errors.image : undefined}>
                  <Upload listType="picture" className="w-full" {...uploadProps}>
                    {isUploading ? (
                      <Spin tip="uploading..." />
                    ) : values.image ? (
                      <img src={values.image} alt={values.image} />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </FormikProvider>
    </Wrapper>
  )
}

export default ModalBannerForm
