import { Pagination } from 'antd'
import React from 'react'

export const usePagination = (
  initializeTotal: () => number,
  currentPage: number = 1,
  pageSize: number = 10,
  displayText: string = '',
) => {
  const [total, setTotal] = React.useState(0)

  React.useEffect(() => {
    setTotal(initializeTotal())
  }, [initializeTotal])

  const [pagination, setPagination] = React.useState<{ currentPage: number; pageSize: number }>({
    currentPage,
    pageSize,
  })

  function renderPagination() {
    return (
      <Pagination
        className="flex justify-end"
        showSizeChanger={false}
        showTotal={(total, range) => `Hiển thị ${range[0]}-${range[1]} trong ${total} ${displayText}`}
        onChange={currentPage => setPagination({ ...pagination, currentPage })}
        total={total}
        pageSize={pagination.pageSize}
        current={pagination.currentPage}
      />
    )
  }

  return { pagination, setPagination, renderPagination }
}
