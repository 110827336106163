import { ManagerRole } from '@coop/common'
import { Avatar, Badge, Input, Modal, Table } from 'antd'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useAuth } from 'src/contexts/AuthContext'
import { useFirestore } from 'src/contexts/FirestoreContext'
import { useSource } from 'src/contexts/SourceContext'
import env from 'src/shared/Env'
import { usePagination } from 'src/shared/hooks/usePagination'
import { Market, SystemOrder, Util } from 'src/shared/model'
import styled from 'styled-components'

const StyledTable = styled('div')`
  .ant-table-tbody > tr {
    cursor: pointer;
  }
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 8px 8px;
  }
`

interface modalProps {
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
}

interface TotalOrders {
  totalOrders: number
}

const ModalStoreList = (props: modalProps) => {
  const { visible, setVisible } = props

  const { Data } = useFirestore()
  const { me, handleSelectStore } = useAuth()
  const { allSources, loading } = useSource()

  const [originSourceList, setOriginSourceList] = useState<(Market & TotalOrders)[]>([])
  const [sourcesList, setSourcesList] = useState<(Market & TotalOrders)[]>([])

  const isManager = me?.role === ManagerRole.Manager
  const filterCity = allSources
    .map(ele => (ele?.city ? ele.city : null))
    .filter((value, index, self) => value !== null && self.indexOf(value) === index)
    .map(e => ({ text: e || '', value: e || '' }))

  const onRowAction = (record: Market) => {
    return {
      onClick: () => {
        handleSelectStore(record.source_code)
        setVisible(false)
      },
    }
  }

  const PAGE_SIZE = 1000
  const {
    //  pagination, setPagination,
    renderPagination,
  } = usePagination(() => sourcesList.length, 1, PAGE_SIZE, 'Cửa hàng')

  useEffect(() => {
    setOriginSourceList([])
    setSourcesList([])
    const managerSources = allSources
      .filter(e => ((me?.listSourceCode || []).indexOf(e.source_code) > -1 ? e : null))
      .filter(eF => eF !== null)

    ;(isManager ? managerSources : allSources).forEach((ele, i) => {
      Data.systemOrder()
        .where('store.id', '==', ele.source_code)
        .onSnapshot(
          snapshot => {
            const docs = snapshot.docs
            const sysOrders = docs.map(doc => (doc.exists ? Util.convert<SystemOrder>(doc) : null))
            const storeItem = { stt: i + 1, totalOrders: sysOrders.length || 0, ...ele }
            setOriginSourceList(init => [...init.filter(eF => eF.source_code !== ele.source_code), { ...storeItem }])
            setSourcesList(init => [...init.filter(eF => eF.source_code !== ele.source_code), { ...storeItem }])
          },
          e => console.error(e),
        )
    })
  }, [Data, allSources, isManager, me])

  return (
    <Modal
      visible={visible}
      closable={true}
      onCancel={() => setVisible(false)}
      width="1024px"
      footer={null}
      bodyStyle={{ maxHeight: 1000, overflowY: 'scroll' }}>
      <>
        <Input
          placeholder="Lọc theo tên hoặc mã cửa hàng"
          style={{ width: '940px' }}
          onChange={(ele: any) => {
            const v = ele.target.value.toLocaleLowerCase('vi')
            v === ''
              ? setSourcesList(originSourceList)
              : setSourcesList(
                  originSourceList.filter(
                    e =>
                      e.source_code.toLocaleLowerCase('vi').includes(v) || e.name.toLocaleLowerCase('vi').includes(v),
                  ),
                )
          }}
        />
        {allSources?.length === 0 ? (
          <div>Không có store phù hợp</div>
        ) : (
          <StyledTable>
            <Table<Market & TotalOrders>
              rowKey="source_code"
              dataSource={sourcesList}
              loading={loading}
              columns={[
                {
                  title: 'STT',
                  render: data => <div className="text-center">{data.stt}</div>,
                  width: 60,
                },
                {
                  title: 'Logo',
                  dataIndex: 'imageUrl',
                  render: (text: string) => (
                    <Avatar shape="square" size={40} src={`${text ? env.serverUrl : ''}/${text}`} />
                  ),
                  width: 70,
                },
                {
                  title: 'Tên cửa hàng',
                  render: data => <div className="font-semibold flex items-center">{data.name}</div>,
                  width: 250,
                },
                {
                  title: 'Địa chỉ',
                  render: d => <div className="font-semibold flex items-center">{`${d.street}, ${d.region}`}</div>,
                  width: 300,
                },
                {
                  title: 'Tỉnh / thành',
                  render: data => <div className="font-semibold flex items-center">{data.city}</div>,
                  filters: filterCity,
                  onFilter: (value: string | number | boolean, record) => record.city?.indexOf(value.toString()) === 0,
                  width: 150,
                },
                {
                  title: 'Đơn hàng',
                  render: d => (
                    <div className="text-center">
                      <Badge showZero count={d.totalOrders} overflowCount={9999} />
                    </div>
                  ),
                  sorter: {
                    compare: (a, b) => b.totalOrders - a.totalOrders,
                    multiple: 1,
                  },
                },
              ]}
              pagination={false}
              scroll={{ y: 400 }}
              onRow={onRowAction}
              footer={renderPagination}
            />
          </StyledTable>
        )}
      </>
    </Modal>
  )
}

export default ModalStoreList
