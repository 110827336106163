import { useLazyQuery } from '@apollo/react-hooks'
import { ProductSkuFilterCondition, PRODUCTS_QUERY, Query } from '@coop/apollo'
import { Col, Empty, Form, Input, Row, Spin } from 'antd'
import { FormikProvider, useFormik } from 'formik'
import React from 'react'
import styled from 'styled-components'
// @ts-ignore
import tw from 'tailwind.macro'
import * as Yup from 'yup'
import { useSource } from '../../../contexts/SourceContext'
import { Button } from '../../../shared/components'
import Modal, { ModalActionGroup, ModalHeader } from '../../../shared/components/Modal'
import { Product, Section } from '../../../shared/model'
import DragListProduct from './DragListProduct'
import ModalDashboardProduct from './ModalDashboardProduct'

const GroupTitle = styled.div`
  ${tw`text-xl`}
`

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Vui lòng nhập tên kệ hàng!'),
  // sourceCodes: Yup.array().required('Vui lòng chọn siêu thị áp dụng'),
})

type Props = {
  selectedItem?: Section
  onSubmit: (data: Omit<Section, 'id'>) => Promise<void>
  onDeleteItem: () => Promise<void>
}

const ModalDashboardCategory: React.FC<Props> = ({ onSubmit, selectedItem, onDeleteItem }) => {
  const formik = useFormik<Omit<Section, 'id'>>({
    initialValues: selectedItem || {
      name: '',
      items: [],
      sourceCode: '',
    },
    onSubmit,
    validationSchema,
  })
  const { values, errors, touched, setFieldValue, isSubmitting, handleSubmit } = formik

  const [showAddProductModal, setShowAddProductModal] = React.useState(false)
  const [products, setProducts] = React.useState<Product[]>([])

  const { source } = useSource()

  const [getProducts, { loading, data: productsData }] = useLazyQuery<Pick<Query, 'products'>>(PRODUCTS_QUERY, {
    variables: {
      filter: {
        source_code: source?.source_code as string,
        skus: { codes: selectedItem?.items || [], condition: ProductSkuFilterCondition.In },
      },
    },
  })

  const handleDelete = () => {
    onDeleteItem()
  }

  const handleAddProducts = async (newProducts: Product[]) => {
    if (!newProducts.length) {
      return
    }
    const selectedProducts = [...(products || []), ...(newProducts || [])]
    setProducts(selectedProducts)
    setShowAddProductModal(false)
  }

  const handleDeleteProduct = (product: Product) => {
    const deleteIndex = products?.findIndex(item => item.id === product.id)

    if (deleteIndex === undefined) {
      return
    }

    const newProducts = [
      ...(products?.slice(0, deleteIndex) || []),
      ...(products?.slice(deleteIndex + 1, products.length) || []),
    ]
    setProducts(newProducts)
  }

  React.useEffect(() => {
    setFieldValue('sourceCode', source?.source_code)
  }, [setFieldValue, source])

  React.useEffect(() => {
    getProducts()
  }, [getProducts])

  React.useEffect(() => {
    const items = productsData?.products?.items || []
    const listProduct = selectedItem?.items?.map(e => ({ ...items.find(eF => eF.sku === e) }))
    setProducts(listProduct as Product[])
  }, [productsData, selectedItem])

  React.useEffect(() => {
    products && setFieldValue('items', [...products.map(e => e.sku)])
  }, [products, setFieldValue])

  return (
    <FormikProvider value={formik}>
      <Form
        labelCol={{ span: 4 }}
        name="dashboard-category-form"
        onFinish={() => handleSubmit()}
        className="flex flex-col flex-1">
        <ModalHeader
          title={selectedItem ? 'Cập nhật kệ hàng' : 'Thêm kệ hàng'}
          actionRenderer={
            <ModalActionGroup
              submitText={'Lưu'}
              loading={isSubmitting}
              onDelete={selectedItem ? handleDelete : undefined}
            />
          }
        />
        <div className="w-full mx-auto p-4 flex-1 overflow-y-auto" style={{ maxWidth: '960px' }}>
          <Row className="mb-4 w-full">
            <Col span={24}>
              <GroupTitle className="mb-4">Thông Tin</GroupTitle>
              <Form.Item
                label="Tên kệ hàng"
                required
                validateStatus={touched.name && errors.name ? 'error' : 'validating'}
                help={touched.name ? errors.name : undefined}>
                <Input
                  placeholder="Nhập tên kệ hàng"
                  value={values.name}
                  onChange={event => setFieldValue('name', event.target.value)}
                />
              </Form.Item>

              <Form.Item label="Áp dụng cho siêu thị">
                <Input value={source?.name} disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row className="flex flex-col">
            <div className="flex flex-1 justify-between mb-4">
              <GroupTitle>Sản Phẩm</GroupTitle>
              <Button type="button" onClick={() => setShowAddProductModal(true)}>
                Thêm sản phẩm
              </Button>
              <Modal
                title="Thêm sản phẩm"
                isOpen={showAddProductModal}
                onRequestClose={() => setShowAddProductModal(false)}>
                <ModalDashboardProduct existingProducts={products || []} onProductsAdded={handleAddProducts} />
              </Modal>
            </div>
            <Form.Item>
              {loading ? (
                <Spin tip="Loading..." className="m-auto" />
              ) : products && products.length > 0 ? (
                <DragListProduct products={products} setProducts={setProducts} onDeleteProduct={handleDeleteProduct} />
              ) : (
                <Empty />
              )}
            </Form.Item>
          </Row>
        </div>
      </Form>
    </FormikProvider>
  )
}

export default ModalDashboardCategory
