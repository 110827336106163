import { PayType } from '@coop/common/dist/model'
import IconLoading from '../../../assets/paymentIcon/LoadingIcon.svg'
import IconATM from '../../../assets/paymentIcon/MethodATM.png'
import IconBankTransfer from '../../../assets/paymentIcon/MethodBankTransfer.png'
import IconCash from '../../../assets/paymentIcon/MethodCash.png'
import IconCredit from '../../../assets/paymentIcon/MethodCredit.png'
import IconWallet from '../../../assets/paymentIcon/MethodWallet.png'

const lowercaseMomo = PayType.MOMO.toLowerCase()
const lowercaseZalo = PayType.ZALO.toLowerCase()

const paymentMethodToText = (method?: string, subMethod?: string) =>
  subMethod === lowercaseMomo
    ? 'Thanh toán bằng ví Momo'
    : subMethod === lowercaseZalo
    ? 'Thanh toán bằng ví Zalo'
    : method === PayType.VA
    ? 'Chuyển khoản ngân hàng'
    : method === PayType.ATM
    ? 'Thẻ ATM nội địa'
    : method === PayType.COD
    ? 'Thanh toán khi nhận hàng'
    : method === PayType.CreditCard
    ? 'Thẻ tín dụng VISA/Master'
    : 'Chưa rõ'

const paymentMethodToIcon = (method?: string): string =>
  method === PayType.EW
    ? IconWallet
    : method === PayType.VA
    ? IconBankTransfer
    : method === PayType.ATM
    ? IconATM
    : method === PayType.COD
    ? IconCash
    : method === PayType.CreditCard
    ? IconCredit
    : IconLoading

export { paymentMethodToText, paymentMethodToIcon }
