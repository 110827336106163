import { Input, Select } from 'antd'
import React from 'react'
import { useSource } from '../../../contexts/SourceContext'

const { Option } = Select

export type FilterInputType = {
  text: string | undefined
  category: string | undefined
  visibility: string | undefined
}

type Props = {
  searchValue: FilterInputType
  onValueChange: (key: keyof FilterInputType, value: string | undefined) => void
}

export const OPTION_ALL: string = 'all'
export enum ProductStatusOption {
  Available = 4,
  Unavailable = 1,
}

const FiltersGroup: React.FC<Props> = ({ searchValue, onValueChange }) => {
  const { categories, source, loading } = useSource()

  return (
    <div className="mb-8 flex items-center" style={{ backgroundColor: '#fafafa' }}>
      <div className="flex-1">
        <Input
          allowClear
          placeholder="Tìm kiếm bằng tên hoặc mã sản phẩm"
          value={searchValue.text}
          onChange={e => onValueChange('text', e.target.value)}
        />
      </div>
      <div className="flex w-1/3 items-center ml-4 mr-4">
        <label className="mr-2">Ngành hàng</label>
        <Select
          style={{ flex: 1 }}
          value={searchValue.category}
          onChange={e => onValueChange('category', e)}
          loading={loading}>
          <Option value={OPTION_ALL}>Tất cả</Option>
          {(categories || []).map(cate => (
            <Option key={cate.id} value={cate.id}>
              {cate.name}
            </Option>
          ))}
        </Select>
      </div>
      {source?.source_code && (
        <div className="flex w-1/3 items-center">
          <label className="mr-2">Tình trạng</label>
          <Select style={{ flex: 1 }} value={searchValue.visibility} onChange={e => onValueChange('visibility', e)}>
            <Option value={OPTION_ALL}>Tất cả</Option>
            <Option value={ProductStatusOption.Available}>Còn hàng</Option>
            <Option value={ProductStatusOption.Unavailable}>Hết hàng</Option>
          </Select>
        </div>
      )}
    </div>
  )
}

export default FiltersGroup
