import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useAbility } from 'src/shared/accessControl'
import { Row, Col } from 'antd'
import { ProductPage } from './ProductPage'

const Product: React.FC<{}> = () => {
  const ability = useAbility()

  // const sideMenuItems = [
  //   ...(ability.can('read', '/san-pham') ? [{ title: 'Quản lý sản phẩm', path: '/san-pham' }] : []),
  // ]

  return (
    <Row className="flex w-full">
      <Col span={24}>
        <Switch>
          {ability.can('read', '/san-pham') && <Route exact path="/san-pham" component={ProductPage} />}
          <Redirect to="/san-pham" />
        </Switch>
      </Col>
    </Row>
  )
}

export default Product
