import { WarningFilled } from '@ant-design/icons'
import { ProcessStatus } from '@coop/common'
import { message } from 'antd'
import { firestore } from 'firebase'
import React, { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Driver, Picker, SystemOrder } from '../shared/model'
import { useFirestore } from './FirestoreContext'
import { useSource } from './SourceContext'

const Bold = styled('div')`
  ${tw`uppercase font-semibold text-gray-900`}
`

type OrderMap = Partial<{ [status in ProcessStatus]: SystemOrder[] }>

interface UserInOrder {
  user?: {
    phone?: string
    name?: string
  }
}

type OrdersContextType = {
  orders: (SystemOrder & UserInOrder)[]
  setFilterOrderText: Dispatch<SetStateAction<string>>
  ordersMap: OrderMap
  freePickers: Picker[]
  comingDrivers: Driver[]
}

const Context = React.createContext<OrdersContextType | string>('useOrders should be used inside OrdersProvider')

export const OrdersProvider: React.FC = ({ children }) => {
  const { Data, Util } = useFirestore()
  const { source } = useSource()

  const [originOrders, setOriginOrders] = React.useState<(SystemOrder & UserInOrder)[]>([])
  const [orders, setOrders] = React.useState<(SystemOrder & UserInOrder)[]>([])
  const [filterOrderText, setFilterOrderText] = React.useState<string>('')
  const [freePickers, setFreePickers] = React.useState<Picker[]>([])
  const [comingDrivers, setComingDrivers] = React.useState<Driver[]>([])
  const [changes, setChanges] = React.useState<firestore.DocumentChange[]>([])

  React.useEffect(() => {
    if (!source?.source_code) {
      return
    }
    const unsub = Data.picker()
      .where('isOl', '==', true)
      .where('disabled', '==', false)
      .where('isAvailable', '==', true)
      .where('sourceCode', '==', source.source_code)
      .onSnapshot(snapshot => {
        const pickers = snapshot.docs
          .map(doc => (doc.exists ? Util.convert<Picker>(doc) : null))
          .filter(p => !!p) as Picker[]
        setFreePickers(pickers)
      })

    return () => {
      unsub()
      setFreePickers([])
    }
  }, [Data, Util, source])

  React.useEffect(() => {
    if (!source?.source_code) {
      return
    }
    const unsub = Data.driver()
      .where('isOl', '==', true)
      .where('disabled', '==', false)
      .where('isAvailable', '==', false)
      .where('store.id', '==', source.source_code)
      .onSnapshot(snapshot => {
        const drivers = snapshot.docs
          .map(doc => (doc.exists ? Util.convert<Driver>(doc) : null))
          .filter(p => !!p && !p.orderRef) as Driver[]
        setComingDrivers(drivers)
      })

    return () => {
      unsub()
      setComingDrivers([])
    }
  }, [Data, Util, source])

  React.useEffect(() => {
    if (!source?.source_code) {
      return
    }
    let snapshotCount = 0
    /// Important: The first query snapshot contains ADDED events for all existing documents that match the query. So we need to ignore this initial load

    const unsub = Data.systemOrder()
      .where('store.id', '==', source.source_code)
      .onSnapshot(
        snapshot => {
          if (snapshotCount) {
            setChanges(snapshot.docChanges())
          }

          const sysOrders = snapshot.docs
            .map(doc => (doc.exists ? { ...doc.data(), id: doc.id } : null))
            .filter(d => d) as (SystemOrder & UserInOrder)[]
          const sysSortedOrders = sysOrders.sort(
            (a, b) => (b.attentionRequired ? 1 : 0) - (a.attentionRequired ? 1 : 0),
          )
          // set từ lần get đầu tiên để layout hiện ra sớm
          setOriginOrders(sysSortedOrders)

          Data.order()
            .where('store.id', '==', source.source_code)
            .get()
            .then(
              orderSnapshot => {
                const theOrders = orderSnapshot.docs
                  .map(doc => (doc.exists ? { ...doc.data(), id: doc.id } : null))
                  .filter(d => d) as (SystemOrder & UserInOrder)[]
                const sortedOrders = theOrders
                  .map(e => ({ ...e, ...sysOrders.find(eF => e.id === eF.id) }))
                  .sort((a, b) => (b.attentionRequired ? 1 : 0) - (a.attentionRequired ? 1 : 0))
                // set lần 2 để thêm thông tin order vào
                setOriginOrders(sortedOrders)
              },
              e => console.error(e),
            )
          snapshotCount += 1
        },
        e => console.error(e),
      )

    return () => {
      unsub()
      setOriginOrders([])
    }
  }, [Data, source])

  React.useEffect(() => {
    const filteredOrderWithDetail = originOrders.filter(e => {
      const isIncluded =
        e.id?.toLocaleLowerCase('vi').includes(filterOrderText) ||
        e.user?.name?.toLocaleLowerCase('vi').includes(filterOrderText) ||
        e.user?.phone?.toLocaleLowerCase('vi').includes(filterOrderText)
      return isIncluded ? true : false
    })
    setOrders(filteredOrderWithDetail)
    return () => {
      setOrders([])
    }
  }, [filterOrderText, originOrders])

  React.useEffect(() => {
    changes.forEach(change => {
      switch (change.type) {
        case 'added':
          message.success(
            <span>
              Order mới <Bold>{change.doc.id}</Bold>
            </span>,
          )
          break
        case 'modified':
          const newData = change.doc.exists && Util.convert<SystemOrder>(change.doc)
          if (newData) {
            const oldData = originOrders.find(o => o.id === newData.id)
            if (!oldData) {
              break
            }
            if (!oldData.attentionRequired && !!newData.attentionRequired) {
              message.warning({
                content: (
                  <span>
                    Đơn hàng <Bold>{change.doc.id}</Bold> cần xử lý
                  </span>
                ),
                icon: <WarningFilled />,
              })
              break
            }
            if (oldData.status !== newData.status && newData.status === ProcessStatus.Returning) {
              message.warning(
                <span>
                  Đơn hàng <Bold>{change.doc.id}</Bold> cần xử lý
                </span>,
              )
              break
            }
            if (oldData.status !== newData.status) {
              message.info(
                <span>
                  Đơn hàng <Bold>{change.doc.id}</Bold> đã chuyển sang trạng thái{' '}
                  <Bold>{processStatusDescription(newData.status)}</Bold>
                </span>,
              )
            }
          }
          break
        default:
          break
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changes])

  const ordersMap = orders.reduce((map, o) => ({ ...map, [o.status]: [...(map[o.status] || []), o] }), {} as OrderMap)

  return (
    <Context.Provider
      value={{
        freePickers,
        comingDrivers,
        orders,
        setFilterOrderText,
        ordersMap,
      }}>
      {children}
    </Context.Provider>
  )
}

export const useOrders = (): OrdersContextType => {
  const context = React.useContext(Context)
  if (typeof context === 'string') {
    throw new Error(context)
  }
  return context
}

export const processStatusDescription = (status?: ProcessStatus): string | null => {
  switch (status) {
    case ProcessStatus.ReadyToPick:
      return 'Đơn hàng mới'
    case ProcessStatus.Picking:
      return 'Đang gom hàng'
    case ProcessStatus.ReadyToDeliver:
      return 'Sẵn sàng giao'
    case ProcessStatus.Delivering:
      return 'Đang giao hàng'
    case ProcessStatus.Returning:
      return 'Trả hàng'
    default:
      return null
  }
}
