import { message } from 'antd'
import React from 'react'
import styled from 'styled-components'
// @ts-ignore
import tw from 'tailwind.macro'
import { useFirestore } from '../../../contexts/FirestoreContext'
import { useSource } from '../../../contexts/SourceContext'
import { Button } from '../../../shared/components'
import Modal from '../../../shared/components/Modal'
import { Section } from '../../../shared/model'
import DragListCategory from './DragListCategory'
import ModalDashboardCategory from './ModalDashboardCategory'

const CategoryWrapper = styled.div``
const Title = styled.div`
  ${tw`uppercase font-normal text-gray-500`}
`

type Props = {}

const DashboardCategory: React.FC<Props> = () => {
  const [showModal, setShowModal] = React.useState(false)
  const [selectedItem, setSelectedItem] = React.useState<Section>()
  const [sections, setSections] = React.useState<Section[]>()

  const { db, Data, Util } = useFirestore()
  const { source } = useSource()

  React.useEffect(() => {
    if (!source) {
      return
    }

    const unsub = Data.section()
      .where('sourceCode', '==', source.source_code)
      .orderBy('order')
      .onSnapshot(snapshot => setSections(snapshot.docs.map(doc => Util.convert<Section>(doc))))

    return () => unsub()
  }, [Data, Util, source])

  const handleSelectCategory = (item: Section) => {
    setSelectedItem(item)
    setShowModal(true)
  }

  const handleSubmit = async (data: Omit<Section, 'id'>) => {
    try {
      if (selectedItem) {
        await Data.section().doc(selectedItem.id).update(data)
        message.success('Cập nhật thông tin kệ hàng thành công!')
      } else {
        const lastSection = [...(sections || [])].pop()
        const nextOrder = (lastSection?.order || 0) + 1
        await Data.section().add({ ...data, order: nextOrder })
        message.success('Thêm kệ hàng thành công!')
      }
      return Promise.resolve()
    } catch (e) {
      message.error('Có lỗi xảy ra. Vui lòng thử lại sau!')
      console.error(e)
      return Promise.reject()
    } finally {
      setShowModal(false)
      setSelectedItem(undefined)
    }
  }

  const handleDelete = async () => {
    if (!selectedItem) {
      return
    }
    try {
      await Data.section().doc(selectedItem.id).delete()
      message.success('Xóa kệ hàng thành công!')
      const batch = db.batch()
      sections &&
        sections
          .filter(eF => eF.id !== selectedItem.id)
          .forEach((e: Section, i: number) => {
            const docRef = Data.section().doc(e.id)
            batch.update(docRef, { order: i + 1 })
          })
      batch
        .commit()
        .then(() => message.success({ content: 'Cập nhật thứ tự kệ hàng thành công', key: 'reOrder', duration: 2 }))
        .catch(error =>
          message.error({ content: `Cập nhật kệ hàng thất bại ${JSON.stringify(error)}`, key: 'reOrder' }),
        )
      return Promise.resolve()
    } catch (e) {
      message.error('Có lỗi xảy ra. Vui lòng thử lại sau!')
      console.error(e)
      return Promise.reject()
    } finally {
      setShowModal(false)
      setSelectedItem(undefined)
    }
  }

  return (
    <CategoryWrapper className="mt-12">
      <div className="flex items-center mb-2 flex-1 justify-between">
        <Title>Kệ hàng</Title>
        <Button onClick={() => setShowModal(true)}>Thêm kệ hàng</Button>
        {showModal && (
          <Modal
            isFullScreen
            title={selectedItem ? 'Cập nhật kệ hàng' : 'Thêm kệ hàng'}
            isOpen={showModal}
            onRequestClose={() => {
              setShowModal(false)
              setSelectedItem(undefined)
            }}>
            <ModalDashboardCategory onSubmit={handleSubmit} selectedItem={selectedItem} onDeleteItem={handleDelete} />
          </Modal>
        )}
      </div>
      <DragListCategory sections={sections || []} handleSelectedCategory={handleSelectCategory} />
    </CategoryWrapper>
  )
}

export default DashboardCategory
