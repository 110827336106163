import { Button, Col, Form, Input, message, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { FirebaseContext } from 'src/contexts/FirebaseContext'
import { useFirestore } from 'src/contexts/FirestoreContext'
import styled from 'styled-components'
import tw from 'tailwind.macro'

const Content = styled.div`
  .ant-table thead tr > th {
    ${tw`text-gray-500 uppercase tracking-wider font-normal pb-2`}
  }
`

type Props = {}

const FormAddCustomers: React.FC<Props> = () => {
  const [form] = Form.useForm()
  const { functions } = React.useContext(FirebaseContext)
  const { Data } = useFirestore()

  const [channelList, setChannelList] = useState<any[]>([])
  const [formLoading, setFormLoading] = useState(false)
  const [invalidInfo, setInvalidInfo] = useState({ email: false, phone: false })

  const checkExists = (type: string) => {
    const getValue = form.getFieldValue(type)
    const valueLen = getValue?.length
    const phoneWithCountryCode = `+84${getValue?.substring(valueLen - 9, valueLen)}`
    const infoToCheck = getValue && type === 'phone' ? phoneWithCountryCode : getValue

    infoToCheck &&
      Data.user()
        .where(type, '==', infoToCheck)
        .get()
        .then(result => {
          if (result.docs.length > 0) {
            const errorText = `${type === 'email' ? 'Email' : 'Số điện thoại'} tồn tại trên hệ thống`
            form.setFields([{ name: type, errors: [errorText] }])
            setInvalidInfo((init: any) => ({ ...init, [type]: true }))
          } else {
            setInvalidInfo((init: any) => ({ ...init, [type]: false }))
          }
        })
  }

  const handleSubmitForm = (formValues: any) => {
    setFormLoading(true)
    const phoneLen = formValues.phone.length
    const dataToPost = {
      ...formValues,
      phoneNumber: `+84${formValues.phone?.substring(phoneLen - 9, phoneLen)}`,
    }
    functions
      .httpsCallable('firestore-adminCreateCustomer')(dataToPost)
      .then(response => {
        window.localStorage.setItem('create_order_access_token', response.data?.accessToken)
        message.success('Tạo tài khoản thành công')
        form.resetFields()
      })
      .catch(error => message.error(`Tạo tài khoản thất bại ${error.message}`))
      .finally(() => setFormLoading(false))
  }

  useEffect(() => {
    Data.channelConfig()
      .get()
      .then(result => result.docs.forEach((e: any) => setChannelList((init: any) => [...init, { ...e.data() }])))
      .catch(() => message.error('Lấy thông tin channel không thành công'))
  }, [Data])

  return (
    <Content className="flex flex-1">
      <Form
        labelCol={{ span: 8, xl: { span: 6 } }}
        form={form}
        name="product-form"
        onFinish={handleSubmitForm}
        className="flex flex-col w-full">
        <div className="" style={{ maxWidth: '960px' }}>
          <Row gutter={16} className="flex-wrap-reverse">
            <Col flex={1}>
              <Form.Item
                name="name"
                label="Tên khách hàng"
                rules={[{ required: true, message: 'Tên khách hàng không để trống' }]}>
                <Input placeholder="Nhập tên khách hàng" />
              </Form.Item>

              <Form.Item
                name="phone"
                label="Số điện thoại"
                rules={[{ required: true, min: 10, message: 'Điện thoại không để trống và có ít nhất 10 số' }]}>
                <Input
                  minLength={10}
                  type={'number'}
                  onBlur={() => checkExists('phone')}
                  placeholder="Nhập số điện thoại"
                />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, type: 'email', message: 'Email không để trống và phải đúng định dang có @' },
                ]}>
                <Input onBlur={() => checkExists('email')} placeholder="Nhập Email" />
              </Form.Item>

              <Form.Item
                name="channel"
                label="Channel"
                required
                rules={[{ required: true, message: 'Vui lòng nhập channel' }]}>
                <Select>
                  {channelList.map((e: any, i: number) => (
                    <Select.Option key={`channel_item_${i}`} value={e?.appId}>
                      {e?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <div style={{ textAlign: 'right' }}>
            <Button
              loading={formLoading}
              disabled={invalidInfo.email || invalidInfo.phone}
              type="primary"
              htmlType="submit"
              size="large"
              className="">
              Thêm khách hàng
            </Button>
          </div>
        </div>
      </Form>
    </Content>
  )
}

export default FormAddCustomers
