import { SyncOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons'
import { Button, message, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import styled from 'styled-components'
import { useFirestore } from '../../../contexts/FirestoreContext'
import { useSource } from '../../../contexts/SourceContext'
import { TableWrapper } from '../../../shared/components'
import Modal from '../../../shared/components/Modal'
import { Coupon } from '../../../shared/model'
import { useApplyNewData, useSyncCoupons } from './api'
import { ActiveSwitch, Cell } from './components'
import FiltersGroup, { FilterInputType } from './FiltersGroup'
import PromoFormModal from './PromoFormModal'

const CustomButton = styled(Button)`
  .ant-btn-loading-icon {
    display: none;
  }
`

const PAGE_SIZE = 10

const columns: ColumnProps<Coupon>[] = [
  { title: 'Mã giảm giá', dataIndex: 'id' },
  {
    title: 'Tên chương trình',
    render: (d: Coupon) => <Cell coupon={d} dataIndex="name" />,
  },
  { title: 'Mô tả', render: (d: Coupon) => <Cell coupon={d} dataIndex="description" /> },
  { title: 'Giá trị', render: (d: Coupon) => <Cell coupon={d} dataIndex="value" /> },
  {
    title: 'Hiển thị',
    render: (d: Coupon) => <ActiveSwitch coupon={d} />,
  },
]

type Props = {}

const PromoPage: React.FC<Props> = () => {
  const [filterInput, setFilterInput] = React.useReducer<React.Reducer<FilterInputType, Partial<FilterInputType>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      text: '',
    },
  )
  const [showModal, setShowModal] = React.useState(false)
  const [selectedItem, setSelectedItem] = React.useState<Coupon>()
  const [coupons, setCoupons] = React.useState<Coupon[]>([])

  const { Data, Util } = useFirestore()
  React.useEffect(() => {
    Data.coupon().onSnapshot(snapshot => setCoupons(snapshot.docs.map(doc => Util.convert<Coupon>(doc))))
  }, [Data, Util])

  const handleSubmit = async (submitData: Pick<Coupon, 'isHidden'>) => {
    const { isHidden } = submitData
    try {
      if (!selectedItem) {
        return message.error('Vui lòng chọn ')
      }
      Data.coupon().doc(selectedItem.id).update({ isHidden })
    } catch (e) {
      message.error('Có lỗi xảy ra. Vui lòng thử lại sau!')
      console.error(e)
    } finally {
      setShowModal(false)
      setSelectedItem(undefined)
    }
  }

  const handleSelectCoupon = (c: Coupon) => {
    setSelectedItem(c)
    setShowModal(true)
  }

  const handleFilterChange = (key: keyof FilterInputType, value: string | undefined) => {
    setFilterInput({ [key]: value })
  }

  const filteredCoupons = React.useMemo(() => {
    const searchLowerCase = (filterInput.text || '').toLowerCase()
    return coupons.filter(
      c => c.id.toLowerCase().includes(searchLowerCase) || c.name.toLowerCase().includes(searchLowerCase),
    )
  }, [coupons, filterInput.text])

  const { source } = useSource()
  const syncCoupons = useSyncCoupons(source?.source_code)
  const applyNewData = useApplyNewData()
  const [syncing, setSyncing] = React.useState(false)
  const [applying, setApplying] = React.useState(false)
  const [hasPendingChange, setHasPendingChange] = React.useState(false)

  React.useEffect(() => {
    setHasPendingChange(coupons.some(c => !!c.newData || c.removing))
  }, [coupons])

  const handleClickSync = async () => {
    try {
      setSyncing(true)
      await syncCoupons(coupons)
      message.success('Đồng bộ thành công!')
    } catch (e) {
      message.error(`Có lỗi xảy ra ${e.message}`)
    } finally {
      setSyncing(false)
    }
  }

  const handleClickApplyNewData = async () => {
    try {
      setApplying(true)
      await applyNewData()
      message.success('Cập nhật thành công!')
    } catch (e) {
      console.error(e)
      message.error(`Có lỗi xảy ra ${e.message}`)
    } finally {
      setApplying(false)
    }
  }

  const dataColumns = React.useMemo(
    () =>
      hasPendingChange
        ? [
            ...columns,
            {
              title: 'Pending action',
              render: (d: Coupon) => (d.removing ? 'Xóa' : d.newData ? 'Cập nhật' : ''),
              width: 100,
            },
          ]
        : columns,
    [hasPendingChange],
  )

  return (
    <>
      <div className="self-end mb-8">
        <Modal
          isFullScreen
          title={selectedItem ? 'Cập nhật Khuyến mãi' : 'Thêm Khuyến mãi'}
          isOpen={showModal}
          onRequestClose={() => {
            setShowModal(false)
            setSelectedItem(undefined)
          }}>
          {selectedItem && <PromoFormModal onSubmit={handleSubmit} coupon={selectedItem} />}
        </Modal>
      </div>
      <TableWrapper className="flex-1">
        <div className="w-full flex justify-between">
          <FiltersGroup searchValue={filterInput} onValueChange={handleFilterChange} />
          <CustomButton type="primary" loading={syncing} onClick={handleClickSync}>
            <SyncOutlined spin={syncing} /> Đồng bộ khuyến mãi
          </CustomButton>
          {hasPendingChange && (
            <CustomButton
              type="primary"
              className="ml-2 bg-green-500 border-0 hover:bg-green-400 focus:bg-green-400"
              loading={applying}
              onClick={handleClickApplyNewData}>
              <VerticalAlignBottomOutlined spin={applying} /> Lưu thay đổi
            </CustomButton>
          )}
        </div>
        <Table<Coupon>
          rowKey="id"
          dataSource={filteredCoupons}
          columns={dataColumns}
          rowClassName={c => (c.removing ? 'bg-red-200' : c.newData ? 'bg-green-200' : '')}
          onRow={item => ({ onClick: () => handleSelectCoupon(item) })}
          pagination={{
            pageSize: PAGE_SIZE,
            showTotal: (total, range) => `Hiển thị ${range[0]}-${range[1]} trong ${total} mã khuyến mãi.`,
          }}
        />
      </TableWrapper>
    </>
  )
}

export default PromoPage
