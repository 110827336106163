import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
// @ts-ignore
import tw from 'tailwind.macro'

const Wrapper = styled.div``
const MenuLinkItem = styled(NavLink).attrs({
  className:
    'pr-4 px-2 w-full flex justify-between border-r-4 border-transparent text-gray-600 hover:text-gray-600 mb-4 cursor-pointer',
  activeClassName: 'active',
})`
  &:hover {
    border-right: 4px solid #4d343b42;
  }
  &.active {
    border-right: 4px solid #343b42;
    ${tw`text-gray-800`};
  }
`

const MenuStateItem = styled('div')`
  ${tw`pr-4 px-2 w-full flex justify-between border-r-4 border-transparent text-gray-600 hover:text-gray-600 mb-4 cursor-pointer`}
  &:hover {
    border-right: 4px solid #4d343b42;
  }
  &.active {
    border-right: 4px solid #343b42;
    ${tw`text-gray-800`};
  }
`

type MenuItemType = {
  title: string
  path?: string
  onClick?: () => void
  isActive?: boolean
}

type Props = {
  data: MenuItemType[]
  type?: 'link' | 'state'
}

const SideMenu: React.FC<Props> = ({ data, type = 'link' }) => {
  return (
    <Wrapper className="flex-initial w-full flex h-full flex-col border-r border-gray-300 border-solid">
      {data.map((item, index) => (
        <React.Fragment key={`${index}`}>
          {type === 'link' && (
            <MenuLinkItem to={item.path || '/'} key={`${item.path}-${index}`} exact>
              {item.title}
            </MenuLinkItem>
          )}
          {type === 'state' && (
            <MenuStateItem
              className={item.isActive ? 'active' : ''}
              onClick={() => item.onClick && item.onClick()}
              key={`${item.path}-${index}`}>
              {item.title}
            </MenuStateItem>
          )}
        </React.Fragment>
      ))}
    </Wrapper>
  )
}

export default SideMenu
