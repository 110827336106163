import { DeleteOutlined } from '@ant-design/icons'
import { Avatar, Button, Input, message, Table } from 'antd'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useFirestore } from 'src/contexts/FirestoreContext'
import { useSource } from 'src/contexts/SourceContext'
import env from 'src/shared/Env'
import { Market } from 'src/shared/model'
import styled from 'styled-components'
import { TagInput } from '../../../shared/components/TagInput'

type KeyDataTableType = 'storeOrder' | 'imageUrl' | 'name' | 'sourceCode' | 'tag'
enum PROGRESS_TYPE {
  EDIT = 'edit',
  DISABLED = 'disabled',
}
type IDataTable = Market & {
  key: string
  storeOrder: number
  name: string
  sourceCode: string
  tag: string[] | []
}

const newRowData: IDataTable = {
  key: '',
  storeOrder: 999,
  imageUrl: null,
  name: '',
  sourceCode: '',
  source_code: '',
  city: '',
  country_id: '',
  latitude: 0,
  longitude: 0,
  tag: [],
}

const DivWrap = styled.div``
let timeout = 0

const StoreTable: FC<any> = props => {
  const { storeList, tagListFiltered } = props
  const { allSources } = useSource()
  const { Data } = useFirestore()

  const [storeListState, setStoreListState] = useState<IDataTable[]>([])
  const [progress, setProgress] = useState<PROGRESS_TYPE>(PROGRESS_TYPE.DISABLED)

  const storeInfo = storeListState
    ?.map((e: IDataTable) => {
      const storeFiltered = allSources?.filter(eF => eF.source_code === e.sourceCode)[0] || {}
      return { ...e, ...storeFiltered }
    })
    .sort((a, b) => a.storeOrder - b.storeOrder)

  const changeTags = (record: IDataTable, newTags: any) => {
    const newRecord = { ...record, tag: newTags }
    const newStoreList = (prev: IDataTable[]) => [
      ...prev.filter((e: IDataTable) => e.sourceCode !== newRecord.sourceCode),
      newRecord,
    ]
    setStoreListState(newStoreList)
  }

  const onInputChange = (key: KeyDataTableType, record: IDataTable) => (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value
    // eslint-disable-next-line radix
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      // eslint-disable-next-line radix
      const newRecord = { ...record, [key]: isNaN(parseInt(value)) ? value : parseInt(value) }
      const newStoreList = (prev: IDataTable[]) => [
        ...prev.filter((e: IDataTable) => e.key !== newRecord.key),
        newRecord,
      ]
      setStoreListState(newStoreList)
    }, 1000)
  }

  const onCancel = () => {
    setStoreListState(storeList)
    setProgress(PROGRESS_TYPE.DISABLED)
  }

  const onAdd = () => {
    const haveNewRow = storeListState.filter((e: IDataTable) => e.sourceCode === '')[0]
    !haveNewRow && setStoreListState((init: any) => [...init, { ...newRowData, key: Math.random() }])
  }
  const onEdit = () => {
    setProgress(PROGRESS_TYPE.EDIT)
  }

  const onDelete = (record: IDataTable) => {
    setStoreListState((init: IDataTable[]) => init.filter((e: IDataTable) => e.sourceCode !== record.sourceCode))
  }

  const onSave = () => {
    const removeEmpty = storeListState.filter((e: IDataTable) => e.sourceCode !== '')
    const removeNotUsedInfo = removeEmpty.map((e: IDataTable) => {
      const { sourceCode, storeOrder, tag } = e
      return { sourceCode, storeOrder, tag }
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { collectionId, ...rest } = tagListFiltered
    const newTagList = { ...rest, stores: removeNotUsedInfo }
    // eslint-disable-next-line no-alert
    const confirmSave = window.confirm('Bạn có muốn lưu các thay đổi được thực hiện ?')
    confirmSave &&
      Data.homeScreen()
        .doc(tagListFiltered.collectionId)
        .set(newTagList)
        .then(() => {
          message.success('Thay đổi thành công')
          window.location.reload()
        })
    setProgress(PROGRESS_TYPE.DISABLED)
  }

  // return custom input component
  const renderInput = (key: KeyDataTableType) => (text: string, record: IDataTable) => {
    const isCodeOrName = key === 'sourceCode' || key === 'name'
    const isDisabled =
      record?.sourceCode === '' ? false : !isNaN(parseInt(record?.key)) ? false : isCodeOrName ? true : false

    return key === 'name' ? (
      <Input defaultValue={text} key={text} onChange={onInputChange(key, record)} disabled={true} />
    ) : (
      <Input
        defaultValue={text}
        key={text}
        onChange={onInputChange(key, record)}
        disabled={progress === PROGRESS_TYPE.DISABLED || isDisabled}
      />
    )
  }

  const columns = [
    {
      title: 'STT ',
      dataIndex: 'storeOrder',
      render: renderInput('storeOrder'),
      width: '8%',
    },
    {
      title: 'Logo',
      dataIndex: 'imageUrl',
      render: (text: string) => <Avatar shape="square" size={40} src={`${text ? env.serverUrl : ''}/${text}`} />,
    },
    {
      title: 'Tên cửa hàng',
      dataIndex: 'name',
      render: renderInput('name'),
      width: '25%',
    },
    {
      title: 'Mã cửa hàng',
      dataIndex: 'sourceCode',
      render: renderInput('sourceCode'),
    },
    {
      title: 'Thẻ',
      dataIndex: 'tag',
      render: (_: any, record: IDataTable) => (
        <TagInput
          tags={record?.tag}
          changeTags={(e: any) => changeTags(record, e)}
          disabled={progress === PROGRESS_TYPE.DISABLED}
        />
      ),
      width: '40%',
    },
    {
      title: '',
      dataIndex: 'actions',
      render: (_: any, record: IDataTable) =>
        progress === PROGRESS_TYPE.EDIT ? (
          <DeleteOutlined className="cursor-pointer" onClick={() => onDelete(record)} />
        ) : (
          <></>
        ),
    },
  ]

  useEffect(() => {
    setStoreListState(storeList)
  }, [storeList])

  return (
    <>
      <DivWrap className="w-full flex justify-end">
        {progress === PROGRESS_TYPE.EDIT && (
          <>
            <Button type="primary" className="mr-2" onClick={onAdd}>
              Add
            </Button>
            <Button className="mr-2" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="primary" onClick={onSave}>
              Save
            </Button>
          </>
        )}
        {progress === PROGRESS_TYPE.DISABLED && (
          <>
            <Button type="primary" className="mr-2" onClick={onEdit}>
              Edit
            </Button>
          </>
        )}
      </DivWrap>
      <Table dataSource={storeInfo} columns={columns} />
    </>
  )
}

export { StoreTable }
