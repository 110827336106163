import { Spin } from 'antd'
import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import UploadProvider from '../../contexts/UploadContext'

type Props = {
  component: React.ComponentType
} & RouteProps

const ProtectedRoute: React.FC<Props> = ({ component, ...restProps }) => {
  const { user, loading } = useAuth()

  if (loading) {
    return (
      <div className="w-full min-h-screen flex items-center justify-center">
        <Spin tip="Loading..." />
      </div>
    )
  }
  if (!user) {
    return <Redirect to="/xac-thuc" />
  }
  return (
    <UploadProvider>
      <Route {...restProps} component={component} />
    </UploadProvider>
  )
}

export default ProtectedRoute
