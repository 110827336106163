import {
  BannerCol,
  CategoryCol,
  ChannelConfigCol,
  CouponCol,
  DriverCol,
  HomeScreenCol,
  ManagerCol,
  MarketCol,
  OrderCol,
  OrderTrackSubCol,
  PaymentConfigCol,
  PickerCol,
  ProfileCol,
  SectionCol,
  StockCol,
  SystemOrderCol,
  SystemOrderLogSubCol,
  TransportStoreCol,
  TransportSystemCol,
  UserCol,
  WorkingHistorySubCol,
} from '@coop/common'
import { firestore } from 'firebase/app'
import {
  Banner,
  Category,
  ColRef,
  Coupon,
  DocRef,
  Driver,
  IdType,
  Log,
  Manager,
  Market,
  Order,
  Section,
  Stock,
  SystemOrder,
  User,
} from '.'
import { Picker, Profile } from './model'

export type DataType = {
  user: () => ColRef
  driver: () => ColRef
  manager: () => ColRef
  picker: () => ColRef
  profile: (employeeRef: DocRef) => ColRef
  coupon: () => ColRef
  order: () => ColRef
  systemOrder: () => ColRef
  log: (ref: DocRef) => ColRef
  tracking: (ref: DocRef) => ColRef
  market: () => ColRef
  category: () => ColRef
  stock: () => ColRef
  banner: () => ColRef
  section: () => ColRef
  workingHistory: (employeeRef: DocRef) => ColRef
  transportStore: () => ColRef
  paymentStore: () => ColRef
  transportSystem: () => ColRef
  homeScreen: () => ColRef
  channelConfig: () => ColRef
}

export type DtoType = {
  user: (d: Partial<User>) => any
  driver: (d: Partial<Driver>) => any
  manager: (d: Partial<Manager>) => any
  picker: (d: Partial<Picker>) => any
  profile: (d: Partial<Profile>) => any
  coupon: (d: Partial<Coupon>) => any
  order: (d: Partial<Order>) => any
  systemOrder: (d: Partial<SystemOrder>) => any
  log: (d: Partial<Log>) => any
  market: (d: Partial<Market>) => any
  category: (d: Partial<Category>) => any
  stock: (d: Partial<Stock>) => any
  banner: (d: Partial<Banner>) => any
  section: (d: Partial<Section>) => any
}

export const Data = (db: firestore.Firestore): DataType => ({
  user: () => db.collection(UserCol),
  driver: () => db.collection(DriverCol),
  manager: () => db.collection(ManagerCol),
  picker: () => db.collection(PickerCol),
  profile: (employeeRef: DocRef) => employeeRef.collection(ProfileCol),
  coupon: () => db.collection(CouponCol),
  order: () => db.collection(OrderCol),
  systemOrder: () => db.collection(SystemOrderCol),
  log: (ref: DocRef) => ref.collection(SystemOrderLogSubCol),
  tracking: (ref: DocRef) => ref.collection(OrderTrackSubCol),
  market: () => db.collection(MarketCol),
  category: () => db.collection(CategoryCol),
  stock: () => db.collection(StockCol),
  banner: () => db.collection(BannerCol),
  section: () => db.collection(SectionCol),
  workingHistory: (employeeRef: DocRef) => employeeRef.collection(WorkingHistorySubCol),
  transportStore: () => db.collection(TransportStoreCol),
  paymentStore: () => db.collection(PaymentConfigCol),
  transportSystem: () => db.collection(TransportSystemCol),
  homeScreen: () => db.collection(HomeScreenCol),
  channelConfig: () => db.collection(ChannelConfigCol),
})

export const Dto: DtoType = {
  user: (d: Partial<User>) => d,
  driver: (d: Partial<Driver>) => d,
  manager: (d: Partial<Manager>) => d,
  picker: (d: Partial<Picker>) => d,
  profile: (d: Partial<Profile>) => d,
  coupon: (d: Partial<Coupon>) => d,
  order: (d: Partial<Order>) => d,
  systemOrder: (d: Partial<SystemOrder>) => d,
  log: (d: Partial<Log>) => d,
  market: (d: Partial<Market>) => d,
  category: (d: Partial<Category>) => d,
  stock: (d: Partial<Stock>) => d,
  banner: (d: Partial<Banner>) => d,
  section: (d: Partial<Section>) => d,
}

export type UtilType = {
  convert: <T extends IdType>(doc: firestore.DocumentSnapshot) => T
}

export const Util: UtilType = {
  convert: <T extends IdType>(doc: firestore.DocumentSnapshot) =>
    (!doc.exists ? {} : { ...doc.data(), id: doc.id }) as T,
}

export const IdPath = () => firestore.FieldPath.documentId()
