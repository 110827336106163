import { ChannelConfigType, ManagerRole, ProfileCol } from '@coop/common'
import { Col, DatePicker, Form, Input, message, Row, Select, Spin, Switch } from 'antd'
import Title from 'antd/lib/typography/Title'
import { firestore } from 'firebase'
import moment from 'moment'
import React from 'react'
import ReactPerfectScrollbar from 'react-perfect-scrollbar'
import { useAuth } from 'src/contexts/AuthContext'
import { getSourceAvatar } from 'src/shared/components/getSourceAvatar'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { useFirestore } from '../../contexts/FirestoreContext'
import { useSource } from '../../contexts/SourceContext'
import { UploadImage } from '../../shared/components'
import { ModalActionGroup, ModalHeader } from '../../shared/components/Modal'
import { useUpload } from '../../shared/hooks/useUpload'
import { Manager, Market, Profile } from '../../shared/model'

const Wrapper = styled.div`
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    margin: 0;
  }
`

const StyledCol = styled(Col)`
  width: 128px;
  .ant-upload img {
    width: 128px;
    /* height: 128px; */
  }
`

const FormItem = styled(Form.Item).attrs({ className: 'mb-0 border border-gray-400' })`
  margin-bottom: -1px;
  .ant-form-item-label {
    ${tw`bg-gray-200 pl-4 flex items-center border-r border-gray-400`}
  }
  input {
    ${tw`h-12 border-transparent`}
  }
  .ant-select .ant-select-selector {
    ${tw`h-12 border-transparent flex items-center`}
  }
  .ant-form-item-explain {
    ${tw`pl-2`}
  }
`

const layout = {
  labelCol: { span: 8, xl: { span: 6 } },
  wrapperCol: { span: 16, xl: { span: 18 } },
}

const defaultValues = {
  email: '',
  role: ManagerRole.Manager,
  name: '',
  isActive: false,
  sourceCode: '',
  password: '',
  profile: {
    identity: {},
  },
}

type ProfileData = Omit<Profile, 'id'>
export type ManagerModalType = Omit<Manager, 'id'> & { password: string; profile: ProfileData }

type Props = {
  data?: Manager
  onSubmit: (data: ManagerModalType) => Promise<void>
}

const ManagerFormModal: React.FC<Props> = ({ data, onSubmit }) => {
  const [form] = Form.useForm()
  const [formLoading, setFormLoading] = React.useState(false)
  const [channelList, setChannelList] = React.useState<ChannelConfigType[]>([])

  const { Data } = useFirestore()
  const { loading, allSources } = useSource()
  const { me } = useAuth()
  const avatarUpload = useUpload()
  const frontPicture = useUpload()
  const backPicture = useUpload()

  const isAdmin = me?.role === ManagerRole.Admin
  const isManager = me?.role === ManagerRole.Manager
  const managerSources = isManager
    ? (me?.listSourceCode?.map(e => ({ ...allSources.find(eF => eF.source_code === e) })) as Market[])
    : allSources

  const handleSubmitForm = (formValues: any) => {
    const { profile } = formValues
    setFormLoading(true)

    const submitData: ManagerModalType = {
      ...formValues,
      disabled: false,
      profile: {
        ...profile,
        identity: {
          ...profile.identity,
        },
      },
    }
    onSubmit(submitData).finally(() => setFormLoading(false))
  }

  React.useEffect(() => {
    Data.channelConfig()
      .get()
      .then(result => {
        const channelListData = result.docs.map(doc => ({ ...doc.data() })) as ChannelConfigType[]
        const managerChannelListData = channelListData.filter(e => e.appId === me?.channel)

        setChannelList(isManager ? managerChannelListData : channelListData)
      })
  }, [Data, isManager, me])

  React.useEffect(() => {
    data &&
      Data.profile(Data.manager().doc(data?.id))
        .doc(ProfileCol)
        .get()
        .then(snapshot => {
          const managerProfile = snapshot?.exists ? snapshot.data() : {}
          form.setFieldsValue({
            ...defaultValues,
            ...data,
            isActive: !data.disabled,
            profile: { ...managerProfile },
          })
        })
        .catch(() => message.error('Lấy thông tin user không thành công'))
  }, [Data, data, form])

  React.useEffect(() => {
    if (avatarUpload.url) {
      form.setFieldsValue({ avatar: avatarUpload.url })
    }
    if (frontPicture.url) {
      form.setFieldsValue({ profile: { identity: { frontPicture: frontPicture.url } } })
    }
    if (backPicture.url) {
      form.setFieldsValue({ profile: { identity: { backPicture: backPicture.url } } })
    }
  }, [avatarUpload.url, backPicture.url, form, frontPicture.url])

  return (
    <Wrapper className="w-full">
      <Form
        {...layout}
        className="flex flex-1 flex-col overflow-hidden h-full"
        form={form}
        initialValues={{ role: ManagerRole.Owner }}
        name="manager-form"
        onFinish={handleSubmitForm}
        labelAlign="left">
        <ModalHeader
          title={data ? 'Cập nhật Quản lý' : 'Thêm Quản lý'}
          actionRenderer={<ModalActionGroup submitText={'Lưu'} loading={formLoading} />}
        />
        <ReactPerfectScrollbar>
          <div className="w-full mx-auto p-4" style={{ maxWidth: '960px' }}>
            <Row>
              <Col flex={1}>
                <Title level={4}>Thông tin</Title>
                <FormItem
                  label="Email"
                  name="email"
                  required
                  rules={[
                    { required: true, message: 'Vui lòng nhập email!' },
                    { type: 'email', message: 'Vui lòng nhập chính xác địa chỉ email!' },
                  ]}>
                  <Input placeholder="Nhập email đăng nhập hệ thống" />
                </FormItem>
                <FormItem
                  label="Họ tên"
                  name="name"
                  required
                  rules={[{ required: true, message: 'Vui lòng nhập họ tên!' }]}>
                  <Input placeholder="Nhập họ tên" />
                </FormItem>
                {!data && (
                  <React.Fragment>
                    <FormItem
                      label="Mật khẩu"
                      name="password"
                      required
                      rules={[
                        { required: true, message: 'Vui lòng nhập mật khẩu!' },
                        {
                          pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                          message:
                            'Mật khẩu phải chứa ít nhất 8 ký tự, gồm cả ký tự viết hoa, viết thường, số và ký tự đặc biệt!',
                        },
                      ]}>
                      <Input type="password" placeholder="Nhập mật khẩu" />
                    </FormItem>
                    <FormItem
                      label="Xác nhận"
                      name="confirm"
                      required
                      dependencies={['password']}
                      rules={[
                        { required: true, message: 'Vui lòng nhập lại mật khẩu!' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve()
                            }
                            return Promise.reject('Mật khẩu nhập lại không khớp!')
                          },
                        }),
                      ]}>
                      <Input type="password" placeholder="Nhập lại mật khẩu" />
                    </FormItem>
                  </React.Fragment>
                )}
                <FormItem label="Trạng thái" shouldUpdate>
                  {({ getFieldValue }) => (
                    <div className="h-12 flex items-center pl-2">
                      <FormItem name="isActive" noStyle valuePropName="checked">
                        <Switch defaultChecked={true} disabled={!!data} />
                      </FormItem>
                      {getFieldValue('isActive') === false
                        ? 'Chờ kích hoạt'
                        : getFieldValue('isActive') === true
                        ? 'Đang hoạt động'
                        : 'Đang hoạt động'}
                    </div>
                  )}
                </FormItem>
                <FormItem label="Quyền hạn" name="role" required>
                  <Select>
                    {isAdmin && <Select.Option value="manager">Manager</Select.Option>}
                    <Select.Option value="owner">Owner</Select.Option>
                  </Select>
                </FormItem>

                <FormItem shouldUpdate noStyle>
                  {({ getFieldValue }) =>
                    getFieldValue('role') === ManagerRole.Manager ? (
                      <FormItem
                        label="Channel"
                        name="channel"
                        required
                        rules={[{ required: true, message: 'Vui lòng chọn channel' }]}>
                        <Select placeholder="Lựa chọn channel cho Manager">
                          {channelList.map((e, i) => (
                            <Select.Option key={`${e.appId}_${i}`} value={e.appId}>
                              {e.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </FormItem>
                    ) : null
                  }
                </FormItem>

                <FormItem shouldUpdate noStyle>
                  {({ getFieldValue }) =>
                    getFieldValue('role') === ManagerRole.Owner ? (
                      <FormItem
                        label="Siêu thị"
                        name="sourceCode"
                        required
                        rules={[{ required: true, message: 'Vui lòng chọn siêu thị' }]}>
                        <Select placeholder="Lựa chọn siêu thị với quản lí">
                          {loading || !allSources ? (
                            <Spin tip="Loading..." />
                          ) : (
                            managerSources.map((market, i) => (
                              <Select.Option key={`${market.source_code}_${i}`} value={market?.source_code}>
                                {getSourceAvatar(market)} {market?.name}
                              </Select.Option>
                            ))
                          )}
                        </Select>
                      </FormItem>
                    ) : null
                  }
                </FormItem>

                <FormItem
                  label="Mã số thuế"
                  name={['profile', 'identity', 'tin']}
                  required
                  rules={[{ required: true, message: 'Vui lòng nhập mã số thuế!' }]}>
                  <Input placeholder="Nhập mã số thuế" />
                </FormItem>
                <br />
                <Row>
                  <Col span={24}>
                    <Title level={4}>Giấy tờ tùy thân</Title>
                    <FormItem
                      label="Địa chỉ"
                      name={['profile', 'permanentAddress']}
                      required
                      rules={[{ required: true, message: 'Vui lòng nhập địa chỉ thường trú' }]}>
                      <Input placeholder="Địa chỉ thường trú" />
                    </FormItem>
                    <FormItem
                      label="Số CMND"
                      name={['profile', 'identity', 'id']}
                      required
                      rules={[{ required: true, message: 'Vui lòng nhập số CMND' }]}>
                      <Input placeholder="Nhập số CMND" />
                    </FormItem>
                    <FormItem shouldUpdate label="Ngày cấp" required>
                      {({ getFieldValue }) => {
                        const fieldValue = getFieldValue(['profile', 'identity', 'issuedDate'])
                        return (
                          <>
                            <DatePicker
                              placeholder="Nhập ngày cấp"
                              className="border-transparent w-full"
                              value={fieldValue ? moment(fieldValue?.toMillis()) : undefined}
                              onChange={value =>
                                value &&
                                form.setFieldsValue({
                                  profile: {
                                    identity: { issuedDate: firestore.Timestamp.fromMillis(value.valueOf()) },
                                  },
                                })
                              }
                            />
                            <Form.Item
                              noStyle
                              name={['profile', 'identity', 'issuedDate']}
                              rules={[{ required: true, message: 'Vui lòng nhập ngày cấp CMND' }]}
                            />
                          </>
                        )
                      }}
                    </FormItem>
                    <FormItem
                      label="Nơi cấp"
                      name={['profile', 'identity', 'issuedAddress']}
                      required
                      rules={[{ required: true, message: 'Vui lòng nhập nơi cấp CMND' }]}>
                      <Input placeholder="Nơi cấp CMND" />
                    </FormItem>

                    <Row>
                      <Col span={12}>
                        <Form.Item shouldUpdate wrapperCol={{ span: 24 }} noStyle>
                          {({ getFieldValue, getFieldError }) => {
                            const imgSrc = getFieldValue(['profile', 'identity', 'frontPicture'])
                            const error = getFieldError(['profile', 'identity', 'frontPicture'])
                            return (
                              <div className="border-gray-400 border p-4 w-full">
                                <div className="text-gray-500 mb-2 flex w-full justify-center uppercase">
                                  Mặt trước CMND
                                </div>
                                <UploadImage
                                  uploading={frontPicture.uploading}
                                  upload={frontPicture.upload}
                                  url={imgSrc}
                                  ratio={9 / 16}
                                />
                                <div className="text-red-500">{error}</div>
                                <Form.Item
                                  noStyle
                                  name={['profile', 'identity', 'frontPicture']}
                                  rules={[{ required: true, message: 'Vui lòng chọn ảnh mặt trước' }]}
                                />
                              </div>
                            )
                          }}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item shouldUpdate wrapperCol={{ span: 24 }} noStyle>
                          {({ getFieldValue, getFieldError }) => {
                            const imgSrc = getFieldValue(['profile', 'identity', 'backPicture'])
                            const error = getFieldError(['profile', 'identity', 'backPicture'])
                            return (
                              <div className="border-gray-400 border p-4 w-full border-l-0">
                                <div className="text-gray-500 mb-2 flex w-full justify-center uppercase">
                                  Mặt sau CMND
                                </div>
                                <UploadImage
                                  uploading={backPicture.uploading}
                                  upload={backPicture.upload}
                                  url={imgSrc}
                                  ratio={9 / 16}
                                />
                                <div className="text-red-500">{error}</div>
                                <Form.Item
                                  noStyle
                                  name={['profile', 'identity', 'backPicture']}
                                  rules={[{ required: true, message: 'Vui lòng chọn ảnh mặt sau' }]}
                                />
                              </div>
                            )
                          }}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="h-10" />
              </Col>
              <StyledCol>
                <Form.Item shouldUpdate wrapperCol={{ span: 24 }} noStyle>
                  {({ getFieldValue, getFieldError }) => {
                    const imgSrc = getFieldValue('avatar')
                    const error = getFieldError('avatar')
                    return (
                      <div className="p-4 w-full">
                        <div className="text-gray-500 mb-2 flex w-full justify-center uppercase">Ảnh đại diện</div>
                        <UploadImage upload={avatarUpload.upload} uploading={avatarUpload.uploading} url={imgSrc} />
                        <div className="text-red-500">{error}</div>
                        <Form.Item
                          noStyle
                          name="avatar"
                          rules={[{ required: true, message: 'Vui lòng chọn ảnh đại diện' }]}
                        />
                      </div>
                    )
                  }}
                </Form.Item>
              </StyledCol>
            </Row>
          </div>
        </ReactPerfectScrollbar>
      </Form>
    </Wrapper>
  )
}

export default ManagerFormModal
