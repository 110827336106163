import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import styled from 'styled-components'
import { useWindowSize } from '../../contexts/WindowSizeContext'
import Header from './Header'

const MainStyles = styled.div.attrs({
  className: 'w-full flex flex-col',
})`
  background-color: #fafafa;
`

type Props = {
  isFullScreen?: boolean
}

const MainLayout: React.FC<Props> = ({ isFullScreen, children }) => {
  const { height } = useWindowSize()
  return (
    <MainStyles style={{ height: `${height}px` }}>
      <Header />
      <PerfectScrollbar className={`flex-1 flex ${isFullScreen ? '' : 'px-8 py-8'}`}>{children}</PerfectScrollbar>
    </MainStyles>
  )
}

export default MainLayout
