import { InboxOutlined, LockOutlined } from '@ant-design/icons'
import { Button, Form, Input, message } from 'antd'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as Logo } from '../../assets/img/logo.svg'
import { useAuth } from '../../contexts/AuthContext'

const Wrapper = styled.div``
const LoginButton = styled(Button)`
  width: 100%;
  height: 50px;
`

type Props = {}

const Login: React.FC<Props> = () => {
  const { signIn } = useAuth()
  const [loading, setLoading] = React.useState(false)
  const params = useParams<{ email: string }>()
  const history = useHistory()

  const handleLogin = async (values: any) => {
    try {
      setLoading(true)
      await signIn(values.email, values.password)
      message.success('Đăng nhập thành công!')
      history.push('/')
    } catch (e) {
      if (e.code === 'auth/wrong-password') {
        message.error('Mật khẩu đăng nhập không chính xác!')
      } else if (e.code === 'auth/user-not-found') {
        message.error('Tài khoản này chưa tồn tại trên hệ thống!')
      } else {
        message.error('Đã có lỗi xảy ra. Vui lòng thử lại sau!')
      }
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Wrapper className="flex min-h-screen items-center" style={{ background: '#F4F5F6' }}>
      <Form
        size="large"
        className="w-1/3 mx-auto bg-white pt-16"
        style={{ maxWidth: 500 }}
        initialValues={{ email: params.email }}
        onFinish={handleLogin}>
        <div className="w-full flex justify-center mb-8">
          <Logo width="250" style={{ background: '#111226' }} className="p-2 rounded-lg" />
        </div>
        <Form.Item className="px-8" name="email" rules={[{ required: true, message: 'Vui lòng nhập email!' }]}>
          <Input prefix={<InboxOutlined className="site-form-item-icon" />} placeholder="Email" />
        </Form.Item>
        <Form.Item className="px-8" name="password" rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}>
          <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" />
        </Form.Item>
        <Form.Item className="text-center">
          <Button size="small" type="link" onClick={() => history.push('/xac-thuc/quen-mat-khau')}>
            Quên mật khẩu?
          </Button>
        </Form.Item>
        <LoginButton
          type="primary"
          htmlType="submit"
          className="login-form-button"
          disabled={loading}
          loading={loading}>
          Đăng nhập
        </LoginButton>
      </Form>
    </Wrapper>
  )
}

export default Login
