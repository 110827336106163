import { Button, Input, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table/Column'
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { useFirestore } from 'src/contexts/FirestoreContext'
import { useSource } from 'src/contexts/SourceContext'
import { usePagination } from 'src/shared/hooks/usePagination'
import { Driver, TransportStore } from 'src/shared/model'
import { formatUrl } from 'src/shared/utils'
import styled from 'styled-components'
import ModalAddDriver from './ModalAddDriver'

const StyledTable = styled('div')`
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 4px 16px;
  }
`

interface props {
  transportStore: TransportStore
  storeOriginDriverList: Driver[]
  setStoreOriginDriverList: Dispatch<SetStateAction<Driver[]>>
}

const StoreTransportList = ({ transportStore, storeOriginDriverList, setStoreOriginDriverList }: props) => {
  const { Data, Util } = useFirestore()
  const { source } = useSource()

  const [addDriverModal, setAddDriverModal] = useState(false)
  const [driverList, setDriverList] = useState<Driver[]>([])
  const [storeDriverList, setStoreDriverList] = useState<Driver[]>([])
  const [driverLoading, setDriverLoading] = useState<boolean>(true)

  const columns: ColumnProps<Driver>[] = [
    {
      title: 'Avatar',
      render: (_, d) => <img src={formatUrl(d.avatar)} alt="" style={{ height: 56, width: 56 }} />,
      width: 100,
    },
    {
      title: 'Họ và tên',
      render: (_, data) => {
        return <div className="font-semibold flex items-center">{data.name}</div>
      },
    },
    { title: 'Số điện thoại', render: (_, d) => <div>{d.phone}</div> },
  ]

  const rowSelection = {
    // selectedRowKeys: selectedDriverList,
    // onChange: (selectedRowKeys: any, selectedRows: any) => {
    //   console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
    // },
  }

  const PAGE_SIZE = 1000
  const {
    //  pagination, setPagination,
    renderPagination,
  } = usePagination(() => storeDriverList.length || 0, 1, PAGE_SIZE, 'Tài xế')

  const getDriverData = useCallback(() => {
    Data.driver().onSnapshot(snapshot => {
      const driverDocs = snapshot.docs.map(doc => Util.convert<Driver>(doc))
      const filterDriverDocs = driverDocs?.filter((e: Driver) => e?.store?.id === source?.source_code)

      setStoreOriginDriverList(filterDriverDocs)
      setStoreDriverList(filterDriverDocs)
      setDriverList(driverDocs)
      setDriverLoading(false)
    })
  }, [Data, Util, setStoreOriginDriverList, source])

  React.useEffect(() => {
    getDriverData()
  }, [getDriverData])

  return (
    <>
      <Button type="primary" style={{ margin: '8px 0' }} onClick={() => setAddDriverModal(true)}>
        Thêm tài xế
      </Button>
      <ModalAddDriver
        visible={addDriverModal}
        setVisible={setAddDriverModal}
        transportStore={transportStore}
        driverList={driverList}
        driverLoading={driverLoading}
        getDriverData={getDriverData}
      />
      <Input
        placeholder="Lọc theo tên, số điện thoại"
        onChange={(ele: any) => {
          const v = ele.target.value
          v === ''
            ? setStoreDriverList(storeOriginDriverList)
            : setStoreDriverList(storeOriginDriverList.filter((e: Driver) => e.phone.includes(v) || e.name.includes(v)))
        }}
      />
      {storeDriverList.length === 0 ? (
        <h5 style={{ marginTop: 8, marginBottom: 16 }}>Cửa hàng của bạn chưa có tài xế </h5>
      ) : (
        <StyledTable>
          <Table<Driver>
            rowKey="id"
            dataSource={storeDriverList as Driver[]}
            loading={driverLoading}
            columns={columns}
            pagination={false}
            scroll={{ y: 400 }}
            rowSelection={{ type: 'checkbox', ...rowSelection }}
            footer={renderPagination}
          />
        </StyledTable>
      )}
    </>
  )
}

export default StoreTransportList
