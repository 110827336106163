import { message } from 'antd'
import Axios from 'axios'
import React from 'react'
import env from 'src/shared/Env'
// import { FirebaseContext } from './FirebaseContext'

type Props = {}

type ContextType = {
  upload: (files: File[]) => void
  uploadMultiple: (files: File[]) => void
  imageUrls: string[]
  multipleImageUrls: string[]
  isUploading: boolean
  multipleLoading: boolean
  cleanUp: () => void
  cleanUpMultiple: () => void
  getImgUrl: (file: File) => Promise<any>
}

const defaultValues: ContextType = {
  upload: () => {},
  uploadMultiple: () => {},
  imageUrls: [],
  multipleImageUrls: [],
  isUploading: false,
  multipleLoading: false,
  cleanUp: () => {},
  cleanUpMultiple: () => {},
  getImgUrl: () => new Promise(() => Promise.resolve()),
}

export const UploadContext = React.createContext(defaultValues)

const UploadProvider: React.FC<Props> = ({ children }) => {
  // const { functions } = React.useContext(FirebaseContext)

  const [imageUrls, setImageUrls] = React.useState<string[]>([])
  const [multipleImageUrls, setMultipleImageUrl] = React.useState<string[]>([])
  const [isUploading, setUploading] = React.useState(false)
  const [multipleLoading, setMultipleLoading] = React.useState(false)

  const maxSize = 5 * 1024 * 1000 // 5MB

  const getImgUrl = async (file: File) => {
    const newFormData = new FormData()
    newFormData.append('file', file)
    if (file.size > maxSize) {
      message.warn(`File có kích thước không được lớn hơn ${maxSize / 1024 / 1000}MB`)
      return null
    }
    try {
      const response = await Axios({
        method: 'post',
        url: `${env.imageApi}/media-uploadImage`,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: newFormData,
      })
      return response ? response.data.url : null
    } catch (error) {
      message.error('Lỗi upload hình ảnh', error.message)
    }
  }

  const upload = async (files: File[]) => {
    setUploading(true)
    await Promise.all(
      files.map(async file => {
        const imgUrl = await getImgUrl(file)
        imgUrl !== null && setImageUrls([imgUrl])
      }),
    )
    setUploading(false)
  }

  const uploadMultiple = async (files: File[]) => {
    files.forEach(async file => {
      setMultipleLoading(true)
      const imgUrl = await getImgUrl(file)
      setMultipleImageUrl(init => {
        const imgLen = init.length
        imgLen === 5 && message.warn('Không thể chọn quá 5 ảnh')
        return imgLen < 5 && imgUrl !== null ? [...init, imgUrl] : init
      })
      setMultipleLoading(false)
    })
  }

  const cleanUp = () => {
    setImageUrls([])
    setUploading(false)
  }

  const cleanUpMultiple = () => {
    setMultipleImageUrl([])
    setMultipleLoading(false)
  }

  return (
    <UploadContext.Provider
      value={{
        upload,
        uploadMultiple,
        imageUrls,
        multipleImageUrls,
        isUploading,
        multipleLoading,
        cleanUp,
        cleanUpMultiple,
        getImgUrl,
      }}>
      {children}
    </UploadContext.Provider>
  )
}

export default UploadProvider
