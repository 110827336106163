import React from 'react'

type MapContext = {
  isLoaded: boolean
}

const MapContext = React.createContext<MapContext | string>('useMap should be used inside MapProvider')

export const MapProvider: React.FC = ({ children }) => {
  const isLoaded = false
  return <MapContext.Provider value={{ isLoaded }}>{children}</MapContext.Provider>
}

export const useMap = (): MapContext => {
  const context = React.useContext(MapContext)
  if (typeof context === 'string') {
    throw Error(context)
  }
  return context
}
