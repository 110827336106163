import { Button, Col, Divider, Form, Input, message, Modal as AntModal, Row, Select, Spin, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import htmlParse from 'html-react-parser'
import React from 'react'
import styled from 'styled-components'
import { CartItem, useCart } from '../../contexts/CartContext'
import { useFirestore } from '../../contexts/FirestoreContext'
import { ModalHeader } from '../../shared/components/Modal'
import { getMoneyString, Money } from '../../shared/components/Money'
import { Coupon } from '../../shared/model'
import { formatUrl } from '../../shared/utils'
import { TableWrapper } from '../Products/ProductPage'

const Title = styled('p').attrs({ className: 'text-lg font-normal' })``
const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0px;
  }
`
type Props = {
  onRequestClose?: () => void
}

export default ({ onRequestClose }: Props) => {
  const { customer, items: cartItems, makeOrder, meta, shippingAddress } = useCart()
  const { grandTotal, subTotal, discount, fee } = meta
  const columns = React.useMemo<ColumnProps<CartItem>[]>(
    () => [
      {
        title: 'Sản phẩm',
        width: '50%',
        render: (d: CartItem) => (
          <div className="flex items-center">
            <img
              src={formatUrl(d.product.thumbnail_path)}
              width="56"
              alt=""
              style={{ objectFit: 'contain', height: 56 }}
            />
            <span className="ml-2">{htmlParse(d.product.name)}</span>
          </div>
        ), // accessor is the "key" in the data
      },
      { title: 'Giá bán', render: (p: CartItem) => <Money value={p.product.special_price} /> },
      { title: 'Số lượng', render: (p: CartItem) => <div>{p.amount}</div> },
      { title: 'Thành tiền', render: (d: CartItem) => <Money value={d.total} /> },
    ],
    [],
  )

  const submitOrder = async () => {
    AntModal.info({
      title: <div />,
      okText: <div />,
      icon: <div />,
      centered: true,
      okButtonProps: { type: 'text', ghost: true, disabled: true },
      content: (
        <div className="flex flex-1 justify-center">
          <Spin tip={'Đang xác nhận đơn hàng'} />
        </div>
      ),
    })
    try {
      const result = await makeOrder()
      console.log('submitOrder', result.data)
      AntModal.destroyAll()
      message.success(`Đơn hàng ${result.key} đã được đặt thành công`)
      onRequestClose?.()
    } catch (error) {
      AntModal.error({
        title: 'Lỗi',
        okText: 'Đóng',
        onOk: () => AntModal.destroyAll(),
        content: error.message,
      })
    }
  }

  if (!customer || !shippingAddress) {
    return null
  }

  return (
    <div className="flex w-full overflow-hidden">
      <div className="flex flex-col w-full">
        <ModalHeader title="Thanh toán đơn hàng" />
        <div className="w-full mx-auto p-4 px-24 overflow-y-auto">
          <Row gutter={16}>
            <StyledForm labelCol={{ span: 4 }} className="flex flex-col w-full">
              <Col flex={1}>
                <Title>Thông tin khách hàng</Title>
                <Form.Item label="HỌ VÀ TÊN" labelAlign="left">
                  {customer.name || ''}
                </Form.Item>
                <Form.Item label="SỐ ĐIỆN THOẠI" labelAlign="left">
                  {customer.phone || ''}
                </Form.Item>
                <Form.Item label="EMAIL" labelAlign="left">
                  {customer.email || ''}
                </Form.Item>
                <Form.Item label="Địa chỉ nhận hàng" labelAlign="left">
                  {shippingAddress.name}
                </Form.Item>
              </Col>
            </StyledForm>
            <Divider />
          </Row>
          <Row>
            <Col span={12}>
              <Form layout="vertical">
                <div className="mr-4">
                  <InputCoupon />
                  <InputNote />
                </div>
              </Form>
            </Col>
            <Col span={12}>
              <div className="ml-4">
                <div className="flex justify-between">
                  <Title>Thông tin giỏ hàng</Title>
                  <Button type="primary" onClick={submitOrder}>
                    {'Xác nhận đặt đơn'}
                  </Button>
                </div>
                <StyledForm labelCol={{ span: 8 }}>
                  <Form.Item label={'Mã khuyến mãi'} labelAlign="left">
                    <Money value={subTotal} />
                  </Form.Item>
                  <Form.Item label={'Giảm giá'} labelAlign="left">
                    <Money value={discount} />
                  </Form.Item>
                  <Form.Item label={'Phí vận chuyển'} labelAlign="left">
                    {fee > 0 ? <Money value={fee} /> : <div>{'Miễn phí'}</div>}
                  </Form.Item>
                  <Form.Item label={'Tổng tiền'} labelAlign="left">
                    <Money className="text-red-600" value={grandTotal} />
                  </Form.Item>
                </StyledForm>
                <TableWrapper className="flex-1 mt-4">
                  <Table<CartItem>
                    rowKey={(_, index) => `${index}`}
                    dataSource={cartItems}
                    columns={columns}
                    scroll={{ y: 400 }}
                  />
                </TableWrapper>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

const InputCoupon = () => {
  const [coupons, setCoupons] = React.useState<Coupon[]>([])
  const { Data, Util } = useFirestore()
  const { applyCartCoupon, removeCartCoupon, market, meta } = useCart()

  React.useEffect(() => {
    Data.coupon().onSnapshot(snapshot => {
      const data = snapshot.docs.map(doc => Util.convert<Coupon>(doc))
      const showCoupons = data.filter(c => !c.isHidden && c.website_id?.includes(market?.sourceId || 0))
      setCoupons(showCoupons)
    })
  }, [Data, Util, market])

  const selectCoupon = async (couponId: string) => {
    const coupon = coupons.find(c => c.id === couponId)
    try {
      if (!coupon) {
        await removeCartCoupon()
      } else {
        await applyCartCoupon(coupon)
      }
    } catch (error) {
      console.error(error)
      AntModal.error({
        title: 'Lỗi',
        okText: 'Đóng',
        onOk: () => AntModal.destroyAll(),
        content: coupon ? `Mã khuyến mãi ${coupon.id} không thể sử dụng` : error.message,
      })
    }
  }

  return (
    <Form.Item label={'Mã khuyến mãi'} labelAlign="left">
      <Select className={'mb-4'} value={meta.coupon.id || 'empty'} onChange={selectCoupon}>
        <Select.Option key="coupon" value="empty">
          <div className="text-gray-500 text-sm">{'-----Empty-----'}</div>
          <div className="text-gray-500 text-sm">{'Bỏ sử dụng coupon đã chọn'}</div>
        </Select.Option>
        {coupons.map((item: Coupon, i: number) => (
          <Select.Option key={`coupon-${i}`} value={item.id}>
            <div>
              <div>{item.id}</div>
              <div className="text-gray-500 text-sm">{item.description}</div>
              <div className="text-gray-500 text-sm">{`Giảm ${
                item.type === 'percentage' ? `${item.value}%` : `${getMoneyString(item.value)}`
              }`}</div>
            </div>
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

const InputNote = () => {
  const { setNote } = useCart()
  const onChange = (event: { target: HTMLTextAreaElement }) => {
    setNote(event.target.value)
  }

  return (
    <Form.Item label={'Nhập ghi chú'} labelAlign="left">
      <Input.TextArea maxLength={255} onChange={onChange} />
    </Form.Item>
  )
}
