import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { Input, message, Modal, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React, { useEffect, useState } from 'react'
import { useFirebase } from 'src/contexts/FirebaseContext'
import { useSource } from 'src/contexts/SourceContext'
import { usePagination } from 'src/shared/hooks/usePagination'
import { Driver } from 'src/shared/model'
import { formatDate, formatUrl } from 'src/shared/utils'
import styled from 'styled-components'

const StyledTable = styled('div')`
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 4px 16px;
  }
`

const columns: ColumnProps<Driver>[] = [
  {
    title: 'Avatar',
    render: (_, d) => <img src={formatUrl(d.avatar)} alt="" style={{ height: 56, width: 56 }} />,
    width: 100,
  },
  {
    title: 'Họ và tên',
    render: (_, data) => {
      return <div className="font-semibold flex items-center">{data.name}</div>
    },
  },
  {
    title: 'Tình trạng',
    render: (_, data) => {
      return (
        <div className="font-semibold flex items-center">
          {data.isAvailable ? (
            <div className="text-green-600">
              <CheckCircleFilled /> Hoạt động
            </div>
          ) : (
            <div className="text-red-600">
              <CloseCircleFilled /> Không hoạt động
            </div>
          )}
        </div>
      )
    },
  },
  {
    title: 'Ngày tham gia',
    render: (_, data) => {
      return <div className="font-semibold flex items-center">{formatDate(data.systemTime.seconds * 1000)}</div>
    },
  },
  {
    title: 'Số điện thoại',
    render: (_, d) => <div>{d.phone}</div>,
  },
]

interface modalProps {
  visible: boolean
  setVisible: (e: boolean) => void
  transportStore: any
  driverList: Driver[]
  driverLoading: boolean
  getDriverData: () => void
}

const ModalAddDriver = (props: modalProps) => {
  const { visible, setVisible, transportStore, driverList, driverLoading, getDriverData } = props
  const { source } = useSource()
  const { functions } = useFirebase()

  const [selectedDriver, setSelectedDriver] = useState<string[]>([])
  const [originDriverList, setOriginDriverList] = useState<Driver[]>([])
  const [confirmLoading, setConfirmLoading] = useState(false)

  const availableDriver = originDriverList.filter(e => e.store === null)

  const rowSelection = {
    onChange: (selectedRowKeys: any) => setSelectedDriver([...selectedRowKeys]),
    getCheckboxProps: (data: Driver) => ({
      disabled: !data.isAvailable,
    }),
  }

  const PAGE_SIZE = 1000
  const {
    //  pagination, setPagination,
    renderPagination,
  } = usePagination(() => availableDriver.length || 0, 1, PAGE_SIZE, 'Tài xế')

  const onOk = () => {
    setConfirmLoading(true)

    const bodyRequest = {
      storeId: source?.source_code,
      config: transportStore,
      driver: selectedDriver,
    }

    selectedDriver.length === 0
      ? message.error('Phải chọn ít nhất 1 tài xế', 3) && setConfirmLoading(false)
      : functions
          .httpsCallable('firestore-adminTransportConfig')(bodyRequest)
          .then(() => {
            message.success('Thêm tài xế thành công', 3)
            setVisible(false)
            getDriverData()
          })
          .catch(error => message.error(`Thêm tài xế thất bại. ${error}`, 3))
          .finally(() => setConfirmLoading(false))
  }

  useEffect(() => {
    setOriginDriverList(driverList)
  }, [driverList])

  return (
    <Modal
      visible={visible}
      closable={false}
      width="1024px"
      onCancel={() => setVisible(false)}
      onOk={onOk}
      cancelText={'Hủy'}
      okText={'Thêm'}
      confirmLoading={confirmLoading}
      bodyStyle={{ maxHeight: 800, overflowY: 'scroll' }}>
      <>
        <Input
          placeholder="Lọc theo tên, số điện thoại"
          onChange={(ele: any) => {
            const v = ele.target.value
            v === ''
              ? setOriginDriverList(driverList)
              : setOriginDriverList(driverList.filter((e: Driver) => e.phone.includes(v) || e.name.includes(v)))
          }}
        />
        {availableDriver?.length === 0 ? (
          <div>Không tìm thấy tài xế phù hợp</div>
        ) : (
          <StyledTable>
            <Table<Driver>
              rowKey="id"
              dataSource={availableDriver as Driver[]}
              loading={driverLoading}
              columns={columns}
              pagination={false}
              scroll={{ y: 400 }}
              rowSelection={{ type: 'checkbox', ...rowSelection }}
              footer={renderPagination}
            />
          </StyledTable>
        )}
      </>
    </Modal>
  )
}

export default ModalAddDriver
