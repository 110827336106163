import { Card, List, message } from 'antd'
import React from 'react'
import styled from 'styled-components'
// @ts-ignore
import tw from 'tailwind.macro'
import { useFirestore } from '../../../contexts/FirestoreContext'
import { useSource } from '../../../contexts/SourceContext'
import { Button } from '../../../shared/components'
import Modal from '../../../shared/components/Modal'
import { Banner } from '../../../shared/model'
import ModalBannerForm from './ModalBannerForm'

const BannerWrapper = styled.div``
const Title = styled.div`
  ${tw`uppercase font-normal text-gray-500`}
`

type Props = {}

const DashboardBanner: React.FC<Props> = () => {
  const [showModal, setShowModal] = React.useState(false)
  const [selectedItem, setSelectedItem] = React.useState<Banner>()
  const [banners, setBanners] = React.useState<Banner[]>()
  const { source } = useSource()
  const { Data, Util } = useFirestore()

  React.useEffect(() => {
    if (!source) {
      return
    }
    const unsub = Data.banner()
      .where('sourceCode', '==', source.source_code)
      .onSnapshot(snapshot => setBanners(snapshot.docs.map(doc => Util.convert<Banner>(doc))))

    return unsub
  }, [Data, Util, source])

  const handleDelete = async () => {
    if (!selectedItem) {
      return
    }
    try {
      await Data.banner().doc(selectedItem.id).delete()
      message.success('Xóa banner thành công!')
      Promise.resolve()
    } catch (e) {
      message.error('Có lỗi xảy ra. Vui lòng thử lại sau!')
      console.error(e)
      Promise.reject()
    } finally {
      setShowModal(false)
      setSelectedItem(undefined)
    }
  }

  const handleSubmit = async (data: Omit<Banner, 'id'>) => {
    try {
      const { name, image, reference, sourceCode } = data
      if (selectedItem) {
        await Data.banner().doc(selectedItem.id).update({ name, image, reference, sourceCode })
        message.success('Cập nhật thành công!')
      } else {
        await Data.banner().add({ name, image, reference, sourceCode })
        message.success('Thêm banner thành công!')
      }
      Promise.resolve()
    } catch (e) {
      message.error('Có lỗi xảy ra. Vui lòng thử lại sau!')
      console.error(e)
      Promise.reject()
    } finally {
      setShowModal(false)
      setSelectedItem(undefined)
    }
  }

  return (
    <BannerWrapper className="">
      <div className="flex items-center mb-2 flex-1 justify-between">
        <Title>Banner</Title>
        <Button onClick={() => setShowModal(true)}>Thêm Banner</Button>
        <Modal
          isFullScreen
          title={selectedItem ? 'Cập nhật banner' : 'Thêm Banner'}
          isOpen={showModal}
          onRequestClose={() => {
            setShowModal(false)
            setSelectedItem(undefined)
          }}>
          <ModalBannerForm onSubmit={handleSubmit} data={selectedItem} onDeleteItem={handleDelete} />
        </Modal>
      </div>
      <div className="flex flex-wrap">
        {banners ? (
          <List
            grid={{ gutter: 16, column: 3 }}
            dataSource={banners}
            renderItem={item => (
              <div
                key={item.id}
                className="w-full cursor-pointer pr-4 pb-4"
                onClick={() => {
                  setSelectedItem(item)
                  setShowModal(true)
                }}>
                <img
                  src={item.image}
                  width="100%"
                  alt=""
                  className="rounded-lg"
                  style={{ height: '130px', objectFit: 'cover' }}
                />
              </div>
            )}
          />
        ) : (
          <Card />
        )}
      </div>
    </BannerWrapper>
  )
}

export default DashboardBanner
