import { CartTotal, Product, ProductSkuFilterCondition, useQueryProductsPromise } from '@coop/apollo'
import { PayType as PaymentMethod, UserCard } from '@coop/common'
import { message } from 'antd'
import React from 'react'
import { usePrevious } from '../shared/hooks/usePrevious'
import { useCloudFunctionApi, useMagentoApi } from '../shared/hooks/UserTokenFunctionApi'
import { Address, Coupon, DatumParam, Loc, Market, Order, TransportInfo, User } from '../shared/model'
import { checkIfOutOfStock, formatUrl } from '../shared/utils'
import { useFirebase } from './FirebaseContext'

// const MAPPING_PAYMENT_TYPE: { [key: string]: PaymentMethod } = {
//   [PaymentTypeCode.ATM]: PaymentMethod.ATM,
//   [PaymentTypeCode.COD]: PaymentMethod.COD,
//   [PaymentTypeCode.CreditCard]: PaymentMethod.CreditCard,
// }

export const SALABLE_THRESHOLD = 5

export type Outlet = Omit<Address, 'loc'>

export type CartItem = {
  product: Product
  amount: number
  total: number
  isOutOfStock: boolean
}

export type PaymentMethodType = {
  payType: PaymentMethod
  selectedCard?: UserCard
}

type FeeType = {
  shippingFee: number
  title: string
  code: string
}

type FeeAddressType = {
  lat?: number
  lng?: number
  address?: string
  name?: string
  mobile?: string
}

type TransportDataType = { orderId?: string; totalPrice?: number; coupon: string; address: any }

export type OrderFee = {
  name: string
  code: string
  title: string
  type: FeeType[]
}

const defaultMeta = {
  id: '',
  note: '',
  discount: 0,
  fee: 0,
  subTotal: 0,
  grandTotal: 0,
  coupon: {} as Coupon,
  paymentMethod: { payType: PaymentMethod.COD } as PaymentMethodType,
}

// const getDefaultPaymentmethod = (paymentSupport: PaymentSupport | null): PaymentMethodType | null => {
//   if (paymentSupport) {
//     const type = findKey(paymentSupport, o => o) || ''
//     if (type) {
//       if (paymentSupport[PaymentTypeCode.COD]) {
//         return { payType: PaymentMethod.COD }
//       }
//       if (paymentSupport[PaymentTypeCode.ATM]) {
//         return { payType: PaymentMethod.ATM }
//       }
//       return { payType: MAPPING_PAYMENT_TYPE[type] }
//     }
//   }
//   return null
// }

export type SyncQuantity = {
  sku: string
  salable: number
}
type ContextType = {
  jwtToken?: string
  shippingAddress?: Outlet
  market?: Market
  customer?: User
  items: CartItem[]
  hasOutOfStock: boolean
  hasSmallSalable: boolean
  notFullFastDelivery: boolean
  isFastDelivery: boolean
  count: number
  total: number
  orderFee?: OrderFee | null

  addToCart(p: Product): void
  removeFromCart(p: Product): void
  removeAllFromCart(p: Product): void

  meta: typeof defaultMeta
  setNote(v: string): void
  setPaymentMethod(newMethod: PaymentMethodType): void
  createMagentoCart(): Promise<void>
  applyCartCoupon(coupon: Coupon): Promise<void>
  removeCartCoupon(): Promise<void>
  makeOrder(): Promise<DatumParam<Order>>
  finishPayment(orderId: string, success: boolean): Promise<void>
  clearCart(): void
  setShippingAddress(address: Outlet): void
  syncSaleableQuantity(items: SyncQuantity[]): void
  // getEstimateOrderFee(address: any): Promise<OrderFee[]>
  updateOrderFee(fee: OrderFee | null): void
  // createTransportOrder(data: TransportDataType): Promise<void>
  // forceRefreshCardItem(): Promise<void>
}

const Context = React.createContext<ContextType | string>('useCart should be called inside CartProvider')
Context.displayName = 'CartContext'

type ActionType =
  | { type: 'PERSIST'; uid: string }
  | { type: 'CLEAR'; cb?(old: CartItem[]): void }
  | { type: 'ADD'; item: Product; amount: number }
  | { type: 'REMOVE'; item: Product; uid: string }
  | { type: 'REMOVE_ALL'; item: Product; uid: string }
  | { type: 'SYNC'; items: SyncQuantity[] }

function reducer(state: CartItem[], action: ActionType): CartItem[] {
  if (action.type === 'PERSIST') {
    const reduceItems = state.reduce((prev, cur) => ({ ...prev, [cur.product.sku]: cur.amount }), {} as ReduceCartItem)
    window.localStorage.setItem(`${action.uid}-${Keys.items}`, JSON.stringify(reduceItems))
    return state
  }
  if (action.type === 'CLEAR') {
    if (action.cb) {
      action.cb(state)
    }
    return []
  }

  if (action.type === 'SYNC') {
    const map = action.items.reduce<{ [key: string]: SyncQuantity }>((map, i) => ({ ...map, [i.sku]: i }), {})
    const newItems = state.map(item => {
      const itemToSync = map[item.product.sku]
      if (itemToSync) {
        return { ...item, product: { ...item.product, salable: itemToSync.salable } }
      }
      return item
    })
    return newItems
  }

  const existed = state.findIndex(i => i.product.id === action.item.id)
  const item = state[existed]
  const currentPrice =
    action.item.special_price && action.item.special_price > 0 ? action.item.special_price : action.item.price || 0
  switch (action.type) {
    case 'ADD':
      const { amount } = action
      return !item
        ? [
            ...state,
            {
              product: action.item,
              amount: amount,
              total: currentPrice * amount,
              isOutOfStock: false,
            },
          ]
        : [
            ...state.slice(0, existed),
            {
              ...item,
              amount: item.amount + amount,
              total: currentPrice * (item.amount + amount),
            },
            ...state.slice(existed + 1),
          ]
    case 'REMOVE':
      if (item.amount - 1 <= 0 && state.length - 1 <= 0) {
        window.localStorage.setItem(`${action.uid}-${Keys.items}`, JSON.stringify(null))
      }
      return !item
        ? state
        : [
            ...state.slice(0, existed),
            ...(item.amount < 2 ? [] : [{ ...item, amount: item.amount - 1, total: currentPrice * (item.amount - 1) }]),
            ...state.slice(existed + 1),
          ]
    case 'REMOVE_ALL':
      if (item.amount - 1 <= 0 && state.length - 1 <= 0) {
        window.localStorage.setItem(`${action.uid}-${Keys.items}`, JSON.stringify(null))
      }
      return !item ? state : [...state.slice(0, existed), ...state.slice(existed + 1)]
    default:
      throw new Error('Unsupported action')
  }
}

const Keys = {
  items: 'cart_items',
}

type ReduceCartItem = {
  [id: string]: number
}

type CartProviderProps = React.PropsWithChildren<{
  uid?: string
  market?: Market
  customer?: User
  jwtToken?: string
}>
export const CartProvider: React.FC<CartProviderProps> = ({ children, uid = '', customer, market, jwtToken }) => {
  const [items, dispatch] = React.useReducer<typeof reducer>(reducer, [])
  const [meta, setMeta] = React.useState(defaultMeta)
  const [shippingAddress, setShippingAddress] = React.useState<Outlet>()
  const [orderFee, setOrderFee] = React.useState<OrderFee | null>()
  const { executeApi: apiCreateOrder } = useCloudFunctionApi('firestore-createOrder', jwtToken as string)
  const { executeApi: apiCheckTransportMethod } = useCloudFunctionApi('transport-checkMethod', jwtToken as string)
  // const { executeApi: apiEstimateTransportFee } = useCloudFunctionApi('transport-estimateOrderFee', jwtToken as string)
  const { executeApi: apiCreateCart } = useMagentoApi(jwtToken as string)
  const { executeApi: apiApplyCartCoupon } = useMagentoApi(jwtToken as string)
  const { executeApi: apiRemoveCartCoupon } = useMagentoApi(jwtToken as string)
  const updateOrderFee = (fee: OrderFee | null) => {
    setOrderFee(fee)
  }

  const { count, total } = items.reduce((p, c) => ({ count: p.count + c.amount, total: p.total + c.total }), {
    count: 0,
    total: 0,
  })

  const [cachedProducts, setCachedProducts] = React.useState<ReduceCartItem>({})

  React.useEffect(() => {
    if (!uid) {
      return
    }

    window.localStorage.removeItem(`${uid}-${Keys.items}`)
  }, [uid])

  // Fetch cached products detail
  const getProducts = useQueryProductsPromise()
  const fetchProducts = React.useCallback(
    async (prods: ReduceCartItem, source_code?: string) => {
      const itemSkus = Object.keys(prods)
      if (!source_code || !itemSkus.length) {
        return
      }
      dispatch({ type: 'CLEAR' })
      // console.info('PROD LOAD', { source_code, prods })
      try {
        const res = await getProducts({
          filter: {
            source_code,
            skus: { codes: itemSkus, condition: ProductSkuFilterCondition.In },
          },
        })
        if (res.errors) {
          console.info(res.errors)
          return
        }
        res.data?.products?.items?.map(item => {
          if (item) {
            dispatch({ type: 'ADD', item, amount: prods[item.sku] })
          }
        })
        dispatch({ type: 'PERSIST', uid })
        setCachedProducts({})
      } catch (e) {
        console.info('LOAD_ERR', e)
      }
    },
    [getProducts, uid],
  )

  // const refreshCardItem = React.useCallback(
  //   async (prods: ReduceCartItem, source_code?: string) => {
  //     const itemSkus = Object.keys(prods)
  //     if (!source_code || !itemSkus.length) {
  //       return
  //     }
  //     try {
  //       const res = await getProducts({
  //         filter: {
  //           source_code,
  //           skus: { codes: itemSkus, condition: ProductSkuFilterCondition.In },
  //         },
  //       })
  //       if (res.errors) {
  //         console.info(res.errors)
  //         return
  //       }
  //       dispatch({ type: 'CLEAR' })
  //       res.data?.products?.items?.map(item => {
  //         if (item) {
  //           dispatch({ type: 'ADD', item, amount: prods[item.sku] })
  //         }
  //       })
  //     } catch (e) {
  //       console.info('LOAD_ERR', e)
  //     }
  //   },
  //   [getProducts],
  // )

  React.useEffect(() => {
    if (market) {
      fetchProducts(cachedProducts, market.source_code)
    }
  }, [market, cachedProducts, fetchProducts])

  const lastMarket = usePrevious(market)
  React.useEffect(() => {
    if (!lastMarket || market?.source_code === lastMarket?.source_code) {
      return
    }
    console.info('MARKET CHANGED')
    dispatch({
      type: 'CLEAR',
      cb: curItems =>
        curItems.length &&
        setCachedProducts(curItems.reduce((pre, cur) => ({ ...pre, [cur.product.sku]: cur.amount }), {})),
    })
  }, [lastMarket, market])

  const { cartItems, hasOutOfStock, notFullFastDelivery, isFastDelivery, hasSmallSalable } = React.useMemo(() => {
    let newHasOutOfStock = false
    let isFast = false
    let fullFastDelivery = true
    let _hasSmallSalable = false
    const newCartItems = items.map(i => {
      const isOutOfStock = checkIfOutOfStock(i.product)
      if (!newHasOutOfStock && isOutOfStock) {
        newHasOutOfStock = true
      }
      if (i.product.status === 2) {
        isFast = true
      } else {
        fullFastDelivery = false
      }
      _hasSmallSalable = _hasSmallSalable || i.product.salable < SALABLE_THRESHOLD
      return {
        ...i,
        isOutOfStock,
      }
    })

    return {
      cartItems: newCartItems,
      hasOutOfStock: newHasOutOfStock,
      isFastDelivery: fullFastDelivery,
      notFullFastDelivery: isFast ? !fullFastDelivery : false,
      hasSmallSalable: _hasSmallSalable,
    }
  }, [items])

  const updateMetaFromResponse = (
    { shipping_amount: fee, discount_amount: discount, grand_total: grandTotal, subtotal: subTotal }: CartTotal,
    newMeta: Partial<typeof defaultMeta> = {},
  ) => setMeta(prev => ({ ...prev, ...newMeta, fee, discount, grandTotal, subTotal }))

  // const [createCartMutation] = useMutationCreateCart()
  const createMagentoCart = async () => {
    if (!customer || !market?.source_code || !shippingAddress) {
      throw Error('Có lỗi xảy ra trong quá trình tạo đơn hàng.\nHãy liên hệ hotline để được hỗ trợ')
    }

    const paymentMethod = { payType: PaymentMethod.COD }

    setMeta(prev => ({ ...prev, paymentMethod }))
    try {
      const splitAddress = shippingAddress?.name ? shippingAddress?.name.split(',') : []
      const cartAddress = {
        street: [shippingAddress?.name || (shippingAddress?.alias as string)],
        city: splitAddress.length > 2 ? splitAddress[splitAddress.length - 2] : 'NA',
        firstname: customer?.name?.split(' ').slice(0, -1).join(' ') || (customer.phone as string),
        lastname: customer?.name?.split(' ').slice(-1).join(' ') || (customer.phone as string),
        postcode: '70000',
        country_id: 'VN',
        telephone: customer?.phone as string,
        region_id: '1',
      }
      const resultTransport: TransportInfo = await apiCheckTransportMethod({ storeId: market?.source_code })

      if (resultTransport.transport) {
        throw Error('Hiện tại chỉ cho phép tạo đơn với cửa hàng sử dụng vận chuyển inhouse')
      }

      const shippingInput = {
        shipping_method: resultTransport.listTransport[0],
        shipping_fee: resultTransport.shippingFee || 0,
      }

      const variables = {
        storeId: market?.source_code,
        cartItems: items.map(item => ({ sku: item.product.sku, qty: item.amount })),
        shippingAddress: {
          ...cartAddress,
          extension_attributes: {
            shipping_method: shippingInput?.shipping_method,
            shipping_fee: shippingInput?.shipping_fee,
          },
        },
      }
      // console.log('createMagentoCart', variables)
      const result = await apiCreateCart(`${market?.source_code}/V1/mc/carts/create-cart`, 'post', variables)
      // console.log('createMagentoCart', result)
      if (result) {
        updateMetaFromResponse(result.total, {
          id: result.cart.id,
          coupon: defaultMeta.coupon,
        })
      }
    } catch (e) {
      throw e
    }
  }

  // const [applyCoupon] = useMutationApplyCartCoupon()
  const applyCartCoupon = async (coupon: Coupon) => {
    const result = await apiApplyCartCoupon(`${market?.source_code}/V1/mc/carts/mine/coupons/${coupon.id}`, 'post', {})
    // ({
    //   variables: { storeId: market!.source_code, couponCode: coupon.id },
    // })
    console.log('applyCartCoupon', result)
    if (result) {
      updateMetaFromResponse(result, { coupon: { id: result.coupon_code as string } as any })
    }
  }

  // const [removeCoupon] = useMutationDeleteCartCoupon()
  const removeCartCoupon = async () => {
    const result = await apiRemoveCartCoupon(`${market?.source_code}/V1/mc/carts/mine/coupons`, 'post', {})
    if (result) {
      updateMetaFromResponse(result, { coupon: defaultMeta.coupon })
    }
  }

  const { functions } = useFirebase()
  const makeOrder = async (): Promise<DatumParam<Order>> => {
    if (!market) {
      throw new Error('Cannot make order')
    }
    const { subTotal, grandTotal, fee, note, coupon, discount } = meta
    const orderData: Partial<Order> = {
      address: { ...shippingAddress!, loc: Loc(shippingAddress!.latitude, shippingAddress!.longitude) },
      store: {
        name: market.name || '',
        id: market.source_code,
        loc: Loc(market.latitude, market.longitude),
        address: market.street || '',
        phone: market.phone || '',
      },
      products: items.map(({ product: { name, price, special_price, thumbnail_path, sku }, amount }) => ({
        amount,
        product: {
          name,
          origPrice: price,
          price: special_price || price,
          image: thumbnail_path ? formatUrl(thumbnail_path) : '',
          sku,
          unit: '',
        },
      })),
      paymentMethod: meta.paymentMethod.payType,
      note,
      subTotal,
      fee,
      couponCode: coupon?.id,
      discount,
      total: grandTotal,
      count,
    }

    // const placeOrder = functions.httpsCallable('firestore-createOrder')
    const res = await apiCreateOrder({ ...orderData, cartId: meta.id })
    return { ref: { id: res.orderId }, key: res.orderId, data: orderData as Order }
  }

  const finishPayment = async (orderId: string, success: boolean): Promise<void> => {
    const finish = functions.httpsCallable('firestore-finishPayment')
    await finish({ orderId, success })
  }

  const clearCart = () => {
    setMeta(defaultMeta)
    dispatch({ type: 'CLEAR' })
    window.localStorage.removeItem(`${uid}-${Keys.items}`)
  }

  const handleAddToCart = (item: Product) => {
    const cartItem = cartItems.find(i => i.product.sku === item.sku)
    if (cartItem && cartItem.amount >= cartItem.product.salable) {
      message.error(`Xin lỗi! Số lượng tối đa là ${cartItem.product.salable} sản phẩm`)
      return
    }
    dispatch({ type: 'ADD', item, amount: 1 })
    dispatch({ type: 'PERSIST', uid })
    // message.success(`Đã thêm ${item.name} vào giỏ hàng`)
  }

  const removeFromCart = (item: Product) => {
    dispatch({ type: 'REMOVE', item, uid })
    dispatch({ type: 'PERSIST', uid })
  }

  const removeAllFromCart = (item: Product) => {
    dispatch({ type: 'REMOVE_ALL', item, uid })
    dispatch({ type: 'PERSIST', uid })
  }

  const syncSaleableQuantity = (_items: SyncQuantity[]) => {
    dispatch({ type: 'SYNC', items: _items })
    dispatch({ type: 'PERSIST', uid })
  }

  // const getEstimateOrderFee = async (): Promise<OrderFee[]> => {
  //   const getFees = functions.httpsCallable('transport-estimateOrderFee')

  //   const storeAddress: FeeAddressType = {
  //     lat: market?.latitude,
  //     lng: market?.longitude,
  //     address: market?.street || '',
  //     name: market?.name,
  //     mobile: market?.phone as string,
  //   }
  //   const customerAddress: FeeAddressType = {
  //     lat: shippingAddress?.latitude,
  //     lng: shippingAddress?.longitude,
  //     address: shippingAddress?.name,
  //     name: customer?.name || '',
  //     mobile: customer?.phone as string,
  //   }

  //   const listItem = items.map(item => ({
  //     _id: String(item.product.id),
  //     num: item.amount,
  //     name: item.product.name,
  //     price: item.product.price * item.amount,
  //   }))

  //   const bodyRequest = {
  //     storeAddress: storeAddress,
  //     customerAddress: customerAddress,
  //     listItem: listItem,
  //   }
  //   const res = await getFees(bodyRequest)
  //   return res.data as OrderFee[]
  // }

  // const createTransportOrder = async ({
  //   orderId,
  //   totalPrice,
  //   coupon = '',
  //   address,
  // }: TransportDataType): Promise<void> => {
  //   // if (!hasTransport) {
  //   //   return
  //   // }

  //   // if (!market || !orderFee || !user || !address || !items) {
  //   //   throw new Error('Cannot make transport order')
  //   // }

  //   const _createTransportOrder = functions.httpsCallable('transport-createOrder')

  //   const storeAddress: FeeAddressType = {
  //     lat: market?.latitude,
  //     lng: market?.longitude,
  //     address: market?.street || '',
  //     name: market?.name,
  //     mobile: market?.phone as string,
  //   }
  //   const customerAddress: FeeAddressType = {
  //     lat: address?.latitude,
  //     lng: address?.longitude,
  //     address: address?.name,
  //     name: customer?.name || '',
  //     mobile: customer?.phone as string,
  //   }

  //   const listItem = items.map(item => ({
  //     _id: item.product.sku,
  //     num: item.amount,
  //     name: item.product.name,
  //     price: (item.product.special_price || item.product.price) * item.amount,
  //   }))

  //   const bodyRequest = {
  //     storeAddress: storeAddress,
  //     customerAddress: customerAddress,
  //     listItem: listItem,
  //     orderId: orderId,
  //     partner: orderFee?.name || '',
  //     totalPrice: totalPrice,
  //     comment: meta.note,
  //     paymentMethod: meta.paymentMethod.payType.toLowerCase(),
  //     coupon,
  //   }

  //   await _createTransportOrder(bodyRequest)
  //   updateOrderFee(null)
  // }

  // const forceRefreshCardItem = async () => {
  //   const result = window.localStorage.multiGet(Object.values(Keys))
  //   if (result) {
  //     let itemKeys: ReduceCartItem = {}
  //     result.map(res => {
  //       if (res[1] === null || res[1].length === 0) {
  //         return
  //       }
  //       switch (res[0]) {
  //         case Keys.items:
  //           const reduceItems = JSON.parse(res[1]) as ReduceCartItem
  //           if (reduceItems) {
  //             itemKeys = reduceItems
  //           }
  //           break
  //         default:
  //           break
  //       }
  //     })
  //     if (itemKeys) {
  //       await refreshCardItem(itemKeys, market?.source_code)
  //     }
  //   }
  // }

  console.log('cartItems', uid, meta, cartItems)

  const value: ContextType = {
    jwtToken,
    shippingAddress,
    market,
    customer,
    items: cartItems,
    hasOutOfStock,
    notFullFastDelivery,
    isFastDelivery,
    hasSmallSalable,
    count,
    total,
    addToCart: item => handleAddToCart(item),
    removeFromCart,
    removeAllFromCart,
    clearCart,
    meta,
    setNote: note => {
      setMeta(prev => ({ ...prev, note }))
    },
    setPaymentMethod: paymentMethod => setMeta(prev => ({ ...prev, paymentMethod })),
    createMagentoCart,
    applyCartCoupon,
    removeCartCoupon,
    makeOrder,
    finishPayment,
    setShippingAddress,
    syncSaleableQuantity,
    // getEstimateOrderFee,
    orderFee,
    updateOrderFee,
    // createTransportOrder,
    // forceRefreshCardItem,
  }
  return <Context.Provider {...{ children, value }} />
}

export function useCart(): ContextType {
  const c = React.useContext(Context)
  if (typeof c === 'string') {
    throw new Error(c)
  }
  return c
}
