import { ProcessStatus } from '@coop/common'

export type LaneType = ProcessStatus

type Style = {
  lane: {
    value: string
    valueBox: string
    sub?: string
  }
  card: {
    title: string
    background: string
    separatorOpacity: number
    alert: string
    alertBackground: string
    driverAlertBackground?: string
  }
}

export const Colors: { [key in LaneType]: Style } = {
  [ProcessStatus.Pending]: {
    lane: { value: '#343B42', valueBox: '#FFF' },
    card: {
      title: '#343B42',
      background: '#FFF',
      separatorOpacity: 1,
      alert: '#000',
      alertBackground: '#C7C7C7',
    },
  },
  [ProcessStatus.ReadyToPick]: {
    lane: { value: '#343B42', valueBox: '#FFF' },
    card: {
      title: '#343B42',
      background: '#FFF',
      separatorOpacity: 1,
      alert: '#000',
      alertBackground: '#EFEF36',
    },
  },
  [ProcessStatus.Picking]: {
    lane: { value: '#343B42', valueBox: '#FFF', sub: '#EF5236' },
    card: {
      title: '#343B42',
      background: '#FFF',
      separatorOpacity: 1,
      alert: '#FFF',
      alertBackground: '#EF5236',
    },
  },
  [ProcessStatus.ReadyToDeliver]: {
    lane: { value: '#343B42', valueBox: '#FFF', sub: '#EF5236' },
    card: {
      title: '#343B42',
      background: '#FFF',
      separatorOpacity: 1,
      alert: '#000',
      alertBackground: '#EFEF36',
    },
  },
  [ProcessStatus.Delivering]: {
    lane: { value: '#343B42', valueBox: '#FFF' },
    card: {
      title: '#343B42',
      background: '#FFF',
      separatorOpacity: 1,
      alert: '#202731',
      alertBackground: '#EFEF36',
      driverAlertBackground: '#3CB371',
    },
  },
  [ProcessStatus.Returning]: {
    lane: { value: '#343B42', valueBox: '#FFF' },
    card: {
      title: '#FFF',
      background: '#EF5236',
      separatorOpacity: 0.1,
      alert: '#FFF',
      alertBackground: '#F1654C',
    },
  },
  [ProcessStatus.Canceled]: {
    lane: { value: '#343B42', valueBox: '#FFF', sub: '#EF5236' },
    card: {
      title: '#343B42',
      background: '#FFF',
      separatorOpacity: 1,
      alert: '#000',
      alertBackground: '#EFEF36',
    },
  },
  [ProcessStatus.Finished]: {
    lane: { value: '#343B42', valueBox: '#FFF', sub: '#EF5236' },
    card: {
      title: '#343B42',
      background: '#FFF',
      separatorOpacity: 1,
      alert: '#000',
      alertBackground: '#EFEF36',
    },
  },
}
