import { DeleteOutlined, DragOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Money } from 'src/shared/components/Money'
import { Product } from 'src/shared/model'
import { formatUrl } from 'src/shared/utils'
import styled from 'styled-components'

const THead = styled.th`
  padding: 8px;
  font-size: 1.5em;
`

const TDetail = styled.td`
  padding: 8px;
  font-size: 1.1em;
  border-bottom: solid 8px white;
`

const TDetailCenter = styled(TDetail)`
  text-align: center;
`

interface Props {
  products: Product[]
  setProducts: React.Dispatch<React.SetStateAction<Product[]>>
  onDeleteProduct: (e: Product) => void
}

const DragListProduct: React.FC<Props> = ({ products, onDeleteProduct, setProducts }) => {
  const productList = [...products]

  const handleDeleteProduct = (e: Product) => {
    onDeleteProduct(e)
  }
  const reOrder = (item: any) => {
    const { destination, source } = item

    if (destination?.index || destination?.index === 0) {
      const [removed] = productList.splice(source.index, 1)
      productList.splice(destination.index, 0, removed)
      setProducts(productList)
    }
  }

  return (
    <DragDropContext onDragEnd={(e: any) => reOrder(e)}>
      <Droppable droppableId="droppableId">
        {provided => (
          <table
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="border-collapse table-fixed"
            style={{ width: '100%' }}>
            <thead>
              <tr>
                <THead className="w-4/12">Sản phẩm ({productList?.length})</THead>
                <THead className="w-2/12">SKU </THead>
                <THead className="w-2/12">Tình trạng </THead>
                <THead className="w-2/12">Giá tiền </THead>
                <THead className="w-1/12" />
                <THead className="w-1/12" />
              </tr>
            </thead>
            <tbody>
              {(productList || []).map((e, i) => (
                <Draggable key={`drag_${i}`} draggableId={`drag_${i}`} index={i}>
                  {(providedDrag, snapshotDrag) => (
                    <tr
                      ref={providedDrag.innerRef}
                      {...providedDrag.draggableProps}
                      {...providedDrag.dragHandleProps}
                      style={{
                        ...providedDrag.draggableProps.style,
                        margin: '0 0 8px 0',
                        background: snapshotDrag.isDragging ? '#1890ff' : 'WhiteSmoke',
                      }}
                      className={'cursor-move'}>
                      <TDetail>
                        <div className="flex items-center">
                          <img src={formatUrl(e.thumbnail_path)} width="56" height="56" alt="" />
                          <span className="ml-2">{e.name}</span>
                        </div>
                      </TDetail>
                      <TDetail>{e.sku}</TDetail>
                      <TDetail>{e.visibility ? 'Còn hàng' : 'Hết hàng'}</TDetail>
                      <TDetail>
                        <Money value={e.price || undefined} />
                      </TDetail>
                      <TDetail>
                        <Tooltip title="Xóa">
                          <Button danger onClick={() => handleDeleteProduct(e)}>
                            <DeleteOutlined />
                          </Button>
                        </Tooltip>
                      </TDetail>
                      <TDetailCenter>
                        <DragOutlined style={{ fontSize: 24 }} className="cursor-pointer" />
                      </TDetailCenter>
                    </tr>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </tbody>
          </table>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DragListProduct
