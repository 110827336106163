import { EnvironmentFilled } from '@ant-design/icons'
import { Button, message, Modal } from 'antd'
import React from 'react'
import { FirebaseContext } from '../../../contexts/FirebaseContext'
import { Order } from '../../../shared/model'
import { OrderItemsList } from '../../Orders/components/OrderItemsList'

type Props = {
  order: Order
}

export const ReturnOrder: React.FC<Props> = ({ order }) => {
  const [returnItems, setReturnItems] = React.useState<Order['products']>([])
  const [showModal, setShowModal] = React.useState(false)
  const [canceling, setCanceling] = React.useState(false)

  const { functions } = React.useContext(FirebaseContext)
  const managerCancelOrder = functions.httpsCallable('firestore-managerCancelOrder')

  const handleCloseModal = () => setShowModal(false)
  const handleCancelOrder = async () => {
    try {
      setCanceling(true)
      setShowModal(false)
      await managerCancelOrder({ id: order.id, returnSkus: returnItems.map(item => item.product.sku) })
      message.success('Trả sản phẩm thành công!')
    } catch (e) {
      message.error(`Không thể trả sản phẩm, có lỗi xảy ra: ${e.message}`)
    } finally {
      setCanceling(false)
    }
  }

  return (
    <div className="absolute z-50 top-0 left-0 pt-4 w-full h-full flex flex-col items-stretch bg-white border-l border-gray-300">
      <div className="flex items-center z-10 mx-4 py-2 px-3 rounded-md bg-white shadow-lg border-t border-gray-100">
        <EnvironmentFilled />
        <span className="w-1" />
        <span>{order.address.name}</span>
      </div>
      <span className="text-gray-500 uppercase p-4">Sản phẩm trả lại</span>
      <div className="border-t border-gray-300 flex-1 px-4">
        <OrderItemsList products={order.products} onReturnItemsChange={setReturnItems} />
      </div>
      <div className="flex p-4 border-t border-gray-300">
        <Button
          loading={canceling}
          type="primary"
          className="uppercase flex-1 rounded-lg py-2 h-auto"
          onClick={() => setShowModal(true)}>
          Xác nhận
        </Button>
      </div>
      <Modal
        bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
        visible={showModal}
        title="Xác nhận sản phẩm trả"
        okText="Xác nhận"
        onOk={handleCancelOrder}
        okButtonProps={{ loading: canceling }}
        cancelText="Hủy"
        onCancel={handleCloseModal}>
        <OrderItemsList products={returnItems} />
      </Modal>
    </div>
  )
}
