import moment from 'moment'
import { ColumnProps } from 'rc-table/lib/sugar/Column'
import React from 'react'
import { User } from 'src/shared/model'
import { dateFormat } from 'src/shared/utils'

const customerHeader = [
  { label: 'Họ và tên', key: 'name' },
  { label: 'Số điện thoại', key: 'phone' },
  { label: 'Email', key: 'email' },
  { label: 'Số Order', key: 'countOrder' },
  { label: 'Ngày tạo', key: 'createdAt' },
  { label: 'Channel', key: 'channel' },
]

const customerColumns: ColumnProps<User>[] = [
  { title: 'HỌ & TÊN', dataIndex: 'name' },
  { title: 'SỐ ĐIỆN THOẠI', dataIndex: 'phone' },
  { title: 'EMAIL', dataIndex: 'email' },
  { title: 'Số Order', align: 'center', dataIndex: 'countOrder' },
  {
    title: 'Ngày tạo',
    render: (data: User & { createdAt?: string }) => (
      <div>{data?.createdAt ? moment(data.createdAt).format(dateFormat) : ''}</div>
    ),
  },
  {
    title: 'Channel',
    render: (data: User) => <div>{data.channel}</div>,
  },
]

export { customerHeader, customerColumns }
