import { OrderItem } from '@coop/common'
import { Checkbox } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { Money } from '../../../shared/components/Money'
import { DocRef, Order } from '../../../shared/model'

const Item = styled('div').attrs({ className: 'py-3 w-full flex items-stretch border-b border-gray-200' })``

const Image = styled('img').attrs({ className: 'h-full mr-4 object-contain rounded-md border border-gray-300' })`
  width: 48px;
`

type Props = {
  products: Order['products']
  onReturnItemsChange?: (items: Order['products']) => void
}

export const OrderItemsList: React.FC<Props> = ({ products, onReturnItemsChange }) => {
  const [returnItems, setReturnItems] = React.useState<Order['products']>(products)
  const [checkedAll, setCheckedAll] = React.useState(true)

  React.useEffect(() => {
    setCheckedAll(returnItems.length === products.length)
    onReturnItemsChange && onReturnItemsChange(returnItems)
  }, [onReturnItemsChange, products.length, returnItems])

  const handleToggleAll = () => {
    setReturnItems(checkedAll ? [] : products)
    setCheckedAll(!checkedAll)
  }

  const handleSelectItem = (checked: boolean, item: OrderItem<DocRef>) => {
    if (!checked) {
      const index = returnItems.findIndex(i => i.product.sku === item.product.sku)
      setReturnItems([...returnItems.slice(0, index), ...returnItems.slice(index + 1)])
    } else {
      setReturnItems([...returnItems, item])
    }
  }

  return (
    <>
      {onReturnItemsChange && products.length && (
        <Item>
          <span className="flex items-center text-gray-900 text-lg mr-2">
            {onReturnItemsChange && <Checkbox className="mr-2" checked={checkedAll} onChange={handleToggleAll} />}
            Tất cả
          </span>
        </Item>
      )}
      <div className="overflow-y-auto" style={{ height: 'calc(100vh - 300px)' }}>
        {products.map(item => (
          <Item key={item.product.sku}>
            <span className="flex items-center text-gray-900 text-lg mr-2">
              {onReturnItemsChange && (
                <Checkbox
                  className="mr-2"
                  checked={returnItems.some(i => i.product.sku === item.product.sku)}
                  onChange={e => handleSelectItem(e.target.checked, item)}
                />
              )}
              {item.amount}
            </span>
            <Image src={item.product.image} />
            <div className="flex flex-col flex-1 justify-center">
              <p className="text-gray-900">{item.product.name}</p>
              <p className="text-gray-700">{item.product.sku}</p>
            </div>
            <Money value={item.amount * item.product.price} />
          </Item>
        ))}
      </div>
    </>
  )
}
