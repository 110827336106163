import { Empty, Tabs } from 'antd'
import React from 'react'
import { OrdersProvider } from 'src/contexts/OrderContext'
import { useSource } from 'src/contexts/SourceContext'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import ExportReport from './ExportReport'
import OrderReport from './OrderReport'
import ProductReport from './ProductReport'

const Wrapper = styled.div``
const StyledTabs = styled(Tabs)`
  .ant-tabs-tab.ant-tabs-tab-active {
    ${tw`text-gray-900 font-bold`}
  }
`

type Props = {}

const ReportPage: React.FC<Props> = () => {
  const { source } = useSource()

  return source ? (
    <Wrapper className="w-full flex-1">
      <StyledTabs className="w-full">
        <Tabs.TabPane tab="Đơn hàng" key="orders">
          <OrdersProvider>
            <OrderReport />
          </OrdersProvider>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Sản phẩm" key="products">
          <ProductReport />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Xuất báo cáo" key="export-report">
          <ExportReport />
        </Tabs.TabPane>
      </StyledTabs>
    </Wrapper>
  ) : (
    <div className="flex flex-1 items-center justify-center pt-48">
      <Empty description="Xem theo siêu thị để hiển thị báo cáo của siêu thị đó" />
    </div>
  )
}

export default ReportPage
