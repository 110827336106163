import { Source } from '@coop/apollo'
import {
  AddressType,
  BannerType,
  CouponType,
  DriverType,
  FcmTopics,
  LogType,
  ManagerType,
  NotificationType,
  OrderItem as OrderItemType,
  OrderType,
  PickerType,
  ProfileType,
  SectionType,
  StockType,
  SystemOrderType,
  TrackingType,
  TransportStoreType,
  TransportSystemType,
  UserAuthenResult as UserAuthenResultType,
  UserType,
} from '@coop/common'
import * as firebase from 'firebase/app'
import { firestore } from 'firebase/app'

export type IdType = {
  id: string
}

export type UserAuthenResult = UserAuthenResultType
export type ColRef = firestore.CollectionReference
export type DocRef = firestore.DocumentReference
export type GeoType = firestore.GeoPoint
export type TimeType = firestore.Timestamp

export type User = IdType & UserType<firestore.GeoPoint>
export type Manager = IdType & ManagerType
export type Driver = IdType & DriverType<DocRef, TimeType, GeoType>
export type Picker = IdType & PickerType<DocRef>
export type Profile = IdType & ProfileType<TimeType>
export type Address = AddressType<GeoType>

export type Coupon = IdType & CouponType<TimeType>
export type Order = IdType & OrderType<DocRef, GeoType, TimeType>
export type OrderItem = OrderItemType<DocRef>
export type SystemOrder = IdType & SystemOrderType<DocRef, GeoType, TimeType>
export type Log = IdType & LogType<DocRef, TimeType>
export type Tracking = IdType & TrackingType<DocRef, GeoType, TimeType>

export type Market = Source
export type { Category, Product } from '@coop/apollo'
export type Stock = IdType & StockType<DocRef>

export type Banner = IdType & BannerType
export type Section = IdType & SectionType<DocRef>

export type UserInfo = IdType & firebase.UserInfo & { password: string }

export type NotificationGroup = IdType & {
  name: string
  description: string
  topic: string
}

export type NotificationTopic = {
  id: FcmTopics
  name: string
  description: string
}

export type Notification = IdType & NotificationType

export type TransportStore = IdType & TransportStoreType

export type TransportSystem = IdType & TransportSystemType

export const Loc = (lat: number, long: number): GeoType => new firestore.GeoPoint(lat, long)

export type Datum<T> = {
  ref: DocRef
  key: string
  data: T
}
export type DatumParam<T> = Omit<Datum<T>, 'ref'> & {
  ref: Pick<DocRef, 'id'>
}

export type TransportInfo = {
  transport: boolean
  shippingFee: number
  listTransport: string[]
}

type FeeType = {
  shippingFee: number
  title: string
  code: string
}

type FeeAddressType = {
  lat?: number
  lng?: number
  address?: string
  name?: string
  mobile?: string
}

export type OrderFee = {
  name: string
  code: string
  title: string
  type: FeeType[]
}

//API Place Order With Method - Update Payment Method
export enum EPaymentMethod {
  COD = 'cashondelivery',
  BANK = 'banktransfer',
  ATM = 'atm',
  CREDITS = 'credits',
  EW = 'ew',
  Zalo = 'zalo',
  Momo = 'momo',
}
