import { Empty } from 'antd'
import React from 'react'
import { useSource } from 'src/contexts/SourceContext'
import StoreControlPage from './StoreControlPage'

export default () => {
  const { source } = useSource()

  return source ? (
    <>
      <StoreControlPage />
    </>
  ) : (
    <div className="flex flex-1 items-center justify-center">
      <Empty description="Xem theo siêu thị để hiển thị thông tin cửa hàng" />
    </div>
  )
}
