import Avatar from 'antd/lib/avatar/avatar'
import React from 'react'
import env from '../Env'
import { Market } from '../model'

export const getSourceAvatar = (source: Market) => (
  <Avatar
    src={source.imageUrl ? `${env.serverUrl}${source.imageUrl}` : require('../../assets/img/g1-mart.png')}
    size={24}
    style={{ backgroundColor: '#ec145c' }}
  />
)
