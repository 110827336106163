import { Source, useQueryCouponsPromise, useQueryRulesPromise } from '@coop/apollo'
import React from 'react'
import { useFirestore } from '../../../contexts/FirestoreContext'
import { Coupon } from '../../../shared/model'

const hasChange = (newData: Coupon, oldData?: Coupon) => {
  if (!oldData) {
    return true
  }
  // @ts-ignore
  return Object.keys(newData).some(key => newData[key] !== oldData[key])
}

const isSameWebId = (newWebId: number[], oldWebId: number[]) => {
  return newWebId.length === oldWebId.length && newWebId.every((e, index) => e === oldWebId[index])
}

export const useSyncCoupons = (sourceCode?: Source['source_code']): ((oldCoupons: Coupon[]) => Promise<void>) => {
  const getCoupons = useQueryCouponsPromise({ storeId: sourceCode })
  const getRules = useQueryRulesPromise({ storeId: sourceCode })
  const { Data, Dto } = useFirestore()

  const sync = React.useCallback(
    async (oldCoupons: Coupon[]) => {
      const coupons = await getCoupons()
      const rules = await getRules()
      if (!coupons.data?.coupons?.items || !rules.data?.rules?.items) {
        Promise.reject('Cannot load Magento data')
      }
      // @ts-ignore
      await Promise.allSettled([
        // creat, update
        ...(coupons.data?.coupons?.items || []).map(async coupon => {
          if (!coupon?.code) {
            return Promise.resolve()
          }
          const { code, coupon_id, rule_id, website_ids } = coupon

          const rule = rules.data?.rules?.items?.find(r => r?.rule_id === rule_id)
          if (!rule) {
            return Promise.resolve()
          }
          const existing = oldCoupons.find(c => c.id === code)
          if (!existing) {
            await Data.coupon()
              .doc(code)
              .set(
                Dto.coupon({
                  rule_id: rule_id!,
                  coupon_id: coupon_id!,
                  isHidden: true,
                  website_id: website_ids!,
                }),
              )
          }
          const { description, name, apply_to_shipping, discount_amount } = rule
          const newData = Dto.coupon({
            description: description || '',
            name: name || '',
            ...(name && { name }),
            value: discount_amount || 0,
            applyToShipping: !!apply_to_shipping,
          })
          if (hasChange(newData, existing) || !isSameWebId(website_ids!, existing?.website_id!)) {
            try {
              await Data.coupon()
                .doc(coupon.code)
                .update(
                  Dto.coupon({
                    isHidden: true,
                    website_id: website_ids!,
                    newData,
                  }),
                )
            } catch (e) {
              Promise.reject(e)
            }
          }
        }),
        // remove
        ...oldCoupons.map(async existing => {
          if (!coupons.data?.coupons?.items?.find(c => c?.code === existing.id)) {
            await Data.coupon()
              .doc(existing.id)
              .update(Dto.coupon({ removing: true, isHidden: true }))
          }
        }),
      ])
    },
    [Data, Dto, getCoupons, getRules],
  )

  return sync
}

export const useApplyNewData = (): (() => Promise<void>) => {
  const { Data, Util, Dto } = useFirestore()

  const apply = React.useCallback(async () => {
    const data = await Data.coupon().get()
    // @ts-ignore
    await Promise.allSettled(
      data.docs.map(async doc => {
        try {
          const { newData, removing } = Util.convert<Coupon>(doc)
          if (removing) {
            await doc.ref.delete()
            return
          }
          if (!newData) {
            return Promise.resolve()
          }
          const { name, description, applyToShipping, value } = newData
          await doc.ref.update(
            Dto.coupon({
              name,
              description,
              applyToShipping,
              value,
              pending: false,
              // @ts-ignore
              newData: null,
            }),
          )
        } catch (e) {
          console.error(e)
          Promise.reject(e)
        }
      }),
    )
  }, [Data, Dto, Util])

  return apply
}
