import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { SystemOrder } from '../../../shared/model'
import { Card } from './Card'
import { Colors, LaneType } from './styles'

type Props = {
  headerProps?: HeaderProps
  orders: SystemOrder[]
  onCardClick: (item: SystemOrder) => void
}

export const Lane: React.FC<Props> = ({ headerProps, orders, onCardClick }) => {
  return (
    <div className="flex-1 self-stretch flex flex-col mx-2 pb-2 rounded-lg" style={{ backgroundColor: '#F0F1F2' }}>
      {headerProps && <LaneHeader {...{ ...headerProps }} />}
      <PerfectScrollbar className="rounded-lg">
        {orders.map(order => (
          <Card key={order.id} onClick={() => onCardClick(order)} order={order} />
        ))}
      </PerfectScrollbar>
    </div>
  )
}

type HeaderProps = {
  value: number
  title: string
  type: LaneType
  subProps?: Omit<SubProps, 'type'>
}

export const LaneHeader: React.FC<HeaderProps> = ({ value, title, subProps, type }) => (
  <div className="w-full flex justify-start items-center p-2">
    <div className="flex justify-start items-center">
      <div
        className={'w-8 h-8 rounded-md mr-2 flex justify-center items-center'}
        style={{ backgroundColor: Colors[type].lane.valueBox }}>
        <span className={'text-xl font-semibold'} style={{ color: Colors[type].lane.value }}>
          {value}
        </span>
      </div>
      <span className="uppercase font-bold text-sm" style={{ color: '#343B42' }}>
        {title}
      </span>
    </div>
    {subProps && (
      <div className="flex-1 flex justify-evenly items-center">
        <div className="h-6 bg-gray-300 mx-2" style={{ width: '2px' }} />
        <SubHeader {...{ ...subProps, type }} />
        {/* <div className="w-8" /> */}
      </div>
    )}
  </div>
)

type SubProps = {
  value: number
  title: string
  type: LaneType
}
const SubHeader: React.FC<SubProps> = ({ value, title, type }) => (
  <div className="flex items-center">
    <span className={'font-bold text-lg mr-2'} style={{ color: Colors[type].lane.sub || Colors[type].lane.value }}>
      {value}
    </span>
    <span className="text-sm">{title}</span>
  </div>
)
