import { Empty } from 'antd'
import React from 'react'
import { useSource } from 'src/contexts/SourceContext'
import PaymentControlPage from './PaymentControlPage'

export default () => {
  const { source } = useSource()

  return source ? (
    <>
      <PaymentControlPage />
    </>
  ) : (
    <div className="flex flex-1 items-center justify-center">
      <Empty description="Xem theo siêu thị để hiển thị thông tin thanh toán" />
    </div>
  )
}
