import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div``

type Props = {
  title: string | React.ReactNode
  actionRenderer?: React.ReactNode
}

const ModalHeader: React.FC<Props> = ({ title, actionRenderer }) => {
  return (
    <Wrapper className="w-full bg-white shadow-md flex items-center" style={{ flex: '0 0 3rem', zIndex: 60 }}>
      <div className="text-lg text-center w-full">{title}</div>
      {actionRenderer && actionRenderer}
    </Wrapper>
  )
}

export default ModalHeader
