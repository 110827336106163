import { InfoCircleTwoTone } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React from 'react'
import { Coupon } from '../../../../shared/model'

export const Cell: React.FC<{ coupon: Coupon; dataIndex: keyof Omit<Coupon, 'newData' | 'id'> }> = ({
  coupon,
  dataIndex,
}) =>
  coupon.newData && coupon.newData[dataIndex] !== undefined && coupon.newData[dataIndex] !== coupon[dataIndex] ? (
    <Tooltip
      title={
        coupon[dataIndex] !== undefined ? (
          <>
            Cũ: <span className="font-semibold">{coupon[dataIndex]}</span>
          </>
        ) : (
          <>Thêm mới</>
        )
      }>
      <span>
        <InfoCircleTwoTone twoToneColor="orange" className="mr-1" />
        {coupon.newData[dataIndex]}
      </span>
    </Tooltip>
  ) : (
    <>{coupon[dataIndex]}</>
  )
