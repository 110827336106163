import { NotificationFilled } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import IconCustomer from '../../assets/img/customer.png'
import IconEmployee from '../../assets/img/employee.png'
import IconOrder from '../../assets/img/order.png'
import IconProduct from '../../assets/img/product.png'
import IconReport from '../../assets/img/report.svg'
import { useAuth } from '../../contexts/AuthContext'
import { useWindowDimensions } from '../hooks/useWindowDimensions'

const Wrapper = styled('div')`
  ${tw`flex items-stretch h-full`}
`
const MenuItem = styled(NavLink).attrs({
  className: 'p-6',
  activeClassName: 'active',
})`
  &.active {
    box-shadow: inset 0 -4px 0 #ec145c;
    color: #ec145c;
  }
  &:hover {
    /* box-shadow: inset 0 -4px 0 #ec145c; */
    color: #ec145c;
  }
`

const Image = styled('img')`
  width: 24px;
  height: 24px;
`

const Icon = ({ src, title }: { src: string; title: string }) => (
  <Tooltip title={title}>
    <Image src={src} />
  </Tooltip>
)

type Props = {}

const TopMenu: React.FC<Props> = () => {
  const { ability } = useAuth()
  const { width } = useWindowDimensions()
  const minimized = width < 1024

  return (
    <Wrapper>
      {ability && (
        <>
          <MenuItem to="/home">{minimized ? <Icon src={IconOrder} title="Đơn hàng" /> : 'Đơn hàng'}</MenuItem>
          {ability.can('read', '/san-pham') && (
            <MenuItem to="/san-pham">{minimized ? <Icon src={IconProduct} title="Sản phẩm" /> : 'Sản phẩm'}</MenuItem>
          )}
          {ability.can('read', '/report') && (
            <MenuItem to="/report">{minimized ? <Icon src={IconReport} title="Báo cáo" /> : 'Báo cáo'}</MenuItem>
          )}
          {ability.can('read', '/khach-hang') && (
            <MenuItem to="/khach-hang">
              {minimized ? <Icon src={IconCustomer} title="Khách hàng" /> : 'Khách hàng'}
            </MenuItem>
          )}
          {ability.can('read', '/nhan-su') && (
            <MenuItem to="/nhan-su">{minimized ? <Icon src={IconEmployee} title="Nhân sự" /> : 'Nhân sự'}</MenuItem>
          )}
          {ability.can('read', '/thong-bao') && (
            <MenuItem to="/thong-bao">
              {minimized ? (
                <Tooltip title="Thông báo">
                  <NotificationFilled size={24} />
                </Tooltip>
              ) : (
                'Thông báo'
              )}
            </MenuItem>
          )}
          {ability.can('read', '/system') && (
            <MenuItem to="/system">
              {minimized ? <Icon src={IconEmployee} title="Cài đặt hệ thống" /> : 'Cài đặt hệ thống'}
            </MenuItem>
          )}
        </>
      )}
    </Wrapper>
  )
}

export default TopMenu
