import { Ability, AbilityBuilder } from '@casl/ability'
import { ManagerRole } from '@coop/common'
import { Manager } from '../model'

type Actions = 'create' | 'read' | 'update' | 'delete'

type RouteSubject =
  | '/nhan-su'
  | '/khach-hang'
  | '/don-hang'
  | '/san-pham'
  | '/thong-bao'
  | '/thong-bao/tai-xe'
  | '/thong-bao/khach-hang'
  | '/nhan-su/quan-ly'
  | '/nhan-su/tai-xe'
  | '/nhan-su/picker'
  | '/system'
  | '/system/khuyen-mai'
  | '/system/channel'
  | '/system/trang-chu'
  | '/report'

type FeatureSubject = 'systemConfig-side-menu' | 'notification-side-menu' | 'order-assignment'

type Subject = RouteSubject | FeatureSubject

export type AbilityType = Ability<[Actions, Subject]>

export default function defineAbilityFor(me: Manager | undefined) {
  const { can, build } = new AbilityBuilder<AbilityType>()

  if (!me || !me.role) {
    return build()
  }

  // console.log('me', me)

  // common ability - các route ko yêu cầu role thì để ngoài vòng if
  // can(['read'], ['/ban-do'])

  if (me?.role === ManagerRole.Admin) {
    can(['read'], ['systemConfig-side-menu'])
    can(['read', 'update'], ['/san-pham'])
    can(['read'], ['/report'])
    can(['read'], ['/system'])
    can(['read'], ['/khach-hang'])
    can(['read', 'update'], ['/nhan-su'])
    can(['read'], ['/nhan-su/quan-ly'])
    can(['read'], ['/nhan-su/tai-xe'])
    can(['read'], ['/nhan-su/picker'])
    can(['read'], ['/thong-bao'])
    can(['read'], ['/system'])
    can(['read'], ['/system/khuyen-mai'])
    can(['read'], ['/system/channel'])
    // can(['read'], ['/system/trang-chu'])
  }

  if (me?.role === ManagerRole.Manager) {
    can(['read'], ['systemConfig-side-menu'])
    can(['read', 'update'], ['/nhan-su'])
    can(['read'], ['/nhan-su/quan-ly'])
    can(['read'], ['/nhan-su/tai-xe'])
    can(['read'], ['/report'])
    can(['read'], ['/khach-hang'])
    can(['read', 'update'], ['/san-pham'])
    can(['read'], ['/system'])
    can(['read'], ['/system/channel'])
    // can(['read'], ['/system/trang-chu'])
  }

  if (me?.role === ManagerRole.Owner) {
    can(['read'], ['systemConfig-side-menu'])
    // can(['read', 'update'], ['/nhan-su'])
    can(['read', 'update'], ['/san-pham'])
    can(['read'], ['/system'])
  }

  return build()
}
