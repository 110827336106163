import { Button, message as Message } from 'antd'
import React from 'react'
import { ENotificationLogType } from '@coop/common'
import { FirebaseContext } from '../../contexts/FirebaseContext'
import { Modal } from '../../shared/components'
import { Notification, NotificationGroup } from '../../shared/model'
import { NotificationFormModal } from './NotificationFormModal'

type ItemProps = {
  notificationGroup: NotificationGroup
  onClickSendMessage: () => void
}

const Item: React.FC<ItemProps> = ({ notificationGroup, onClickSendMessage }) => {
  return (
    <div className="flex rounded-sm items-center border border-gray-200 mb-4 p-3 pr-5 bg-white cursor-pointer">
      <div className="px-4 flex-1">
        <p className="text-gray-900">{notificationGroup.name}</p>
        <p className="text-sm">{notificationGroup.description}</p>
      </div>
      <Button className="" type="primary" onClick={onClickSendMessage}>
        Gửi thông báo
      </Button>
    </div>
  )
}

type Props = {
  notifications: NotificationGroup[]
}

export const NotificationPage: React.FC<Props> = ({ notifications }) => {
  const [showModal, setShowModal] = React.useState(false)
  const [topic, setTopic] = React.useState<string>()
  const { functions } = React.useContext(FirebaseContext)
  const notifyTopic = functions.httpsCallable('messaging-notifyTopic')
  const submitNotificationLog = functions.httpsCallable('firestore-logNotifications')

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleOpenModal = (notiGroup: NotificationGroup) => {
    setShowModal(true)
    setTopic(notiGroup.topic)
  }

  const handleSubmit = React.useCallback(
    async ({ title, body, image, shortDesc }: Notification) => {
      if (!topic) {
        Message.error('Chưa chọn nhóm người dùng!')
        return
      }
      try {
        const notification = {
          title,
          body,
          shortDesc,
          image: image || undefined,
        }
        await submitNotificationLog({
          notification: { ...notification, type: ENotificationLogType.all },
        })
        await notifyTopic({
          notification: { ...notification, body: shortDesc },
          topic,
        })
        Message.success('Gửi thông báo thành công!')
      } catch (e) {
        Message.error('Đã có lỗi xảy ra. Vui lòng thử lại sau!')
      } finally {
        setShowModal(false)
      }

      // TODO: put the result in message history
    },
    [notifyTopic, submitNotificationLog, topic],
  )

  return (
    <div className="self-end mb-8 w-full">
      {notifications.map(noti => (
        <Item notificationGroup={noti} key={noti.id} onClickSendMessage={() => handleOpenModal(noti)} />
      ))}

      <Modal isFullScreen title={'Gửi thông báo mới'} isOpen={showModal} onRequestClose={handleCloseModal}>
        <NotificationFormModal onSubmit={handleSubmit} />
      </Modal>
    </div>
  )
}
