import { QueryLazyOptions, QueryResult, useLazyQuery } from '@apollo/react-hooks'
import { fetchMoreCompletedOrdersOptions, ORDERS_QUERY, Query, QueryCompletedOrdersArgs } from '@coop/apollo'

export const useLazyQueryCompletedOrders = ({ variables }: { variables: QueryCompletedOrdersArgs }) => {
  const [getCompletedOrders, result] = useLazyQuery<Pick<Query, 'completedOrders'>>(ORDERS_QUERY, {
    variables,
  })

  const { data, networkStatus, fetchMore } = result

  const handleFetchMore =
    (data?.completedOrders.items.length || 0) < (data?.completedOrders.total_count || 0)
      ? () => {
          const options = fetchMoreCompletedOrdersOptions({ variables, data, networkStatus })
          options && fetchMore && fetchMore(options)
        }
      : undefined

  return [getCompletedOrders, { ...result, fetchMore: handleFetchMore }] as [
    (options?: QueryLazyOptions<QueryCompletedOrdersArgs>) => void,
    Omit<QueryResult<Pick<Query, 'completedOrders'>, QueryCompletedOrdersArgs>, 'fetchMore'> & {
      fetchMore?: () => void
    },
  ]
}
