import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { useLazyQuery } from '@apollo/react-hooks'
import { ProductSkuFilterCondition, PRODUCTS_QUERY, Query } from '@coop/apollo'
import { Form, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { TableRowSelection } from 'antd/lib/table/interface'
import htmlParse from 'html-react-parser'
import React from 'react'
import styled from 'styled-components'
import { useSource } from '../../../contexts/SourceContext'
import { ModalActionGroup, ModalHeader } from '../../../shared/components/Modal'
import { Money } from '../../../shared/components/Money'
import useDebounce from '../../../shared/hooks/useDebounce'
import { usePagination } from '../../../shared/hooks/usePagination'
import { Product } from '../../../shared/model'
import { formatUrl } from '../../../shared/utils'
import FiltersGroup, { FilterInputType } from '../PromoPage/FiltersGroup'

const StyledTable = styled('div')`
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 4px 16px;
  }
`

const columns: ColumnProps<Product>[] = [
  {
    title: 'Sản phẩm',
    render: (_, d) => (
      <div className="flex items-center">
        <img src={formatUrl(d.thumbnail_path)} width="56" alt="" style={{ objectFit: 'contain', height: 56 }} />
        <span className="ml-2">{d.name}</span>
      </div>
    ),
  },
  {
    title: 'SKU',
    dataIndex: 'sku',
    render: (_, data) => <div>{htmlParse(data.sku)}</div>,
  },
  {
    title: 'Tình trạng',
    render: (_, data) => {
      return (
        <div className="font-semibold flex items-center">
          {!data.visibility ? (
            <div className="text-red-600">
              <CloseCircleFilled /> Hết hàng
            </div>
          ) : (
            <div className="text-green-600">
              <CheckCircleFilled /> Còn hàng
            </div>
          )}
        </div>
      )
    },
  },
  {
    title: 'Giá tiền',
    render: (_, d) => <Money value={d.price} />,
    align: 'right',
  },
]

type Props = {
  existingProducts: Product[]
  onProductsAdded: (data: Product[]) => void
}

const ModalDashboardProduct: React.FC<Props> = ({ onProductsAdded, existingProducts }) => {
  const [form] = Form.useForm()
  const [selectedProducts, setSelectedProducts] = React.useState<Product[]>([])

  const [filterInput, setFilterInput] = React.useReducer<React.Reducer<FilterInputType, Partial<FilterInputType>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      text: '',
      category: 'all',
      status: 'all',
    },
  )

  const PAGE_SIZE = 10
  const { pagination, setPagination, renderPagination } = usePagination(
    () => productsData?.products?.total_count || 0,
    1,
    PAGE_SIZE,
    'sản phẩm',
  )

  const debouncedSearch = useDebounce(filterInput.text, 500)

  const { source } = useSource()
  const [getProducts, { data: productsData, loading }] = useLazyQuery<Pick<Query, 'products'>>(PRODUCTS_QUERY)

  React.useEffect(() => {
    getProducts({
      variables: {
        pageSize: pagination.pageSize,
        currentPage: pagination.currentPage,
        searchName: debouncedSearch,
        filter: {
          source_code: source?.source_code as string,
          category_ids: filterInput.category && filterInput.category !== 'all' ? [+filterInput.category] : [],
          skus: { codes: existingProducts.map(p => p.sku), condition: ProductSkuFilterCondition.Nin },
        },
      },
    })
  }, [
    debouncedSearch,
    existingProducts,
    filterInput.category,
    getProducts,
    pagination.currentPage,
    pagination.pageSize,
    source,
  ])

  const handleFilterChange = (key: keyof FilterInputType, value: string | undefined) => {
    setFilterInput({ [key]: value })
    setPagination({ currentPage: 1, pageSize: PAGE_SIZE })
  }

  const handleSubmitForm = () => {
    onProductsAdded(selectedProducts)
  }

  const rowSelection: TableRowSelection<Product> = {
    selectedRowKeys: selectedProducts.map(product => product.sku),
    onSelect: (record, selected) => {
      if (selected) {
        setSelectedProducts([...selectedProducts, record])
      } else {
        setSelectedProducts(selectedProducts.filter(product => product.sku !== record.sku))
      }
    },
    onSelectAll: (selected, _, changeRows) => {
      if (selected) {
        setSelectedProducts([...selectedProducts, ...changeRows])
      } else {
        setSelectedProducts(selectedProducts.filter(product => !changeRows.find(r => r.sku === product.sku)))
      }
    },
  }

  return (
    <Form onFinish={handleSubmitForm} form={form} style={{ width: 900 }} className="flex flex-col flex-1">
      <ModalHeader title={'Thêm sản phẩm'} actionRenderer={<ModalActionGroup submitText={'Lưu'} />} />
      <div className="w-full p-4 flex-1 overflow-y-auto">
        <div className={'text-green-500 mb-2'}>Đã lựa chọn {selectedProducts.length} sản phẩm!</div>
        <FiltersGroup searchValue={filterInput} onValueChange={handleFilterChange} />
        <StyledTable>
          <Table<Product>
            rowKey="sku"
            dataSource={productsData?.products?.items as Product[]}
            loading={loading}
            columns={columns}
            pagination={false}
            scroll={{ y: 400 }}
            rowSelection={rowSelection}
            footer={renderPagination}
          />
        </StyledTable>
      </div>
    </Form>
  )
}

export default ModalDashboardProduct
