import { SearchOutlined } from '@ant-design/icons'
import { OrderDetail, OrderStatus, useLazyQueryOrderByStore } from '@coop/apollo'
import { Button, DatePicker, Input, message, Select, Spin, Table, Typography } from 'antd'
import moment from 'moment'
import React from 'react'
import { CSVLink } from 'react-csv'
import { useSource } from 'src/contexts/SourceContext'
import { TableWrapper } from 'src/shared/components'
import { Order } from 'src/shared/model'
import { dateFormat, offsetUTC, yearFirstFormat } from 'src/shared/utils'
import { magentoStatusCodeToText, paymentCodeToText } from './CodeToText'
import { orderColumn, reportOrderHeader, reportSkuHeader, skuColumn } from './TableColumn'

interface SearchProps {
  kind: string
  fromDate: string
  toDate: string
  orderStatus: string
  searchName: string
}

interface CustomTableProps {
  sourceName?: string
  customerName?: string
  statusText?: string
  paymentText?: string
  addressStreet?: string | null
  customerPhone?: string | null
  STT: number
}

const ExportReport: React.FC = () => {
  const { source } = useSource()

  const [searchValue, setSearchValue] = React.useState<SearchProps>({
    kind: 'order',
    fromDate: moment().format(yearFirstFormat) + ' 00:00:00',
    toDate: moment().format(yearFirstFormat) + ' 23:59:59',
    orderStatus: OrderStatus.Pending,
    searchName: '',
  })
  const [dataBySku, setDataBySku] = React.useState<(Order & any)[]>([])

  const [getOrderByStore, result] = useLazyQueryOrderByStore({
    variables: {
      sourceCode: source?.source_code,
      currentPage: 1,
      ...searchValue,
    },
  })

  const resultOrder = result?.data?.orderByStore?.items || []
  const isOrder = searchValue.kind === 'order'
  const dataToShow =
    resultOrder.map((e: OrderDetail, i: number) => ({
      ...e,
      key: `${e.display_id}_${i}`,
      STT: i + 1,
      sourceName: source?.name,
      customerName: `${e.address.firstname} ${e.address.lastname}`,
      addressStreet: e.address?.street ? e.address?.street[0] : '',
      customerPhone: e.address.telephone,
      statusText: magentoStatusCodeToText(e.status) || '',
      paymentText: paymentCodeToText(e.payment.method) || '',
      created_at: e.created_at ? offsetUTC(e.created_at, '+07:00') : '',
      completed_date: e.completed_date ? offsetUTC(e.completed_date, '+07:00') : '',
    })) || []

  const handleSearch = (ele: any) => {
    const value = ele.target.value
    setSearchValue(init => ({ ...init, searchName: value }))
  }

  React.useEffect(() => {
    if (resultOrder.length > 0) {
      setDataBySku([])
      resultOrder?.forEach((ele, eleIn: number) =>
        ele?.items?.forEach((e, eIn: number) =>
          setDataBySku(init => [
            ...init,
            {
              ...ele,
              ...e,
              key: `order_${eleIn}${eIn}`,
              productName: e.name,
              customerName: `${ele.address.firstname} ${ele.address.lastname}`,
              addressStreet: ele.address?.street ? ele.address?.street[0] : '',
              customerPhone: ele.address.telephone,
              statusText: magentoStatusCodeToText(ele.status) || '',
              paymentText: paymentCodeToText(ele.payment.method) || '',
              created_at: ele.created_at ? offsetUTC(ele.created_at, '+07:00') : '',
              completed_date: ele.completed_date ? offsetUTC(ele.completed_date, '+07:00') : '',
            },
          ]),
        ),
      )
    }
  }, [resultOrder])

  React.useEffect(() => {
    if (dataToShow.length === 0) {
      setDataBySku([])
    }
  }, [dataToShow.length])

  return (
    <div className="flex-1">
      <div className="flex justify-between">
        <div className="flex items-center w-3/5">
          <Typography className="w-40 ">Loại báo cáo</Typography>
          <Select
            defaultValue="order"
            className="mr-4"
            style={{ width: 360 }}
            onChange={value => setSearchValue(init => ({ ...init, kind: value }))}>
            <Select.Option value="order">Order (đơn hàng)</Select.Option>
            <Select.Option value="sku">Chi tiết SKUs</Select.Option>
          </Select>
          <Input placeholder={'Nhập mã đơn hàng để tìm'} onChange={e => handleSearch(e)} prefix={<SearchOutlined />} />
        </div>

        {dataToShow.length === 0 ? (
          <Button type="primary" style={{ width: 120 }} onClick={() => message.warn('Không có dữ liệu để xuất')}>
            Xuất báo cáo
          </Button>
        ) : (
          <CSVLink
            filename={`${source?.source_code}-${moment().format(dateFormat)}.csv`}
            data={isOrder ? dataToShow : dataBySku}
            headers={isOrder ? reportOrderHeader : reportSkuHeader}>
            <Button type="primary" style={{ width: 120 }}>
              Xuất báo cáo
            </Button>
          </CSVLink>
        )}
      </div>
      <div className="flex justify-between mt-6">
        <div className="flex items-center">
          <Typography className="mr-4">Từ ngày</Typography>
          <DatePicker
            className="mr-6 w-48"
            defaultValue={moment()}
            allowClear={false}
            format={dateFormat}
            onChange={value =>
              setSearchValue(init => ({ ...init, fromDate: moment(value).format(yearFirstFormat) + ' 00:00:00' }))
            }
            disabledDate={current =>
              (current && current < moment().subtract(3, 'months')) || current > moment().endOf('day')
            }
          />
          <Typography className="mr-4">Đến ngày</Typography>
          <DatePicker
            className="mr-6 w-48"
            allowClear={false}
            format={dateFormat}
            defaultValue={moment()}
            onChange={value =>
              setSearchValue(init => ({ ...init, toDate: moment(value).format(yearFirstFormat) + ' 23:59:59' }))
            }
            disabledDate={current => current && current > moment().endOf('day')}
          />
          <Typography className="mr-4">Trạng thái đơn hàng</Typography>
          <Select
            defaultValue={OrderStatus.Pending}
            style={{ width: 240 }}
            onChange={value => setSearchValue(init => ({ ...init, orderStatus: value }))}>
            <Select.Option value={OrderStatus.Pending}>{magentoStatusCodeToText(OrderStatus.Pending)}</Select.Option>
            <Select.Option value={OrderStatus.Processing}>
              {magentoStatusCodeToText(OrderStatus.Processing)}
            </Select.Option>
            <Select.Option value={OrderStatus.Complete}>{magentoStatusCodeToText(OrderStatus.Complete)}</Select.Option>
            <Select.Option value={OrderStatus.Canceled}>{magentoStatusCodeToText(OrderStatus.Canceled)}</Select.Option>
            <Select.Option value={OrderStatus.Closed}>{magentoStatusCodeToText(OrderStatus.Closed)}</Select.Option>
          </Select>
        </div>
        <Button type="primary" style={{ width: 120 }} onClick={() => getOrderByStore()}>
          Xem
        </Button>
      </div>

      <div className="mt-4">
        <TableWrapper className="flex-1">
          {result.loading ? (
            <div className="flex justify-center mt-12">
              <Spin size="large" tip="Loading..." className="m-auto" />
            </div>
          ) : (
            <Table<OrderDetail & CustomTableProps>
              rowKey={'key'}
              dataSource={isOrder ? dataToShow : dataBySku}
              columns={isOrder ? orderColumn : skuColumn}
              pagination={{
                pageSize: 20,
                showTotal: (total, range) =>
                  `Hiển thị ${range[0]}-${range[1]} trong ${total} ${searchValue.kind === 'sku' ? 'SKU' : 'Đơn hàng'}`,
              }}
            />
          )}
        </TableWrapper>
      </div>
    </div>
  )
}

export default ExportReport
