import { Input, message, Modal, Row, Select, Typography } from 'antd'
import React from 'react'
import { useFirebase } from 'src/contexts/FirebaseContext'
import { SystemOrder } from 'src/shared/model'

interface modalProps {
  visible: boolean
  setVisible: (e: boolean) => void
  systemOrder: SystemOrder | undefined
}

const ModalCancelOrder = ({ visible, setVisible, systemOrder }: modalProps) => {
  const [loading, setLoading] = React.useState(false)
  const [cancelPosition, setCancelPosition] = React.useState<number>(0)
  const [cancelReason, setCancelReason] = React.useState<string>('')
  const [errorMessage, setErrorMessage] = React.useState<string>('')

  const { functions } = useFirebase()
  const { Option } = Select
  const { TextArea } = Input

  const reasonList = [
    'Hết hàng, siêu thị/ cửa hàng yêu cầu hủy',
    'Khách hàng đặt nhầm sản phẩm',
    'Khách hàng đặt sai địa chỉ giao hàng',
    'Khách hàng đặt sai hình thức thanh toán',
    'Khác',
  ]

  const handleCancelOrder = () => {
    setLoading(true)
    const adminCancelOrder = functions.httpsCallable('firestore-adminCancelOrder')
    const emptyReason = cancelPosition === 4 && cancelReason === ''
    const trueReason = cancelPosition !== 4 ? reasonList[cancelPosition] : cancelReason

    if (emptyReason) {
      setErrorMessage('Lí do không được để trống')
      setLoading(false)
    } else {
      adminCancelOrder({ id: systemOrder?.id, cancelReason: trueReason })
        .then(() => message.success('Đơn hàng được hủy thành công!'))
        .catch(err => message.error(`Hủy đơn thất bại, ${err.message}`))
        .finally(() => setLoading(false))
    }
  }

  return (
    <Modal
      visible={visible}
      confirmLoading={loading}
      closable={false}
      onCancel={() => setVisible(false)}
      onOk={handleCancelOrder}
      cancelText={'Hủy'}
      okText={'Xác nhận'}
      okButtonProps={{ type: 'primary', danger: true }}>
      <Typography style={{ margin: '0 0 8px 0' }}>Lí do hủy :</Typography>
      <Select
        defaultValue={0}
        style={{ width: '100%' }}
        onChange={e => {
          setErrorMessage('')
          setCancelPosition(e)
        }}>
        {reasonList.map((e, i) => (
          <Option key={`reason_${i}`} value={i}>
            {e}
          </Option>
        ))}
      </Select>
      {cancelPosition === 4 && (
        <Row>
          <Typography style={{ margin: '8px 0' }}>Nhập lí do : </Typography>
          <TextArea
            allowClear
            rows={6}
            maxLength={200}
            placeholder="Nhập lí do hủy cho đơn hàng này"
            style={{ borderColor: errorMessage.length > 0 ? 'red' : '#1890ff' }}
            onChange={e => {
              setCancelReason(e.target.value)
              setErrorMessage('')
            }}
          />
        </Row>
      )}
      {errorMessage.length > 0 && <Typography.Text type="danger">{errorMessage}</Typography.Text>}
    </Modal>
  )
}

export default ModalCancelOrder
