import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import ForgotPassword from './ForgotPassword'
import Login from './Login'

type Props = {}

const Auth: React.FC<Props> = () => {
  const { user, loading } = useAuth()

  if (!loading && user) {
    return <Redirect to="/" />
  }

  return (
    <Switch>
      <Route path="/xac-thuc/dang-nhap/:email?" component={Login} />
      <Route path="/xac-thuc/quen-mat-khau" component={ForgotPassword} />
      <Redirect to="/xac-thuc/dang-nhap" />
    </Switch>
  )
}

export default Auth
