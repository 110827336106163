import React from 'react'
import axios, { Method, AxiosResponse } from 'axios'
import { pickBy } from 'lodash'
import { User, UserAuthenResult } from '../../shared/model'
import { useFirebase } from '../../contexts/FirebaseContext'
import env from '../Env'

export type UserTokenType = {
  loading: boolean
  uid?: string
  jwtToken?: string
  error?: Error
}

export const useUserToken = (customer?: User): UserTokenType => {
  const { functions } = useFirebase()
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState()
  const [authenResult, setAuthenResult] = React.useState<UserAuthenResult>()
  const adminCreateCustomer = functions.httpsCallable('firestore-adminCreateCustomer')
  const [jwtToken, setJwtToken] = React.useState<string>()

  const getUserToken = React.useCallback(async () => {
    const result = await adminCreateCustomer(
      pickBy(
        {
          phoneNumber: customer?.phone,
          name: customer?.name,
          email: customer?.email,
        },
        // eslint-disable-next-line no-undef
        _.identity,
      ),
    )
    setAuthenResult({ ...result.data } as UserAuthenResult)
    const result2 = await axios({
      url: `https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyCustomToken?key=${env.firebaseApiKey}`,
      method: 'post',
      data: {
        token: result.data.accessToken,
        returnSecureToken: true,
      },
    })

    setJwtToken(result2.data.idToken)
    setLoading(false)
  }, [customer, adminCreateCustomer])

  React.useEffect(() => {
    if (!loading && customer && !authenResult) {
      setLoading(true)
      getUserToken()
        .catch(err => setError(err))
        .finally(() => setLoading(false))
    }
  }, [loading, customer, getUserToken, authenResult])

  return { loading, uid: authenResult?.uid, jwtToken, error }
}

export const useCloudFunctionApi = (functionName: string, jwtToken: string) => {
  const [loading, setLoading] = React.useState(false)
  const [result, setResult] = React.useState()
  const executeApi = React.useCallback(
    async (data: any) => {
      setLoading(true)
      try {
        const _result: AxiosResponse = await axios({
          url: `${env.functionApi}/${functionName}`,
          method: 'post',
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
          data: {
            data,
          },
        })
        setResult(_result.data.result)
        return _result.data.result
      } finally {
        setLoading(false)
      }
    },
    [functionName, jwtToken],
  )

  return { loading, result, executeApi }
}

export const useMagentoApi = (jwtToken: string) => {
  const [loading, setLoading] = React.useState(false)
  const [result, setResult] = React.useState()
  const executeApi = React.useCallback(
    async (endpoint: string, method: Method, data: any) => {
      setLoading(true)
      try {
        // const _result = await fetch(`${env.serverUrl}/rest/${endpoint}`, {
        //   method,
        //   mode: 'no-cors',
        //   // withCredentials: true,
        //   // credentials: 'same-origin',
        //   // crossdomain: true,
        //   headers: {
        //     Authorization: `Bearer ${jwtToken}`,
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify(data),
        // })
        // const json = await _result.json()
        // setResult(json)
        // return json
        const _result: AxiosResponse = await axios({
          url: `${env.serverUrl}/rest/${endpoint}`,
          method,
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
          },
          data,
        })
        setResult(_result.data)
        return _result.data
      } finally {
        setLoading(false)
      }
    },
    [jwtToken],
  )

  return { loading, result, executeApi }
}
