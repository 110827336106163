import { Button, Col, Form, Input, InputNumber, message, Row, Switch } from 'antd'
import React from 'react'
import { useSource } from 'src/contexts/SourceContext'

const StoreControlPage: React.FC<{}> = () => {
  const [form] = Form.useForm()
  const { source, updateCurrentStore } = useSource()
  const [loading, setLoading] = React.useState(false)
  const [enableOperationTime, setEnableOperationTime] = React.useState(false)

  const handleSubmitForm = (formValues: any) => {
    setLoading(true)
    const { openingTime, closingTime } = formValues
    const operationTime = { openingTime, closingTime }
    const dataToPost = enableOperationTime ? { ...formValues, ...operationTime } : { ...formValues }

    setLoading(false)
    updateCurrentStore({ ...dataToPost })
      .then(() => {
        message.success('Cập nhật thông tin thành công')
        message.loading('...đang tải lại')
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      })
      .catch(() => message.error('Cập nhật thông tin thất bại!'))
      .finally(() => setLoading(false))
  }

  React.useEffect(() => {
    setEnableOperationTime(!!(source?.openingTime && source?.closingTime))
    form.setFieldsValue({ ...source })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source])

  return (
    <Form labelCol={{ span: 8, xl: { span: 6 } }} form={form} onFinish={handleSubmitForm}>
      <Row>
        <Col flex={1}>
          <Form.Item label=" " colon={false}>
            <Button loading={loading} className="mt-12" type="primary" htmlType="submit">
              Lưu thay đổi
            </Button>
          </Form.Item>
          <Form.Item
            name="phone"
            label="Số điện thoại"
            rules={[
              () => ({
                validator(_, value) {
                  return !value
                    ? Promise.reject(new Error('Số điện thoại không được để trống'))
                    : !/^[0-9]+$/.test(value)
                    ? Promise.reject(new Error('Số điện thoại chỉ gồm số'))
                    : Promise.resolve()
                },
              }),
            ]}>
            <Input type="text" placeholder="Nhập số điện thoại" />
          </Form.Item>
          <Form.Item label="Thời gian hoạt động">
            <Switch checked={enableOperationTime} onChange={() => setEnableOperationTime(!enableOperationTime)} />
          </Form.Item>
          {enableOperationTime && (
            <>
              <Form.Item
                name="openingTime"
                label="Giờ mở cửa"
                rules={[
                  () => ({
                    validator(_, value) {
                      return value === 0
                        ? Promise.resolve()
                        : !value
                        ? Promise.reject(new Error('Phải nhập giờ mở cửa'))
                        : value > 23
                        ? Promise.reject(new Error('Giờ mở cửa phải nhỏ hơn 23'))
                        : Promise.resolve()
                    },
                  }),
                ]}>
                <InputNumber min={0} type="number" />
              </Form.Item>
              <Form.Item
                name="closingTime"
                label="Giờ đóng cửa"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      return !value && value !== 0
                        ? Promise.reject(new Error('Phải nhập giờ đóng cửa'))
                        : value > 23
                        ? Promise.reject(new Error('Giờ đóng cửa phải nhỏ hơn 23'))
                        : getFieldValue('openingTime') >= value
                        ? Promise.reject(new Error('Giờ đóng cửa phải sau giờ mở cửa'))
                        : Promise.resolve()
                    },
                  }),
                ]}>
                <InputNumber min={0} type="number" />
              </Form.Item>
            </>
          )}
        </Col>
        <Col flex={2} />
      </Row>
    </Form>
  )
}

export default StoreControlPage
