import { Modal, Radio, Spin } from 'antd'
import { FormInstance } from 'antd/lib/form/Form'
import React, { useState } from 'react'
import { Order } from 'src/shared/model'
import { currencyFormat } from 'src/shared/utils'

interface modalProps {
  visible: boolean
  feeLoading: boolean
  setVisible: (e: boolean) => void
  setMustSelectTransport: (e: boolean) => void
  order: Order & any
  form: FormInstance
}

const ModalTransportFee = ({ visible, feeLoading, setVisible, setMustSelectTransport, order, form }: modalProps) => {
  const allDataForm = form.getFieldsValue(['address', 'lat', 'lng', 'name', 'mobile', 'transportList', 'fee'])

  const radioDefault =
    `${order.transport?.type.toLocaleLowerCase()}` +
    '_' +
    `${order.transport?.serviceId.toLocaleLowerCase()}` +
    '_' +
    `${allDataForm.fee}`

  const [radioState, setRadioState] = useState(radioDefault)

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={() => setVisible(false)}
      onOk={() => {
        setVisible(false)
        form.setFieldsValue({ partner: radioState.split('_')[0], fee: radioState.split('_')[2] })
      }}
      style={{ top: 50, width: 500 }}
      cancelText={'Hủy'}
      okText={'Xác nhận'}
      bodyStyle={{ maxHeight: 800, overflowY: 'scroll' }}>
      {feeLoading ? (
        <Spin />
      ) : allDataForm?.transportList[0]?.type?.length === 0 ? (
        <div>Không tìm thấy đơn vị vận chuyển phù hợp</div>
      ) : (
        <Radio.Group name="transport_fee" defaultValue={radioState}>
          {allDataForm?.transportList?.map((e: any, i: number) =>
            e?.type?.map((ex: any, ix: number) => (
              <Radio
                key={`fee_${i}_${ix}`}
                value={`${e.title.toLocaleLowerCase()}_${ex._id.toLocaleLowerCase()}_${ex.shippingFee}`}
                style={{ marginBottom: 16, whiteSpace: 'normal' }}
                onChange={(ele: any) => {
                  setRadioState(ele.target.value)
                  setMustSelectTransport(false)
                }}>
                <div style={{ float: 'right', width: 400 }}>
                  <div style={{ position: 'relative', width: 400 }}>
                    <p style={{ fontWeight: 700 }}>
                      {e.title} - {ex.title}
                    </p>
                    <p>{ex.description}</p>
                    <p style={{ position: 'absolute', right: 0, top: 0 }}>{currencyFormat(ex.shippingFee)}đ</p>
                  </div>

                  <p style={{ fontWeight: 500 }}>{ex.subTitle}</p>
                </div>
              </Radio>
            )),
          )}
        </Radio.Group>
      )}
    </Modal>
  )
}

export default ModalTransportFee
