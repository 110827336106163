import { Affix, Button, Dropdown, Menu, Spin } from 'antd'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as Logo } from '../../assets/img/logo.svg'
import { useAuth } from '../../contexts/AuthContext'
import { SelectStore } from './SelectStore'
import TopMenu from './TopMenu'

const Wrapper = styled.div`
  background: #111226;
`
const UserProfile = styled(Button)``

type Props = {}

const Header: React.FC<Props> = () => {
  const { user, signOut } = useAuth()
  const history = useHistory()

  const menu = (
    <Menu>
      {/* <Menu.Item key="0">Thông tin tài khoản</Menu.Item> */}
      {/* <Menu.Divider /> */}
      <Menu.Item key="1" onClick={() => signOut()}>
        Đăng xuất
      </Menu.Item>
    </Menu>
  )

  return (
    <Affix offsetTop={0}>
      <Wrapper className="text-white h-16 flex items-center justify-between px-6 shadow-md">
        <div className="h-full flex items-center">
          <Link to="/home">
            <Logo className="p-2 rounded-lg" width={100} height={48} onClick={() => history.push('/home')} />
          </Link>
          <div className="w-4" />
          <TopMenu />
        </div>
        <div className="flex flex-row">
          <SelectStore />
          <Dropdown overlay={menu} trigger={['click']} className="ml-2">
            <UserProfile ghost>{user ? user.email : <Spin />}</UserProfile>
          </Dropdown>
        </div>
      </Wrapper>
    </Affix>
  )
}

export default Header
