import { ProfileCol } from '@coop/common'
import { Col, DatePicker, Form, Input, Row } from 'antd'
import Title from 'antd/lib/typography/Title'
import { firestore } from 'firebase'
import { FormikProvider, useFormik } from 'formik'
import moment from 'moment'
import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import styled from 'styled-components'
// @ts-ignore
import tw from 'tailwind.macro'
import * as Yup from 'yup'
import { useFirestore } from '../../contexts/FirestoreContext'
import { UploadImage } from '../../shared/components'
import { ModalActionGroup, ModalHeader } from '../../shared/components/Modal'
import { useUpload } from '../../shared/hooks/useUpload'
import { Driver, Profile } from '../../shared/model'
import { formatPhoneNumber, validatePhoneNumber } from '../../shared/utils'

const Wrapper = styled('div').attrs({ className: 'w-full' })`
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    margin: 0;
  }
`

const StyledCol = styled(Col)`
  .ant-upload img {
    width: 128px;
    /* height: 128px; */
  }
`

const FormItem = styled(Form.Item).attrs({ className: 'mb-0 border border-gray-400' })`
  margin-bottom: -1px;
  .ant-form-item-label {
    ${tw`bg-gray-200 pl-4 flex items-center border-r border-gray-400`}
  }
  input {
    ${tw`h-12 border-transparent`}
  }
  .ant-form-item-explain {
    ${tw`pl-2`}
  }
`

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const defaultValues = {
  name: '',
  avatar: '',
  phone: '',
  plate: {
    code: '',
    color: '',
    type: '',
  },
  disabled: false,
  isOl: false,
  profile: {
    tin: '',
    permanentAddress: '',
    identity: {
      id: '',
      issuedDate: null,
      issuedAddress: '',
      backPicture: '',
      frontPicture: '',
    },
    driverLicense: {
      backPicture: '',
      frontPicture: '',
    },
    bankAccount: {
      name: '',
      accountNumber: '',
    },
  },
}

const validationSchema = Yup.object().shape<Partial<DriverDto>>({
  name: Yup.string().required('Vui lòng nhập tên!'),
  phone: Yup.string()
    .required('Vui lòng nhập số điện thoại!')
    .test('phone', 'Số điện thoại chưa chính xác!', validatePhoneNumber),
  plate: Yup.object().shape({
    code: Yup.string().required('Vui lòng nhập biển số!'),
    color: Yup.string().required('Vui lòng nhập màu xe'),
    type: Yup.string().required('Vui lòng nhập loại xe!'),
  }),
  avatar: Yup.string().required('Vui lòng chọn ảnh!'),
  profile: Yup.object().shape({
    tin: Yup.string().required('Vui lòng nhập số mã số thuế'),
    permanentAddress: Yup.string().required('Vui lòng nhập địa chỉ thường trú'),
    identity: Yup.object().shape({
      id: Yup.string().required('Vui lòng nhập số CMND'),
      issuedDate: Yup.mixed().required('Vui lòng nhập ngày cấp CMND'),
      issuedAddress: Yup.string().required('Vui lòng nhập địa chỉ cấp CMND'),
      frontPicture: Yup.string().required('Vui lòng chọn ảnh mặt trước CMND'),
      backPicture: Yup.string().required('Vui lòng chọn ảnh mặt sau CMND'),
    }),
    driverLicense: Yup.object().shape({
      frontPicture: Yup.string().required('Vui lòng chọn ảnh mặt trước bằng lái xe'),
      backPicture: Yup.string().required('Vui lòng chọn ảnh mặt sau bằng lái xe'),
    }),
    bankAccount: Yup.object().shape({
      name: Yup.string().required('Vui lòng nhập tên chủ thẻ'),
      accountNumber: Yup.string().required('Vui lòng nhập số tài khoản'),
    }),
  }),
})

type ProfileDto = Omit<Profile, 'id'>
export type DriverDto = Driver & { profile: ProfileDto }

type Props = {
  data?: Driver
  onSubmit: (data: DriverDto) => Promise<void>
}

export const DriverFormModal: React.FC<Props> = ({ data, onSubmit }) => {
  const [formLoading, setFormLoading] = React.useState(false)
  const formik = useFormik<DriverDto>({
    initialValues: ((data ? { ...defaultValues, ...data } : defaultValues) as unknown) as DriverDto,
    onSubmit: (formData: DriverDto) => {
      setFormLoading(true)
      onSubmit({
        ...formData,
        phone: formatPhoneNumber(formData.phone),
      }).finally(() => setFormLoading(false))
    },
    validationSchema,
  })

  const { values, setFieldValue, handleSubmit, errors, touched } = formik
  const { Data } = useFirestore()

  React.useEffect(() => {
    const loadProfile = async () => {
      let driverProfile
      try {
        if (!data) {
          throw Error()
        }
        const snapshot = await Data.profile(Data.driver().doc(data.id)).doc(ProfileCol).get()
        driverProfile = snapshot.exists ? (snapshot.data() as ProfileDto) : undefined
      } catch {
      } finally {
        driverProfile && setFieldValue('profile', driverProfile)
      }
    }

    loadProfile()
  }, [Data, data, setFieldValue])

  const avatar = useUpload()
  const frontPicture = useUpload()
  const backPicture = useUpload()
  const frontLicense = useUpload()
  const backLicense = useUpload()
  React.useEffect(() => {
    if (avatar.url) {
      setFieldValue('avatar', avatar.url)
    }
    if (frontPicture.url) {
      setFieldValue('profile.identity.frontPicture', frontPicture.url)
    }
    if (backPicture.url) {
      setFieldValue('profile.identity.backPicture', backPicture.url)
    }
    if (frontLicense.url) {
      setFieldValue('profile.driverLicense.frontPicture', frontLicense.url)
    }
    if (backLicense.url) {
      setFieldValue('profile.driverLicense.backPicture', backLicense.url)
    }
  }, [avatar.url, backLicense.url, backPicture.url, frontLicense.url, frontPicture.url, setFieldValue])

  return (
    <Wrapper>
      <FormikProvider value={formik}>
        <Form
          {...layout}
          onFinish={() => handleSubmit()}
          name="driver-form"
          labelAlign="left"
          colon={false}
          className="flex flex-1 flex-col overflow-hidden h-full">
          <ModalHeader
            title="Thêm tài xế"
            actionRenderer={<ModalActionGroup submitText={'Lưu'} loading={formLoading} />}
          />
          <PerfectScrollbar>
            <div className="w-full mx-auto p-4" style={{ maxWidth: '960px' }}>
              <Row gutter={16} className="flex-wrap-reverse">
                <Col flex={1}>
                  <Title level={4}>Thông tin</Title>
                  <FormItem
                    label="Họ và Tên"
                    required
                    validateStatus={errors.name && touched.name ? 'error' : 'validating'}
                    help={touched.name && errors.name}>
                    <Input
                      name="name"
                      value={values.name}
                      onChange={e => setFieldValue('name', e.target.value)}
                      placeholder="Họ và tên tài xế"
                    />
                  </FormItem>
                  <FormItem
                    label="Số điện thoại"
                    required
                    validateStatus={errors.phone && touched.phone ? 'error' : 'validating'}
                    help={touched.phone && errors.phone}>
                    <Input
                      name="phone"
                      value={values.phone}
                      onChange={!data ? e => setFieldValue('phone', e.target.value) : undefined}
                      disabled={!!data}
                      placeholder="Số điện loại liên hệ"
                    />
                  </FormItem>
                  <FormItem
                    label="Mã số thuế"
                    required
                    validateStatus={errors.profile?.tin && touched.profile?.tin ? 'error' : 'validating'}
                    help={touched.profile?.tin && errors.profile?.tin}>
                    <Input
                      name="phone"
                      value={values.profile?.tin}
                      onChange={!data ? e => setFieldValue('profile.tin', e.target.value) : undefined}
                      disabled={!!data}
                      placeholder="Mã số thuế"
                    />
                  </FormItem>
                  <br />
                  <Title level={4}>Thông tin xe đăng ký</Title>
                  <FormItem
                    label="Biển kiểm soát"
                    required
                    validateStatus={errors.plate?.code && touched.plate?.code ? 'error' : 'validating'}
                    help={touched.plate?.code && errors.plate?.code}>
                    <Input
                      name="plate.code"
                      value={values.plate.code}
                      onChange={e => setFieldValue('plate.code', e.target.value)}
                      placeholder="Biển kiểm soát"
                    />
                  </FormItem>
                  <FormItem
                    label="Loại xe"
                    required
                    validateStatus={errors.plate?.type && touched.plate?.type ? 'error' : 'validating'}
                    help={touched.plate?.type && errors.plate?.type}>
                    <Input
                      name="plate.type"
                      value={values.plate.type}
                      onChange={e => setFieldValue('plate.type', e.target.value)}
                      placeholder="Yamaha"
                    />
                  </FormItem>
                  <FormItem
                    label="Màu sắc"
                    required
                    validateStatus={errors.plate?.color && touched.plate?.color ? 'error' : 'validating'}
                    help={touched.plate?.color && errors.plate?.color}>
                    <Input
                      name="plate.color"
                      value={values.plate.color}
                      onChange={e => setFieldValue('plate.color', e.target.value)}
                      placeholder="Đen"
                    />
                  </FormItem>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        wrapperCol={{ span: 24 }}
                        validateStatus={
                          // @ts-ignore
                          errors.profile?.driverLicense?.frontPicture && touched.profile?.driverLicense?.frontPicture
                            ? 'error'
                            : 'validating'
                        }
                        help={
                          // @ts-ignore
                          touched.profile?.driverLicense?.frontPicture && errors.profile?.driverLicense?.frontPicture
                        }>
                        <div className="border-gray-400 border p-4 w-full">
                          <div className="text-gray-500 mb-2 flex w-full justify-center uppercase">
                            Mặt trước Bằng lái xe
                          </div>
                          <UploadImage
                            upload={frontLicense.upload}
                            uploading={frontLicense.uploading}
                            url={values.profile.driverLicense?.frontPicture}
                            ratio={9 / 16}
                          />
                        </div>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        wrapperCol={{ span: 24 }}
                        validateStatus={
                          // @ts-ignore
                          errors.profile?.driverLicense?.backPicture && touched.profile?.driverLicense?.backPicture
                            ? 'error'
                            : 'validating'
                        }
                        help={
                          // @ts-ignore
                          touched.profile?.driverLicense?.backPicture && errors.profile?.driverLicense?.backPicture
                        }>
                        <div className="border-gray-400 border p-4 w-full border-l-0">
                          <div className="text-gray-500 mb-2 flex w-full justify-center uppercase">
                            Mặt sau Bằng lái xe
                          </div>
                          <UploadImage
                            upload={backLicense.upload}
                            uploading={backLicense.uploading}
                            url={values.profile.driverLicense?.backPicture}
                            ratio={9 / 16}
                          />
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Title level={4}>Giấy tờ tùy thân</Title>
                      <FormItem
                        label="Địa chỉ"
                        required
                        validateStatus={
                          errors.profile?.permanentAddress && touched.profile?.permanentAddress ? 'error' : 'validating'
                        }
                        help={touched.profile?.permanentAddress && errors.profile?.permanentAddress}>
                        <Input
                          placeholder="Địa chỉ thường trú"
                          value={values.profile.permanentAddress}
                          onChange={e => setFieldValue('profile.permanentAddress', e.target.value)}
                        />
                      </FormItem>
                      <FormItem
                        label="Số CMND"
                        required
                        validateStatus={
                          errors.profile?.identity?.id && touched.profile?.identity?.id ? 'error' : 'validating'
                        }
                        help={touched.profile?.identity?.id && errors.profile?.identity?.id}>
                        <Input
                          placeholder="Nhập số CMND"
                          value={values.profile.identity.id}
                          onChange={e => setFieldValue('profile.identity.id', e.target.value)}
                        />
                      </FormItem>
                      <FormItem
                        label="Ngày cấp"
                        required
                        validateStatus={
                          errors.profile?.identity?.issuedDate && touched.profile?.identity?.issuedDate
                            ? 'error'
                            : 'validating'
                        }
                        help={touched.profile?.identity?.issuedDate && errors.profile?.identity?.issuedDate}>
                        <DatePicker
                          className="border-transparent w-full"
                          placeholder="Nhập ngày cấp"
                          value={
                            values.profile.identity?.issuedDate
                              ? moment(values.profile.identity?.issuedDate?.toMillis())
                              : undefined
                          }
                          onChange={value =>
                            value !== null &&
                            setFieldValue(
                              'profile.identity.issuedDate',
                              firestore.Timestamp.fromMillis(value.valueOf()),
                            )
                          }
                        />
                      </FormItem>
                      <FormItem
                        label="Nơi cấp"
                        required
                        validateStatus={
                          errors.profile?.identity?.issuedAddress && touched.profile?.identity?.issuedAddress
                            ? 'error'
                            : 'validating'
                        }
                        help={touched.profile?.identity?.issuedAddress && errors.profile?.identity?.issuedAddress}>
                        <Input
                          placeholder="Nơi cấp CMND"
                          value={values.profile.identity.issuedAddress}
                          onChange={e => setFieldValue('profile.identity.issuedAddress', e.target.value)}
                        />
                      </FormItem>
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            wrapperCol={{ span: 24 }}
                            validateStatus={
                              errors.profile?.identity?.frontPicture && touched.profile?.identity?.frontPicture
                                ? 'error'
                                : 'validating'
                            }
                            help={touched.profile?.identity?.frontPicture && errors.profile?.identity?.frontPicture}>
                            <div className="border-gray-400 border p-4 w-full">
                              <div className="text-gray-500 mb-2 flex w-full justify-center uppercase">
                                Mặt trước CMND
                              </div>
                              <UploadImage
                                upload={frontPicture.upload}
                                uploading={frontPicture.uploading}
                                url={values.profile.identity.frontPicture}
                                ratio={9 / 16}
                              />
                            </div>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            wrapperCol={{ span: 24 }}
                            validateStatus={
                              errors.profile?.identity?.backPicture && touched.profile?.identity?.backPicture
                                ? 'error'
                                : 'validating'
                            }
                            help={touched.profile?.identity?.backPicture && errors.profile?.identity?.backPicture}>
                            <div className="border-gray-400 border p-4 w-full border-l-0">
                              <div className="text-gray-500 mb-2 flex w-full justify-center uppercase">
                                Mặt sau CMND
                              </div>
                              <UploadImage
                                upload={backPicture.upload}
                                uploading={backPicture.uploading}
                                url={values.profile.identity.backPicture}
                                ratio={9 / 16}
                              />
                            </div>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Title level={4}>Tài khoản ngân hàng HDBank</Title>
                      <FormItem
                        label="Tên chủ thẻ"
                        required
                        validateStatus={
                          // @ts-ignore
                          errors.profile?.bankAccount?.name && touched.profile?.bankAccount?.name
                            ? 'error'
                            : 'validating'
                        }
                        // @ts-ignore
                        help={touched.profile?.bankAccount?.name && errors.profile?.bankAccount?.name}>
                        <Input
                          placeholder="Tên chủ thẻ"
                          value={values.profile.bankAccount?.name}
                          onChange={e => setFieldValue('profile.bankAccount.name', e.target.value)}
                        />
                      </FormItem>
                      <FormItem
                        label="Số tài khoản"
                        required
                        validateStatus={
                          // @ts-ignore
                          errors.profile?.bankAccount?.accountNumber && touched.profile?.bankAccount?.accountNumber
                            ? 'error'
                            : 'validating'
                        }
                        help={
                          // @ts-ignore
                          touched.profile?.bankAccount?.accountNumber && errors.profile?.bankAccount?.accountNumber
                        }>
                        <Input
                          placeholder="Số tài khoản"
                          value={values.profile.bankAccount?.accountNumber}
                          onChange={e => setFieldValue('profile.bankAccount.accountNumber', e.target.value)}
                        />
                      </FormItem>
                      <div className="h-10" />
                    </Col>
                  </Row>
                </Col>
                <StyledCol>
                  <div className="text-gray-500 mb-2 flex w-full justify-center uppercase">Ảnh đại diện</div>
                  <UploadImage upload={avatar.upload} uploading={avatar.uploading} url={values.avatar} />
                  <div className="text-red-500">{touched.avatar && errors.avatar}</div>
                </StyledCol>
              </Row>
            </div>
          </PerfectScrollbar>
        </Form>
      </FormikProvider>
    </Wrapper>
  )
}
